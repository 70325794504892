import React from 'react';

import {
    REGION_PAGE_TABS
} from '../../../constants/RegionPage.constants';

export default class RegionPageTabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.tabClicked = this.tabClicked.bind(this);
    }
    tabClicked(event)  {
        if (this.props.tabClicked) {
            this.props.tabClicked(event.target.value);
        }
    }
    render() {
        return (
            <div className="tds-product-page-tab-menu-container">
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                    <div className="td-multi-single-select-buttons-group">
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3Group"
                                value={REGION_PAGE_TABS.REAL_TIME_COVERAGE} 
                                checked={this.props.tab === REGION_PAGE_TABS.REAL_TIME_COVERAGE}
                                id="selectButtonSegmented3-1" />
                            <label htmlFor="selectButtonSegmented3-1" title="Real-Time Coverage">Real-Time Coverage</label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3Group"
                                value={REGION_PAGE_TABS.MOST_POPULAR} 
                                checked={this.props.tab === REGION_PAGE_TABS.MOST_POPULAR}
                                id="selectButtonSegmented3-2" />
                            <label htmlFor="selectButtonSegmented3-2" title="Most Popular">Most Popular</label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3Group"
                                value={REGION_PAGE_TABS.TRADE_IDEAS} 
                                checked={this.props.tab === REGION_PAGE_TABS.TRADE_IDEAS}
                                id="selectButtonSegmented3-3" />
                            <label htmlFor="selectButtonSegmented3-3" title="Trade Ideas">Trade Ideas</label>
                        </div>
                    </div>
                </fieldset>
            </div>
            
        )
    }
}

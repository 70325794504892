import React from 'react';
import {
    COMPANY_DETAILS_TABS
} from '../../constants/CompanyDetails.constants';
import {isCowen, isCowenEnabled} from "../../utils/valueUtils";
import CowenFlagContext from "../../system/CowenFlagContext";

export default class CompanyDetailsTabComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {cowenEnabled: false};
        this.tabChange = this.tabChange.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r });
        }).catch(e => {
            console.log(e);
        });

    }
    tabChange(event) {
        if (this.props.tabChange) {
            this.props.tabChange(event.target.value);
        }
    }
    render() {
        const cowenFlag = this.context;
        return (
            <div className="tds-company-details-page-tab-container">
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                    <div className="td-multi-single-select-buttons-group">
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabChange}
                                value={COMPANY_DETAILS_TABS.ACTION_NOTES}
                                checked={this.props.tab === COMPANY_DETAILS_TABS.ACTION_NOTES}
                                name="selectButtonSegmented3Group"
                                id="selectButtonSegmented3-1" />
                            <label 
                                htmlFor="selectButtonSegmented3-1"
                                title="Action Notes"
                            >
                                {isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? "Company Notes" : "Action Notes"}
                            </label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabChange}
                                value={COMPANY_DETAILS_TABS.INDUSTRY_NOTES}
                                checked={this.props.tab === COMPANY_DETAILS_TABS.INDUSTRY_NOTES}
                                name="selectButtonSegmented3Group"
                                id="selectButtonSegmented3-2" />
                            <label 
                                htmlFor="selectButtonSegmented3-2"
                                title="Industry Research - Notes/Bulletins"
                                className="industry-research"
                            >
                                Industry Research - Notes/Bulletins
                            </label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabChange}
                                value={COMPANY_DETAILS_TABS.FLASH_NOTES}
                                checked={this.props.tab === COMPANY_DETAILS_TABS.FLASH_NOTES}
                                name="selectButtonSegmented3Group"
                                id="selectButtonSegmented3-3" />
                            <label 
                                htmlFor="selectButtonSegmented3-3"
                                title="Flash Notes/Fast Facts - Company/Industry"    
                            >
                                {isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? "Quick Takes Fast Facts - Company/Industry" : "Flash Notes/Fast Facts - Company/Industry"}
                            </label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabChange}
                                value={COMPANY_DETAILS_TABS.COMPANY_REPORT}
                                checked={this.props.tab === COMPANY_DETAILS_TABS.COMPANY_REPORT}
                                name="selectButtonSegmented3Group"
                                id="selectButtonSegmented3-4" />
                            <label 
                                htmlFor="selectButtonSegmented3-4"
                                title="Indepth Company Report"
                            >
                                Indepth Company Report
                            </label>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }
}

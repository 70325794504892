import React from 'react';
import AnalystCardComponent from '../analyst-card/AnalystCard.component';
import ErrorComponent from '../../misc/error/Error.component';
import {
    ANALYST_SEARCH_ALL,
    ANALYST_SEARCH_GLOBAL_STRATEGY,
    ANALYST_SEARCH_LAST_NAME,
    ANALYST_SEARCH_ASC
} from '../../../constants/Analyst.constants';
import {
    ANALYST_PAGE_TITLE
} from '../../../constants/PageTitle.constants';
import {
    ERROR_TYPE, FIRM_NAME_COWEN
} from '../../../constants/Misc.constants';
import {
    ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB,
    ACL_ANALYST_TEAMS_EQUITIES_TAB
} from '../../../constants/Entitlements.constants';
import SideBar from '../../side-bar/SideBar.component';
import Spinner from '../../spinner/Spinner.component';
import AnalystService from '../../../services/Analyst.service';
import UserService from '../../../services/User.service';

export default class AllAnalystsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysts: null,
            loading: true,
            error: false,
        };
        this.ficmAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB);
        this.eqAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_EQUITIES_TAB);
        if (this.ficmAccess && this.eqAccess) {
            this.state.firm = ANALYST_SEARCH_ALL;
        } else if (this.ficmAccess && !this.eqAccess) {
            this.state.firm = ANALYST_SEARCH_GLOBAL_STRATEGY;
        } else if (this.eqAccess && !this.ficmAccess) {
            this.state.firm = FIRM_NAME_COWEN;
        }

    }
    componentDidMount() {
        document.title = ANALYST_PAGE_TITLE;
        this.getAnalysts();
    }
    getAnalysts() {
        var params = {
            searchType: ANALYST_SEARCH_ALL,
            orderBy: ANALYST_SEARCH_LAST_NAME,
            direction: ANALYST_SEARCH_ASC,
            firm: this.state.firm,
            page: 0,
            size: 1000
        }
        AnalystService.getAnalysts(params).then((_analysts) => {
            this.setState({
                analysts: _analysts,
                loading: false
            });

        }).catch((error) => {
            this.setState({
                analysts: null,
                loading: false,
                error: true
            });
        });
    }
    render() {
        return (
            <section className="tds-analyst">
                <div className="tds-analyst-container">
                    <div className="tds-three-column-container">
                        <h2>Analysts</h2>
                        {
                            this.state.loading &&
                            <Spinner minHeight={1200} />

                        }
                        {
                            !this.state.loading && this.state.error &&
                            <div className="tds-analyst-no-results">
                                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                            </div>
                        }
                        {
                            !this.state.loading && !this.state.error && this.state.analysts.length > 0 &&
                            <div className="tds-analyst-results">
                                {
                                    this.state.analysts.map((analystItem, index) => {
                                        return <AnalystCardComponent
                                            key={analystItem.firstName + index}
                                            analyst={analystItem}
                                            hideAddToContacts={true}
                                            hidePhone={true}
                                            hideEmail={true} />
                                    })
                                }
                            </div>
                        }
                        {
                            !this.state.loading && !this.state.error && this.state.analysts &&
                            this.state.analysts.length === 0 &&
                            <div className="tds-analyst-no-results">
                                <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                            </div>
                        }
                    </div>
                    <SideBar />
                </div>
            </section>
        )
    }
}

import React from "react";
import {
  LINK_TDSECURITIES
} from "../../../constants/LinkPaths.constants";

const LandingSubPageHeaderComponent = (props) => {
  return (
    <header>
      <div className="td-bg-colour-2 tds-header">
        <div className="tds-container">
          <div className="tds-section-left">
            <span className="tds-logo">
              <a 
                href={LINK_TDSECURITIES}
                title="TD Securities"
              >
              <img
                src="/assets/img/tds_shield.svg"
                title="TD Securities"
                alt="TD Securities"
              />
              </a>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingSubPageHeaderComponent;

import React from 'react';
import { randomNumber } from '../../../utils/valueUtils';

export default class StickyElementComponent extends React.Component {
    element = null;
    stickyOffset = null;
    boundingClientRect = null;
    id = null;
    constructor(props) {
        super(props);
        this.state = {
            sticky: false
        }
        this.id = 'sticky-element-' + Math.floor(randomNumber() * Math.floor(1000000));
        this.handleScroll = this.handleScroll.bind(this);
        this.getOffset = this.getOffset.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("orientationchange", this.getOffset);
        window.addEventListener('resize', this.getOffset);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('orientationchange', this.getOffset);
        window.removeEventListener('resize', this.getOffset);
    }
    getOffset() {
        this.element = document.getElementById(this.id);
        if (this.element) {
            this.stickyOffsetTop = this.element.offsetTop;
        }
        this.boundingClientRect = this.element.getBoundingClientRect();

    }
    handleScroll(event) {
        if (!this.element || this.stickyOffsetTop === 0) {
            this.getOffset();
        }
        if (!this.element || this.stickyOffsetTop === 0) { 
            return;
        }
        if (window.pageYOffset > this.stickyOffsetTop) {
            this.setState({
                sticky: true
            });
        } else {
            this.setState({
                sticky: false
            });
        }
    }
    render() {
        return (
            <div 
                id={this.id} 
                className={this.state.sticky ? this.props.stickyClassName : this.props.className}
            >
                {this.props.children}
            </div>
        )
    }
}

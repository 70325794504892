import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import Paginator from '../pagination/Paginator.component';
import { EMAIL_DELIMITER, ERROR_TYPE, TDS_DATE_FORMAT } from '../../constants/Misc.constants';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import ErrorComponent from '../misc/error/Error.component';
import SecureLinkService from '../../services/SecureLink.service';
import moment from 'moment';
import MailtoComponent from '../misc/mailto/Mailto.component';

class FundDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateCalenderIconClick = this.handleStartDateCalenderIconClick.bind(this);
        this.handleEndDateCalenderIconClick = this.handleEndDateCalenderIconClick.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    handleStartDateChange(date) {
        if (this.props.handleStartDateChange) {
            this.props.handleStartDateChange(date)
        }
    }
    handleEndDateChange(date) {
        if (this.props.handleEndDateChange) {
            this.props.handleEndDateChange(date)
        }
    }
    handleStartDateCalenderIconClick() {
        this.startDateCalendar.setOpen(true);
    }
    handleEndDateCalenderIconClick() {
        this.endDateCalendar.setOpen(true);
    }
    goToPage(page) {
        if (this.props.goToPage) {
            this.props.goToPage(page);
        }
    }
    noResults() {
        return this.props.data && this.props.data.results && this.props.data.results.length === 0 && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    render() {
        return (
            <React.Fragment>
                <div className="fund-details-component">
                    <div className="date-range-container form-group">
                        <h4 className="date-label">Date Range</h4>
                        <div className="date-from-ctn">
                            <DatePicker
                                ref={(c) => this.startDateCalendar = c}
                                className="tds-search-item-input form-control text-right"
                                selected={this.props.startDate}
                                placeholderText='MM/DD/YYYY'
                                onChange={this.handleStartDateChange}
                                maxDate={this.props.maxStartDate}
                            />
                            <span className="td-icon td-icon-bookAppointment"
                                onClick={this.handleStartDateCalenderIconClick}></span>
                        </div>
                        <div className="date-range-divider">-</div>
                        <div className="date-to-ctn">
                            <DatePicker
                                ref={(c) => this.endDateCalendar = c}
                                className="tds-search-item-input form-control text-right"
                                selected={this.props.endDate}
                                placeholderText='MM/DD/YYYY'
                                onChange={this.handleEndDateChange}
                                maxDate={this.props.maxDate}
                                minDate={this.props.minEndDate}
                            />
                            <span className="td-icon td-icon-bookAppointment"
                                onClick={this.handleEndDateCalenderIconClick}></span>
                        </div>
                    </div>
                    {this.noResults()}
                    {this.props.data && this.props.data.results && this.props.data.results.length > 0 &&
                        <React.Fragment>
                            <div className="results-container">
                                <div className="tds-row header">
                                    <div className="tds-column title">
                                        Title
                                    </div>
                                    <div className="tds-column date">
                                        Date
                                    </div>
                                    <div className="tds-column action">
                                        Action
                                    </div>
                                </div>
                                {
                                    this.props.data.results.map((item, index) => {
                                        item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
                                        return (
                                            <div className="tds-row" key={index}>
                                                <div className="tds-column title">
                                                    {item.title}
                                                </div>
                                                <div className="tds-column date">
                                                    <FormatedDateComponent
                                                        date={item.date}
                                                        format={TDS_DATE_FORMAT}
                                                    />
                                                </div>
                                                <div className="tds-column action">
                                                    {/*{ item.showHtml &&*/}
                                                    {/*    <a*/}
                                                    {/*        href={item.secureHtmlLink || item.htmlLink}*/}
                                                    {/*        title="View"*/}
                                                    {/*        target="_blank"*/}
                                                    {/*        rel="noopener noreferrer"*/}
                                                    {/*    >*/}
                                                    {/*        <img alt="View" className="tds-view-icon" src="/assets/img/view-icon.svg" />*/}
                                                    {/*    </a>*/}
                                                    {/*}*/}
                                                    <a
                                                        href={item.pdfLink}
                                                        title="View PDF"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span
                                                            className="td-icon td-icon-insights icon-small"
                                                        ></span>
                                                    </a>
                                                    <MailtoComponent
                                                        email={''}
                                                        titleText={'Share'}
                                                        icon={true}
                                                        subject={item.title + ' ' + moment.utc(item.date).format(TDS_DATE_FORMAT)}
                                                        body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + item.pdfLink}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Paginator
                                currentPage={this.props.currentPage}
                                resultsPerPage={this.props.size}
                                total={this.props.data.total}
                                goToPage={this.goToPage}
                            />
                        </React.Fragment>
                    }
                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(FundDetailsComponent);

import React from "react";
import FormatedNumberComponent from "../misc/formated-number/FormatedNumber.component";
import { FIRM_NAME_COWEN } from "../../constants/Misc.constants";

const HomePageEquitiesInfoComponent = (props) => {
    return (
        <div className={props.className}>
            <div className="tds-three-column-single-container-equities-info">
                <dl>
                    {
                        props.item.recommendation &&
                        <>
                            <dt>Recommendation:</dt>
                            <dd>{props.item.recommendation}</dd>
                        </>
                    }
                    {
                        props.item.risk &&
                        <>
                            <dt>Risk:</dt>
                            <dd>{props.item.risk}</dd>
                        </>
                    }
                    {
                        props.item.priceTargetValue &&
                        <>
                            <dt>12-Month Target Price:</dt>
                            <dd>{ props.item.priceTargetValue &&
                                    <FormatedNumberComponent
                                        number={props.item.priceTargetValue}
                                        decimal={2}
                                        currency={props.item.currency}
                                    />
                                }
                            </dd>
                        </>
                    }
                    {
                        props.item.marketCap &&
                        <>
                            <dt>{ props.item.firmName === FIRM_NAME_COWEN ? "Mkt Cap:" : "Mkt Cap (f.d.)($mm):"}</dt>
                            <dd>
                                { props.item.firmName === FIRM_NAME_COWEN ? 
                                     props.item.marketCap  : 
                                    <FormatedNumberComponent
                                        number={props.item.marketCap}
                                        decimal={1}
                                        currency={props.item.currency}
                                    />
                                }
                            </dd>
                        </>
                    }
                    {
                        props.item.marketCap && !props.noTrailingSpace &&
                        <>
                            &nbsp;
                        </>
                    }
                </dl>
            </div>
        </div>
    );
};

export default HomePageEquitiesInfoComponent;
import React from 'react';

export default class FiccResearchCreditStrategyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="tds-container">
                <div className="tds-generic-content-page">
                    <h1>FICC Research and Credit Strategy</h1>

                    <p>
                        The provision of Fixed Income, research and strategy (FICC Research and Strategy) is subject to the MiFID II
                        inducement provisions. After considering the guidance from ESMA, TD Securities has decided to
                        provide all written FICC Research that is in scope of the MiFID II inducement provisions free of
                        charge to any EU investment firm upon request. This FICC Strategy offering is also subject
                        to the UK Financial Conduct Authority ("FCA") rules regarding minor non-monetary benefit, where
                        the provision of this product may be provided free of charge or bundled with execution services
                        without it being considered an inducement.  The above provisions from ESMA and the FCA are on the basis
                        that UK and EU investment firms meet the criteria to be classified as professional or eligible counterparties.
                        To access our FICC Strategy offerings entities are required to register for access via our research portal.
                        TD Securities is employing this approach to prevent retail clients from inadvertently gaining access to information that is not suitable for them.
                    </p>
                </div>
            </div>
        )
    }
}

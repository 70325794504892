import React from 'react';

export default class FormErrorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="td-error">
                <div role="alert" id="selectError" aria-hidden="false"><span className="td-error-symbol" aria-hidden="true"></span><span className="sr-only">Error:</span>Required</div>
            </div>
        )
    }
}
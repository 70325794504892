import React from 'react';
import striptags from 'striptags';

export default class MailtoComponent extends React.Component {

    ref = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            href: this.buildHref(),
            text: this.props.text,
            iconClassName: this.buildIconClassName()
        };
        this.click = this.click.bind(this);
        this.buildHref = this.buildHref.bind(this);
        this.buildIconClassName = this.buildIconClassName.bind(this);
        this.clickCallBack = this.clickCallBack.bind(this);
        this.encodeSpecialChars = this.encodeSpecialChars.bind(this);
    }
    buildHref() {
        var href = "mailto:";
        var paramsList = [];
        if (this.props.email) {
            href += this.props.email;
        }
        if (this.props.subject) {
            paramsList.push('subject=' + this.encodeSpecialChars(this.props.subject));
        }
        if (this.props.body) {
            const bodyWithoutHTML = striptags(this.decodeSpecialHexChars(this.props.body));
            paramsList.push('body=' + this.encodeSpecialChars(bodyWithoutHTML));
        }
        if (this.props.cc) {
            paramsList.push('cc=' + this.props.cc);
        }
        if (paramsList.length > 0) {
            if (paramsList.lenth === 1) {
                href += '?' + paramsList[0];
            } else {
                href += '?' + paramsList.join("&")
            }
        }

        return href;
    }
    buildIconClassName() {
        var iconClassName = "";
        if (this.props.iconType) {
            iconClassName = 'td-icon td-icon-' + this.props.iconType + ' icon-small';
        } else {
            iconClassName = 'td-icon td-icon-email icon-small';
        }

        return iconClassName;
    }
    encodeSpecialChars(text) {
        var _text = "" + text;
        _text = _text.replace("?", "%3F");
        return _text.replace("&", "%26")
    }
    decodeSpecialHexChars(text) {
        const regexs = [
            ['%20', ' '],
            ['%3C', '<'],
            ['%3D', '='],
            ['%22', '"'],
            ['%3E', '>'],
            ['%2F', '/'],
            ['%3A', ':'],
            ['%27', '\'']
        ];
    
        for (const regex of regexs) {
            text = text.replace(new RegExp(regex[0], 'g'), regex[1]);
        }
    
        return text;    
    }
    click() {
        this.setState({
            href: this.buildHref()
        }, this.clickCallBack);
    }
    clickCallBack() {
        this.ref.current.click();
    }
    render() {
        return (
            <React.Fragment>
                { this.props.icon &&
                    <a
                        ref={this.ref}
                        className={this.props.className}
                        href={this.state.href}
                        title={this.props.titleText}
                    >
                        <span className={this.state.iconClassName}></span>
                    </a>
                }
                { !this.props.icon &&
                    <a
                        ref={this.ref}
                        className={this.props.className}
                        href={this.state.href}
                        title={this.props.titleText}
                    >
                        {this.props.text}
                    </a>
                }

            </React.Fragment>
        )
    }
}

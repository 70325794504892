import React from 'react';
import PersonSearchResultsComponent from './PersonSearchResults.component';
import PersonSearchService from "../../services/PersonSearch.service";
const intialState = {
    firstname: null,
    lastname: null,
    email: null,
    loading: false,
    results: null,
    error: null
}

export default class PersonSearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = intialState;
        this.setNullState = this.setNullState.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    setNullState = () => {
        this.setState(intialState);
    }
    handleFirstnameChange = (event) => {
        this.setState({ firstname: event.target.value });
    }
    handleLastnameChange = (event) => {
        this.setState({ lastname: event.target.value });
    }
    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }
    handleCancel = () => {
        this.setState({
            searchResults: null,
            firstname: null,
            lastname: null,
            email: null,
        });
    }
    handleSearch = () => {
        this.setState({
            loading: true,
            searchResults: null
        });
        PersonSearchService.getResults({
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            email: this.state.email
        }).then((searchResults) => {
            this.setState({
                searchResults: searchResults,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                searchResults: null,
                loading: false,
                error: true
            });
        });
    }
    render() {
        return (
            <section className="tds-section-search">
                <div className="tds-container">
                    <h1>Person Search</h1>
                    <div className="tds-search-row">
                        <div className="tds-search-item search-item-analyst form-group">
                            <label className="tds-search-item-label" htmlFor="analyst">First Name</label>
                            <input
                                aria-label="Enter First Name"
                                className="tds-search-item-input form-control"
                                name="firstname"
                                type="text"
                                value={this.state.firstname || ''}
                                onChange={this.handleFirstnameChange}
                            />
                        </div>
                        <div className="tds-search-item search-item-analyst form-group">
                            <label className="tds-search-item-label" htmlFor="industries">Last Name</label>
                            <input
                                aria-label="Enter Last Name"
                                className="tds-search-item-input form-control"
                                name="lastname"
                                type="text"
                                value={this.state.lastname || ''}
                                onChange={this.handleLastnameChange}
                            />
                        </div>
                        <div className="tds-search-item search-item-regions form-group">
                            <label className="tds-search-item-label" htmlFor="regions">Email</label>
                            <input
                                aria-label="Enter E-mail"
                                className="tds-search-item-input form-control"
                                name="email"
                                type="text"
                                value={this.state.email || ''}
                                onChange={this.handleEmailChange}
                            />
                        </div>
                    </div>
                    <div className="tds-search-row">
                        <div className="tds-search-button">
                            <button
                                aria-label="Search"
                                className="btn btn-block td-btn-primary-light"
                                onClick={this.handleSearch}>Search</button>
                        </div>
                        <div className="tds-clear-button">
                            <button
                                aria-label="Clear Results"
                                className="btn btn-block td-btn-secondary-clear"
                                onClick={this.handleCancel}>Clear</button>
                        </div>
                    </div>
                    {
                        this.state.searchResults &&
                        <PersonSearchResultsComponent results={this.state.searchResults} />
                    }
                    {
                        this.state.loading &&
                        <div style={
                            {
                                'textAlign': 'center',
                                'padding': '100px',
                                'float': 'left',
                                'width': '100%'

                            }
                        }>
                            <img
                                src="assets/img/loading-spinner-small.gif"
                                alt=""

                            />
                        </div>
                    }
                    {
                        this.state.error &&
                        <h1 style={
                            {
                                'textAlign': 'center',
                                'padding': '100px',
                                'float': 'left',
                                'width': '100%'

                            }
                        }>
                            Error retrieving results
                        </h1>
                    }
                    {
                        <React.Fragment>
                            <div className="tds-divider-space-10 tds-clear">
                                &nbsp;
                            </div>
                            <div className="tds-divider-space-10 tds-clear">
                                &nbsp;
                            </div>
                        </React.Fragment>
                    }
                </div>

            </section >
        )
    }
}

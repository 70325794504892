//Default Images

export var DEFAULT_IMAGE_BASE_METALS = '/assets/img/Base-Metals.jpg';
export var DEFAULT_IMAGE_BUILDING = '/assets/img/Building.jpg';
export var DEFAULT_IMAGE_CHART_LOGIC = '/assets/img/Chart-Logic.jpg';
export var DEFAULT_IMAGE_COMMODITIES = '/assets/img/Commodities.jpg';
export var DEFAULT_IMAGE_CURRENCY = '/assets/img/Currency.jpg';
export var DEFAULT_IMAGE_EMERGING_MARKETS = '/assets/img/Emerging-Markets.jpg';
export var DEFAULT_IMAGE_ENERGY_1 = '/assets/img/Energy-1.jpg';
export var DEFAULT_IMAGE_ENERGY_2 = '/assets/img/Energy-2.jpg';
export var DEFAULT_IMAGE_ENERGY_3 = '/assets/img/Energy-3.jpg';
export var DEFAULT_IMAGE_FEDERAL_RESERVE = '/assets/img/Federal-Reserve.jpg';
export var DEFAULT_IMAGE_LONDON = '/assets/img/London.jpg';
export var DEFAULT_IMAGE_NEW_YORK = '/assets/img/New-York.jpg';
export var DEFAULT_IMAGE_OFFICE_IPAD = '/assets/img/Office-iPad.jpg';
export var DEFAULT_IMAGE_OFFICE_MEETING = '/assets/img/Office-Meeting.jpg';
export var DEFAULT_IMAGE_OFFICE_WINDOW = '/assets/img/Office-Window.jpg';
export var DEFAULT_IMAGE_PRECIOUS_METALS = '/assets/img/Precious-Metals.jpg';
export var DEFAULT_IMAGE_SUSTAINABLE_BUILDING = '/assets/img/Sustainable-Building.jpg';
export var DEFAULT_IMAGE_TOKYO = '/assets/img/Tokyo.jpg';
export var DEFAULT_IMAGE_TORONTO = '/assets/img/Toronto.jpg';

export var DEFAULT_IMAGE_LIST = [
    DEFAULT_IMAGE_BASE_METALS,
    DEFAULT_IMAGE_BUILDING,
    DEFAULT_IMAGE_CHART_LOGIC,
    DEFAULT_IMAGE_COMMODITIES,
    DEFAULT_IMAGE_CURRENCY,
    DEFAULT_IMAGE_ENERGY_1,
    DEFAULT_IMAGE_ENERGY_2,
    DEFAULT_IMAGE_ENERGY_3,
    DEFAULT_IMAGE_FEDERAL_RESERVE,
    DEFAULT_IMAGE_LONDON,
    DEFAULT_IMAGE_NEW_YORK,
    DEFAULT_IMAGE_OFFICE_IPAD,
    DEFAULT_IMAGE_OFFICE_MEETING,
    DEFAULT_IMAGE_OFFICE_WINDOW,
    DEFAULT_IMAGE_SUSTAINABLE_BUILDING,
    DEFAULT_IMAGE_TOKYO,
    DEFAULT_IMAGE_TORONTO
];

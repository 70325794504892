import HttpClient from '../utils/HttpClient';
import { 
    REST_COMPANY_DETAILS_PATH,
    REST_COMPANY_NOTES_PATH,
    REST_PUBLIC_DISCLOSURES_EQUITY_PATH
} from '../constants/Rest.constants';

import {
    FORWARD_SLASH
} from '../constants/Misc.constants';

export default class CompanyService {

    static getDetails(issuerId) {
        return HttpClient.get(REST_COMPANY_DETAILS_PATH + issuerId);
    }

    static getNotes(issuer, params) {
        return HttpClient.get(REST_COMPANY_NOTES_PATH + FORWARD_SLASH + issuer, params);
    }

    static getPublicDisclosuresEquityResearchBySymbol(params) {
        return HttpClient.get(REST_PUBLIC_DISCLOSURES_EQUITY_PATH, params);
    }
}

import React, { useEffect, useState } from "react";
import { ERROR_TYPE } from "../../constants/Misc.constants";
import CoveragePageService from "../../services/CoveragePage.service";
import {isNotEmptyArray} from "../../utils/valueUtils";
import ErrorComponent from "../misc/error/Error.component";
import HighlightCardsComponent from "../misc/highlight-cards/HighlightCards.component";
import SpinnerComponent from "../spinner/Spinner.component";

const HighlightBlock = (props) => {

    const [highlightData, setHighlightData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const getHighlightDataThen = (data) => {
          const highlightPublications = [];
          if(data.mainCards && data.mainCards.mainCard) {
            highlightPublications.push(data.mainCards.mainCard)
          }
          if(data.mainCards && isNotEmptyArray(data.mainCards.middleCards) ) {
            highlightPublications.push(...data.mainCards.middleCards)
          }
          setHighlightData(highlightPublications);
          setIsLoading(false);
        }
        const getDataError = (error) => {
          setHighlightData(null);
          setIsLoading(false);
          setIsError(true);
        }
        const getHighlightPublications = async () => {
          CoveragePageService.getHighlightPublications().then(getHighlightDataThen).catch(getDataError);
        }
        getHighlightPublications();
    }, []);

    return (
        <React.Fragment>
        {
            isLoading && <SpinnerComponent noContainer={true} outerWidth={'100%'} />
        }
        { !isLoading && isNotEmptyArray(highlightData) &&
            <HighlightCardsComponent data={highlightData} />
        }
        { !isLoading && isError &&
            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />       
        }
        </React.Fragment>
    );
};

export default HighlightBlock;
import React, {createContext, useEffect, useState} from 'react';

const CowenFlagContext = createContext(false);
export const COWEN_FLAG = "cowen_enabled";

export const CowenFlagProvider = ({children}) => {
    const [value] = useState(() => {
        const saved = localStorage.getItem(COWEN_FLAG);

        return saved !== null ? JSON.parse(saved) === true : false;
    });

    useEffect(() => {
        localStorage.setItem(COWEN_FLAG, JSON.stringify(value));
    }, [value]);

    return (
        <CowenFlagContext.Provider value={value}>
            {children}
        </CowenFlagContext.Provider>
    )
}

export default CowenFlagContext;
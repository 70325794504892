import React from 'react';
import moment from 'moment';

export default class FormatedDateComponent extends React.Component {
    formatDate(date, format) {
        if (!date) {
            return "";
        }
        return moment.utc(date).format(format);
    }
    render() {
        return (
            <React.Fragment>
                { this.formatDate(this.props.date, this.props.format) }
            </React.Fragment>
        )
    }
}

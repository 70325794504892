import React from 'react';
import { withRouter } from "react-router-dom";
import ResponsiveBackgroundImageComponent
    from '../../misc/responsive-background-image/ResponsiveBackgroundImage.component';
import UtilsService from "../../../services/Utils.service";
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
class ProductCardSmallComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            defaultImage: UtilsService.getRandomDefaultImage()
        };
        this.handleCardClick = this.handleCardClick.bind(this);
    }
    handleCardClick = (link) => e => {
        this.props.history.push(link);
    };
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data &&
                    <ResponsiveBackgroundImageComponent
                        className={'tds-small-product-card'}
                        default={this.props.data.image || this.state.defaultImage }
                        tablet1={this.props.data.tablet1Image || this.state.defaultImage }
                        tablet2={this.props.data.tablet2Image || this.state.defaultImage }
                        mobile={this.props.data.mobileImage || this.state.defaultImage }
                        ariaLabel={this.props.data.title}
                        title={this.props.data.title}
                        onClick={this.handleCardClick(LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.contentId)}
                    >
                        <div className="tds-small-product-card-text-container">
                            <h2>{this.props.data.title}</h2>
                            {
                                (this.props.data.primaryAnalyst?.firstName || this.props.data.primaryAnalyst?.lastName || this.props.data.date) &&
                                <React.Fragment>
                                    <span className="tds-small-product-card-byline">{this.props.data.primaryAnalyst?.firstName} {this.props.data.primaryAnalyst?.lastName}</span>
                                    <span className="tds-small-product-card-date">
                                        <FormatedDateComponent
                                            date={this.props.data.date}
                                            format={TDS_DATE_FORMAT}
                                        />
                                    </span>
                                </React.Fragment>
                            }
                        </div>
                    </ResponsiveBackgroundImageComponent>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(ProductCardSmallComponent);
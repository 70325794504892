
import {
    ENV_LOCAL_URL, 
    ENV_DEV_URL, 
    ENV_PAT_URL,
    ENV_PROD_URL,
    ENV_LOCAL_LABEL,
    ENV_DEV_LABEL,
    ENV_PAT_LABEL,
    ENV_PROD_LABEL,
    ENV_LOGOUT_URL
} from '../constants/Enivornment.constants';

import {
    MockupUserProfileEncodedEntitlements, MockupUserProfileEquityOnlyEncodedEntitlements, MockupUserProfileFicmOnlyEncodedEntitlements
  } from '../sampleMockupData/headerMockupData';

export default class EnvironmentService {

    static environment = null;
    static logoutURL = ENV_LOGOUT_URL.DEV;
    static hostname = null;

    static isLocal() {
        return EnvironmentService.environment === ENV_LOCAL_LABEL;
    }

    static isDev() {
        return EnvironmentService.environment === ENV_DEV_LABEL;
    }

    static isPat() {
        return EnvironmentService.environment === ENV_PAT_LABEL;
    }

    static isProd() {
        return EnvironmentService.environment === ENV_PROD_LABEL;
    }

    static initialize() {
        EnvironmentService.environment = EnvironmentService.getEnvironment();
        console.log("EnvironmentService.initialize():",  EnvironmentService.environment);
        if (EnvironmentService.isLocal()) {
            EnvironmentService.logoutURL = ENV_LOGOUT_URL.LOCAL;
        }
        if (EnvironmentService.isDev()) {
            EnvironmentService.logoutURL = ENV_LOGOUT_URL.DEV;
        }
        if (EnvironmentService.isPat()) {
            EnvironmentService.logoutURL = ENV_LOGOUT_URL.PAT;
        }
        if (EnvironmentService.isProd()) {
            EnvironmentService.logoutURL = ENV_LOGOUT_URL.PROD;
        }
        /* DO NOT REMOVE OR COMMENT OUT */
        console.log("EnvironmentService.environment:", EnvironmentService.environment);
        console.log("EnvironmentService.logoutURL:", EnvironmentService.logoutURL);
        /* DO NOT REMOVE OR COMMENT OUT */
    }

    static getEnvironment() {
        const url = document.createElement('a');
        url.setAttribute('href', window.location.href);
        console.log(url.hostname);
        EnvironmentService.hostname = url.hostname;
        if (url.hostname === ENV_LOCAL_URL) {
            EnvironmentService.port = url.port;
            return ENV_LOCAL_LABEL;
        } else if (url.hostname === ENV_DEV_URL) {
            return ENV_DEV_LABEL;
        } else if (url.hostname === ENV_PAT_URL) {
            return ENV_PAT_LABEL;
        } else if (url.hostname === ENV_PROD_URL) {
            return ENV_PROD_LABEL;
        }
        return null;
    }

    static isSearch() {
        var pathName = window.location.pathname;
        var pattern = new RegExp("search");
        var results = pathName.match(pattern);
        if (results && results.index && results.index === 1) {
            return true
        }
        return false;
    }

    static isAnalystProfilePage() {
        var pathName = window.location.pathname;
        if (pathName.includes('/analysts/profile/')) {
            return true
        }
        return false;
    }

    static getUserMockProfile() {
        const entitlement = process.env.REACT_APP_USER_MOCK_ENTITLEMENT;
        console.log("Process Env", process.env);
        if(entitlement) {
            if (entitlement === 'equity') {
                return MockupUserProfileEquityOnlyEncodedEntitlements;
            }
            else if (entitlement === 'ficm') {
                return MockupUserProfileFicmOnlyEncodedEntitlements;
            }
        }
        return MockupUserProfileEncodedEntitlements;
    }


}
import React from "react";
import {isNotEmpty} from "../../../utils/valueUtils";
import SecureLinkService from "../../../services/SecureLink.service";

const AnimatedInfoLine = (props) => {

  const infoLinePublications= props.data;

  /**
   * Pauses all animations
   */
  function pause() {    
    document.getElementById("animationStart").classList.remove('tds-info-line-animation-unpaused');
    document.getElementById("animationMiddle").classList.remove('tds-info-line-animation-unpaused');
    document.getElementById("animationEnd").classList.remove('tds-info-line-animation-unpaused');
    document.getElementById("animationStart").classList.add('tds-info-line-animation-paused');
    document.getElementById("animationMiddle").classList.add('tds-info-line-animation-paused');
    document.getElementById("animationEnd").classList.add('tds-info-line-animation-paused');
	}

  /**
   * Resumes all animations
   */
	function unpause() {  
    document.getElementById("animationStart").classList.remove('tds-info-line-animation-paused');
    document.getElementById("animationMiddle").classList.remove('tds-info-line-animation-paused');
    document.getElementById("animationEnd").classList.remove('tds-info-line-animation-paused');
    document.getElementById("animationStart").classList.add('tds-info-line-animation-unpaused');
    document.getElementById("animationMiddle").classList.add('tds-info-line-animation-unpaused');
    document.getElementById("animationEnd").classList.add('tds-info-line-animation-unpaused');
  }

  function getTitle(publication) {
    const companySymbol = publication.companySymbol;
    const title = publication.publicationTitle;
    if (isNotEmpty(companySymbol)) {

      return `(${companySymbol}) ${title}`;
    }

    return title;
  }

  return (
    <React.Fragment>
      { infoLinePublications && infoLinePublications.length <= 5 &&
        <div className="tds-static-info-line-container">
          {
            infoLinePublications.map((publication, index) => {
              const publicationTitle = getTitle(publication);
              return (
                <div className="tds-info-line-slot" key={"tds-info-line-slot-" + index}>
                  <div className="tds-info-line-slot-content" key={"tds-info-line-slot-content-" + index}>
                    <div className="publication-title" key={"publication-title-" + index}>
                      <a
                          href={SecureLinkService.buildSecureLink(publication.publicationLink)}
                          target="_blank"
                          title={publicationTitle}
                          rel="noopener noreferrer"
                          className="more-link"
                          key={"publication-link-" + index}
                      >
                      {publicationTitle}
                      </a>
                    </div>
                    { publication.analystName && 
                      <div className="analyst-name" key={"analyst-name-" + index}>{publication.analystName}</div>
                    }
                    <div className="publication-date" key={"publication-date-" + index}>{publication.publicationDate}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { infoLinePublications && infoLinePublications.length > 5 &&
        <div className="tds-animated-info-line-container" onMouseOver={pause} onMouseOut={unpause}>
          <div className="tds-animated-info-line">
            <div id="animationStart" className={"tds-info-line-items-container tds-info-line-start-animation-for-num-items-" + infoLinePublications.length}>
              {
                infoLinePublications.map((publication, index) => {
                  const publicationTitle = getTitle(publication);
                  return (
                    <div className="tds-info-line-slot" key={"tds-info-line-slot-" + index}>
                      <div className="tds-info-line-slot-content" key={"tds-info-line-slot-content-" + index}>
                        <div className="publication-title" key={"publication-title-" + index}>
                          <a
                             href={SecureLinkService.buildSecureLink(publication.publicationLink)}
                              target="_blank"
                              title={publicationTitle}
                              rel="noopener noreferrer"
                              className="more-link"
                              key={"publication-link-" + index}
                          >
                          {publicationTitle}
                          </a>
                        </div>
                        { publication.analystName && 
                          <div className="analyst-name" key={"analyst-name-" + index}>{publication.analystName}</div>
                        }
                        <div className="publication-date" key={"publication-date-" + index}>{publication.publicationDate}</div>
                      </div>
                    </div>
                  )
                })
              }              
            </div>
          </div>
          <div className="tds-animated-info-line">        
            <div id="animationMiddle" className={"tds-info-line-items-container tds-info-line-animation-for-num-items-" + infoLinePublications.length}>
              {
                infoLinePublications.map((publication, index) => {
                  const publicationTitle = getTitle(publication);
                  return (
                    <div className="tds-info-line-slot" key={"tds-info-line-slot-" + index}>
                      <div className="tds-info-line-slot-content" key={"tds-info-line-slot-content-" + index}>
                        <div className="publication-title" key={"publication-title-" + index}>
                          <a
                              href={SecureLinkService.buildSecureLink(publication.publicationLink)}
                              target="_blank"
                              title={publicationTitle}
                              rel="noopener noreferrer"
                              className="more-link"
                              key={"publication-link-" + index}
                          >
                          {publicationTitle}
                          </a>
                        </div>
                        { publication.analystName && 
                          <div className="analyst-name" key={"analyst-name-" + index}>{publication.analystName}</div>
                        }
                        <div className="publication-date" key={"publication-date-" + index}>{publication.publicationDate}</div>
                      </div>
                    </div>  
                  )
                })
              }              
            </div>
          </div>          
          <div className="tds-animated-info-line">        
            <div id="animationEnd" className={"tds-info-line-items-container tds-info-line-animation-for-num-items-" + infoLinePublications.length + " tds-info-line-end-animation-delay-for-num-items-" + infoLinePublications.length}>
              {
                infoLinePublications.map((publication, index) => {
                  const publicationTitle = getTitle(publication);
                  return (
                    <div className="tds-info-line-slot" key={"tds-info-line-slot-" + index}>
                      <div className="tds-info-line-slot-content" key={"tds-info-line-slot-content-" + index}>
                        <div className="publication-title" key={"publication-title-" + index}>
                          <a
                              href={SecureLinkService.buildSecureLink(publication.publicationLink)}
                              target="_blank"
                              title={publicationTitle}
                              rel="noopener noreferrer"
                              className="more-link"
                              key={"publication-link-" + index}
                          >
                          {publicationTitle}
                          </a>
                        </div>
                        { publication.analystName && 
                          <div className="analyst-name" key={"analyst-name-" + index}>{publication.analystName}</div>
                        }
                        <div className="publication-date" key={"publication-date-" + index}>{publication.publicationDate}</div>
                      </div>
                    </div>
                  )
                })
              }              
            </div>
          </div>
        </div>
      }      
    </React.Fragment>
  );
};

export default AnimatedInfoLine;
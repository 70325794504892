export const EXCHANGE_TRADED_FUNDS_QUERY_PARAM_START_DATE = 'startDate';
export const EXCHANGE_TRADED_FUNDS_QUERY_PARAM_END_DATE = 'endDate';
export const EXCHANGE_TRADED_FUNDS_QUERY_PARAM_PAGE = 'page';

export const EXCHANGE_TRADED_FUNDS_QUERY_PARAM_DATE_FORMAT = 'MM/DD/YYYY';

export const EXCHANGE_TRADED_FUNDS_PAGE_SIZE = 10;

export const EXCHANGE_TRADED_FUNDS_TYPE = {
    'WEEKLY': { path: 'weekly', title: 'TD ETF Weekly' },
    'INSIGHTS': { path: 'insights', title: 'TD ETF Insights' },
    'STRATEGY_IDEAS': { path: 'strategyIdeas', title: 'TD ETF Strategy Ideas' },
    'EQUITY_OPTIONS': { path: 'equityOptions', title: 'Equity Options' }
}
import React from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import ShowContentComponent from '../../misc/show-content/ShowContent.component';
import ProductTradeIdeasItemComponent from './ProductTradeIdeasItem.component';
import InnerHtmlComponent from "../../misc/inner-html/InnerHtml.component";
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';


export default class ProductTradeIdeasSectionComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleExpandClick() {
        this.setState({ expanded: true });
    }
    handleCollapseClick() {
        this.setState({ expanded: false });
    }
    render() {
        return (
            <div className="tds-product-table-trade-ideas-row-section">
                {this.props.data &&
                    <div className="tds-product-table-trade-ideas-row mobile">
                        <div className="tds-product-table-trade-ideas-column date">
                            <FormatedDateComponent
                                date={this.props.data._date}
                                format={TDS_DATE_FORMAT}
                            />
                        </div>
                        <ShowContentComponent
                            if={this.props.data._tradeStatusOpen}
                        >
                            <div className="tds-product-table-trade-ideas-column status open">
                                Open Trade
                    </div>
                        </ShowContentComponent>
                        <ShowContentComponent
                            if={!this.props.data._tradeStatusOpen}
                        >
                            <div className="tds-product-table-trade-ideas-column status closed">
                                Closed Trade
                    </div>
                        </ShowContentComponent>
                    </div>
                }
                {this.props.data && this.props.data.rowData.map(
                    (productTradeIdeaItem, index) => {
                        return (
                            <ProductTradeIdeasItemComponent
                                key={'product-trade-ideas-item-component' + index}
                                expanded={this.state.expanded}
                                data={productTradeIdeaItem}
                                tradeIdeasFilter={this.props.tradeIdeasFilter}
                                handleExpandClick={this.handleExpandClick}
                                handleCollapseClick={this.handleCollapseClick}
                            />
                        )
                    }
                )
                }
                <div className={this.state.expanded ? 'tds-product-table-trade-ideas-row content active' : 'tds-product-table-trade-ideas-row content'}>
                    <div className="tds-product-table-trade-ideas-expanded">
                        {this.props.data &&
                            <React.Fragment>
                                <Link
                                    to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.contentId}
                                    title={this.props.data.title}
                                    className={'tds-product-table-trade-ideas-expanded-title'}
                                >
                                    {this.props.data.title}
                                </Link>
                                <span className="tds-product-table-trade-ideas-expanded-description">
                                    <span className="tds-product-table-trade-ideas-expanded-description-content">
                                        <InnerHtmlComponent data={this.props.data.summary} />
                                    </span>
                                </span>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export const COVERAGE_PAGE_TABS_COMPANIES = 'COMPANIES';
export const COVERAGE_PAGE_TABS_ACTION_LIST = 'ACTION_LIST';

export const COVERAGE_PAGE_TABS = {
    COMPANIES: COVERAGE_PAGE_TABS_COMPANIES,
    ACTION_LIST: COVERAGE_PAGE_TABS_ACTION_LIST
}

export const COVERAGE_ADVANCED_FILTER_PAGE_SIZE = 10;
export const COVERAGE_IMPORTANT_DISCLOSURES_PAGE_SIZE = 10;

export const COVERAGE_COMPANY_SORT_BY_NAME = 'Name';
export const COVERAGE_COMPANY_SORT_BY_SYMBOL = 'Symbol';
export const COVERAGE_COMPANY_SORT_BY_ANALYSTS = 'Analysts';
export const COVERAGE_COMPANY_SORT_BY_INDUSTRY = 'Industry';

export const COVERAGE_ACTION_LIST_PAGE_SIZE = 100;

export const OPERATOR_GREATER_THAN = '>';
export const OPERATOR_LESS_THAN = '<';
export const OPERATOR_GREATER_THAN_EQUALS = '>=';
export const OPERATOR_LESS_THAN_EQUALS = '<=';
export const OPERATOR_EQUALS = '=';

// export const REC_VALUE_SPECULATIVE_BUY = '3';
// export const REC_VALUE_HOLD = '4';
// export const REC_VALUE_BUY = '2';
// export const REC_VALUE_REDUCE = '5';
// export const REC_VALUE_ACTION_LIST_BUY = '1';
// export const REC_VALUE_TENDER = '6';
export const REC_VALUE_TENDER_BUY = '1';
export const REC_VALUE_TENDER_HOLD = '2';
export const REC_VALUE_TENDER_SELL = '3';
export const REC_VALUE_TENDER_SUSPENDED = '4';

export const RISK_RATING_VALUE_LOW = '1';
export const RISK_RATING_VALUE_MEDIUM = '2';
export const RISK_RATING_VALUE_HIGH = '3';
export const RISK_RATING_VALUE_SPECULATIVE = '4';

export const ADVANCED_FILTER_ORDER_BY_COMPANY_NAME = 'companyName';
export const ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL = 'companySymbol';
export const ADVANCED_FILTER_ORDER_BY_REC = 'recommendation';
export const ADVANCED_FILTER_ORDER_BY_RISK_RATING = 'riskRating';
export const ADVANCED_FILTER_ORDER_BY_CURRENCY = 'currency';
export const ADVANCED_FILTER_ORDER_BY_PRICE_TARGET = 'priceTarget';
export const ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE = 'currentPrice';
export const ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD = 'dividendYield';
export const ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN = 'totalReturn';
export const ADVANCED_FILTER_ORDER_BY_AVG_VOLUME = 'avgVolume';
export const ADVANCED_FILTER_ORDER_BY_MKT_CAP = 'marketCapitalFd';
export const ADVANCED_FILTER_ORDER_BY_ANALYST = 'analyst';

export const ADVANCED_FILTER_SORT_TYPE_ASC = 'ASC';
export const ADVANCED_FILTER_SORT_TYPE_DESC = 'DESC';

export const URL_PARAM_TAB = 'tab';

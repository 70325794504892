export var ANALYST_SEARCH_DOCUMENT_TYPES = [
    'PDF', 'Word', 'Excel', 'Power Point'
];
export var ANALYST_SEARCH_ALL = 'ALL';
export var ANALYST_SEARCH_GLOBAL_STRATEGY = 'strategy';
export var ANALYST_SEARCH_EQUITIES = 'research';
export var ANALYST_SEARCH_LAST_NAME = 'lastName';
export var ANALYST_SEARCH_ASC = 'ASC';
export var ANALYST_NAME_DELIMITER = ' ';
export var ANALYST_ROLE_FILTER = '5';
export var ANALYST_ROLE_DELIMITER = ',';
export const EQ_RESEARCH_CAN_MAPPING_ANALYST_PROFILE = [
    { bmIdSt: 1, bmIdCowen: null },
    { bmIdSt: 2, bmIdCowen: 924 },
    { bmIdSt: 3, bmIdCowen: 925 },
    { bmIdSt: 4, bmIdCowen: 886 },
    { bmIdSt: 5, bmIdCowen: null },
    { bmIdSt: 6, bmIdCowen: 926 },
    { bmIdSt: 7, bmIdCowen: null },
    { bmIdSt: 8, bmIdCowen: 927 },
    { bmIdSt: 9, bmIdCowen: null },
    { bmIdSt: 10, bmIdCowen: null },
    { bmIdSt: 11, bmIdCowen: 919 },
    { bmIdSt: 12, bmIdCowen: 928 },
    { bmIdSt: 13, bmIdCowen: null },
    { bmIdSt: 14, bmIdCowen: 929 },
    { bmIdSt: 15, bmIdCowen: null },
    { bmIdSt: 16, bmIdCowen: null },
    { bmIdSt: 17, bmIdCowen: 930 },
    { bmIdSt: 18, bmIdCowen: 931 },
    { bmIdSt: 19, bmIdCowen: 932 },
    { bmIdSt: 20, bmIdCowen: 933 },
    { bmIdSt: 21, bmIdCowen: 934 },
    { bmIdSt: 22, bmIdCowen: 935 },
    { bmIdSt: 23, bmIdCowen: null },
    { bmIdSt: 24, bmIdCowen: null },
    { bmIdSt: 25, bmIdCowen: 936 },
    { bmIdSt: 26, bmIdCowen: null },
    { bmIdSt: 27, bmIdCowen: 937 },
    { bmIdSt: 28, bmIdCowen: 91 },
    { bmIdSt: 29, bmIdCowen: null },
    { bmIdSt: 30, bmIdCowen: 938 },
    { bmIdSt: 31, bmIdCowen: null },
    { bmIdSt: 32, bmIdCowen: 939 },
    { bmIdSt: 33, bmIdCowen: null },
    { bmIdSt: 34, bmIdCowen: null },
    { bmIdSt: 35, bmIdCowen: 940 },
    { bmIdSt: 36, bmIdCowen: null },
    { bmIdSt: 37, bmIdCowen: null },
    { bmIdSt: 38, bmIdCowen: 941 },
    { bmIdSt: 39, bmIdCowen: null },
    { bmIdSt: 40, bmIdCowen: 942 },
    { bmIdSt: 41, bmIdCowen: null },
    { bmIdSt: 42, bmIdCowen: null },
    { bmIdSt: 43, bmIdCowen: null },
    { bmIdSt: 44, bmIdCowen: null },
    { bmIdSt: 45, bmIdCowen: 943 },
    { bmIdSt: 46, bmIdCowen: null },
    { bmIdSt: 47, bmIdCowen: 944 },
    { bmIdSt: 48, bmIdCowen: 945 },
    { bmIdSt: 49, bmIdCowen: null },
    { bmIdSt: 50, bmIdCowen: null },
    { bmIdSt: 51, bmIdCowen: null },
    { bmIdSt: 52, bmIdCowen: null },
    { bmIdSt: 53, bmIdCowen: null },
    { bmIdSt: 54, bmIdCowen: null },
    { bmIdSt: 55, bmIdCowen: null },
    { bmIdSt: 56, bmIdCowen: null },
    { bmIdSt: 57, bmIdCowen: null },
    { bmIdSt: 58, bmIdCowen: null },
    { bmIdSt: 59, bmIdCowen: null },
    { bmIdSt: 60, bmIdCowen: null },
    { bmIdSt: 61, bmIdCowen: null },
    { bmIdSt: 62, bmIdCowen: null },
    { bmIdSt: 63, bmIdCowen: 946 },
    { bmIdSt: 64, bmIdCowen: null },
    { bmIdSt: 65, bmIdCowen: null },
    { bmIdSt: 66, bmIdCowen: 947 },
    { bmIdSt: 67, bmIdCowen: 948 },
    { bmIdSt: 68, bmIdCowen: 949 },
    { bmIdSt: 69, bmIdCowen: null },
    { bmIdSt: 70, bmIdCowen: null },
    { bmIdSt: 71, bmIdCowen: null },
    { bmIdSt: 72, bmIdCowen: null },
    { bmIdSt: 73, bmIdCowen: null },
    { bmIdSt: 74, bmIdCowen: 950 },
    { bmIdSt: 75, bmIdCowen: 951 },
    { bmIdSt: 76, bmIdCowen: 952 },
    { bmIdSt: 77, bmIdCowen: null },
    { bmIdSt: 78, bmIdCowen: null },
    { bmIdSt: 79, bmIdCowen: null },
    { bmIdSt: 80, bmIdCowen: null },
    { bmIdSt: 81, bmIdCowen: 953 },
    { bmIdSt: 82, bmIdCowen: null },
    { bmIdSt: 83, bmIdCowen: null },
    { bmIdSt: 84, bmIdCowen: null },
    { bmIdSt: 85, bmIdCowen: 954 },
    { bmIdSt: 86, bmIdCowen: null },
    { bmIdSt: 87, bmIdCowen: null },
    { bmIdSt: 88, bmIdCowen: null },
    { bmIdSt: 89, bmIdCowen: null },
    { bmIdSt: 90, bmIdCowen: null },
    { bmIdSt: 91, bmIdCowen: null },
    { bmIdSt: 92, bmIdCowen: 955 },
    { bmIdSt: 93, bmIdCowen: 956 },
    { bmIdSt: 94, bmIdCowen: null },
    { bmIdSt: 95, bmIdCowen: null },
    { bmIdSt: 96, bmIdCowen: null },
    { bmIdSt: 97, bmIdCowen: null },
    { bmIdSt: 98, bmIdCowen: null },
    { bmIdSt: 99, bmIdCowen: null },
    { bmIdSt: 100, bmIdCowen: null },
    { bmIdSt: 100, bmIdCowen: null },
    { bmIdSt: 101, bmIdCowen: null },
    { bmIdSt: 102, bmIdCowen: null },
    { bmIdSt: 103, bmIdCowen: 957 },
    { bmIdSt: 104, bmIdCowen: 958 },
    { bmIdSt: 105, bmIdCowen: null },
    { bmIdSt: 106, bmIdCowen: null },
    { bmIdSt: 107, bmIdCowen: 959 },
    { bmIdSt: 108, bmIdCowen: null },
    { bmIdSt: 109, bmIdCowen: 960 },
    { bmIdSt: 110, bmIdCowen: null },
    { bmIdSt: 111, bmIdCowen: null },
    { bmIdSt: 112, bmIdCowen: null },
    { bmIdSt: 113, bmIdCowen: null },
    { bmIdSt: 114, bmIdCowen: null },
    { bmIdSt: 115, bmIdCowen: 961 },
    { bmIdSt: 116, bmIdCowen: null },
    { bmIdSt: 117, bmIdCowen: null },
    { bmIdSt: 118, bmIdCowen: null },
    { bmIdSt: 119, bmIdCowen: null },
    { bmIdSt: 120, bmIdCowen: 962 },
    { bmIdSt: 121, bmIdCowen: null },
    { bmIdSt: 122, bmIdCowen: null },
    { bmIdSt: 123, bmIdCowen: null },
    { bmIdSt: 124, bmIdCowen: null },
    { bmIdSt: 125, bmIdCowen: null },
    { bmIdSt: 126, bmIdCowen: null },
    { bmIdSt: 127, bmIdCowen: null },
    { bmIdSt: 128, bmIdCowen: null },
    { bmIdSt: 129, bmIdCowen: null },
    { bmIdSt: 130, bmIdCowen: null },
    { bmIdSt: 131, bmIdCowen: null },
    { bmIdSt: 132, bmIdCowen: null },
    { bmIdSt: 133, bmIdCowen: 963 },
    { bmIdSt: 134, bmIdCowen: 964 },
    { bmIdSt: 135, bmIdCowen: null },
    { bmIdSt: 136, bmIdCowen: null },
    { bmIdSt: 137, bmIdCowen: 918 },
    { bmIdSt: 138, bmIdCowen: 965 },
    { bmIdSt: 139, bmIdCowen: 966 },
    { bmIdSt: 140, bmIdCowen: null },
    { bmIdSt: 141, bmIdCowen: null },
    { bmIdSt: 142, bmIdCowen: 967 },
    { bmIdSt: 143, bmIdCowen: null },
    { bmIdSt: 144, bmIdCowen: null },
    { bmIdSt: 145, bmIdCowen: null },
    { bmIdSt: 146, bmIdCowen: null },
    { bmIdSt: 147, bmIdCowen: null },
    { bmIdSt: 148, bmIdCowen: null },
    { bmIdSt: 149, bmIdCowen: null },
    { bmIdSt: 150, bmIdCowen: 968 },
    { bmIdSt: 151, bmIdCowen: 969 },
    { bmIdSt: 152, bmIdCowen: null },
    { bmIdSt: 153, bmIdCowen: null },
    { bmIdSt: 154, bmIdCowen: null },
    { bmIdSt: 155, bmIdCowen: null },
    { bmIdSt: 156, bmIdCowen: 970 },
    { bmIdSt: 157, bmIdCowen: 971 },
    { bmIdSt: 158, bmIdCowen: 972 },
    { bmIdSt: 159, bmIdCowen: 973 },
    { bmIdSt: 160, bmIdCowen: 974 },
    { bmIdSt: 161, bmIdCowen: null },
    { bmIdSt: 162, bmIdCowen: 975 },
    { bmIdSt: 163, bmIdCowen: 976 },
    { bmIdSt: 164, bmIdCowen: null },
    { bmIdSt: 165, bmIdCowen: null },
    { bmIdSt: 166, bmIdCowen: 977 },
    { bmIdSt: 167, bmIdCowen: null },
    { bmIdSt: 168, bmIdCowen: 978 },
    { bmIdSt: 169, bmIdCowen: 979 },
    { bmIdSt: 170, bmIdCowen: null },
    { bmIdSt: 171, bmIdCowen: 980 },
    { bmIdSt: 172, bmIdCowen: null },
    { bmIdSt: 173, bmIdCowen: 981 },
    { bmIdSt: 174, bmIdCowen: 982 },
    { bmIdSt: 175, bmIdCowen: 983 },
    { bmIdSt: 176, bmIdCowen: 984 },
    { bmIdSt: 177, bmIdCowen: 985 },
    { bmIdSt: 178, bmIdCowen: 986 },
    { bmIdSt: 179, bmIdCowen: 987 },
    { bmIdSt: 180, bmIdCowen: 988 },
    { bmIdSt: 181, bmIdCowen: 989 },
    { bmIdSt: 182, bmIdCowen: 990 },
    { bmIdSt: 183, bmIdCowen: 991 },
    { bmIdSt: 184, bmIdCowen: 992 },
    { bmIdSt: 185, bmIdCowen: 993 },
    { bmIdSt: 186, bmIdCowen: null },
    { bmIdSt: 187, bmIdCowen: 994 },
    { bmIdSt: 188, bmIdCowen: null },
    { bmIdSt: 189, bmIdCowen: 747 },
    { bmIdSt: 190, bmIdCowen: 888 },
    { bmIdSt: 191, bmIdCowen: null },
    { bmIdSt: 192, bmIdCowen: null },
    { bmIdSt: 193, bmIdCowen: 995 },
    { bmIdSt: 194, bmIdCowen: 996 },
    { bmIdSt: 195, bmIdCowen: 997 },
    { bmIdSt: 196, bmIdCowen: null },
    { bmIdSt: 197, bmIdCowen: null },
    { bmIdSt: 198, bmIdCowen: null },
    { bmIdSt: 199, bmIdCowen: null },
    { bmIdSt: 200, bmIdCowen: null }
];

import HttpClient from '../utils/HttpClient';
import { 
    REST_SIDEBAR_UPCOMING_EVENT_PATH,
    REST_SIDEBAR_TRENDING_NOW_PATH,
    REST_SIDEBAR_RECENTLY_VIEWED_PATH
} from '../constants/Rest.constants';
export default class SidebarService {

    static getUpcomingEventData() {
        return HttpClient.get(REST_SIDEBAR_UPCOMING_EVENT_PATH);
    }
    static getTrendingNowData() {
        return HttpClient.get(REST_SIDEBAR_TRENDING_NOW_PATH);
    }
    static getRecentlyViewedData(eqOnly=false) {
        return HttpClient.get(REST_SIDEBAR_RECENTLY_VIEWED_PATH + "?eqOnly=" + eqOnly);
    }
}
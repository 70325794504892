import React from 'react';
import { Link } from "react-router-dom";
import {
    LINK_EQUITIES_COVERAGE_PAGE,
    LINK_EQUITIES_RESEARCH_PAGE,
    LINK_PATH_ANALYSTS_PAGE,
    LINK_PATH_ANALYST_TEAMS_PAGE,
    LINK_MACRO_PAGE,
    LINK_RATES_PAGE,
    LINK_EMERGING_MARKETS_PAGE,
    LINK_FX_PAGE,
    LINK_COMMODITIES_PAGE,
    LINK_REGIONS_US_PAGE,
    LINK_REGIONS_CANADA_PAGE,
    LINK_REGIONS_ASIA_PACIFIC_PAGE,
    LINK_REGIONS_EUROPE_PAGE,
    LINK_REGIONS_EMEA_PAGE,
    LINK_REGIONS_ASIA_PAGE,
    LINK_REGIONS_LATIN_AMERICA_PAGE,
    LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE,
    LINK_DESK_RESEARCH_ASSET_SECURITIZATION,
    LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE

} from '../../constants/LinkPaths.constants';


export default class SiteIndexComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.hideSiteIndexModal = this.hideSiteIndexModal.bind(this);
    }
    hideSiteIndexModal() {
        if (this.props.hideSiteIndexModal) {
            this.props.hideSiteIndexModal();
        }
    }
    render() {
        return (
            <React.Fragment>
                <h2>Site Index</h2>
                <div className="tds-site-index-container">
                    <div className="tds-site-index-links">
                        <h3 className="tds-site-index-link-title">
                            Analyst
                        </h3>
                        <ul>
                            <li>
                                <Link
                                    to={LINK_PATH_ANALYSTS_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Analysts'}
                                >
                                    Analysts
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_PATH_ANALYST_TEAMS_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Analyst Teams'}
                                >
                                    Analyst Teams
                                </Link>
                            </li>
                        </ul>
                        <h3 className="tds-site-index-link-title">
                            <a
                                href="https://www.tdsecurities.com/ca/en/research-strategy"
                                target="_blank"
                                title="About Us"
                                rel="noopener noreferrer">
                                About Us
                            </a>
                        </h3>
                        <h3 className="tds-site-index-link-title">
                            Equities
                        </h3>
                        <ul>
                            <li>
                                <Link
                                    to={LINK_EQUITIES_RESEARCH_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Research'}
                                >
                                    Research
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_EQUITIES_COVERAGE_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Coverage Universe'}
                                >
                                    Coverage Universe
                                </Link>
                            </li>
                            <li>                                
                                <Link
                                    to={LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Index Market Structures'}
                                >
                                    Index Market Structures
                                </Link>
                            </li>
                            <li>
                                Exchange Traded Funds (ETFs)
                            </li>
                        </ul>
                        <h3 className="tds-site-index-link-title">
                            Regions
                        </h3>
                        <ul>
                            <li>
                                <Link
                                    to={LINK_REGIONS_US_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'United States'}
                                >
                                    US
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_CANADA_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Canada'}
                                >
                                    Canada
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_ASIA_PACIFIC_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Asia Pacific'}
                                >
                                    Asia-Pacific
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_EUROPE_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Europe'}
                                >
                                    Europe
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_EMEA_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'EMEA'}
                                >
                                    EMEA
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_ASIA_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Asia'}
                                >
                                    Asia
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={LINK_REGIONS_LATIN_AMERICA_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Latin America'}
                                >
                                    Latin America
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="tds-site-index-links">
                        <h3 className="tds-site-index-link-title">
                            <Link
                                to={LINK_MACRO_PAGE}
                                onClick={this.hideSiteIndexModal}
                                title={'Macro'}
                            >
                                Macro
                            </Link>
                        </h3>
                        <h3 className="tds-site-index-link-title">
                            <Link
                                to={LINK_RATES_PAGE}
                                onClick={this.hideSiteIndexModal}
                                title={'Rates'}
                            >
                                Rates
                            </Link>
                        </h3>
                        <h3 className="tds-site-index-link-title">
                            Credit
                        </h3>
                        <ul>
                            <li>
                                High Yield
                            </li>
                            <li>
                                Investment Grade
                            </li>
                        </ul>
                        <h3 className="tds-site-index-link-title">
                            <Link
                                to={LINK_EMERGING_MARKETS_PAGE}
                                onClick={this.hideSiteIndexModal}
                                title={'Emerging Markets'}
                            >
                                Emerging Markets
                            </Link>
                        </h3>
                        <h3 className="tds-site-index-link-title">
                            <Link
                                to={LINK_FX_PAGE}
                                onClick={this.hideSiteIndexModal}
                                title={'FX'}
                            >
                                FX
                            </Link>
                        </h3>
                        <h3 className="tds-site-index-link-title">
                            <Link
                                to={LINK_COMMODITIES_PAGE}
                                onClick={this.hideSiteIndexModal}
                                title={'Commodities'}
                            >
                                Commodities
                            </Link>
                        </h3>
                    </div>
                    <div className="tds-site-index-links">
                        <h3 className="tds-site-index-link-title">
                            Trade and Forecast
                        </h3>
                        <ul>
                            <li>
                                Global Strategy
                            </li>
                            <li>
                                Asset Securization
                            </li>
                        </ul>
                        <h3 className="tds-site-index-link-title">
                            Desk Research
                        </h3>
                        <ul>
                            <li>
                                <Link
                                    to={LINK_DESK_RESEARCH_ASSET_SECURITIZATION}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Asset Securitization'}
                                >
                                    Asset Securitization
                                </Link>                                
                            </li>
                            <li>
                                <Link
                                    to={LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE}
                                    onClick={this.hideSiteIndexModal}
                                    title={'Exchange Traded Funds (ETFs)'}
                                >
                                    Exchange Traded Funds (ETFs)
                                </Link>                                
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

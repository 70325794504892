import {
    SECURE_LINK_APPEND,
    ALPHA_FICM,
    ALPHA_EQ
} from '../constants/Misc.constants';
import {
    HeaderTopNavUserMenuData
} from '../constants/HeaderNav.constants';

const defaultAccess = {
    ficm: true,
    eq: true
}

export default class UserService {

    static user = { };

    static setUser(user) {
        console.log("User Info:", user); // DO NOT REMOVE 
        if (user.email) {
            var _secureLinkEmail = user.email + SECURE_LINK_APPEND;
            _secureLinkEmail = btoa(_secureLinkEmail);
            user.secureLinkEmail = _secureLinkEmail;
        }
        UserService.user = user;
        UserService.user.access = { ...defaultAccess };
        if (UserService.user.accessLevel && UserService.user.accessLevel.length > 0) {
            UserService.user.access.ficm = !!UserService.user.accessLevel.includes(ALPHA_FICM);
            UserService.user.access.eq = !!UserService.user.accessLevel.includes(ALPHA_EQ);
        }
        UserService.updateMenu();
        console.log("User Access:", UserService.user.access); // DO NOT REMOVE 
    }
    static updateMenu() {
        if (UserService.user.access.ficm && !UserService.user.access.eq) {
            HeaderTopNavUserMenuData[0].items.shift();
        }
    }

    static getSecureLinkEmail() {
        return UserService.user.secureLinkEmail;
    }

    static isFicm() {
        return UserService.user?.access?.ficm;
    }

    static isEQ() {
        return UserService.user?.access?.eq;
    }

    static isFicmOnly() {
        return UserService.user?.access?.ficm && !UserService.user?.access?.eq;
    }

    static isEQOnly() {
        return !UserService.user?.access?.ficm && UserService.user?.access?.eq;
    }

    static isBoth() {
        return UserService.user?.access?.ficm && UserService.user?.access?.eq;
    }

    static hasAccess(ACL) {
        var ficmAccess = false;
        var eqAccess = false;
        // check FICM access
        if (UserService.user?.access?.ficm && ACL.FICM) {
            ficmAccess = true;
        }
        // check EQ access
        if (UserService.user?.access?.eq && ACL.EQ) {
            eqAccess = true;
        }
        return ficmAccess || eqAccess;
    }
    
}
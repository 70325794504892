import React from 'react';
import { Link } from 'react-router-dom';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../misc/mailto/Mailto.component';
import {
    EMAIL_DELIMITER,
    TDS_DATE_FORMAT,
    ERROR_TYPE
} from '../../constants/Misc.constants';
import {
    LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_DETAILS_PAGE
} from '../../constants/LinkPaths.constants';
import ErrorComponent from '../misc/error/Error.component';
import SecureLinkService from '../../services/SecureLink.service';

export default class ExchangeTradedFundsSectionComponent extends React.Component {
    error() {
        return !this.props.data && !this.props.type && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    noResults() {
        return this.props.data && this.props.data && this.props.data.length === 0 && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    render() {
        return (
            <React.Fragment>
                { this.error()}
                { this.props.type && this.props.data &&
                    <div className="exchange-traded-funds-section">
                        <h2>{this.props.type.title}</h2>
                        {this.noResults()}
                        {this.props.data.length > 0 &&
                            <div className="results-container">
                                {
                                    this.props.data.map((item, i) => {
                                        item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
                                        return (
                                            <div key={i} className="tds-row">
                                                <div className="tds-column title">
                                                    <span className="etf-title">{item.title}</span>
                                                    <span className="etf-date">
                                                        <FormatedDateComponent
                                                            date={item.updated}
                                                            format={TDS_DATE_FORMAT}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="tds-column action">
                                                    <span className="etf-date">
                                                        <FormatedDateComponent
                                                            date={item.updated}
                                                            format={TDS_DATE_FORMAT}
                                                        />
                                                    </span>
                                                    <div className="links">
                                                        {/*<a*/}
                                                        {/*    href={item.pdfLink}*/}
                                                        {/*    title="View"*/}
                                                        {/*    target="_blank"*/}
                                                        {/*    rel="noopener noreferrer"*/}
                                                        {/*>*/}
                                                        {/*    <img alt="View" className="tds-view-icon" src="/assets/img/view-icon.svg" />*/}
                                                        {/*</a>*/}
                                                        <a
                                                            href={item.pdfLink}
                                                            title="View PDF"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <span
                                                                className="td-icon td-icon-insights icon-small"
                                                                data-firmid={item.firmId}
                                                                data-productid={item.productId}
                                                                data-filename={item.title}
                                                            ></span>
                                                        </a>
                                                        <MailtoComponent
                                                            email={''}
                                                            titleText={'Share'}
                                                            icon={true}
                                                            subject={item.title}
                                                            body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + item.pdfLink}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        { this.props.data.length > 0 &&
                            <Link
                                className="view-all"
                                to={LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_DETAILS_PAGE + this.props.type.path}
                                title={'View All ' + this.props.type.title}
                            >
                                View All &gt;
                            </Link>
                        }
                        { this.props.data.length === 0 && <div className="spacer"></div> }
                    </div>
                }
            </React.Fragment>
        )
    }
}

import React from 'react';
import { withRouter } from "react-router-dom";
import ResponsiveImageComponent from '../../misc/responsive-image/ResponsiveImage.component';
import UtilsService from "../../../services/Utils.service";
import FormatedDateComponent from "../../misc/formated-date/FormatedDate.component";
import {
    TDS_DATE_FORMAT,
    FIRM_NAME_COWEN
} from '../../../constants/Misc.constants';
import SecureLinkService from '../../../services/SecureLink.service';
import UserService from '../../../services/User.service';
import InnerHtmlComponent from "../../misc/inner-html/InnerHtml.component";
import EqOverviewInfoComponent from '../EqOverviewInfo.component';

class TailoredForYouComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            defaultImages: [
                UtilsService.getRandomDefaultImage(),
                UtilsService.getRandomDefaultImage(),
                UtilsService.getRandomDefaultImage(),
                UtilsService.getRandomDefaultImage(),
                UtilsService.getRandomDefaultImage(),
                UtilsService.getRandomDefaultImage()
            ]
        };
        this.handleCardClick = this.handleCardClick.bind(this);
    }
    handleCardClick = (item) => e => {
        if (item?.pdfLink) {
            item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
        }
        if (item.firmName === FIRM_NAME_COWEN) {
            window.open(item.pdfLink || item.htmlLink || item.link);
        }
    };
    render() {
        return (
            <React.Fragment>
                { (UserService.isFicm() && !this.props.eqOnly) &&
                    <div className="tds-three-column-title-container">
                        <h2>Tailored For You</h2>
                    </div>
                }
                {
                    this.props.tailoredForYouData &&
                    this.props.tailoredForYouData.map(
                        (tailoredForYouItem, index) => {
                            return (
                                <div
                                    className="tds-three-column-single-container"
                                    key={index}
                                    onClick={this.handleCardClick(tailoredForYouItem)}
                                >
                                    <ResponsiveImageComponent
                                        className="tds-tailored-for-you-image"
                                        alt={tailoredForYouItem.title}
                                        default={tailoredForYouItem.image || this.state.defaultImages[index]}
                                        tablet1={tailoredForYouItem.tablet1Image || this.state.defaultImages[index]}
                                        tablet2={tailoredForYouItem.tablet2Image || this.state.defaultImages[index]}
                                        mobile={tailoredForYouItem.mobileImage || this.state.defaultImages[index]}
                                        title={tailoredForYouItem.title}
                                        ariaLabel={tailoredForYouItem.ariaLabel}
                                    />
                                    <div className="tds-three-column-single-container-content" title={tailoredForYouItem.title}>
                                        <span className="tds-three-column-single-container-date">
                                            <FormatedDateComponent
                                                date={tailoredForYouItem.date}
                                                format={TDS_DATE_FORMAT}
                                            />
                                        </span>
                                        <h3>{tailoredForYouItem.title}</h3>
                                        <span className="tds-three-column-single-container-byline">
                                            {tailoredForYouItem.primaryAnalyst.firstName} {tailoredForYouItem.primaryAnalyst.lastName}
                                            {tailoredForYouItem.primaryAnalyst.position && <React.Fragment>, {tailoredForYouItem.primaryAnalyst.position}</React.Fragment>}
                                        </span>
                                        {
                                            tailoredForYouItem.firmName === FIRM_NAME_COWEN &&
                                            !UtilsService.isNullorEmpty(
                                                [tailoredForYouItem.recommendation, tailoredForYouItem.risk,
                                                tailoredForYouItem.priceTargetValue, tailoredForYouItem.marketCap
                                                ]) &&
                                            <EqOverviewInfoComponent
                                                item={tailoredForYouItem}
                                            />
                                        }
                                        {
                                            tailoredForYouItem.firmName === FIRM_NAME_COWEN &&
                                            UtilsService.isNullorEmpty([
                                                tailoredForYouItem.recommendation, tailoredForYouItem.risk,
                                                tailoredForYouItem.priceTargetValue, tailoredForYouItem.marketCap]) &&
                                            <span className="tds-three-column-single-container-equities-info">
                                                <InnerHtmlComponent data={tailoredForYouItem.description} />
                                            </span>
                                        }
                                        <span className="tds-three-column-single-container-read-full-article">Read Full Article</span>
                                    </div>
                                </div>
                            );

                        }

                    )}
            </React.Fragment>
        );
    }
}
export default withRouter(TailoredForYouComponent);
import React from 'react';
import RegionCardsComponent from './region-cards/RegionCards.component';
import ForecastSnapshotComponent from './forcast-snapshot/ForecastSnapshot.component';
import RegionPageTabsComponent from './region-page-tabs/RegionPageTabs.component';
import RealTimeCoverageComponent from '../product-page/real-time-coverage/RealTimeCoverage.component';
import ProductTradeIdeasComponent from '../product-page/product-trade-ideas/ProductTradeIdeas.component';
import HomePageTabItem from '../home-page/home-page-tabs/HomePageTabItem.component';
import ResearchCentralBankTabs from './research-central-bank-tabs/ResearchCentralBankTabs.component';
import ThematicResearchDataflowComponent from './thematic-research-dataflow/ThematicResearchDataflow.component';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    HOME_PAGE_MOST_POPULAR_BY_REGION_TAB,
} from '../../constants/HomePage.constants';
import {
    REGION_PAGE_TABS,
    RESEARCH_CENTRAL_BANK_TABS,
    RESEARCH_CENTRAL_BANK_TYPES,
    REGION_PAGE_TOP_TABS
} from '../../constants/RegionPage.constants';
import {
    CSS_DISPLAY_NONE,
    CSS_DISPLAY_BLOCK
} from '../../constants/Misc.constants';
import {
    REAL_TIME_COVERAGE_TYPES
} from '../../constants/RealTimeCoverage.constants';
import {
    TRADE_IDEAS_TYPES
} from "../../constants/TradeIdeas.constants";
import {
    ACL_REGIONS_PAGE
} from '../../constants/Entitlements.constants';
import {
    LINK_REGIONS_BASE_PAGE
} from '../../constants/LinkPaths.constants';

export default class RegionPageComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            currentTab: REGION_PAGE_TABS.REAL_TIME_COVERAGE,
            currentResearchTab: RESEARCH_CENTRAL_BANK_TABS.THEMATIC_RESEARCH,
            regionTopTab: this.getRegionTopTab()
        };
        this.hasAccess = UserService.hasAccess(ACL_REGIONS_PAGE);
        this.tabClicked = this.tabClicked.bind(this);
        this.researchTabClicked = this.researchTabClicked.bind(this);
        this.getRegionTopTab = this.getRegionTopTab.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        document.title = this.props.pageTitle;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getRegionTopTab() {
        for (let i = 0; i < REGION_PAGE_TOP_TABS.length; i++) {
            if (REGION_PAGE_TOP_TABS[i].regions.includes(this.props.type)) {
                return {
                    display: true,
                    regions: REGION_PAGE_TOP_TABS[i].regions,
                    labels: REGION_PAGE_TOP_TABS[i].labels,
                }
            }
        }
        return {
            display: false,
            regions: []
        };
    }
    tabClicked(tab) {
        this.setState(
            {
                currentTab: tab
            }
        );
    }
    researchTabClicked(tab) {
        this.setState(
            {
                currentResearchTab: tab
            }
        );
    }
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-region-page">
                        <h1>{this.props.title}</h1>
                        {
                            this.state.regionTopTab.display &&
                            <div className="tds-region-page-top-tab-container">
                                <div className="tds-region-page-top-tab-menu">
                                    <div className="tds-region-page-top-tab-menu-scroller">
                                        <div className="tds-region-page-top-tab-menu-item">
                                            {
                                                this.state.regionTopTab.regions.map((item, index) => {
                                                    return (
                                                        <a
                                                            key={index}
                                                            href={LINK_REGIONS_BASE_PAGE + item.toLowerCase()}
                                                            title={this.state.regionTopTab.labels[index]}
                                                            className={`tds-region-page-top-tab-link ${item === this.props.type ? 'active': ''}`}
                                                        >
                                                            {this.state.regionTopTab.labels[index]}
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <RegionCardsComponent type={this.props.type} />
                        <ForecastSnapshotComponent type={this.props.type} />
                        <div className="tds-regions-page-research-central-banks-container">
                            <ResearchCentralBankTabs
                                tabClicked={this.researchTabClicked}
                                tab={this.state.currentResearchTab}
                            />
                            <div
                                className="tds-regions-page-research-central-banks-tab-content-container"
                                style={
                                    {
                                        'display': this.state.currentResearchTab === RESEARCH_CENTRAL_BANK_TABS.THEMATIC_RESEARCH ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                    }
                                }
                            >
                                <ThematicResearchDataflowComponent
                                    type={RESEARCH_CENTRAL_BANK_TYPES.THEMATIC_RESEARCH}
                                    region={this.props.type}
                                />
                            </div>
                            <div
                                className="tds-regions-page-research-central-banks-tab-content-container"
                                style={
                                    {
                                        'display': this.state.currentResearchTab === RESEARCH_CENTRAL_BANK_TABS.CENTRAL_BANK ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                    }
                                }
                            >
                                <ThematicResearchDataflowComponent
                                    type={RESEARCH_CENTRAL_BANK_TYPES.CENTRAL_BANK}
                                    region={this.props.type}
                                />
                            </div>
                        </div>
                        <RegionPageTabsComponent
                            tabClicked={this.tabClicked}
                            tab={this.state.currentTab}
                        />
                        <div
                            className="tds-product-page-tab-content-container"
                            style={
                                {
                                    'display': this.state.currentTab === REGION_PAGE_TABS.REAL_TIME_COVERAGE ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <RealTimeCoverageComponent
                                type={REAL_TIME_COVERAGE_TYPES.REGION}
                                regionType={this.props.type}
                            />
                        </div>
                        <div
                            className="tds-product-page-tab-content-container"
                            style={
                                {
                                    'display': this.state.currentTab === REGION_PAGE_TABS.TRADE_IDEAS ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <ProductTradeIdeasComponent
                                type={TRADE_IDEAS_TYPES.REGION}
                                regionType={this.props.type}
                            />
                        </div>
                        <div
                            className="tds-product-page-tab-content-container"
                            style={
                                {
                                    'display': this.state.currentTab === REGION_PAGE_TABS.MOST_POPULAR ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <HomePageTabItem
                                display={this.state.currentTab === REGION_PAGE_TABS.MOST_POPULAR}
                                type={HOME_PAGE_MOST_POPULAR_BY_REGION_TAB}
                                regionType={this.props.type}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

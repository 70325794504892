import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import {
  COOKIE_CONSENT_COOKIE_EXPIRATION,
  COOKIE_CONSENT_COOKIE_NAME
} from "../../constants/GoogleAnalytics.constants";
import { ROUTE_PATH_PREFERENCES } from "../../constants/RoutePaths.constants";

const CookieConsentBanner = (props) => {

  const handleAccept = () => {
    props.acceptCallback();
  };

  const handleDecline = () => {
    props.declineCallBack();
  };

  return (
    <React.Fragment>
      <CookieConsent
        enableDeclineButton
        overlay
        disableStyles={true}
        containerClasses="cookie-consent"
        contentClasses="consent-content"
        overlayClasses="consent-overlay"
        buttonWrapperClasses="consent-buttons"
        buttonClasses="btn td-btn-primary-light"
        declineButtonClasses="btn td-btn-secondary-clear"
        flipButtons
        location="bottom"
        buttonText="Accept additional cookies"
        declineButtonText="Reject additional cookies"
        cookieName={COOKIE_CONSENT_COOKIE_NAME}
        expires={COOKIE_CONSENT_COOKIE_EXPIRATION}
        onAccept={() => handleAccept()}
        onDecline={() => handleDecline()}
      >
        This site uses cookies to authenticate authorized users and enable access to its content.<br/><br/>
        We use cookies and other technologies to deliver personalized content on our websites and relevant advertising on other websites. Unless you change your preferences, these technologies allow us to better align ad and content to your interests.&nbsp;
        <Link to={ROUTE_PATH_PREFERENCES} title="View cookies">
          View cookies
        </Link>        
      </CookieConsent>
    </React.Fragment>
  );
};

export default CookieConsentBanner;
import React from 'react';
import { withRouter } from "react-router-dom";
import UserService from '../../../services/User.service';
import {
    isTablet,
    isMobileOnly
} from "react-device-detect";
import {
    ACL_TOP_NAV_BOOK_MEETING
  } from '../../../constants/Entitlements.constants';

class RouteChangeDetectorComponent extends React.Component {
    lastPath = "";
    allPaths = [];
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            console.log("RouteChangeDetectorComponent: ", location, action, this.lastPath);
            this.allPaths.push(location.pathname);
            if (this.lastPath === location.pathname || this.lastPath === "") {
                this.props.turnOffScrollToTop();
            } else {
                this.props.turnOnScrollToTop();
            }
            this.lastPath = location.pathname;
            if (location === '/search') {
                this.props.hideSearchBar();
            } else {
                this.props.showSearchBar();
            }
            if ((isTablet || isMobileOnly) &&  UserService.hasAccess(ACL_TOP_NAV_BOOK_MEETING)) {
                if (location.pathname.includes('/analysts/profile/')) {
                    this.props.hideMeetingIcon();
                } else {
                    this.props.showMeetingIcon();
                }
            } else {
                this.props.hideMeetingIcon();
            }
            // this function is to fix the GSBM-8334, Back navigation required 2 clicks before, now it needs only 1
            onpopstate = (event) => {
                // Here we check if we are in the search page or not to apply this condition only on search page
                if(this.allPaths[this.allPaths.length-2].split("/").includes("search")){
                    const index = this.allPaths.findIndex( path => path.split("/").includes("search"));
                    if(index !== -1){
                        window.location.href = this.allPaths[index-1];
                    }
                    this.allPaths = this.allPaths.slice(0,index);
                }
            };
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return (
            <span className="tds-route-change-detector">

            </span>
        )
    }
}
export default withRouter(RouteChangeDetectorComponent);
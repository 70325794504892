import React from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../misc/mailto/Mailto.component';
import UtilsService from '../../services/Utils.service';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH,
    FIRM_NAME_RESEARCH,
    QUESTION_MARK,
    EQUALS,
    AMPERSAND, QUERY_PARAM_FIRM_NAME, FIRM_NAME_COWEN, QUERY_PARAM_TEAM,
} from '../../constants/Misc.constants';
import {
    LINK_PATH_ANALYST_PROFILE_PAGE,
    LINK_EQUITIES_COMPANY_DETAILS_PAGE,
    LINK_SEARCH_PAGE
} from '../../constants/LinkPaths.constants';
import {
    SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME,
    SEARCH_RESULTS_SHOW_HISTORIC_DATA
} from '../../constants/SearchResults.constants';
import SecureLinkService from '../../services/SecureLink.service';
import { isNotEmpty, isNotEmptyArray, isCowen, isCowenEnabled } from '../../utils/valueUtils';
import InnerHtml from '../misc/inner-html/InnerHtml.component';
import CowenFlagContext from "../../system/CowenFlagContext";

export default class CompanyActionNoteItemComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            cowenEnabled: false,
        }
    }
    displaySpace = (listOfItems, index) => {
        return <>
            {
                (index < (listOfItems.length - 1)) &&
                <> </>
            }
        </>
    }
    isFlashNote() {
        return this.props?.data.bluematrixXmlId === '12'
    }
    isFastFact() {
        return this.props?.data.bluematrixXmlId === '19'
    }

    isIndFlashNote() {
        return this.props?.data.bluematrixXmlId === '21'
    }
    isIndFastFact() {
        return this.props?.data.bluematrixXmlId === '20'
    }

    isFastFactOrIndFlashNote = () => {
        return ['19','20','21'].includes(this.props?.data.bluematrixXmlId);
    }

    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(e);
        });
    }

    getAnalystName(analyst) {
        let analystName = '';
        if (isNotEmpty(analyst?.lastName)) {
            analystName = analyst.lastName;
        }
        if (isNotEmpty(analyst?.firstName)) {
            analystName += `, ${analyst.firstName}`;
        }
        return (isNotEmpty(analystName)) ? analystName : null;
    }

    getAnalysts(analysts) {
        if (isNotEmptyArray(analysts)) {
            return analysts.filter((analyst) =>
                this.getAnalystName(analyst) !== null
            );
        }

        return null;
    }

    getIndustryLink(industryId) {
        const link = LINK_SEARCH_PAGE + QUESTION_MARK + SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME + EQUALS +
            industryId + AMPERSAND + QUERY_PARAM_FIRM_NAME + EQUALS;
        if (!isCowenEnabled(this.state.cowenEnabled, this.context)) {
            return link + FIRM_NAME_RESEARCH;
        }
        const team = this.props.team;
        return link + FIRM_NAME_COWEN + AMPERSAND + QUERY_PARAM_TEAM + EQUALS + team;
    }

    render() {
        const cowenFlag = this.context;
        const securePdfLink = SecureLinkService.buildSecureLink(this.props?.data?.pdfLink);
        const analysts = this.getAnalysts(this.props?.data?.analysts);
        return (
            <React.Fragment>
                {this.props.data &&
                    <div className="tds-company-details-notes-row">
                        <div className="tds-company-details-notes-column date">
                            <FormatedDateComponent
                                date={this.props.data.displayDateTime}
                                format={TDS_DATE_FORMAT}
                            />
                        </div>
                        <div className="tds-company-details-notes-column headline">
                            <div className="tds-company-details-notes-headline-title">
                                <a
                                    href={securePdfLink}
                                    target="_blank"
                                    title={this.props.data.title}
                                    rel="noopener noreferrer"
                                >{this.props.data.title}</a>
                                <MailtoComponent
                                    email={''}
                                    titleText={'Share'}
                                    icon={true}
                                    subject={this.props.data.title}
                                    body={UtilsService.buildEQShareText(
                                        securePdfLink,
                                        this.props.data.riskRating,
                                        this.props.data.recommendation,
                                        this.props.data.twelveMonthTarget,
                                        this.props.data.marketCapitalFd,
                                        this.props.data.releaseDate,
                                        this.props.data.description,
                                        isCowenEnabled(this.state.cowenEnabled, cowenFlag),
                                        this.props.data.bluematrixXmlAbbr
                                    )}
                                />
                            </div>
                            <div className="tds-company-details-notes-background mobile">
                                <div className="tds-company-details-notes-background-label">
                                    Date:
                                </div>
                                <div className="tds-company-details-notes-background-value">
                                    <FormatedDateComponent
                                        date={this.props.data.releaseDate}
                                        format={TDS_DATE_FORMAT}
                                    />
                                </div>
                            </div>
                            <div className="tds-company-details-notes-background mobile">
                                <div className="tds-company-details-notes-background-label">
                                    Type:
                                </div>
                                <div className="tds-company-details-notes-background-value">
                                    {this.props.data.type}  
                                </div>
                            </div>
                                    { isNotEmptyArray(analysts) &&
                                        <div className="tds-company-details-notes-background">
                                            <div className="tds-company-details-notes-background-label">
                                                Analysts:
                                            </div>
                                            <div className="tds-company-details-notes-background-value">
                                                {analysts.map((analyst, index) => (
                                                    <React.Fragment
                                                        key={'tds-company-details-notes-analysts' + analyst.id + '-' + index}
                                                    >
                                                        <Link

                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH
                                                                + (isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? FIRM_NAME_COWEN : FIRM_NAME_RESEARCH) +
                                                                FORWARD_SLASH + analyst.id}
                                                            title={this.getAnalystName(analyst)}
                                                        >
                                                            { this.getAnalystName(analyst) }
                                                        </Link>&nbsp;
                                                    </React.Fragment>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="tds-company-details-notes-background">
                                        <div className="tds-company-details-notes-background-label">
                                            Companies:
                                        </div>
                                        <div className="tds-company-details-notes-background-value">
                                            {this.props.data.companies &&
                                                this.props.data.companies.map((item, index) => (
                                                    <React.Fragment
                                                        key={'tds-company-details-notes-company' + item.issuerId + '-' + index}
                                                    >
                                                        <a
                                                            href={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                            title={item.name}
                                                        >
                                                            {item.name}
                                                        </a>
                                                        {this.displaySpace(this.props.data.companies, index)}
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </div><div className="tds-company-details-notes-background">
                                    <div className="tds-company-details-notes-background-label">
                                        Industries:
                                    </div>
                                    <div className="tds-company-details-notes-background-value">
                                        {this.props.data.industries &&
                                            this.props.data.industries.map((item, index) => (
                                                <React.Fragment
                                                    key={'tds-company-details-notes-' + item.industryId + '-' + index}
                                                >
                                                    <Link
                                                        key={'tds-company-details-notes-industries' + item.industryId + '-' + index}
                                                        to={this.getIndustryLink(item.industryId)}
                                                        title={item.name}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                    {this.displaySpace(this.props.data.industries, index)}
                                                </React.Fragment>
                                            ))}
                                    </div>
                                </div>
                            {this.props.data.twelveMonthTargetValue && !this.isFastFactOrIndFlashNote() &&
                                <div className="tds-company-details-notes-summary">
                                    <>
                                        <div className="tds-company-details-notes-summary-row">
                                            <span className="tds-company-details-notes-summary-row-label">Recommendation: </span>
                                            <span className="tds-company-details-notes-summary-row-value">{this.props.data.recommendation}</span>
                                        </div>
                                        {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                            <div className="tds-company-details-notes-summary-row">
                                                <span className="tds-company-details-notes-summary-row-label">Risk: </span>
                                                <span className="tds-company-details-notes-summary-row-value">{this.props.data.riskRating}</span>
                                            </div>
                                        }
                                        <div className="tds-company-details-notes-summary-row">
                                            <span className="tds-company-details-notes-summary-row-label">12-Month Target Price: </span>
                                            <span className="tds-company-details-notes-summary-row-value">{this.props.data.twelveMonthTarget}
                                                {this.isFlashNote() && !this.props.data.teaser &&

                                                    <a
                                                        href={securePdfLink}
                                                        target="_blank"
                                                        title="More"
                                                        rel="noopener noreferrer"
                                                        className="more-link"
                                                    >
                                                        [more]
                                                    </a>
                                                }
                                            </span>
                                        </div>
                                        {!this.isFlashNote() && this.props.data.marketCapitalFd && 
                                            <div className="tds-company-details-notes-summary-row">
                                                <span className="tds-company-details-notes-summary-row-label">{SEARCH_RESULTS_SHOW_HISTORIC_DATA.includes(this.props.data.bluematrixXmlAbbr) ? "Mkt Cap (f.d.) ($mm):" : "Mkt Cap:"}</span>
                                                <span className="tds-company-details-notes-summary-row-value">
                                                    <span>
                                                        {this.props.data.marketCapitalFd}
                                                    </span>
                                                    {!this.props.data.teaser &&
                                                        <a
                                                            href={securePdfLink}
                                                            target="_blank"
                                                            title="More"
                                                            rel="noopener noreferrer"
                                                            className="more-link"
                                                        >
                                                            [more]
                                                        </a>
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </>
                                </div>
                            }
                            {!this.props.data.twelveMonthTargetValue && !this.isFastFactOrIndFlashNote() &&
                                <div className="tds-company-details-notes-summary">
                                    <div className="tds-company-details-notes-summary-row teaser">
                                        <p>
                                            <InnerHtml data={this.props.data.teaser} />
                                        </p>
                                        <a
                                            href={securePdfLink}
                                            target="_blank"
                                            title="More"
                                            rel="noopener noreferrer"
                                            className="more-link"
                                        >
                                            [more]
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="tds-company-details-notes-column type">
                            {this.props.data.type}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
} 

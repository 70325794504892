import React from 'react';
import PersonSearchResultsItemComponent from './PersonSearchResultsItem.component'
export default class PersonSearchResultsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.createItems()
    }
    createItems() {
        if (this.props.results) {
            this.items = [];
            for (var item of this.props.results) {
                this.items.push(
                    <PersonSearchResultsItemComponent key={item.firstName + item.lastname + item.email} item={item} />
                )
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.results.length === 0 &&
                    <React.Fragment>
                        <div className="tds-search-results-title-ctn">
                            <h1 className="tds-search-results-title">No Results</h1>
                        </div>
                    </React.Fragment>
                }
                {
                    this.props.results.length > 0 &&
                    <React.Fragment>
                        <div className="tds-search-results-title-ctn">
                            <h1 className="tds-search-results-title">Person Search Results</h1>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.items}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment >
        )
    }
}

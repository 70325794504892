import React from 'react';

import {
    RESEARCH_CENTRAL_BANK_TABS
} from '../../../constants/RegionPage.constants';

export default class ResearchCentralBankTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.tabClicked = this.tabClicked.bind(this);
    }
    tabClicked(event)  {
        if (this.props.tabClicked) {
            this.props.tabClicked(event.target.value);
        }
    }
    render() {
        return (
            <div className="tds-regions-page-research-central-banks-tab-menu-container">
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                    <div className="td-multi-single-select-buttons-group">
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3GroupThematicResearch"
                                value={RESEARCH_CENTRAL_BANK_TABS.THEMATIC_RESEARCH} 
                                checked={this.props.tab === RESEARCH_CENTRAL_BANK_TABS.THEMATIC_RESEARCH}
                                id="selectButtonSegmented3-2-1" />
                            <label htmlFor="selectButtonSegmented3-2-1" title="Thematic Research">Thematic Research</label>
                        </div>
                        <div className="td-multi-single-select-button-wrapper">
                            <input 
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3GroupThematicResearch"
                                value={RESEARCH_CENTRAL_BANK_TABS.CENTRAL_BANK} 
                                checked={this.props.tab === RESEARCH_CENTRAL_BANK_TABS.CENTRAL_BANK}
                                id="selectButtonSegmented3-2-2" />
                            <label htmlFor="selectButtonSegmented3-2-2" title="Dataflow and Central Banks">Dataflow and Central Banks</label>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }
}

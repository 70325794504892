import React from 'react';
import AnalystProfileCard from '../analyst-profile-card/AnalystProfileCard.component';
import AnalystCardComponent from '../analyst-card/AnalystCard.component';
import ErrorComponent from '../../misc/error/Error.component';
import { ERROR_TYPE } from '../../../constants/Misc.constants';
import { getTeam } from '../../../utils/valueUtils';

export default class AnalystTabsContentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="tds-analyst-teams-tab-content">
                {
                    this.props.analystTabContent && this.props.analystTabContent.mainProfile &&
                    <AnalystProfileCard
                        analyst={this.props.analystTabContent.mainProfile}
                        nomeeting={true}
                        link={true}
                        team={getTeam(this.props.analystTabContent.title)}
                    />
                }
                { this.props.analystTabContent && this.props.analystTabContent.analystsTabSection.map((item, index) => {
                    return (
                        <React.Fragment>
                            { item &&
                                <div className="tds-analyst-teams-grid" key={index}>
                                    <div className="tds-analyst-teams-grid-title">
                                        <h2>{item.title}</h2>
                                    </div>
                                    {item.analysts.map((analystItem, index) => {
                                        analystItem.division = {
                                            name: item.title
                                        }
                                        return <AnalystCardComponent key={analystItem.firstName + index} analyst={analystItem} />
                                    })}
                                </div>
                            }
                        </React.Fragment>
                    )
                })
                }
                { this.props.analystTabContent && this.props.analystTabContent.analystsTabSection.length === 0 &&
                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                }
            </div>
        )
    }
}

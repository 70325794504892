import React from 'react';
import Spinner from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import CompanyListComponent from '../misc/company-list/CompanyList.component';
import CoveragePageService from '../../services/CoveragePage.service';
import DownloadFileService from '../../services/DownloadFile.service';
import {
    COVERAGE_COMPANY_SORT_BY_NAME,
    COVERAGE_COMPANY_SORT_BY_SYMBOL,
    COVERAGE_COMPANY_SORT_BY_ANALYSTS,
    COVERAGE_COMPANY_SORT_BY_INDUSTRY
} from '../../constants/CoveragePage.constants';
import {
    ERROR_TYPE
} from '../../constants/Misc.constants';
import {
    REST_EQUITIES_COMPANIES_DOWNLOAD
} from '../../constants/Rest.constants';

export default class CoverageCompaniesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            data: null,
            sort: COVERAGE_COMPANY_SORT_BY_NAME
        };
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        var params = {
            sortBy: this.state.sort
        }
        CoveragePageService.getCompanies(params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState( {
            loading: false,
            data: data,
        });
    }
    getDataError(error) {
        this.setState( {
            loading: false,
            data: null,
            error: true
        });
    }
    handleSortChange(event) {
        this.setState( {
            sort: event.target.value
        }, this.getData);
    }
    handleDownload() {
        DownloadFileService.downloadExcel(REST_EQUITIES_COMPANIES_DOWNLOAD, 'companies');
    }
    render() {
        return (
            <div className="tds-coverage-page-tab-content-companies">
                <div className="tds-companies-tab-action-bar">
                    <span className="tds-companies-tab-action-bar-sort-label">
                        <label htmlFor="tds-companies-tab-action-bar-sort-by">
                            Sort By
                        </label>
                    </span>
                    <div className="tds-companies-tab-action-bar-sort-dropdown form-group td-form-group-padding">
                        <div className="td-select">
                            <select 
                                className="form-control"
                                id="tds-companies-tab-action-bar-sort-by"
                                onChange={this.handleSortChange}
                            >
                                <option value={COVERAGE_COMPANY_SORT_BY_NAME}>{COVERAGE_COMPANY_SORT_BY_NAME}</option>
                                <option value={COVERAGE_COMPANY_SORT_BY_SYMBOL}>{COVERAGE_COMPANY_SORT_BY_SYMBOL}</option>
                                <option value={COVERAGE_COMPANY_SORT_BY_INDUSTRY}>{COVERAGE_COMPANY_SORT_BY_INDUSTRY}</option>
                                <option value={COVERAGE_COMPANY_SORT_BY_ANALYSTS}>{COVERAGE_COMPANY_SORT_BY_ANALYSTS}</option>                                
                            </select>
                        </div>
                    </div>
                    <div className="tds-companies-tab-action-bar-download">
                        <button 
                            onClick={this.handleDownload}
                            title="Download in Excel"
                            className="btn btn-block td-btn-primary-light">
                            <span className="td-icon td-icon-download"></span>
                            <span>Download in Excel</span>
                        </button>
                    </div>
                </div>
                {
                    this.state.loading && 
                    <div className="tds-loading">
                        <Spinner />
                    </div>
                }
                {
                    !this.state.loading && this.state.data && this.state.data.length === 0 &&
                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                }
                {
                    !this.state.loading && this.state.data && this.state.data.length > 0 &&
                    <CompanyListComponent
                        data={this.state.data}
                        symbol={this.state.sort === COVERAGE_COMPANY_SORT_BY_SYMBOL}
                    />
                }
                {
                    !this.state.loading && this.state.error &&
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                }
            </div>
        )
    }
}

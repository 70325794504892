import { REST_INDEX_MARKET_STRUCTURES_PATH } from '../constants/Rest.constants';
import HttpClient from '../utils/HttpClient';


export default class IndexMarketStructuresService {

    static getIMSData(params) {
        return HttpClient.get(REST_INDEX_MARKET_STRUCTURES_PATH, params, {});
    }

}
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LandingSubPageHeaderComponent from '../landing-page/header/LandingSubPageHeader.component';
import LandingPageFooterComponent from '../landing-page/footer/LandingPageFooter.component';
import { LINK_LANDING_PAGE } from "../../constants/LinkPaths.constants";

const LoggedoutCompletedPageComponent = (props) => {
    let history = useHistory();

    const redirectToLandingPage = () => {
        history.push(LINK_LANDING_PAGE);
    }

    useEffect(() => {
        window.setTimeout(redirectToLandingPage, 5000);
    })

    return (
        <div className="tds-landing-sub-page-container">
            <LandingSubPageHeaderComponent />
            <main>
                <div className="tds-container">
                    <div className="tds-loggedout">
                        <h1>You have been successfully logged out</h1>
                    </div>
                </div>
            </main>
            <LandingPageFooterComponent />
        </div>
    );

}

export default LoggedoutCompletedPageComponent;
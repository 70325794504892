import React from 'react';
import Spinner from '../../spinner/Spinner.component';
import ShowContentComponent from '../../misc/show-content/ShowContent.component';
import ErrorComponent from '../../misc/error/Error.component';
import RegionPageService from '../../../services/RegionPage.service';
import { ERROR_TYPE } from '../../../constants/Misc.constants';
import {isNotEmptyArray} from '../../../utils/valueUtils';
import InnerHtml from '../../misc/inner-html/InnerHtml.component';

export default class ForecastSnapshotComponent extends React.Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: false
        };
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }
    getData() {
        RegionPageService.getForecastSnapshotData(this.props.type).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState(
            {
                data: data,
                loading: false
            },
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false
            }
        );
    }
    render() {
        return (
            <div className="tds-regions-page-snapshot-container">
                <h2 className="tds-regions-snapshot-main-title">Forecast Snapshot</h2>
                <ShowContentComponent
                    if={this.state.loading}
                >
                    <Spinner />
                </ShowContentComponent>
                <ShowContentComponent
                    if={!this.state.loading && this.state.error}
                >
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                </ShowContentComponent>
                {
                    !this.state.loading && this.state.data &&
                    <React.Fragment>
                        { isNotEmptyArray(this.state.data?.macro) &&
                            <React.Fragment>
                                <h3 className="tds-regions-snapshot-sub-title">Macro</h3>
                                {this.state.data.macro.map(
                                    (macroItem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                { macroItem &&
                                                    <React.Fragment>
                                                        <p>{
                                                            this.state.data.macroTitles && this.state.data.macroTitles.length > 0 &&
                                                            <React.Fragment>
                                                                <b>
                                                                    {this.state.data.macroTitles[index]}:
                                                                </b>
                                                            </React.Fragment>
                                                        } <InnerHtml data={macroItem}/></p>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                )
                                }
                            </React.Fragment>
                        }
                        {this.state.data.rates && this.state.data.rates.length > 0 &&
                            <React.Fragment>
                                <h3 className="tds-regions-snapshot-sub-title">Rates</h3>
                                {this.state.data.rates.map(
                                    (ratesItem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                { ratesItem &&
                                                    <React.Fragment>
                                                        <p>{
                                                            this.state.data.ratesTitles && this.state.data.ratesTitles.length > 0 &&
                                                            <React.Fragment>
                                                                <b>
                                                                    {this.state.data.ratesTitles[index]}:
                                                                </b>
                                                            </React.Fragment>
                                                        } <InnerHtml data={ratesItem}/></p>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                )
                                }
                            </React.Fragment>
                        }
                        {this.state.data.fx && this.state.data.fx.length > 0 &&
                            <React.Fragment>
                                <h3 className="tds-regions-snapshot-sub-title">FX</h3>
                                {this.state.data.fx.map(
                                    (fxItem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                { fxItem &&
                                                    <React.Fragment>
                                                        <p> {
                                                            this.state.data.fxTitles && this.state.data.fxTitles.length > 0 &&
                                                            <React.Fragment>
                                                                <b>
                                                                    {this.state.data.fxTitles[index]}:
                                                                </b>
                                                            </React.Fragment>
                                                        } <InnerHtml data={fxItem}/></p>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                )
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

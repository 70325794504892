import React from 'react';

export default class HeaderNavLoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    render() {
        return (
            <div className="tds-section-right">
                <div className="tds-login-button-ctn">
                    <button className="btn btn-block td-btn-primary-light"
                    onClick={this.props.onClick}>Login</button>
                </div>
            </div>
        )
    }
}

export var ENV_LOCAL_URL = 'localhost';
export var ENV_DEV_URL = 'gsbm-web.appgw2.eastus2.dev.azure.td.com';
export var ENV_PAT_URL = 'pat.gsbm.tdbank.com';
export var ENV_PROD_URL = 'portal.tdsecurities.com';

export var ENV_LOCAL_LABEL = 'LOCAL';
export var ENV_DEV_LABEL = 'DEV';
export var ENV_PAT_LABEL = 'PAT';
export var ENV_PROD_LABEL = 'PROD';


export var ENV_LOGOUT_URL = {
    LOCAL: {
        iframe1URL: 'https://gsbm-web.appgw2.eastus2.dev.azure.td.com/logout',
        iframe2URL: 'https://tdglobalstrategy-test.bluematrix.com/links2/logout'
    },
    DEV: {
        iframe1URL: 'https://gsbm-web.appgw2.eastus2.dev.azure.td.com/logout',
        iframe2URL: 'https://tdglobalstrategy-test.bluematrix.com/links2/logout'
    },
    PAT: {
        iframe1URL: 'https://pat.gsbm.tdbank.com/logout',
        iframe2URL: 'https://tdglobalstrategy-test.bluematrix.com/links2/logout'
    },
    PROD: {
        iframe1URL: 'https://portal.tdsecurities.com/logout',
        iframe2URL: 'https://tdglobalstrategy.bluematrix.com/links2/logout'
    }
}
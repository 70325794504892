import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  TDS_TOP_NAV_DROPDOWN_LINK_MOBILE,
  TDS_DROPDOWN_CONTENT_MOBILE,
  TDS_DROPDOWN_ACTIVE,
  LINK_DESTINATION_INTERNAL,
  LINK_DESTINATION_EXTERNAL,
  AccessCondition,
} from "../../../constants/HeaderNav.constants";
import UserService from "../../../services/User.service";
import { createBrowserHistory } from "history";
import { LOG_LEVEL, logger } from "../../../system/Logger";
import { isCowen, isCowenEnabled, isNotEmptyArray } from "../../../utils/valueUtils";
import CowenFlagContext from "../../../system/CowenFlagContext";

export default class HeaderNavDropDownMobileComponent extends React.Component {
  dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK_MOBILE;
  dropDownContentClassName = TDS_DROPDOWN_CONTENT_MOBILE;
  static contextType = CowenFlagContext;
  history = createBrowserHistory();
  constructor(props) {
    super(props);
    logger.log(LOG_LEVEL.DEBUG, "HeaderNavDropDownMobileComponent", this.props);
    this.state = {
      open: false,
      redirect: false,
      cowenEnabled: false,
      target: this.history.location.pathname,
    };
    this.hover = false || this.props.hover;
    this.handleOnClick = this.handleOnClick.bind(this);
    this.isItemVisible = this.isItemVisible.bind(this);
  }

  componentDidMount() {
    isCowen().then(r =>{
      this.setState({cowenEnabled: r})
    }).catch(e =>{
      console.log(e);
    });
  }

  handleOnClick(event) {
    event.preventDefault();
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }
  closeMenu() {
    this.setState({ open: false });
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.target} />;
    }
  };
  navigate(target, hasAccess, modalType) {
    if (this.props.closeNavPanel) {
      this.props.closeNavPanel();
    }
    if (UserService.hasAccess(hasAccess)) {
      this.setState(
        { target: target },
        this.setState({
          redirect: true,
        })
      );
    } else {
      this.props.showModal(modalType);
    }
  }
  navigateExternal(target) {
    window.open(target);
  }
  handleLinkClick(e) {
    e.preventDefault();
  }

  isItemVisible(item) {
    if (item.hasAccess && item.showCondition) {
      if (item.showCondition === AccessCondition.AND) {
        return UserService.isBoth();
      } else {
        return UserService.hasAccess(item.hasAccess);
      }
    }
    return true;
  }

  buildMenuLink(menuItem) {
    if (menuItem.destination === LINK_DESTINATION_EXTERNAL) {
      return (
        <li>
          <a
            href={menuItem.link}
            target="_blank"
            rel="noopener noreferrer"
            title={menuItem.text}
            onClick={this.navigateExternal.bind(this, menuItem.link)}
          >
            {menuItem.text}
          </a>
        </li>
      );
    }
    if (
      menuItem.destination === LINK_DESTINATION_INTERNAL &&
      this.isItemVisible(menuItem)
    ) {
      return (
        <li
          onClick={this.navigate.bind(
            this,
            menuItem.link,
            menuItem.hasAccess,
            menuItem.modalType
          )}
        >
          <Link
            to={menuItem.link}
            title={menuItem.text}
            onClick={this.handleLinkClick}
          >
            {menuItem.text}
          </Link>
        </li>
      );
    }
  }

  render() {
    const cowenFlag = this.context;
    if (this.props.dropDownData) {
      return (
        <div className="tds-mobile-navbar-item">
          {this.renderRedirect()}
          <a
            href="/"
            title={this.props.dropDownData.text}
            className={
              this.dropDownClassName +
              `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`
            }
            onClick={this.handleOnClick}
          >
            {this.props.dropDownData.text}
            {!this.state.open && (
              <span className="td-icon td-icon-downCaret icon-mobile"></span>
            )}
            {this.state.open && (
              <span className="td-icon td-icon-upCaret icon-mobile"></span>
            )}
          </a>
          <ul
            className={
              this.dropDownContentClassName +
              `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`
            }
          >
            {this.props.dropDownData.items?.map((item, index) => (
              <React.Fragment
                key={
                  this.props.dropDownData.text + index + "_" + item.header
                    ? item.header
                    : item.title
                }
              >
                {!(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) && item.header && (
                  <div className="menu-header">{item.header}</div>
                )}
                {isNotEmptyArray(item.items) &&
                  item.items.map((subItem, index) => (
                    <span
                      className="sub-menu-item"
                      key={this.props.dropDownData.text + index + "_" + subItem.text}
                    >
                      {this.buildMenuLink(subItem)}
                    </span>
                  ))}
                {this.buildMenuLink(item)}
              </React.Fragment>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}

import React from 'react';
import { REQUEST_ACCESS_MAIL_FROM_MAILTO } from '../../../constants/LandingPage.constants';

export default class ContactUsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <h2>Contact Us</h2>
                <div className="tds-contact-us-container">
                    <div className="tds-contact-us-description">
                        <p>Thank you for visiting our site.</p>
                        <p>For access to additional content on this site, email&nbsp;
                        <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM_MAILTO}>{REQUEST_ACCESS_MAIL_FROM_MAILTO}</a>
                        </p>
                        <p>For special requests or other questions, get in touch with your Sales Person or via the contact details provided below:</p>
                        <div className="tds-contact-us-table">
                            <span className="tds-contact-us-email-column-label">
                                <span>TD Securities Equity Research including ETF and Market & Index Structure Content:</span>
                            </span>
                            <span className="tds-contact-us-email-column-info">
                                <span>Email: <a href="mailto:EquityResearchWebHelp@tdsecurities.com" title="EquityResearchWebHelp@tdsecurities.com">EquityResearchWebHelp@tdsecurities.com</a></span>
                            </span>
                        </div>
                        <div className="tds-contact-us-table">
                            <span className="tds-contact-us-email-column-label">
                                <span>TD Securities Global Strategy including Asset Securitization Content</span>

                            </span>
                            <span className="tds-contact-us-email-column-info">
                                <span>Email: <a href="mailto:TDStrategy@tdsecurities.com" title="TDStrategy@tdsecurities.com">TDStrategy@tdsecurities.com</a></span><br />
                                <span>Phone: <span className="tds-contact-us-phone-text">+001-416-944-5517.</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

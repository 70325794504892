export const SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_DESKTOP = "Keywords, analysts, company, document type etc";
export const SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_TABLET = "Keywords, analysts etc";
export const SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_MOBILE = "Keywords";

export const SEARCH_RESULTS_PAGE_SIZE = 10;

export const SEARCH_RESULTS_SORTBY = 'sortby';
export const SEARCH_RESULTS_ASC = 'ASC';
export const SEARCH_RESULTS_DESC = 'DESC';
export const SEARCH_RESULTS_ORDER_BY_DATE = 'date';
export const SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE = 'doctype';
export const SEARCH_RESULTS_ORDER_BY_TITLE = 'title';
export const SEARCH_RESULTS_ORDER_BY_ANALYST = 'analyst';

export const SEARCH_RESULTS_KEYWORDS_QUERY_PARAM_NAME = 'keywords';
export const SEARCH_RESULTS_ANALYSTS_QUERY_PARAM_NAME = 'analysts';
export const SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME = 'regions';
export const SEARCH_RESULTS_PRODUCTS_QUERY_PARAM_NAME = 'products';
export const SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME = 'documentTypes';
export const SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME = 'documentTypesMatchExact';
export const SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME = 'industries';
export const SEARCH_RESULTS_FIRM_NAME_QUERY_PARAM_NAME = 'firmName';
export const SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME = 'team';

export const SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES = ['CCN', 'CCNL', 'CCNBI', 'CQCN', 'QTC', 'AN', 'CB', 'FC', 'FAF'];
export const SEARCH_RESULTS_SHOW_HISTORIC_DATA = ['AN', 'CB', 'FC', 'FAF'];
export const SEARCH_RESULTS_SHOW_COWEN_DATA = ['CCN', 'CCNL', 'CCNBI', 'CQCN', 'QTC'];
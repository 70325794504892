import React from 'react';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import ProductCardLargeComponent from "./product-card/ProductCardLarge.component";
import ProductCardSmallComponent from "./product-card/ProductCardSmall.component";
import ProductPageTabsComponent from "./product-page-tabs/ProductPageTabs.component";
import ProductTradeIdeasComponent from "./product-trade-ideas/ProductTradeIdeas.component";
import SearchResultsComponent from '../search/SearchResults.component';
import PaginationComponent from '../pagination/Paginator.component';
import RealTimeCoverageComponent from './real-time-coverage/RealTimeCoverage.component';
import ErrorComponent from '../misc/error/Error.component';
import Spinner from "../spinner/Spinner.component";
import ProductPageService from "../../services/ProductPage.service";
import SearchService from "../../services/Search.service";
import UtilsService from "../../services/Utils.service";
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';

import {
    SEARCH_RESULTS_PAGE_SIZE,
    SEARCH_RESULTS_DESC,
    SEARCH_RESULTS_ORDER_BY_DATE
} from '../../constants/SearchResults.constants';

import {
    TDS_DATE_FORMAT,
    CSS_DISPLAY_NONE,
    CSS_DISPLAY_BLOCK,
    ERROR_TYPE,
    FIRM_NAME_STRATEGY,
    AND,
    EQUALS,
    QUESTION_MARK, 
    URL_QUERY_PARAMS_TAB
} from '../../constants/Misc.constants';
import {
    REAL_TIME_COVERAGE_TYPES
} from '../../constants/RealTimeCoverage.constants';
import {
    PRODUCT_PAGE_TABS,
    PRODUCT_PAGE_TYPE,
    PRODUCT_PAGE_TABS_PAGE_URL_PARAMS
} from '../../constants/ProductPage.constants';
import {
    TRADE_IDEAS_TYPES,
    TRADE_IDEAS_STATUS_URL_PARAM,
    TRADE_IDEAS_KEYWORDS_URL_PARAM,
    PRODUCT_TRADE_IDEAS_FILTER
} from "../../constants/TradeIdeas.constants";
import {
    ACL_REGIONS_PAGE
} from '../../constants/Entitlements.constants';
import { 
    RTC_DOC_TYPE_URL_PARAM, 
    RTC_REGION_URL_PARAM 
} from "../../constants/RealTimeCoverage.constants";

class ProductPageComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            currentTab: PRODUCT_PAGE_TABS.PUBLICATIONS,
            dropDownData: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true,
            productPageData: null,
            cardsLoading: true,
            cardsError: false,
            publicationsCurrentPage: 1,
            publicationsLoading: true,
            publicationsError: false,
            tradeIdeasCurrentPage: 1,
            tradeIdeasLoading: true,
            tradeIdeasError: false,
            tradeIdeasStatus: PRODUCT_TRADE_IDEAS_FILTER.ALL,
            tradeIdeasKeywords: '',
            realTimeCoveragePage: 1,
            productList: null
        };
        let urlQueryParams = queryString.parse(this.props.location.search);
        if (urlQueryParams.publicationsPage) {
            this.state.publicationsCurrentPage = parseInt(urlQueryParams.publicationsPage);
        }
        if (urlQueryParams.tradeIdeasPage) {
            this.state.tradeIdeasCurrentPage = parseInt(urlQueryParams.tradeIdeasPage);
        }
        if (urlQueryParams.realTimeCoveragePage) {
            this.state.realTimeCoveragePage = parseInt(urlQueryParams.realTimeCoveragePage);
        }
        if (urlQueryParams.tradeIdeasStatus) {
            this.state.tradeIdeasStatus = urlQueryParams.tradeIdeasStatus;
        }
        if (urlQueryParams.tradeIdeasKeywords) {
            this.state.tradeIdeasKeywords = urlQueryParams.tradeIdeasKeywords;
        }
        if (urlQueryParams.rtcDocType) {
            this.state.rtcDocType = urlQueryParams.rtcDocType;
        }
        if (urlQueryParams.rtcRegion) {
            this.state.rtcRegion = UtilsService.getHomePageRegionByValue(urlQueryParams.rtcRegion);
        }
        if (urlQueryParams.tab) {
            this.state.currentTab = urlQueryParams.tab;
        }


        this._lastType = this.props.title;
        this.hasAccess = UserService.hasAccess(ACL_REGIONS_PAGE);
        this.getData = this.getData.bind(this);
        this.goToPublicationsPage = this.goToPublicationsPage.bind(this);
        this.goToPublicationsInitialPage = this.goToPublicationsInitialPage.bind(this);
        this.tabClicked = this.tabClicked.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.rtcDocTypeChange = this.rtcDocTypeChange.bind(this);
        this.rtcRegionChange = this.rtcRegionChange.bind(this);
        this.rtcPageChange = this.rtcPageChange.bind(this);
        this.getDropDownDataThen = this.getDropDownDataThen.bind(this);
        this.getDropDownDataError = this.getDropDownDataError.bind(this);
        this.getPublicationsThen = this.getPublicationsThen.bind(this);
        this.getPublicationsError = this.getPublicationsError.bind(this);
        this.getProductCardsThen = this.getProductCardsThen.bind(this);
        this.getProductCardsError = this.getProductCardsError.bind(this);
        this.tradeIdeasStatusChange = this.tradeIdeasStatusChange.bind(this);
        this.tradeIdeasKeywordsChange = this.tradeIdeasKeywordsChange.bind(this);
        this.tradeIdeasPageChange = this.tradeIdeasPageChange.bind(this);
        this.getPublicationsAndUpdateUrl = this.getPublicationsAndUpdateUrl.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.hasAccess) {
            this.getData();
        }
        document.title = this.props.pageTitle;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        document.title = this.props.pageTitle;
    }

    getData() {
        this.getProductCards();
        this.getDropDownData();
    }

    goToPublicationsPage(page) {
        this.setState(
            {
                publicationsCurrentPage: page
            },
            this.getPublicationsAndUpdateUrl
        );
    }
    goToPublicationsInitialPage() {
        this.goToPublicationsPage(this.state.publicationsCurrentPage);
    }
    getDropDownData() {
        this.setState({
            dropDownLoading: false,
            errorLoadingDropDownData: false
        });
        SearchService.getDropDownData().then(this.getDropDownDataThen).catch(this.getDropDownDataError)
    }
    getDropDownDataThen(dropDownData) {
        if (this._isMounted) {
            var productList = [];
            productList = UtilsService.getProductId(dropDownData.products, this.props.dropDownType);
            this.setState({
                dropDownData: dropDownData,
                dropDownLoading: false,
                productList: productList
            }, this.goToPublicationsInitialPage);
        }
    }
    getDropDownDataError(error) {
        this.setState({
            dropDownData: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true
        });
    }
    getProductCards = () => {
        if (!this.props.type) {
            this.setState({
                productPageData: null,
                cardsLoading: false,
                cardsError: true
            });
            return
        }
        this.setState({
            cardsLoading: true,
            cardsError: false,
        });
        ProductPageService.getProductPageData(this.props.type).then(this.getProductCardsThen).catch(this.getProductCardsError);
    }
    getProductCardsThen(productPageData) {
        this.setState({
            productPageData: productPageData,
            cardsLoading: false
        });
    }
    getProductCardsError(error) {
        this.setState({
            productPageData: null,
            cardsLoading: false,
            cardsError: true
        });
    }
    getPublicationsAndUpdateUrl = () => {
        this.getPublications();
        this.updateUrl();
    }
    getPublications = () => {
        this.setState({
            publicationsLoading: true,
            searchResults: null,
        });
        var params = {
            firm: FIRM_NAME_STRATEGY,
            products: this.state.productList,
            page: this.state.publicationsCurrentPage,
            size: SEARCH_RESULTS_PAGE_SIZE,
            direction: SEARCH_RESULTS_DESC,
            contentSearchTypeOrder: SEARCH_RESULTS_ORDER_BY_DATE
        };
        SearchService.getSearchResults(params).then(this.getPublicationsThen).catch(this.getPublicationsError);
    }
    getPublicationsThen(searchResults) {
        this.setState({
            searchResults: searchResults,
            publicationsLoading: false
        });
    }
    getPublicationsError(error) {
        this.setState({
            searchResults: null,
            publicationsLoading: false,
            publicationsError: true
        });
    }
    isPublicationsLoading = () => {
        return (this.state.publicationsLoading || this.state.dropDownLoading) && !this.isPublicationsError();
    }
    isPublicationsError = () => {
        return this.state.publicationsError || this.state.errorLoadingDropDownData;
    }
    tabClicked(tab) {
        this.setState(
            {
                currentTab: tab
            }, this.updateUrl
        )
    }
    rtcDocTypeChange(value) {
        this.setState({ rtcDocType: value }, this.updateUrl);
    }
    rtcRegionChange(value) {
        this.setState({ rtcRegion: value }, this.updateUrl);
    }
    rtcPageChange(value) {
        this.setState( { realTimeCoveragePage: value }, this.updateUrl);
    }
    tradeIdeasPageChange(value) {
        this.setState( { tradeIdeasCurrentPage: value}, this.updateUrl);
    }
    tradeIdeasStatusChange(value) {
        this.setState({ tradeIdeasStatus: value}, this.updateUrl);
    }
    tradeIdeasKeywordsChange(value){
        this.setState({ tradeIdeasKeywords: value}, this.updateUrl);
    }
    updateUrl() {
        var url = this.props.routePath;
        var urlParamsAdded = false;
        var defaultTab = PRODUCT_PAGE_TABS.PUBLICATIONS;
        if (this.state.currentTab && (this.state.currentTab !== defaultTab)) {
            url += QUESTION_MARK + URL_QUERY_PARAMS_TAB + EQUALS + this.state.currentTab;
            urlParamsAdded = true;
        }
        let pages = [this.state.publicationsCurrentPage, this.state.tradeIdeasCurrentPage, this.state.realTimeCoveragePage];
        let urls = [PRODUCT_PAGE_TABS_PAGE_URL_PARAMS.PUBLICATIONS, PRODUCT_PAGE_TABS_PAGE_URL_PARAMS.TRADE_IDEAS, PRODUCT_PAGE_TABS_PAGE_URL_PARAMS.REAL_TIME_COVERAGE];
        for (let i = 0; i < urls.length; i++) {
            if (pages[i] > 1) {
                if (urlParamsAdded) {
                    url += AND;
                } else {
                    url += QUESTION_MARK;
                }
                url += urls[i] + EQUALS + pages[i]
                urlParamsAdded = true;
            }
        }
        if (this.state.tradeIdeasStatus && (this.state.tradeIdeasStatus !== PRODUCT_TRADE_IDEAS_FILTER.ALL)) {
            if (urlParamsAdded) {
                url += AND;
            } else {
                url += QUESTION_MARK;
            }
            url += TRADE_IDEAS_STATUS_URL_PARAM + EQUALS + this.state.tradeIdeasStatus;
            urlParamsAdded = true;
        }
        if (this.state.tradeIdeasKeywords && (this.state.tradeIdeasKeywords !== '')) {
            if (urlParamsAdded) {
                url += AND;
            } else {
                url += QUESTION_MARK;
            }
            url += TRADE_IDEAS_KEYWORDS_URL_PARAM + EQUALS + this.state.tradeIdeasKeywords;
            urlParamsAdded = true;
        }
        if (this.state.rtcDocType && (this.state.rtcDocType !== '')) {
            if (urlParamsAdded) {
                url += AND;
            } else {
                url += QUESTION_MARK;
            }
            url += RTC_DOC_TYPE_URL_PARAM + EQUALS + this.state.rtcDocType;
            urlParamsAdded = true;
        }
        if (this.state.rtcRegion && (this.state.rtcRegion.value !== '')) {
            if (urlParamsAdded) {
                url += AND;
            } else {
                url += QUESTION_MARK;
            }
            url += RTC_REGION_URL_PARAM + EQUALS + this.state.rtcRegion.value;
            urlParamsAdded = true;
        }
        this.props.history.replace(url);
    }
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-product-page">
                        <h1>{this.props.title}</h1>
                        {this.state.cardsLoading && <Spinner minHeight={500} />}
                        {!this.state.cardsLoading && this.state.productPageData &&
                            <div className="tds-full-width-three-column-container">
                                <div className="tds-product-promo-card-container-column large">
                                    <div className="tds-product-promo-card-container">
                                        {this.state.productPageData.block1 &&
                                            <ProductCardLargeComponent
                                                data={this.state.productPageData.block1}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="tds-product-promo-card-container-column">
                                    <div className="tds-product-promo-card-container">
                                        {this.state.productPageData.block2 &&
                                            <ProductCardSmallComponent
                                                data={this.state.productPageData.block2}
                                            />
                                        }
                                    </div>
                                    <div className="tds-product-promo-card-container">
                                        {this.state.productPageData.block3 &&
                                            <ProductCardSmallComponent
                                                data={this.state.productPageData.block3}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="tds-product-promo-card-container-column">
                                    <div className="tds-product-promo-card-container">
                                        {this.state.productPageData.block4 &&
                                            <ProductCardSmallComponent
                                                data={this.state.productPageData.block4}
                                            />
                                        }
                                    </div>
                                    <div className="tds-product-promo-card-container">
                                        {this.state.productPageData.block5 &&
                                            <ProductCardSmallComponent
                                                data={this.state.productPageData.block5}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.state.cardsLoading && this.state.cardsError &&
                            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                        }
                        <ProductPageTabsComponent
                            tabClicked={this.tabClicked}
                            tab={this.state.currentTab}
                            type={this.props.type}
                        />
                        {this.state.currentTab === PRODUCT_PAGE_TABS.PUBLICATIONS &&
                            <div className="tds-product-page-tab-content-container">
                                {this.isPublicationsLoading() && <Spinner minHeight={500} />}
                                {!this.isPublicationsLoading() && this.state.searchResults &&
                                    this.state.searchResults.results &&
                                    <React.Fragment>
                                        <SearchResultsComponent
                                            results={this.state.searchResults.results}
                                            hideTitle={true}
                                            hideSort={true}
                                            dateFormat={TDS_DATE_FORMAT}
                                        />
                                        {this.state.searchResults.results.length > 0 &&
                                            <PaginationComponent
                                                currentPage={this.state.publicationsCurrentPage}
                                                resultsPerPage={SEARCH_RESULTS_PAGE_SIZE}
                                                total={this.state.searchResults.total}
                                                goToPage={this.goToPublicationsPage}
                                            />
                                        }
                                    </React.Fragment>
                                }
                                {!this.isPublicationsLoading() && this.isPublicationsError() &&
                                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                                }
                            </div>
                        }
                        {this.props.type !== PRODUCT_PAGE_TYPE.MACRO &&
                            <div
                                className="tds-product-page-tab-content-container"
                                style={
                                    {
                                        'display': this.state.currentTab === PRODUCT_PAGE_TABS.TRADE_IDEAS ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                    }
                                }
                            >
                                <ProductTradeIdeasComponent
                                    type={TRADE_IDEAS_TYPES.PRODUCT}
                                    productType={this.props.type}
                                    dropDownType={this.props.dropDownType}
                                    pageChange={this.tradeIdeasPageChange}
                                    statusChange={this.tradeIdeasStatusChange}
                                    keywordsChange={this.tradeIdeasKeywordsChange}
                                    initialPage={this.state.tradeIdeasCurrentPage}
                                    initialStatus={this.state.tradeIdeasStatus}
                                    initialKeywords={this.state.tradeIdeasKeywords}
                                />
                            </div>
                        }
                        <div
                            className="tds-product-page-tab-content-container"
                            style={
                                {
                                    'display': this.state.currentTab === PRODUCT_PAGE_TABS.REAL_TIME_COVERAGE ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <RealTimeCoverageComponent
                                type={REAL_TIME_COVERAGE_TYPES.PRODUCT}
                                productType={this.props.type}
                                productDropDownType={this.props.dropDownType}
                                pageChange={this.rtcPageChange}
                                docTypeChange={this.rtcDocTypeChange}
                                regionChange={this.rtcRegionChange}
                                initialPage={this.state.realTimeCoveragePage}
                                initialDocType={this.state.rtcDocType}
                                initialRegion={this.state.rtcRegion}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(ProductPageComponent);
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DOCUMENT_TYPES_FLASH_NOTES_FAST_FACTS } from "../../constants/DocumentType.constants";
import { LINK_SEARCH_PAGE } from "../../constants/LinkPaths.constants";
import { ERROR_TYPE, FIRM_NAME_RESEARCH } from "../../constants/Misc.constants";
import { SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME, SEARCH_RESULTS_FIRM_NAME_QUERY_PARAM_NAME } from "../../constants/SearchResults.constants";
import ResearchPageService from "../../services/ResearchPage.service";
import {isNotEmptyArray} from "../../utils/valueUtils";
import AnimatedInfoLineComponent from '../misc/animated-reel/AnimatedInfoLine.component';
import ErrorComponent from "../misc/error/Error.component";
import Spinner from '../spinner/Spinner.component';

const FirstTakePublications = (props) => {
  const showViewAllLink = (props?.showViewAllLink === false) ? false : true;
  const [infoLinePublications, setInfoLinePublications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getViewAllLink = () => {
    return LINK_SEARCH_PAGE + "?" + `${SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME}=${DOCUMENT_TYPES_FLASH_NOTES_FAST_FACTS}&${SEARCH_RESULTS_FIRM_NAME_QUERY_PARAM_NAME}=${FIRM_NAME_RESEARCH}`;
  }
  
  useEffect(() => {
    const getFirstTakeDataThen = (data) => {
      setInfoLinePublications(data);
      setIsLoading(false);
    }
    const getDataError = (error) => {
      setInfoLinePublications(null);
      setIsLoading(false);
      setIsError(true);
    }
    const getInfoLinePublications = async () => {
      ResearchPageService.getFirstTake().then(getFirstTakeDataThen).catch(getDataError);
    }
    getInfoLinePublications();
  }, []);

  return (
    <React.Fragment>
      {
        isLoading && <Spinner noContainer={true} outerWidth={'100%'} />
      }
      { !isLoading && isNotEmptyArray(infoLinePublications) &&
        <div className="tds-first-take-animated-info-line">            
            <div className="title"><h3>First Take / Flash Notes Fast Facts</h3></div>
            { showViewAllLink && <div className="view-all-link"><Link to={getViewAllLink()}>View All &gt;</Link></div> }            
            <AnimatedInfoLineComponent data={infoLinePublications}/>
        </div>
      }
      { !isLoading && isError &&
        <div className="tds-first-take-animated-info-line">            
            <div className="title"><h3>First Take / Flash Notes Fast Facts</h3></div>                          
            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />                                                 
        </div>        
      }
    </React.Fragment>
  );
};

export default FirstTakePublications;
import React from 'react';
import { CURRENCIES } from '../../../constants/Misc.constants';
import UtilsService from '../../../services/Utils.service';
import { isNotEmpty } from '../../../utils/valueUtils';

export default class FormatedNumberComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    isCurrency() {
        return isNotEmpty(this.props.currency);
    }
    isCAD() {
        return this.isCurrency() && this.props.currency === CURRENCIES.CAD;
    }
    isUSD() {
        return this.isCurrency() && this.props.currency === CURRENCIES.USD;
    }
    render() {        
        return (
        <React.Fragment>
            { this.props.number && this.props.decimal &&
                <React.Fragment>
                { this.isCurrency() &&
                    <React.Fragment>
                        { (this.isCAD()) ? 'C$' : '$' }
                    </React.Fragment>
                }
                { this.props.commas &&
                    <React.Fragment>
                        {UtilsService.numberWithCommas(UtilsService.formatNumber(this.props.number, this.props.decimal))}
                    </React.Fragment>
                }
                { !this.props.commas &&
                    <React.Fragment>
                        {UtilsService.formatNumber(this.props.number, this.props.decimal)}
                    </React.Fragment>
                }                    
                { this.isUSD() && ' (US)' }
                </React.Fragment>
            }
        </React.Fragment>            
        )
    }
}

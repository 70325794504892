import React, { useEffect, useState } from "react";
import { ERROR_TYPE } from "../../constants/Misc.constants";
import CoveragePageService from "../../services/CoveragePage.service";
import SecureLinkService from "../../services/SecureLink.service";
import ErrorComponent from "../misc/error/Error.component";
import SpinnerComponent from "../spinner/Spinner.component";

const ActionListFullReport = (props) => {

    const [actionListFullReport, setActionListFullReport] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const setLatestActionListFullReport = (data) => {
          data.pdfLink = SecureLinkService.buildSecureLink(data.pdfLink);
          setActionListFullReport(data);
          setIsLoading(false);
        }
        const getDataError = (error) => {
          setActionListFullReport(null);
          setIsLoading(false);
          setIsError(true);
        }
        const getLatestActionListFullReport = async () => {
          CoveragePageService.getLatestActionListFullReport().then(setLatestActionListFullReport).catch(getDataError);
        }
        getLatestActionListFullReport();
    }, []);

    return (
        <React.Fragment>
          {
            isLoading && <SpinnerComponent noContainer={true} outerWidth={'100%'} />
          }
          { !isLoading && isError &&
              <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />       
          }
          { !isLoading && !isError && actionListFullReport !== null &&
            <a
              data-testid="tds-action-list-full-report-container"
              className="tds-action-list-full-report-container"
              href={actionListFullReport.pdfLink}
              title="Action List - Our Best Ideas (Full Report)"
              target="_blank"
              rel="noopener noreferrer"
            >Action List - Our Best Ideas (Full Report)</a>
          }
        </React.Fragment>
    );
};

export default ActionListFullReport;
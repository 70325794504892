import React from 'react';
import SearchResultsComponent from '../../search/SearchResults.component';
import SearchResultsComponentV2 from '../../search/v2/SearchResults.component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchService from '../../../services/Search.service';
import SearchServiceV2 from '../../../services/v2/Search.service';
import Spinner from '../../spinner/Spinner.component';
import ErrorComponent from '../../misc/error/Error.component';
import PaginationComponent from '../../pagination/Paginator.component';
import {
    SEARCH_RESULTS_PAGE_SIZE,
    SEARCH_RESULTS_DESC,
    SEARCH_RESULTS_ASC,
    SEARCH_RESULTS_ORDER_BY_DATE,
    SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE,
    SEARCH_RESULTS_ORDER_BY_TITLE
} from '../../../constants/SearchResults.constants';
import {
    ERROR_TYPE
} from '../../../constants/Misc.constants';
import AnalystProfileSearchInputFilterComponent from '../analyst-profile-search-input-filter/AnalystProfileSearchInputFilter.component';
import { getExactFirmName, isCowen, isCowenEnabled } from "../../../utils/valueUtils";
import CowenFlagContext from "../../../system/CowenFlagContext";

const intialState = {
    hideAfterfirstLoad: true,
    noResultsFound: false,
    currentPage: 1,
    showFilter: false,
    startDate: null,
    endDate: null,
    maxStartDate: new Date(),
    direction: SEARCH_RESULTS_DESC,
    minEndDate: null,
    analysts: null,
    keywords: null,
    documentTypes: null,
    searchResults: null,
    dropDownData: null,
    dropDownLoading: true,
    searchLoading: true,
    errorLoadingDropDownData: false,
    errorLoadingSearchResultsData: false,
    orderBy: SEARCH_RESULTS_ORDER_BY_DATE,
    cowenEnabled: false,
}

export default class AnalystProfileSearchComponent extends React.Component {
    static contextType = CowenFlagContext;
    maxDate = new Date();
    constructor(props) {
        super(props);
        this.state = intialState;
        this.state.analysts = [{
            code: parseInt(this.props.analystId),
            firmName: this.props.firmName
        }];
        this.filterChildComponent = React.createRef();
        this.setNullState = this.setNullState.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateCalenderIconClick = this.handleStartDateCalenderIconClick.bind(this);
        this.handleEndDateCalenderIconClick = this.handleEndDateCalenderIconClick.bind(this);
        this.updateDocumentTypes = this.updateDocumentTypes.bind(this);
        this.updateKeywords = this.updateKeywords.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.getDropDownData = this.getDropDownData.bind(this);
        this.changeSort = this.changeSort.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
            this.getDropDownData();
            this.handleSearch();
          }).catch(e => {
            console.log(e);
          });
    }
    handleFilterClick() {
        if (!this.state.filter) {
            this.setState({
                filter: true
            })
        } else {
            this.setState({
                filter: false
            })
        }
    }
    handleClear() {
        this.filterChildComponent.current.triggerClear();
        this.setState(
            {
                hideAfterfirstLoad: this.state.hideAfterfirstLoad,
                currentPage: 1,
                startDate: null,
                endDate: null,
                analysts: [{
                    code: parseInt(this.props.analystId),
                    firmName: this.props.firmName
                }],
                keywords: null,
                documentTypes: null
            }
        );
    }
    handleApply() {
        this.filterChildComponent.current.closeOptions();  
        this.goToPage(1);
    }
    setNullState = () => {
        var _state = intialState;
        _state.analysts = [{
            code: parseInt(this.props.analystId),
            firmName: this.props.firmName
        }];
        this.setState(intialState);
    }
    getDropDownData() {
        const cowenFlag = this.context;
        this.setState({
            dropDownLoading: false,
            errorLoadingDropDownData: false
        });
        if(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) {
            SearchServiceV2.getDropDownData(this.props.team).then((dropDownData) => {
                this.setState({
                    dropDownData: SearchServiceV2.filterDocumentTypeByFirmName(dropDownData),
                    dropDownLoading: false
                });
            }).catch((error) => {
                this.setState({
                    dropDownData: null,
                    loading: false,
                    errorLoadingDropDownData: true
                });
            })
        } else {
            SearchService.getDropDownData().then((dropDownData) => {
                this.setState({
                    dropDownData: SearchService.filterDocumentTypeByFirmName(dropDownData, this.props.firmName),
                    dropDownLoading: false
                });
            }).catch((error) => {
                this.setState({
                    dropDownData: null,
                    loading: false,
                    errorLoadingDropDownData: true
                });
            })
        }
    }
    handleAnalystChange() {
        this.setState({
            analysts: [{
                code: parseInt(this.props.analystId),
                firmName: this.props.firmName
            }],
            hideAfterfirstLoad: true
        }, this.handleSearch);
    }
    handleSearch = () => {
        this.setState({
            searchLoading: true,
            searchResults: null
        }, this.getSearchResults);

    }
    getSearchResults() {
        const cowenFlag = this.context;
        var params = {
            value: this.state.keywords,
            analysts: this.state.analysts,
            documentTypes: this.convertValues(this.state.documentTypes, true),
            pubFrom: this.state.startDate,
            pubTo: this.state.endDate,
            page: this.state.currentPage,
            size: SEARCH_RESULTS_PAGE_SIZE,
            direction: this.state.direction,
            contentSearchTypeOrder: this.state.orderBy,
            firm: this.props.firmName,
            exactFirm: getExactFirmName(this.props.team)
        };
        if(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) {
            SearchServiceV2.getSearchResults(params).then((searchResults) => {
                let _hideAfterfirstLoad = this.state.hideAfterfirstLoad;
                let _noResultsFound = this.state.noResultsFound;
                if (searchResults.total > 0 && _hideAfterfirstLoad) {
                    _hideAfterfirstLoad = false;
                }
                if (searchResults.total === 0 && _hideAfterfirstLoad) {
                    _hideAfterfirstLoad = false;
                    _noResultsFound = true;
                }
                this.setState({
                    hideAfterfirstLoad: _hideAfterfirstLoad,
                    noResultsFound: _noResultsFound,
                    searchResults: searchResults,
                    searchLoading: false
                });
            }).catch((error) => {
                this.setState({
                    searchResults: null,
                    loading: false,
                    errorLoadingSearchData: true
                });
            })
        } else {
            SearchService.getSearchResults(params).then((searchResults) => {
                var _hideAfterfirstLoad = this.state.hideAfterfirstLoad;
                if (searchResults.total > 0 && _hideAfterfirstLoad) {
                    _hideAfterfirstLoad = false;
                }
                this.setState({
                    hideAfterfirstLoad: _hideAfterfirstLoad,
                    searchResults: searchResults,
                    searchLoading: false
                });
            }).catch((error) => {
                this.setState({
                    searchResults: null,
                    loading: false,
                    errorLoadingSearchData: true
                });
            })
        }
    }
    updateDocumentTypes(documentTypes) {
        this.setState({ documentTypes: documentTypes });
    }
    updateKeywords(keywords) {
        this.setState({ keywords: keywords });
    }
    convertValues = (dropDownValues, isDocumentTypeOrAnalyst) => {
        if (!dropDownValues || dropDownValues.length === 0) {
            return null;
        }
        var dropDownValuesArray = [];
        for (var i = 0; i < dropDownValues.length; i++) {
            if (isDocumentTypeOrAnalyst) {
                dropDownValuesArray.push({
                    code: dropDownValues[i].value,
                    firmName: dropDownValues[i].firmName,
                });
            } else {
                dropDownValuesArray.push(dropDownValues[i].value);
            }
        }
        return dropDownValuesArray;
    }
    handleStartDateChange = date => {
        this.setState({
            startDate: date,
            minEndDate: date
        });
    };
    handleEndDateChange = date => {
        this.setState({
            endDate: date,
            maxStartDate: date
        });
    };
    handleStartDateCalenderIconClick = (event) => {
        this.startDateCalendar.setOpen(true);
    }
    handleEndDateCalenderIconClick = (event) => {
        this.endDateCalendar.setOpen(true);
    }
    changeSort(sort) {
        let _direction = SEARCH_RESULTS_DESC;
        if (sort === SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE ||
            sort === SEARCH_RESULTS_ORDER_BY_TITLE) {
            _direction = SEARCH_RESULTS_ASC;
        }
        this.setState(
            {
                orderBy: sort,
                direction: _direction
            },
            this.goToPage(1)
        );
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.handleSearch
        );
    }
    handleKeyPress(event) {
        this.goToPage(1);
    }
    render() {
        const cowenFlag = this.context;
        return (
            <React.Fragment>
                {
                    this.state.hideAfterfirstLoad && !this.state.noResultsFound &&
                    <Spinner minHeight={1200} />
                }
                {
                    !this.state.hideAfterfirstLoad &&
                    <div className="tds-three-column-container tds-analyst-profile-search-publications">
                        <div className="tds-analyst-profile-search-bar">
                            <h3>Publications</h3>
                            <div className="tds-analyst-profile-filter-container"
                                title={this.state.filter ? "Hide Filters" : "Show Filters"}>
                                <img className="tds-analyst-profile-filter-icon"
                                    src="/assets/img/filter.svg"
                                    alt="filter"
                                    onClick={this.handleFilterClick}
                                />
                                <span
                                    className="tds-analyst-profile-filter-text"
                                    onClick={this.handleFilterClick}>
                                    Filter
                                </span>
                            </div>
                        </div>
                        {
                            this.state.filter && this.state.dropDownLoading &&
                            <Spinner minHeight={100} />
                        }
                        {
                            this.state.filter && !this.state.dropDownLoading &&
                            <div className="tds-analyst-profile-filter-bar">
                                <AnalystProfileSearchInputFilterComponent
                                    ref={this.filterChildComponent}
                                    documentTypes={this.state.dropDownData.documentTypes}
                                    updateDocumentTypes={this.updateDocumentTypes}
                                    updateKeywords={this.updateKeywords}
                                    handleKeyPress={this.handleKeyPress}
                                />
                                <div className="tds-search-item search-item-date-range form-group">
                                    <div className="tds-search-date-from-ctn">
                                        <label className="tds-search-item-label"
                                            htmlFor="date-range">Date Range</label>
                                        <DatePicker
                                            ref={(c) => this.startDateCalendar = c}
                                            className="tds-search-item-input form-control text-right"
                                            selected={this.state.startDate}
                                            placeholderText='MM/DD/YYYY'
                                            onChange={this.handleStartDateChange}
                                            maxDate={this.state.maxStartDate}
                                        />
                                        <span className="td-icon td-icon-bookAppointment"
                                            onClick={this.handleStartDateCalenderIconClick}></span>
                                    </div>
                                    <div className="tds-search-item-date-range-divider">-</div>
                                    <div className="tds-search-date-to-ctn">
                                        <label className="tds-search-item-label"
                                            htmlFor="date-range-from">&nbsp;</label>
                                        <DatePicker
                                            ref={(c) => this.endDateCalendar = c}
                                            className="tds-search-item-input form-control text-right"
                                            selected={this.state.endDate}
                                            placeholderText='MM/DD/YYYY'
                                            onChange={this.handleEndDateChange}
                                            maxDate={this.maxDate}
                                            minDate={this.state.minEndDate}
                                        />
                                        <span className="td-icon td-icon-bookAppointment"
                                            onClick={this.handleEndDateCalenderIconClick}></span>
                                    </div>
                                </div>
                                <div className="tds-search-item search-buttons">
                                    <div className="tds-analyst-profile-clear-button">
                                        <button
                                            className="btn btn-block td-btn-secondary-clear"
                                            onClick={this.handleClear}
                                            title="Clear"
                                        >Clear</button>
                                    </div>
                                    <div className="tds-analyst-profile-search-button">
                                        <button
                                            className="btn btn-block td-btn-primary-light"
                                            onClick={this.handleApply}
                                            title="Apply"
                                        >Apply</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.searchLoading &&
                            <Spinner minHeight={1200} />
                        }
                        {
                            !this.state.searchLoading && this.state.errorLoadingSearchData &&
                            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                        }
                        {
                            !this.state.searchLoading &&
                            this.state.searchResults &&
                            this.state.searchResults.results &&
                            this.state.searchResults.results.length > 0 &&
                            <React.Fragment>
                              { isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? <SearchResultsComponentV2
                                    results={this.state.searchResults.results}
                                    hideTitle={true}
                                    changeSort={this.changeSort}
                                    hideOrderByAnalystOption={true}
                                    orderBy={this.state.orderBy}
                                /> : <SearchResultsComponent
                                    results={this.state.searchResults.results}
                                    hideTitle={true}
                                    changeSort={this.changeSort}
                                    hideOrderByAnalystOption={true}
                                    orderBy={this.state.orderBy}
                                />
                              }
                                <PaginationComponent
                                    currentPage={this.state.currentPage}
                                    resultsPerPage={SEARCH_RESULTS_PAGE_SIZE}
                                    total={this.state.searchResults.total}
                                    goToPage={this.goToPage}
                                />
                            </React.Fragment>
                        }
                        {
                            !this.state.searchLoading &&
                            (!this.state.searchResults ||
                                (this.state.searchResults.results &&
                                    (!this.state.searchResults.results ||
                                        this.state.searchResults.results.length === 0)
                                )
                            ) &&
                            <div className="tds-search-no-results">
                                <ErrorComponent type={ERROR_TYPE.SEARCH_RESULTS} />
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}
export const TDS_CALENDAR_DATE_CREATION_FORMAT = 'YYYY-MM-DD';
export const TDS_CALENDAR_MONTH_FORMAT = 'MMMM';
export const TDS_CALENDAR_MONTH_NUMBER_FORMAT = 'M';
export const TDS_CALENDAR_YEAR_FORMAT = 'YYYY';
export const TDS_CALENDAR_DAY_FORMAT = 'D';
export const TDS_CALENDAR_EVENT_DATE_FORMAT = 'ddd MMM D, YYYY';
export const TDS_CALENDAR_EARNING_DATE_FORMAT = 'MMM D';

export const TDS_CALENDAR_EVENT_TYPE = {
    EARNINGS_RELEASE: 'Earnings Release',
    MARKETING_EVENT: 'Marketing Event',
    CONFERENCE: 'Conference'
}

export const TDS_EVENT_ARROW_CLASSNAME = [
    'first', 'second', 'third', 'fourth', 'fifth'
]
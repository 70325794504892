import React from 'react';
import { DEFAULT_ALL } from '../../../../constants/Misc.constants';
import AlphabetFilterComponent from '../../../misc/alphabet-filter/AlphabetFilter.component';
import CompanyListComponent from '../../../misc/company-list/CompanyList.component';

export default class ResearchCompanyListingComponent extends React.Component {
    constructor(props) {
        super(props);
        let filter = DEFAULT_ALL;
        if(this.props.filter){
            filter = this.props.filter;
        }
        this.state = {
            shownData: this.filterData(),
            filter
        }
        this.filterData = this.filterData.bind(this);
        this.handleAlphabetFilterClick = this.handleAlphabetFilterClick.bind(this);
    }
    componentDidUpdate() {
        this.state.shownData = this.filterData(); // eslint-disable-line
    }
    filterData() {
        if (!this.props.data) { return []; }
        var _data = this.props.data;
        var _shown_data = this.props.data;
        if (this.props.filter !== DEFAULT_ALL) {
            for (var i = 0; i < _data.length; i++) {
                if (_data[i].group === this.props.filter) {
                    var single_letter = [];
                    single_letter.push(_data[i]);
                    _shown_data = single_letter;
                }
            }
        }
        return _shown_data;
    }
    handleAlphabetFilterClick(filter) {
        if (this.props.handleAlphabetFilterClick) {
            this.props.handleAlphabetFilterClick(filter);
        }
    }
    render() {
        return (
            <React.Fragment>
                <AlphabetFilterComponent
                    filter={this.state.filter}
                    handleAlphabetFilterClick={this.handleAlphabetFilterClick}
                    noBorder={true}
                    noPadding={true}
                    disabled={false}
                />
                <CompanyListComponent
                    data={this.state.shownData}
                    singleLetter={this.props.filter !== DEFAULT_ALL}
                />
            </React.Fragment>
        )
    }
}

import { REST_CONFERENCE_CALLS_OTHER_MEDIA_PATH } from '../constants/Rest.constants';
import HttpClient from '../utils/HttpClient';


export default class ConferenceCallsOtherMediaService {

    static async getData(params) {
        return await HttpClient.get(REST_CONFERENCE_CALLS_OTHER_MEDIA_PATH, params, {});
    }

}
import HttpClient from "../../utils/HttpClient";
import {
  REST_FIRST_TAKE_PUBLICATIONS_PATH_V2,
  REST_RESEARCH_LATEST_BY_TYPES_V2,
  REST_RESEARCH_PAGE_V2,
} from "../../constants/Rest.constants";

export default class ResearchPageService {
  static getResearch(team) {
    let params = {
      team: team ? team : ""
    }
    return HttpClient.get(
        REST_RESEARCH_PAGE_V2, params
    );
  }

  static async getFirstTake(team) {
    let params = {
      team: team ? team : ""
    }
    return HttpClient.get(REST_FIRST_TAKE_PUBLICATIONS_PATH_V2, params);
  }
  static getResearchLatestByTypes(team) {
    let params = {
      team: team
    }
    return HttpClient.get(
      REST_RESEARCH_LATEST_BY_TYPES_V2, params
    );
  }
}

export const RM_FORM_TITLE_STRATEGIST_REQUESTED = 'Strategist Requested';
export const RM_FORM_TITLE_TD_REQUESTER = 'TD Requester/Sponsor';
export const RM_FORM_TITLE_DESK_HEAD = 'Desk Head (for Approval)';
export const RM_FORM_TITLE_PREFERRED_MEETING_DATE = 'Preferred Meeting Date';
export const RM_FORM_TITLE_PREFERRED_MEETING_TYPE = 'Preferred Meeting Type';
export const RM_FORM_TITLE_PREFERRED_MEETING_TYPE_SELECT = 'Select Preferred Meeting Type';
export const RM_FORM_TITLE_OTHER_MEETING_TYPE = 'Other';
export const RM_FORM_TITLE_LOCATION = 'Country/State/Province';
export const RM_FORM_TITLE_CLIENT_ACCOUNT_NAME = 'Company';
export const RM_FORM_TITLE_FIRST_AND_LAST_NAME = 'First and Last Name';
export const RM_FORM_TITLE_PHONE_NUMBER = 'Phone Number';
export const RM_FORM_TITLE_PREFERRED_EMAIL = 'Preferred Email';
export const RM_FORM_TITLE_NOTES = 'Notes';

export const RM_FORM_MEETING_TYPE_FACE_TO_FACE = 'Face-to-face';
export const RM_FORM_MEETING_TYPE_CONFERANCE_CALL = 'Conference call';
export const RM_FORM_MEETING_TYPE_CONFERENCE = 'Conference';
export const RM_FORM_MEETING_TYPE_OTHER = 'Other';

export const RM_EMAIL_COLON_SPACE = ': ';
export const RM_EMAIL_SUBJECT_LINE = 'TDS Portal Request a meeting';
export const RM_FORM_DEFAULT_EMAIL = 'TDStrategy@tdsecurities.com';

export const RM_EMAIL_DELIMITER = '%0D%0A';
import HttpClient from '../utils/HttpClient';

import { 
    REST_ANALYSTS_PATH, 
    REST_ANALYST_PROFILE_PATH, 
    REST_ANALYST_TEAMS_PATH 
} from '../constants/Rest.constants';

import {
    FORWARD_SLASH
} from '../constants/Misc.constants';

export default class AnalystService {

    static getAnalysts(params) {
        return HttpClient.get(REST_ANALYSTS_PATH, params);
    }

    static getAnalyst(id, firmName) {
        return HttpClient.get(REST_ANALYST_PROFILE_PATH + firmName + FORWARD_SLASH + id);
    }

    static getAnalystTeams() {
        return HttpClient.get(REST_ANALYST_TEAMS_PATH);
    }
    
}
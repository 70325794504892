import React from 'react';
import RegionCardItemComponent from './RegionCardItem.component';
import Spinner from '../../spinner/Spinner.component';
import ShowContentComponent from '../../misc/show-content/ShowContent.component';
import ErrorComponent from '../../misc/error/Error.component';
import RegionPageService from '../../../services/RegionPage.service';
import { ERROR_TYPE } from '../../../constants/Misc.constants';

export default class RegionCardsComponent extends React.Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: false
        };
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }
    getData() {
        RegionPageService.getRegionCardData(this.props.type).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(_data) {
        this.setState(
            {
                data: _data,
                loading: false
            }
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false
            }
        );
    }
    render() {
        return (
            <div className="tds-full-width-three-column-container">
                <ShowContentComponent
                    if={this.state.loading}
                >
                    <Spinner />
                </ShowContentComponent>
                <ShowContentComponent
                    if={!this.state.loading && this.state.error}
                >
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                </ShowContentComponent>
                {
                    !this.state.loading && this.state.data &&
                    <React.Fragment>
                        <RegionCardItemComponent data={this.state.data.block1} type="main"/>
                        <RegionCardItemComponent data={this.state.data.block2} />
                        <RegionCardItemComponent data={this.state.data.block3} />
                    </React.Fragment>
                }
            </div>
        )
    }
}

import React from 'react';
import {
    CONTACT_CARD_OUTER_CSS_CLASS,
    CONTACT_CARD_ICON_CSS_CLASS,
    CONTACT_CARD_TEXT_CSS_CLASS
} from '../../../constants/Misc.constants';
import DownloadFileService from '../../../services/DownloadFile.service';

export default class ContactCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.getFirstName = this.getFirstName.bind(this);
        this.getLastName = this.getLastName.bind(this);
        this.getOrg = this.getOrg.bind(this);
        this.getEmail = this.getEmail.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getPhone = this.getPhone.bind(this);
    }
    getFirstName() {
        return this.props.firstName || "";
    }
    getLastName() {
        return this.props.lastName || "";
    }
    getOrg() {
        return this.props.org || "";
    }
    getFax() {
        return this.props.fax || "";
    }
    getEmail() {
        return this.props.email || "";
    }
    getTitle() {
        return this.props.title || "";
    }
    getPhone() {
        return this.props.phone || "";
    }
    handleOnClick() {
        var endOfLine = "\n";
        var vcard = "BEGIN:VCARD" + endOfLine +
            "VERSION:2.1" + endOfLine +
            "N:" + this.getLastName() + ";" + this.getFirstName() + endOfLine +
            "FN:" + this.getFirstName() + " " + this.getLastName() + endOfLine +
            "ORG:" + this.getOrg() + endOfLine +
            "TITLE:" + this.getTitle() + endOfLine +
            "TEL;WORK;VOICE:" + this.getPhone() + endOfLine +
            "TEL;WORK;FAX:" + this.getFax() + endOfLine +
            "EMAIL;PREF;INTERNET:" + this.getEmail() + endOfLine +
            "END:VCARD";
        DownloadFileService.download(this.getFirstName() + " " + this.getLastName() + ".vcf", vcard);
    }

    render() {
        return (
            <span
                className={this.props.outerCssClass || CONTACT_CARD_OUTER_CSS_CLASS}
                onClick={this.handleOnClick}
                title="Add to contacts"
            >
                <img
                    className={this.props.iconCssClass || CONTACT_CARD_ICON_CSS_CLASS}
                    alt="Add to contacts"
                    src="/assets/img/icn_contact.svg"
                />
                <span className={this.props.textCssClass || CONTACT_CARD_TEXT_CSS_CLASS}>Add to contacts</span>
            </span>
        )
    }
}

export const PRODUCT_PAGE_TYPE = {
    RATES: 'rates',
    EMERGING_MARKETS: 'em',
    FX: 'fx',
    COMMODITIES: 'commodities',
    MACRO: 'macro'
}

export const PRODUCT_PAGE_DROPDOWN_TYPES = {
    RATES: ['Rates'],
    EMERGING_MARKETS: ['EM'],
    FX: ['FX', 'EM-FX'],
    COMMODITIES: ['Commodities'],
    MACRO: ['Macro']
}

export const PRODUCT_PAGE_TABS = {
    PUBLICATIONS: 'publicationTab',
    TRADE_IDEAS: 'tradeIdeasTab',
    REAL_TIME_COVERAGE: 'realTimeCoverageTab'
}


export const PRODUCT_PAGE_TABS_PAGE_URL_PARAMS = {
    PUBLICATIONS: 'publicationsPage',
    TRADE_IDEAS: 'tradeIdeasPage',
    REAL_TIME_COVERAGE: 'realTimeCoveragePage'
}
import React from 'react';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import CalendarService from '../../services/Calendar.service';
import {
    TDS_CALENDAR_EVENT_DATE_FORMAT
} from '../../constants/Calendar.constants';

export default class CalendarConferenceEventComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleAddToCalendarClick = this.handleAddToCalendarClick.bind(this);
    }
    handleAddToCalendarClick = (index) => {
        if (!this.props.event) {
            return;
        }
        CalendarService.downloadMarketingConferenceEvent(this.props.event);
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.event &&
                    <div className="tds-calender-details-event conference">
                        <h1><FormatedDateComponent date={this.props.event.startDate} format={TDS_CALENDAR_EVENT_DATE_FORMAT} /> - Conference</h1>
                        <h2>{this.props.event.description}</h2>
                        <div className="tds-calender-add-to-calender">
                            <button 
                                className="btn btn-block td-btn-primary-light"
                                onClick={this.handleAddToCalendarClick}    
                            >Add to Calendar</button>
                        </div>
                        <h3>Commentary</h3>
                        <p>{this.props.event.staticCommentary}</p>
                    </div>
                }
            </React.Fragment>
        )
    }
}

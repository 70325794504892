import React from 'react';

export default class AnalystProfileSearchInputFilterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
            selectAll: false,
            clickedOptions: [],
            options: [],
            optionsSelected: [],
            keywords: null
        };
        this.initOptions();
        this.triggerClear = this.triggerClear.bind(this);
        this.closeOptions = this.closeOptions.bind(this);
        this.handleShowOptionClick = this.handleShowOptionClick.bind(this);
        this.handleOptionItemClick = this.handleOptionItemClick.bind(this);
        this.handleOptionItemSelectAll = this.handleOptionItemSelectAll.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    initOptions() {
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            for (var i = 0; i < this.props.documentTypes.length; i++) {
                this.state.clickedOptions.push(false);
            }
        }
    }
    updateOptions() {
        var newOptions = [];
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            for (var i = 0; i < this.props.documentTypes.length; i++) {
                newOptions.push(
                    <li key={'checkbox' + [i]}>
                        <div className="form-check">
                            <input type="checkbox"
                                id={'checkbox' + [i]}
                                data-id={i}
                                checked={this.state.clickedOptions[i]}
                                value={this.props.documentTypes[i].value}
                                onChange={this.handleOptionItemClick.bind(this)}
                            />
                            <label htmlFor={'checkbox' + [i]}>{this.props.documentTypes[i].label}</label>
                        </div>
                    </li>
                );
            }
        }
        this.setState({
            options: newOptions
        });
    }
    handleShowOptionClick() {
        this.setState({
            showOptions: !this.state.showOptions
        });
    }

    closeOptions() {
        this.setState({
            showOptions: false
        });
    }

    updateDocumentTypes() {
        var documentTypesSelected = [];
        for (var i = 0; i < this.props.documentTypes.length; i++) {
            if (this.state.clickedOptions[i] === true) {
                documentTypesSelected.push(this.props.documentTypes[i]);
            }
        }
        this.props.updateDocumentTypes(documentTypesSelected);
    }
    handleOptionItemClick(e) {
        var _state = this.state;
        _state.clickedOptions[e.currentTarget.dataset.id] = !_state.clickedOptions[e.currentTarget.dataset.id];
        this.setState(_state);
        this.updateOptions(this.state.clickedOptions);
        this.createSelectedOptionsList();
        this.updateDocumentTypes()
    }
    handleOptionSelectedItemClick(e) {
        var _state = this.state;
        _state.clickedOptions[e.currentTarget.dataset.id] = false;
        this.setState(_state);
        this.updateOptions(this.state.clickedOptions);
        this.createSelectedOptionsList();
        this.updateDocumentTypes();
    }
    handleKeywordsChange = (event) => {
        this.setState({ keywords: event.target.value }, this.props.updateKeywords(event.target.value));
    }
    createSelectedOptionsList() {
        var newOptionsSelected = [];
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            for (var i = 0; i < this.props.documentTypes.length; i++) {
                if (this.state.clickedOptions[i]) {
                    newOptionsSelected.push(
                        <li 
                            key={'tds-analyst-profile-search-option-selected' + [i]}
                            data-id={i}
                            onClick={this.handleOptionSelectedItemClick.bind(this)}
                        >
                            <span className="tds-analyst-profile-filter-options-selected-label">{this.props.documentTypes[i].label}</span>
                            <span
                                className="tds-analyst-profile-filter-options-selected-close"
                            >x</span>
                        </li>
                    )
                }
            }
        }
        this.setState({
            optionsSelected: newOptionsSelected
        });
    }
    triggerClear() {
        var _clickedOptions = [];
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            for (var i = 0; i < this.props.documentTypes.length; i++) {
                _clickedOptions.push(false);
            }
        }
        this.setState({
            showOptions: false,
            selectAll: false,
            clickedOptions: _clickedOptions,
            optionsSelected: [],
            keywords: null
        }, this.postTrigggerClear() );
        
    }
    postTrigggerClear() {
        this.updateOptions(this.state.clickedOptions);
        this.createSelectedOptionsList();
    }
    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.props.handleKeyPress();
        }
    }
    handleOptionItemSelectAll() {
        var _state = this.state;
        _state.selectAll = !_state.selectAll;
        for (var i = 0; i< _state.clickedOptions.length; i++) {
            if (_state.selectAll) {
                _state.clickedOptions[i] = true;
            } else {
                _state.clickedOptions[i] = false;
            }
        }
        this.setState(_state);
        this.updateOptions(this.state.clickedOptions);
        this.createSelectedOptionsList();
        this.updateDocumentTypes();
    }
    render() {
        return (
            <div className="tds-search-item keywords form-group">
                <label className="tds-search-item-label"
                    htmlFor="keywords">Keywords</label>
                <div className="tds-analyst-profile-filter-keywords-container">
                    <input className="tds-search-item-input form-control"
                        name="keywords"
                        type="text"
                        placeholder="Keywords"
                        value={this.state.keywords || ''}
                        onChange={this.handleKeywordsChange}
                        onKeyPress={this.handleKeyPress}
                    />
                    <span className="tds-analyst-profile-filter-options-button">
                        <span className="tds-analyst-profile-filter-options-button-text"
                            title={this.state.showOptions ? "Collapse Filters" : "Expand Filters"}
                            onClick={this.handleShowOptionClick}>Filters +</span>
                        {
                            this.state.showOptions &&
                            <ul className="tds-analyst-profile-filter-options-dropdown">
                                <li key={'checkboxSelectAll'}>
                                    <div className="form-check">
                                        <input type="checkbox"
                                            id={'checkboxSelectAll'}
                                            data-id={'-1'}
                                            checked={this.state.selectAll}
                                            value={this.state.selectAll}
                                            onChange={this.handleOptionItemSelectAll.bind(this)}
                                        />
                                        <label htmlFor={'checkboxSelectAll'}>Select All</label>
                                    </div>
                                </li>
                                {
                                    this.state.clickedOptions.map((option, i) => (
                                        <li key={'checkbox' + i}>
                                            <div className="form-check">
                                                <input type="checkbox"
                                                    id={'checkbox' + i}
                                                    data-id={i}
                                                    checked={option}
                                                    value={this.props.documentTypes[i].value}
                                                    onChange={this.handleOptionItemClick.bind(this)}
                                                />
                                                <label htmlFor={'checkbox' + i}>{this.props.documentTypes[i].label}</label>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </span>
                </div>
                <ul className="tds-analyst-profile-filter-options-selected">
                    {this.state.optionsSelected}
                </ul>
            </div>
        )
    }
}

import React from 'react';
import EnvironmentService from '../../../services/Environment.service';
import {
  HTTPS
} from '../../../constants/Misc.constants';
import {
  LINK_TDSECURITIES
} from '../../../constants/LinkPaths.constants';

const LandingPageHeaderComponent = (props) => {
  const handleLogin = () => {
    if (!EnvironmentService.isLocal()) {
      window.location.href = HTTPS + EnvironmentService.hostname + '?login=true';
    } else {
      window.location.href = HTTPS + EnvironmentService.hostname + ':' + EnvironmentService.port + '?login=true';
    }
  };
  return (
    <header className="tds-landing-cta-section">
      <span className="tds-landing-logo">
        <a
          href={LINK_TDSECURITIES}
          title="TD Securities"
        >
          <img
            src="/assets/img/tds_shield.svg"
            title="TD Securities"
            alt="TD Securities"
          />
        </a>
      </span>

      <h3 className="tds-landing-header-message">
        Welcome to your central source for Research and Strategy.
      </h3>

      <button
        className="tds-landing-login-button btn btn-block td-btn-primary-light"
        onClick={handleLogin}
      >
        Login
      </button>

      <p className="tds-landing-request-access-message">
        If you've not registered with us and would like to request access,
        please click the
        <strong> Request Access </strong>
        button below.
      </p>

      <button
        className="tds-landing-request-access-button btn btn-block td-btn-secondary-clear"
        onClick={() => props.onRequestFormOpen()}
      >
        Request Access
      </button>
    </header>
  );
};

export default LandingPageHeaderComponent;

import React from 'react';
import { Link } from "react-router-dom";

export default class AnalystProfileCardPicComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                {
                    !this.props.linkDisabled &&
                    <Link to={this.props.link} aria-hidden="true" tabIndex="-1">
                        <span
                            className={this.props.className}
                            style={
                                {
                                    backgroundImage: 'url(' + this.props.defaultImage + ')'
                                }
                            }
                        >
                        </span>
                        <img className="tds-analyst-card-profile-photo-default" alt="" src="/assets/img/Placeholder_Silhouette.jpg" />
                    </Link>
                }
                {
                    this.props.linkDisabled &&
                    <React.Fragment>
                    <span
                        className={this.props.className}
                        style={
                            {
                                backgroundImage: 'url(' + this.props.defaultImage + ')',
                                cursor: 'default'
                            }
                        }
                    >
                    </span>
                    <img className="tds-analyst-card-profile-photo-default" alt=""  src="/assets/img/Placeholder_Silhouette.jpg" />
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

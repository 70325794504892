import React from 'react';
import {
    ERROR_TYPE
} from '../../../constants/Misc.constants';

export default class ErrorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleViewAllClick = this.handleViewAllClick.bind(this);
    }
    handleViewAllClick(e) {
        e.preventDefault();
        if (this.props.handleViewAllClick) {
            this.props.handleViewAllClick(e);
        }
    }
    render() {
        return (
            <div className={'tds-error ' + this.props.type}>
                {
                    this.props.type === ERROR_TYPE.SEARCH_RESULTS &&
                    <span>Sorry, we didn't find results matching your search criteria. You could try adjusting your filter or keyword search.</span>
                }
                {
                    this.props.type === ERROR_TYPE.TRADE_IDEAS &&
                    <span>
                        Sorry, we didn't find results matching your search criteria. You could try adjusting your filter or keyword search.<br />
                        To view all results, <a href="# " onClick={this.handleViewAllClick}>click here.</a>
                    </span>
                }
                {
                    this.props.type === ERROR_TYPE.NO_RECORDS &&
                    <span>No results found</span>
                }
                {
                    this.props.type === ERROR_TYPE.ERROR_ALL &&
                    <span>Sorry, something went wrong. Please try again later.</span>
                }
            </div>
        )
    }
}

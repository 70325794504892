import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import CalendarService from '../../services/Calendar.service';
import SidebarComponent from '../side-bar/SideBar.component';
import ErrorComponent from '../misc/error/Error.component';
import SpinnerComponent from '../spinner/Spinner.component';
import CalendarRowComponent from './CalendarRow.component';
import {
    CALENDAR_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import {
    isMobileOnly
} from "react-device-detect";
import {
    ERROR_TYPE
} from '../../constants/Misc.constants';

import {
    TDS_CALENDAR_DATE_CREATION_FORMAT,
    TDS_CALENDAR_MONTH_FORMAT,
    TDS_CALENDAR_MONTH_NUMBER_FORMAT,
    TDS_CALENDAR_YEAR_FORMAT,
    TDS_CALENDAR_EVENT_TYPE,
    TDS_CALENDAR_DAY_FORMAT,
    TDS_EVENT_ARROW_CLASSNAME
} from '../../constants/Calendar.constants';
import {
    LINK_CALENDER
} from '../../constants/LinkPaths.constants';
import CalendarDayEventComponent from './CalendarDayEvent.component';
import CalendarMarketingEventComponent from './CalendarMarketingEvent.component';
import CalendarConferanceEventComponent from './CalendarConferanceEvent.component';
import CalendarEarningsEventComponent from './CalendarEarningsEvent.component';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import { ACL_CALENDAR } from '../../constants/Entitlements.constants';

class CalendarPageComponent extends React.Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: false,
            eventTypeMarketing: true,
            eventTypeEarnings: true,
            eventTypeConference: true,
            eventId: null
        };
        let urlQueryParams = queryString.parse(this.props.location.search);
        var date = new Date();
        if (urlQueryParams.month) {
            this.state.month = urlQueryParams.month
        } else {
            this.state.month = date.getMonth() + 1;
        }
        if (urlQueryParams.year) {
            this.state.year = urlQueryParams.year
        } else {
            this.state.year = date.getFullYear();
        }
        this.hasAccess = UserService.hasAccess(ACL_CALENDAR);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }
    componentDidMount() {
        document.title = CALENDAR_PAGE_TITLE;
        this._isMounted = true;
        this.getData();
    }
    getData() {
        CalendarService.getCalendar().then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState(
            {
                data: data,
                loading: true,
                error: false
            }, this.calculateRows
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false
            }
        );
    }
    handleClickBackButton(e) {
        e.preventDefault();
        window.history.back()
    }
    initializeRows() {
        var calendarDatesRows = [];
        for (var row = 0; row <= 5; row++) {
            calendarDatesRows[row] = [];
            for (var rowDay = 0; rowDay <= 4; rowDay++) {
                calendarDatesRows[row][rowDay] = {};
            }
        }
        return calendarDatesRows;
    }
    removeBlankTopRow(calendarDatesRows) {
        var _calendarDatesRows = calendarDatesRows;
        var removeTopRow = true;
        for (var rowDay = 0; rowDay <= 4; rowDay++) {
            if (_calendarDatesRows[0][rowDay].dayOfMonth) {
                removeTopRow = false;
            }
        }
        if (removeTopRow) {
            _calendarDatesRows.splice(0, 1);
        }
        return _calendarDatesRows;
    }
    calculateRows() {
        var today = moment();
        var todayDay = today.format(TDS_CALENDAR_DAY_FORMAT);
        var todayMonth = today.format(TDS_CALENDAR_MONTH_NUMBER_FORMAT);
        var todayYear = today.format(TDS_CALENDAR_YEAR_FORMAT);
        var events = this.state.data;
        var date = moment(this.state.year + '-' + this.state.month + '-01', TDS_CALENDAR_DATE_CREATION_FORMAT);
        var monthLongDescription = date.format(TDS_CALENDAR_MONTH_FORMAT);
        var calendarDatesRows = this.initializeRows();
        var currentDayIterator = moment(this.state.year + '-' + this.state.month + '-01', TDS_CALENDAR_DATE_CREATION_FORMAT);
        var current_week = 0;
        this.eventsByDay = [];
        for (var i = 1; i <= date.daysInMonth(); i++) {
            var currentDayOfMonth = currentDayIterator.format(TDS_CALENDAR_DAY_FORMAT);
            var current_weekDay = currentDayIterator.day() - 1;
            currentDayIterator = currentDayIterator.add(1, 'days');
            if (this.state.year == todayYear && this.state.month == todayMonth && currentDayOfMonth == todayDay && current_weekDay > 0) { // eslint-disable-line
                if (calendarDatesRows[current_week][current_weekDay]) {
                    calendarDatesRows[current_week][current_weekDay].today = true;
                }
            }
            var eventsForDay = [];
            if (current_weekDay < 5 && current_weekDay > -1) {
                calendarDatesRows[current_week][current_weekDay].dayOfMonth = currentDayOfMonth;
                calendarDatesRows[current_week][current_weekDay].events = [];
                for (var j = 0; j < events.length; j++) {
                    var dateObj = new Date(events[j].startDate);
                    var eventDate = moment.utc(dateObj);
                    var eventYear = eventDate.format(TDS_CALENDAR_YEAR_FORMAT);
                    var eventMonth = eventDate.format('M');
                    var eventDayOfMonth = eventDate.format(TDS_CALENDAR_DAY_FORMAT);
                    if (eventYear == this.state.year && eventMonth == this.state.month && eventDayOfMonth == currentDayOfMonth) { // eslint-disable-line
                        calendarDatesRows[current_week][current_weekDay].events.push(events[j]);
                        events[j].row = current_week;
                        events[j].column = current_weekDay;
                        events[j].currentDayOfMonth = currentDayOfMonth;
                        eventsForDay.push(events[j]);
                    }
                }
            }
            this.eventsByDay.push(eventsForDay);
            if (current_weekDay == 5) { // eslint-disable-line
                current_week++;
            }
        }
        this.setState({
            monthLongDescription: monthLongDescription,
            calendarDatesRows: this.removeBlankTopRow(calendarDatesRows),
            loading: false,
            events: events
        })
    }
    handlePreviousClick() {
        var newDate = moment(this.state.year + '-' + this.state.month + '-01', TDS_CALENDAR_DATE_CREATION_FORMAT).subtract(1, 'months');
        var newMonth = newDate.format(TDS_CALENDAR_MONTH_NUMBER_FORMAT);
        var newYear = newDate.format(TDS_CALENDAR_YEAR_FORMAT);
        window.history.pushState(null, CALENDAR_PAGE_TITLE, LINK_CALENDER + '?month=' + newMonth + '&year=' + newYear)
        this.setState(
            {
                month: newMonth,
                year: newYear,
                loading: true,
                day: null,
                week: null,
                column: null,
                eventId: null,
                event: null
            }, this.calculateRows
        )
    }
    handleNextClick() {
        var newDate = moment(this.state.year + '-' + this.state.month + '-01', TDS_CALENDAR_DATE_CREATION_FORMAT).add(1, 'months');
        var newMonth = newDate.format(TDS_CALENDAR_MONTH_NUMBER_FORMAT);
        var newYear = newDate.format(TDS_CALENDAR_YEAR_FORMAT);
        window.history.pushState(null, CALENDAR_PAGE_TITLE, LINK_CALENDER + '?month=' + newMonth + '&year=' + newYear)
        this.setState(
            {
                month: newMonth,
                year: newYear,
                loading: true,
                day: null,
                week: null,
                column: null,
                eventId: null,
                event: null
            }, this.calculateRows
        )
    }
    handleEventChange(e) {
        if (e.target.value === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE) {
            this.setState({
                eventTypeEarnings: !this.state.eventTypeEarnings
            });
        } else if (e.target.value === TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT) {
            this.setState({
                eventTypeMarketing: !this.state.eventTypeMarketing
            });
        } else if (e.target.value === TDS_CALENDAR_EVENT_TYPE.CONFERENCE) {
            this.setState({
                eventTypeConference: !this.state.eventTypeConference
            });
        }
    }
    handleEventClick(id) {
        if (id === this.state.eventId) {
            this.setState(
                {
                    eventId: null,
                    event: null
                }
            )
        } else {
            var eventSelected = null;
            for (var i = 0; i < this.state.events.length; i++) {
                if (this.state.events[i].eventId == id) { // eslint-disable-line
                    eventSelected = this.state.events[i];
                }
            }
            this.setState(
                {
                    eventId: id,
                    event: eventSelected,
                    day: eventSelected.currentDayOfMonth,
                    week: eventSelected.row
                }
            )
        }
    }
    handleDayClick(day, week, column) {
        if (this.eventsByDay[day - 1].length > 0) {
            if (day === this.state.day) {
                this.setState(
                    {
                        day: null,
                        week: null,
                        column: null,
                        eventId: null,
                        event: null
                    }
                )
            } else {
                this.setState(
                    {
                        day: day,
                        week: week,
                        column: column,
                        eventId: null,
                        event: null
                    }
                )
            }
        }

    }
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-container tds-calendar-page">
                        <div className="tds-calendar-container">
                            <h1>Calendar</h1>
                            {
                                this.state.loading && !this.state.error && <SpinnerComponent />
                            }
                            {
                                !this.state.loading && this.state.error &&
                                <div className="tds-calendar-error-container">
                                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                                </div>
                            }
                            {
                                !this.state.loading && !this.state.error && !this.state.data && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                            }
                            {
                                !this.state.loading && !this.state.error && this.state.data &&
                                <React.Fragment>
                                    <fieldset className="tds-calendar-instructions">
                                        <legend>
                                            Please click on the category buttons to display each category seperately on the calender.
                                        </legend>
                                        <div className="tds-calendar-event-type form-check form-check-inline">
                                            <input type="checkbox"
                                                id="earnings"
                                                value={TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE}
                                                checked={this.state.eventTypeEarnings === true}
                                                className="earnings"
                                                onChange={this.handleEventChange}
                                            />
                                            <label htmlFor="earnings"
                                                className="earnings">Earnings Release</label>
                                        </div>
                                        <div className="tds-calendar-event-type form-check form-check-inline">
                                            <input type="checkbox"
                                                id="marketing"
                                                value={TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT}
                                                checked={this.state.eventTypeMarketing === true}
                                                onChange={this.handleEventChange}
                                                className="marketing" />
                                            <label htmlFor="marketing"
                                                className="marketing">Marketing Event</label>
                                        </div>
                                        <div className="tds-calendar-event-type form-check form-check-inline">
                                            <input type="checkbox"
                                                id="conference"
                                                value={TDS_CALENDAR_EVENT_TYPE.CONFERENCE}
                                                checked={this.state.eventTypeConference === true}
                                                onChange={this.handleEventChange}
                                                className="conference" />
                                            <label htmlFor="conference"
                                                className="conference">Conference</label>
                                        </div>
                                    </fieldset>
                                    <div className="tds-calender-outer-container">
                                        <div className="tds-calender-container">
                                            <div className="tds-calender-month-header">
                                                <span className="tds-calender-month-previous-month">
                                                    <span
                                                        className="td-icon td-icon-leftCaret icon-small"
                                                        onClick={this.handlePreviousClick}
                                                    ></span>
                                                </span>
                                                <span className="tds-calender-month-label">{this.state.monthLongDescription} {this.state.year}</span>
                                                <span className="tds-calender-month-next-month">
                                                    <span
                                                        className="td-icon td-icon-rightCaret icon-small"
                                                        onClick={this.handleNextClick}
                                                    ></span>
                                                </span>
                                            </div>
                                            <div className="tds-calender-day-row header">
                                                <div className="tds-calender-day-column">
                                                    <span className="day-label-dekstop">Monday</span>
                                                    <span className="day-label-mobile">Mon</span>
                                                </div>
                                                <div className="tds-calender-day-column">
                                                    <span className="day-label-dekstop">Tuesday</span>
                                                    <span className="day-label-mobile">Tues</span>
                                                </div>
                                                <div className="tds-calender-day-column">
                                                    <span className="day-label-dekstop">Wednesday</span>
                                                    <span className="day-label-mobile">Wed</span>
                                                </div>
                                                <div className="tds-calender-day-column">
                                                    <span className="day-label-dekstop">Thursday</span>
                                                    <span className="day-label-mobile">Thur</span>
                                                </div>
                                                <div className="tds-calender-day-column">
                                                    <span className="day-label-dekstop">Friday</span>
                                                    <span className="day-label-mobile">Fri</span>
                                                </div>
                                            </div>
                                            {
                                                this.state.calendarDatesRows.map((row, index) => {
                                                    return (
                                                        <React.Fragment
                                                            key={'calendar-row-' + index}
                                                        >
                                                            <CalendarRowComponent

                                                                row={row}
                                                                week={index}
                                                                handleEventClick={this.handleEventClick}
                                                                handleDayClick={this.handleDayClick}
                                                                event={this.state.event}
                                                                eventTypeMarketing={this.state.eventTypeMarketing}
                                                                eventTypeEarnings={this.state.eventTypeEarnings}
                                                                eventTypeConference={this.state.eventTypeConference}
                                                            />
                                                            {isMobileOnly && this.state.day && this.state.week === index &&
                                                                <div className="tds-calender-day-row mobile-details">
                                                                    <div className={'triangle ' + TDS_EVENT_ARROW_CLASSNAME[this.state.column]}></div>
                                                                    {
                                                                        this.eventsByDay[this.state.day - 1].map((event, index) => {
                                                                            return (
                                                                                <CalendarDayEventComponent
                                                                                    key={'calendar-day-event-' + index}
                                                                                    event={event}
                                                                                    handleEventClick={this.handleEventClick}
                                                                                    eventTypeMarketing={this.state.eventTypeMarketing}
                                                                                    eventTypeEarnings={this.state.eventTypeEarnings}
                                                                                    eventTypeConference={this.state.eventTypeConference}
                                                                                />
                                                                            )
                                                                        }

                                                                        )
                                                                    }
                                                                </div>
                                                            }
                                                            {this.state.event && this.state.event.row === index &&
                                                                <div className="tds-calendar-day-row details">
                                                                    {isMobileOnly &&
                                                                        <div className={'triangle ' + TDS_EVENT_ARROW_CLASSNAME[2]}></div>
                                                                    }
                                                                    {!isMobileOnly &&
                                                                        <div className={'triangle ' + TDS_EVENT_ARROW_CLASSNAME[this.state.event.column]}></div>
                                                                    }
                                                                    {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT &&
                                                                        <CalendarMarketingEventComponent
                                                                            event={this.state.event}
                                                                        />
                                                                    }
                                                                    {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE &&
                                                                        <CalendarEarningsEventComponent
                                                                            event={this.state.event}
                                                                        />
                                                                    }
                                                                    {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.CONFERENCE &&
                                                                        <CalendarConferanceEventComponent
                                                                            event={this.state.event}
                                                                        />
                                                                    }
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="tds-calender-details sidebar">
                                            {this.state.eventId === null &&
                                                <div className="tds-calender-details-no-selection">
                                                    <h1>
                                                        Events Details
                                                    </h1>
                                                    <p>Please select an event from the calendar</p>
                                                </div>
                                            }
                                            {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT &&
                                                <CalendarMarketingEventComponent
                                                    event={this.state.event}
                                                />
                                            }
                                            {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE &&
                                                <CalendarEarningsEventComponent
                                                    event={this.state.event}
                                                />
                                            }
                                            {this.state.eventId && this.state.event.eventType === TDS_CALENDAR_EVENT_TYPE.CONFERENCE &&
                                                <CalendarConferanceEventComponent
                                                    event={this.state.event}
                                                />
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <SidebarComponent />
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(CalendarPageComponent);

import HttpClient from '../utils/HttpClient';
import { 
    REST_REAL_TIME_COVERAGE_PATH,
    REST_REAL_TIME_COVERAGE_REGIONS_PATH, 
    REST_REAL_TIME_COVERAGE_PRODUCTS_PATH 
} from '../constants/Rest.constants';

export default class RealTimeCoverageService {

    static getRealTimeCoverageHomePageData(params) {
        return HttpClient.get(REST_REAL_TIME_COVERAGE_PATH, params);
    }
    
    static getRealTimeCoverageRegionData(type, params) {
        return HttpClient.get(REST_REAL_TIME_COVERAGE_REGIONS_PATH + "/" + type, params);
    }
    
    static getRealTimeCoverageProductData(type, params) {
        return HttpClient.get(REST_REAL_TIME_COVERAGE_PRODUCTS_PATH + "/" + type, params);
    }
}
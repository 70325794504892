import React from 'react';
import { Link } from "react-router-dom";
import Spinner from '../../spinner/Spinner.component';
import PaginationComponent from '../../pagination/Paginator.component';
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import ResponsiveBackgroundImageComponent from '../../misc/responsive-image/ResponsiveImage.component';
import InnerHtmlComponent from "../../misc/inner-html/InnerHtml.component";
import ErrorComponent from "../../misc/error/Error.component";
import UtilsService from "../../../services/Utils.service";
import {
    HOME_PAGE_MOST_RECENT_TAB,
    HOME_PAGE_IN_DEPTHS_TAB,
    HOME_PAGE_TAB_PAGE_SIZE
} from '../../../constants/HomePage.constants';
import {
    LINK_PATH_ANALYST_PROFILE_PAGE
} from '../../../constants/LinkPaths.constants';

import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH,
    ERROR_TYPE,
    FIRM_NAME_COWEN,
} from '../../../constants/Misc.constants';

import EqOverviewInfoComponent from '../EqOverviewInfo.component';
import SecureLinkService from '../../../services/SecureLink.service';
import {isNotEmpty, isNotEmptyArray} from '../../../utils/valueUtils';
import EquityOverviewPageService from '../../../services/v2/EquityOverviewPage.service';
import HomePageService from '../../../services/HomePage.service';

export default class EqOverviewTabItemComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: null,
            currentPage: 1,
            firstLoad: true
        };
        if (this.props.initialPage) {
            this.state.currentPage = parseInt(this.props.initialPage, 10);
        }
        this.goToPage = this.goToPage.bind(this);
        this.getMostRecentData = this.getMostRecentData.bind(this);
        this.getInDepthsData = this.getInDepthsData.bind(this);
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        if (!this.state.firstload) {
            this.setState({
                data: {}
            });
            this.goToPage(this.state.currentPage);
        }
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.team !== this.props.team) {
          this.getData();
        }
    }

    getInDepthsData(params) {
        HomePageService.getInDepthsData(params).then((data) => {
            this.setState({
                data: data,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                data: null,
                loading: false,
                error: true
            });
        })
    }
    getMostRecentData(params) {
            EquityOverviewPageService.getEquityOverviewPageMostRecentTabData(params).then((data) => {
            this.setState({
                data: data,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                data: null,
                loading: false,
                error: true
            });
        })
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.getData
        );
        if (this.props.pageChange && this.props.initialPage !== page) {
            this.props.pageChange(this.props.type, page);
        }
    }
    getData = () => {
        this.setState({
            loading: true,
            error: null
        });
        var params = {
            page: this.state.currentPage,
            size: HOME_PAGE_TAB_PAGE_SIZE,
            eqOnly: this.props.eqOnly,
            team: this.props.team
        };
        if (this.props.type === HOME_PAGE_MOST_RECENT_TAB) {
            this.getMostRecentData(params);
        } else if (this.props.type === HOME_PAGE_IN_DEPTHS_TAB) {
            this.getInDepthsData(params);
        }
    }
    isIndepthReportsTab() {
        return this.props.type === HOME_PAGE_IN_DEPTHS_TAB;
    }
    displayPrimaryAnalyst(item) {
        let primaryAnalyst = '';
        if (isNotEmpty(item?.primaryAnalyst?.firstName)) primaryAnalyst += item?.primaryAnalyst.firstName;
        if (primaryAnalyst.length > 0) primaryAnalyst += ' ';
        if (isNotEmpty(item?.primaryAnalyst?.lastName)) primaryAnalyst += item?.primaryAnalyst.lastName;
        if (isNotEmpty(item?.primaryAnalyst?.position)) {
            if (primaryAnalyst.length > 0) primaryAnalyst += ', ';
            primaryAnalyst += item?.primaryAnalyst.position;
        }        

        return primaryAnalyst;
    }
    render() {
        return (
            <React.Fragment>
                <div className="tds-tab-container home-page" style={
                    {
                        'display': this.props.display ? 'block' : 'none'
                    }
                }>
                    {this.state.loading &&
                        <Spinner
                            textAlign='center'
                            outerWidth='100%'
                            minHeight='1000px'
                        />
                    }
                    {!this.state.loading && this.state.error &&
                        <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                    }
                    {!this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length === 0 &&
                        <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                    }
                    {!this.state.loading && this.state.data &&
                        <React.Fragment>
                            <div className="tds-tab-most-popular-article-list">
                                { isNotEmptyArray(this.state.data.results) ? this.state.data.results.map(
                                    (item, index) => {                                        
                                        item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
                                        const defaultImage = UtilsService.getRandomDefaultImage();
                                        return (
                                            <div
                                                key={'tds-tab-most-popular-article-list-item' + index}
                                                className="tds-tab-most-popular-article-list-item">
                                                <ResponsiveBackgroundImageComponent
                                                    className={'tds-tab-most-popular-article-list-item-img-container'}
                                                    alt={item.title}
                                                    default={item.image || defaultImage}
                                                    tablet1={item.tablet1Image || defaultImage}
                                                    tablet2={item.tablet2Image || defaultImage}
                                                    mobile={item.mobileImage || defaultImage}
                                                />
                                                <div className="tds-tab-most-popular-article-list-item-content">
                                                    <h3>
                                                        {item.firmName === FIRM_NAME_COWEN &&
                                                            <a
                                                                href={item.pdfLink}
                                                                title={item.title}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >{item.title}</a>
                                                        }
                                                    </h3>
                                                    <span className="tds-tab-most-popular-article-list-item-content-date-byline">
                                                        <span className="tds-tab-most-popular-article-list-item-content-date">
                                                            <FormatedDateComponent
                                                                date={item.date}
                                                                format={TDS_DATE_FORMAT}
                                                            />
                                                        </span>
                                                        {
                                                            item.primaryAnalyst.active &&
                                                            <Link
                                                                className="tds-tab-most-popular-article-list-item-content-byline"
                                                                to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + item.firmName +
                                                                    FORWARD_SLASH + item.primaryAnalyst.id}
                                                                title={this.displayPrimaryAnalyst(item)}
                                                            >
                                                                {this.displayPrimaryAnalyst(item)}
                                                            </Link>
                                                        }
                                                        {
                                                            !item.primaryAnalyst.active &&
                                                            <React.Fragment>
                                                                {this.displayPrimaryAnalyst(item)}
                                                            </React.Fragment>
                                                        }
                                                    </span>                                                   
                                                    { (item.firmName === FIRM_NAME_COWEN) && isNotEmpty(item?.description) &&                                                       
                                                        <div className="tds-tab-most-popular-article-list-item-content-description">
                                                            <InnerHtmlComponent data={item.description} />
                                                        </div>
                                                    }
                                                    {
                                                        (item.firmName === FIRM_NAME_COWEN) && !this.isIndepthReportsTab() &&
                                                        !UtilsService.isNullorEmpty([item.recommendation, item.risk, item.priceTargetValue, item.marketCap]) &&
                                                        <EqOverviewInfoComponent
                                                            className={'tds-tab-most-popular-article-list-item-content-equities-info'}
                                                            item={item}
                                                            noTrailingSpace={true}
                                                        />

                                                    }
                                                    {item.firmName === FIRM_NAME_COWEN &&
                                                        <a
                                                            className="tds-tab-most-popular-article-list-item-content-read-full-article"
                                                            href={item.pdfLink}
                                                            title="Read Full Article"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >Read Full Article</a>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                ) : "No results."}
                            </div>
                            <PaginationComponent
                                currentPage={this.state.currentPage}
                                resultsPerPage={HOME_PAGE_TAB_PAGE_SIZE}
                                total={this.state.data.total}
                                goToPage={this.goToPage}
                            />
                        </React.Fragment>
                    }
                </div>
            </React.Fragment >
        )
    }
}


const PADDING_FIRST_LEVEL = "0px 0px 0px 10px";
export const DEFAULT_STATUS = "Please choose a status";
export const DEFAULT_EMP_SELECT = "Please choose 'Yes' or 'No'";


export const MIFID_II_STATUS_DROPDOWN_LIST = [
  {
      label: "Mifid II does not apply to my firm/jurisdiction (i.e. Non-European Client)",
      value: "Mifid II does not apply to my firm/jurisdiction (i.e. Non-European Client)",
      padding: PADDING_FIRST_LEVEL,
      width: "auto",
      fontWeight: "bold"
  },
  {
    label: "Mifid II applies to my firm/jurisdiction, but I am exempt",
    value: "Mifid II applies to my firm/jurisdiction, but I am exempt",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
  },
  {
    label: "Mifid II applies to my firm/jurisdiction, but I am covered by a subscription to TD",
    value: "Mifid II applies to my firm/jurisdiction, but I am covered by a subscription to TD",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
  },
  {
    label: "Mifid II applies to my firm/jurisdiction. I would like to apply for a 3-month trial",
    value: "Mifid II applies to my firm/jurisdiction. I would like to apply for a 3-month trial",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
  },
  {
    label: "I am a UK investor and agree TD's FICC content is exempt from MiFID II content payment requirements",
    value: "I am a UK investor and agree TD's FICC content is exempt from MiFID II content payment requirements",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
  },
  {
    label: "I confirm I meet the MiFID criteria to be deemed a professional or eligible counterparty in the EU and with no TD Sales contact requesting access to TD's FICC content",
    value: "I confirm I meet the MiFID criteria to be deemed a professional or eligible counterparty in the EU and with no TD Sales contact requesting access to TD's FICC content",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
  },
]

export const MII_STATUS_DROPDOWN_LIST = [
  {
    label: "Major Institutional Investor (MII) requirements do not apply to my firm (i.e., I am not a U.S. recipient)",
    value: "Major Institutional Investor (MII) requirements do not apply to my firm (i.e., I am not a U.S. recipient)",
    padding: PADDING_FIRST_LEVEL,
    width: "auto",
    fontWeight: "bold"
},
{
  label: "My firm is an asset manager that meets the MII threshold (minimum US$100 million AUM)",
  value: "My firm is an asset manager that meets the MII threshold (minimum US$100 million AUM)",
  padding: PADDING_FIRST_LEVEL,
  width: "auto",
  fontWeight: "bold"
},
{
  label: "My firm is a corporate client that meets the MII threshold (minimum US$100 million in assets)",
  value: "My firm is a corporate client that meets the MII threshold (minimum US$100 million in assets)",
  padding: PADDING_FIRST_LEVEL,
  width: "auto",
  fontWeight: "bold"
},
];

export const IS_TD_EMPLOYEE_OPTIONS = [
  {
    label: "Yes",
    value: "Yes",
    padding: PADDING_FIRST_LEVEL,
    width: "250px",
    fontWeight: "bold"
  },
  {
    label: "No",
    value: "No",
    padding: PADDING_FIRST_LEVEL,
    width: "250px",
    fontWeight: "bold"
  },
];


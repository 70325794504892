import React from 'react';

export default class ElipsisTextComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }
    handlecharacterLimit(text,length){
        if (text == null) {
            return "";
        }
        if (text.length > length) {
            text = text.substr(0, length);
            return text + "...";
        }
            return text ;
    }
    render() {
        return (
            <React.Fragment>
                { this.handlecharacterLimit(this.props.text, this.props.length) }
            </React.Fragment>
        )
    }
}
import { saveAs } from 'file-saver';
import {
    REST_PDF_PATH
} from '../constants/Rest.constants';
import {
    GET,
    BLOB,
    FORWARD_SLASH,
    TEXT_PLAIN,
    EXCEL_FILE_TYPE,
    EXCEL_EXTENSION,
    END_OF_LINE
} from '../constants/Misc.constants';
import HttpClient from '../utils/HttpClient';

export default class DownloadFileService {

    static download(filename, text) {
        var blob = new Blob([text], {
            type: TEXT_PLAIN
        });
        saveAs(blob, filename)
    }

    static getPDF(url) {
        return HttpClient.get(url);
    }

    static downloadFile(url, filename, fileType, extension) {
        var oReq = new XMLHttpRequest();
        var _filename = filename || "TDS One Portal File";
        // Configure XMLHttpRequest
        oReq.open(GET, url, true);

        // Important to use the blob response type
        oReq.responseType = BLOB;

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
            // Once the file is downloaded, open a new window with the PDF
            // Remember to allow the POP-UPS in your browser
            var file = new Blob([oReq.response], {
                type: fileType
            });

            // Generate file download directly in the browser !
            saveAs(file, _filename + extension);

            // below is open in new window
            /*
            var a = document.createElement('a');
            a.setAttribute('target', '_blank');
            a.href = window.URL.createObjectURL(file);
            a.click();
            */
        };

        oReq.send();
    }

    static downloadPDF(firmId, contentId, filename) {
        var url = REST_PDF_PATH +  firmId + FORWARD_SLASH + contentId;

        /* GSBM-3179 */
        var a = document.createElement('a');
        a.setAttribute('target', '_blank');
        a.href = url;
        a.click();
    }

    static downloadExcel(url, filename) {
        DownloadFileService.downloadFile(url, filename, EXCEL_FILE_TYPE, EXCEL_EXTENSION);
    }

    static generateVcalender(data) {
        var event = "";
        event += "BEGIN:VCALENDAR" + END_OF_LINE;
        event += "VERSION:2.0" + END_OF_LINE;
        event += "CALSCALE:GREGORIAN" + END_OF_LINE;
        event += "BEGIN:VEVENT" + END_OF_LINE;
        event += "SUMMARY:" + data.title  + END_OF_LINE;
        event += "DTSTART;TZID=America/Toronto:" + data.eventDate + END_OF_LINE;
        if (data.eventDateEnd) {
            event += "DTEND;TZID=America/Toronto:" + data.eventDateEnd + END_OF_LINE;
        }
        //event += "DTEND;TZID=America/New_York:20130802T110400" + END_OF_LINE;
        //event += "LOCATION:Toronto" + END_OF_LINE;
        if (data.description) {
            event += "DESCRIPTION: " + data.description + END_OF_LINE;
        } else {
            event += "DESCRIPTION: " + data.title + END_OF_LINE;
        }
        event += "STATUS:CONFIRMED" + END_OF_LINE;
        event += "SEQUENCE:3" + END_OF_LINE;
        // event += "BEGIN:VALARM" + END_OF_LINE;
        // event += "TRIGGER:-PT10M" + END_OF_LINE;
        // event += "DESCRIPTION:Pickup Reminder" + END_OF_LINE;
        // event += "ACTION:DISPLAY" + END_OF_LINE;
        // event += "END:VALARM" + END_OF_LINE;
        event += "END:VEVENT" + END_OF_LINE;
        // event += "BEGIN:VEVENT" + END_OF_LINE;
        // event += "SUMMARY:Access-A-Ride Pickup" + END_OF_LINE;
        // event += "DTSTART;TZID=America/New_York:20130802T200000" + END_OF_LINE;
        // event += "DTEND;TZID=America/New_York:20130802T203000" + END_OF_LINE;
        // event += "LOCATION:900 Jay St.\, Brooklyn" + END_OF_LINE;
        // event += "DESCRIPTION: Access-A-Ride trip to 1000 Broadway Ave.\, Brooklyn" + END_OF_LINE;
        // event += "STATUS:CONFIRMED" + END_OF_LINE;
        // event += "SEQUENCE:3" + END_OF_LINE;
        // event += "BEGIN:VALARM" + END_OF_LINE;
        // event += "TRIGGER:-PT10M" + END_OF_LINE;
        // event += "DESCRIPTION:Pickup Reminder" + END_OF_LINE;
        // event += "ACTION:DISPLAY" + END_OF_LINE;
        // event += "END:VALARM" + END_OF_LINE;
        // event += "END:VEVENT" + END_OF_LINE;
        event += "END:VCALENDAR" + END_OF_LINE;

        return event;
    }

}
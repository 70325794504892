import HttpClient from '../utils/HttpClient';
import { 
    REST_ASSET_SECURITIZATION_PAGE
} from '../constants/Rest.constants';
import {
    FORWARD_SLASH
} from '../constants/Misc.constants';

export default class AssetSecuritizationService {

    static getASGData(type, params) {
        return HttpClient.get(REST_ASSET_SECURITIZATION_PAGE + FORWARD_SLASH + type, params);
    }
    
}
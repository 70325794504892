import HttpClient from '../utils/HttpClient';
import {
    REST_TRADE_IDEAS_PRODUCT_PATH,
    REST_TRADE_IDEAS_REGIONS_PATH
} from '../constants/Rest.constants';
import { PRODUCT_TRADE_IDEAS_FILTER, TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL } from '../constants/TradeIdeas.constants';

export default class TradeIdeasService {

    static getTradeIdeasProductData(type, params) {
        return HttpClient.get(REST_TRADE_IDEAS_PRODUCT_PATH + "/" + type, params);
    }

    static getTradeIdeasRegionData(type, params) {
        return HttpClient.get(REST_TRADE_IDEAS_REGIONS_PATH + "/" + type, params);
    }

    static getStopLossExitColumnName(tradeIdeasFilter) {
        switch (tradeIdeasFilter) {
            case PRODUCT_TRADE_IDEAS_FILTER.OPEN:
                return TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL.STOP_LOSS;
            case PRODUCT_TRADE_IDEAS_FILTER.CLOSED:
                return TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL.EXIT;
            case PRODUCT_TRADE_IDEAS_FILTER.ALL:
                return TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL.STOP_LOSS_EXIT;
            default:
                return TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL.STOP_LOSS_EXIT;
        }
    }
}
export const REQUEST_ACCESS_FORM_TEXT = {
    TEXT_1: "To request access to TDS One Portal, please complete the following Access Request Form.",
    TEXT_2: "Access to the site is available only to entities that meet the criteria to be classified "+
    "as an Institutional Investor* with limited exception for some Commercial and Corporate accounts which is "+
    "dependent on local regulatory requirements.  For more information, please contact a TD Securities representative. "+
     "A TD Securities Representative may be in touch with you to understand your content needs and get you the required access."
}


export const REQUEST_ACCESS_FORM_NOTES = {
    Foot_Notes: ["IIROC Notice 21-0028, definition of an \"institutional client\", an acceptable counterparty, "+
    "an acceptable institution, a regulated entity, a registrant under securities law, other than an '"+
    "individual registrant, or a non-individual with total securities under administration or '"+
    "management of more than $10 million.",

    "Directive 2014/65/EU of the European Parliament and Council ('MiFID') and FCA COBS 3.5 defines, a "+
    "professional client is as a client who possesses the experience, knowledge and expertise to make "+
    "its own investment decisions and properly assess the risks that it incurs. In order to be considered a "+
    "professional client, a client must meet the criteria laid out in Annex II of MiFID/FCA COBS 3.5. "+
    "Clients may request to be treated as opt up professionals and can be treated as such, provided they "+
    "meet the criteria in Annex II.I of MiFID/FCA COBS 3.5.",
    
    "In US, you are, or you are authorized to act on behalf of, a Qualified Institutional Buyer (\"QIB\"), as "+
    "defined under Rule 144A of the Securities Act of 1933."
    ],
    Compliance_Contacts_Note : "If you have questions regarding your institutional status, contact your Legal / Compliance team "+
                                "for additional guidance."
};

export const COMPLIANCE_INFO = {
    compliance_note : "The content is meant for institutional accounts only, you may not forward any content to any external recipients without the consent of TD Strategy team",
    compliance_checkBox_note: "I agree",
    compliance_country: "US",
    compliance_excluded_email_domain: "tdsecurities.com"
}


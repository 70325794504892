import { COMPLIANCE_INFO } from "../components/landing-page/request-access-form/RequestAccessForm.constants";
import { REQUEST_ACCESS_MAIL_DELIMITER } from "../constants/LandingPage.constants"
import { isNotEmpty } from "../utils/valueUtils";

export default class MailService {

    static formatRequestAccessMailBody(data) {
        const {
            areasOfInterest,
            firstName,
            lastName,
            companyName,
            occupation,
            companyAddress,
            country,
            city,
            zipOrPostalCode,
            email,
            institutionalContact,
            mifidIIStatus,
            mIIStatus,
            tdEmployeeStatus,
            lineOfBusiness,
            otherLineOfBusiness,
            compliance
        } = data;

        const mailBody = [
            `Area(s) of Interest:`,
            ...areasOfInterest,
            `Requestor's Details (Completed):`,
            `- *First Name: ${firstName}`,
            `- *Last Name: ${lastName}`,
            `- *Company Name: ${companyName}`,
            `- *Occupation: ${occupation}`,
            `- Company Address: ${companyAddress}`,
            `- *Country: ${country}`,
            `- City: ${city}`,
            `- Zip / Postal Code: ${zipOrPostalCode}`,
            `- *Email: ${email}`,
            `- *Institutional or Corporate Sales Contact: ${institutionalContact}`,
            `- *Mifid II Status: ${mifidIIStatus}`,
            `- *MII Status: ${mIIStatus}`,
            `- *Are you a TD Employee?: ${tdEmployeeStatus}`
        ]

        if ( isNotEmpty(lineOfBusiness) ) {
            mailBody.push(`- *Line of Business: ${lineOfBusiness}`)
        }
        if ( isNotEmpty(otherLineOfBusiness) ) {
            mailBody.push(`- *Other Line of Business Description: ${otherLineOfBusiness}`)
        }
        if ( compliance ) {
            mailBody.push(`- *${COMPLIANCE_INFO.compliance_note}: ${COMPLIANCE_INFO.compliance_checkBox_note}`)
        }        

        return mailBody.join(REQUEST_ACCESS_MAIL_DELIMITER)
    }
}
import React from 'react';
import {
    PRODUCT_PAGE_TABS,
    PRODUCT_PAGE_TYPE
} from '../../../constants/ProductPage.constants';

export default class ProductPageTabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.tabClicked = this.tabClicked.bind(this);
    }
    tabClicked(event) {
        if (this.props.tabClicked) {
            this.props.tabClicked(event.target.value);
        }
    }
    render() {
        return (
            <div className={this.props.type === PRODUCT_PAGE_TYPE.MACRO ? 'tds-product-page-tab-menu-container ' + PRODUCT_PAGE_TYPE.MACRO : 'tds-product-page-tab-menu-container'}>
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                    <div className="td-multi-single-select-buttons-group">
                        <div className="td-multi-single-select-button-wrapper">
                            <input
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3Group"
                                value={PRODUCT_PAGE_TABS.PUBLICATIONS}
                                checked={this.props.tab === PRODUCT_PAGE_TABS.PUBLICATIONS}
                                id="selectButtonSegmented3-1" />
                            <label htmlFor="selectButtonSegmented3-1" title="Publications">Publications</label>
                        </div>
                        { 
                            this.props.type !== PRODUCT_PAGE_TYPE.MACRO && 
                            <div className="td-multi-single-select-button-wrapper">
                                <input
                                    type="radio"
                                    onChange={this.tabClicked}
                                    name="selectButtonSegmented3Group"
                                    value={PRODUCT_PAGE_TABS.TRADE_IDEAS}
                                    checked={this.props.tab === PRODUCT_PAGE_TABS.TRADE_IDEAS}
                                    id="selectButtonSegmented3-2" />
                                <label htmlFor="selectButtonSegmented3-2" title="Trade Ideas">Trade Ideas</label>
                            </div>
                        }
                        <div className="td-multi-single-select-button-wrapper">
                            <input
                                type="radio"
                                onChange={this.tabClicked}
                                name="selectButtonSegmented3Group"
                                value={PRODUCT_PAGE_TABS.REAL_TIME_COVERAGE}
                                checked={this.props.tab === PRODUCT_PAGE_TABS.REAL_TIME_COVERAGE}
                                id="selectButtonSegmented3-3" />
                            <label htmlFor="selectButtonSegmented3-3" title="Real-Time Coverage">Real-Time Coverage</label>
                        </div>
                    </div>
                </fieldset>
            </div>

        )
    }
}

import React from 'react';
import ResponsiveBackgroundImageComponent
    from '../misc/responsive-background-image/ResponsiveBackgroundImage.component'
import { Redirect } from 'react-router-dom';
import {
    LINK_HOME_PAGE,
    LINK_LANDING_PAGE
} from "../../constants/LinkPaths.constants";
import {
    FOUR_O_FOUR_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import { FOUROFOURPAGE_IMAGE_DEFAULT } from '../../constants/FourOFourPage.constants';
export default class FourOFourPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        };
        this.handleGoTohomePageClick = this.handleGoTohomePageClick.bind(this);
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }
    componentDidMount() {
        document.title = FOUR_O_FOUR_PAGE_TITLE;
    }
    handleGoTohomePageClick = () => {
        const target = this.props.unauthenticated ? LINK_LANDING_PAGE : LINK_HOME_PAGE;
        this.setState(
            { target: target },
            this.setState(
                {
                    redirect: true
                }
            )
        );
    }
    render() {
        return (
            <React.Fragment>
                { this.renderRedirect()}
                <section className={`tds-fourofour-page${this.props.unauthenticated ? ' unauthenticated' : ''}`}>
                    <ResponsiveBackgroundImageComponent
                        className={"tds-fourofour-page-container"}
                        default={FOUROFOURPAGE_IMAGE_DEFAULT.image}
                        tablet1={FOUROFOURPAGE_IMAGE_DEFAULT.tablet1Image}
                        tablet2={FOUROFOURPAGE_IMAGE_DEFAULT.tablet2Image}
                        mobile={FOUROFOURPAGE_IMAGE_DEFAULT.mobileImage}>
                        <div className="tds-fourofour-page-row">
                            <div className="tds-fourofour-page-column">
                                <div className="tds-fourofour-page-column-description">
                                    <p>
                                        Sorry, we couldn't find what you were looking for.
                                    </p>
                                </div>
                                <div className="tds-fourofour-page-button-wrapper">
                                    {!this.props.unauthenticated &&
                                        <button title="Go to Homepage"
                                            className="btn td-btn-primary-light"
                                            onClick={this.handleGoTohomePageClick}
                                        >
                                            Go to Homepage
                                        </button>
                                    }
                                    {this.props.unauthenticated &&
                                        <button title="Go to Homepage"
                                            className="btn td-btn-primary-light"
                                            onClick={this.handleGoTohomePageClick}
                                        >
                                            Go to Homepage
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </ResponsiveBackgroundImageComponent>
                </section>
            </React.Fragment>
        )
    }
}

import React from 'react';

/* USAGE

React version of ngIF

<ShowContentComponent
    if="yourAwesomeConditionalExpression or function"
/>
    Your awesome non interpolated content
    <SimpleComponent>
</ShowContentComponent>

Not recommended for <InterpolatedDataComponet data={this.state.interpolateddata} />
because it will interpolated the data before sending
Todo - make InterpolatedDataComponet work

*/

export default class ShowContentComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.if &&
                    <React.Fragment>
                        {this.props.children}
                    </React.Fragment>
                }
                { this.props.elseShowEmpty &&
                    <React.Fragment>
                        &nbsp;
                    </React.Fragment>
                }
            </React.Fragment>

        )
    }
}

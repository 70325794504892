import React from 'react';
import { withRouter } from "react-router-dom";
import Select, { components } from 'react-select';
import Spinner from "../../spinner/Spinner.component";
import ShowContentComponent from '../../../components/misc/show-content/ShowContent.component';
import RealTimeCoverageService from '../../../services/RealTimeCoverage.service';
import RealTimeCoverageSectionComponent from './RealTimeCoverageSection.component';
import ErrorComponent from '../../misc/error/Error.component';
import PaginationComponent from '../../pagination/Paginator.component';
import {
    REAL_TIME_COVERAGE_KEYPOINTS_CHAR_LIMIT, 
} from '../../../constants/RealTimeCoverage.constants';
import {
    SEARCH_RESULTS_PRODUCTS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME, SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME
} from "../../../constants/SearchResults.constants";
import {
    LINK_SEARCH_PAGE
} from "../../../constants/LinkPaths.constants";
import {
    REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY,
    REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW,
    REAL_TIME_COVERAGE_DOC_TYPE_BLURB,
    REAL_TIME_COVERAGE_DOC_TYPE_DROPDOWN,
    REAL_TIME_COVERAGE_REGION_DEFAULT,
    REAL_TIME_COVERAGE_TYPES,
    REAL_TIME_COVERAGE_INTIAL_STATE,
    REAL_TIME_COVERAGE_RELOAD_STATE,
    REAL_TIME_COVERAGE_PAGE_SIZE,
    REAL_TIME_COVERAGE_REGION_OPTIONS,
    REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES
} from '../../../constants/RealTimeCoverage.constants';
import {
    ERROR_TYPE, TEAM
} from '../../../constants/Misc.constants';

import { ReactSelectDesktopStyles } from '../../../constants/ReactSelect.constants';
import UtilsService from '../../../services/Utils.service';
import { 
    PRODUCT_PAGE_TYPE 
} from '../../../constants/ProductPage.constants';
import { HOME_PAGE_REAL_TIME_COVERAGE_TAB } from '../../../constants/HomePage.constants';

const Option = props => {
    return (
        <div style={{
            padding: props.data.padding,
            fontWeight: props.data.fontWeight
        }}>
            <components.Option {...props} />
        </div>
    );
};


class RealTimeCoverageComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = REAL_TIME_COVERAGE_INTIAL_STATE;
        if (this.props.initialPage) {
            this.state.currentPage = parseInt(this.props.initialPage, 10);
        }
        this.dropdownOptions = null;
        if (this.props.type === REAL_TIME_COVERAGE_TYPES.HOME) {
            this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS;
            this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS[0];
            if (this.props.initialDocType) {
                this.state.docType = this.props.initialDocType;
            }
            if (this.props.initialRegion) {
                this.state.regionName = this.props.initialRegion;
            }
        } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.PRODUCT) {

            if (this.props.productType === PRODUCT_PAGE_TYPE.MACRO) {
                this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.MACRO;
                this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.MACRO[0];
            }

            if (this.props.productType === PRODUCT_PAGE_TYPE.EMERGING_MARKETS) {
                this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.EMERGING_MARKETS;
                this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.EMERGING_MARKETS[0];
            }

            if (this.props.productType === PRODUCT_PAGE_TYPE.FX) {
                this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.FX;
                this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.FX[0];
            }

            if (this.props.productType === PRODUCT_PAGE_TYPE.RATES) {
                this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.RATES;
                this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.RATES[0];
            }

            if (this.props.productType === PRODUCT_PAGE_TYPE.COMMODITIES) {
                this.dropdownOptions = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.COMMODITIES;
                this.state.regionName = REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES.COMMODITIES[0];
            }
            if (this.props.initialDocType) {
                this.state.docType = this.props.initialDocType;
            }
            if (this.props.initialRegion) {
                this.state.regionName = this.props.initialRegion;
            }
        }
        this.getData = this.getData.bind(this);
        this.getProductData = this.getProductData.bind(this);
        this.getRegionData = this.getRegionData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.handleDocTypeChange = this.handleDocTypeChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleViewAllClick = this.handleViewAllClick.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.goToPage(this.state.currentPage);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.getData
        );
        if (this.props.pageChange) {
            if (this.props.type === REAL_TIME_COVERAGE_TYPES.HOME) {
                this.props.pageChange(HOME_PAGE_REAL_TIME_COVERAGE_TAB, page);
            } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.PRODUCT) {
                this.props.pageChange(page);
            }
        }
    }
    processData(data) {
        if (!data) {
            return null;
        }
        if (data.results.length === 0) {            
            return data;
        }
        for (var i = 0; i < data.results.length; i++) {
            var textElementsArray = [];
            data.results[i].expandable = false;
            data.results[i].hawkishdovishArrow = "";
            if (data.results[i].textElements && data.results[i].textElements.event) {                
                if (data.results[i].textElements.event.p_event_1) {
                    var textElementItem = {};
                    textElementItem.event = data.results[i].textElements.event.p_event_1;
                    textElementItem.hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a1;
                    textElementItem.tdForecast = data.results[i].textElements.tdForecast.p_td_1;
                    textElementItem.consenus = data.results[i].textElements.consenus.p_cons_1;
                    textElementsArray.push(textElementItem);
                    data.results[i].hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a1;
                }
                if (data.results[i].textElements.event.p_event_2) {
                    var textElementItem2 = {};
                    textElementItem2.event = data.results[i].textElements.event.p_event_2;
                    textElementItem2.hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a2;
                    textElementItem2.tdForecast = data.results[i].textElements.tdForecast.p_td_2;
                    textElementItem2.consenus = data.results[i].textElements.consenus.p_cons_2;
                    textElementsArray.push(textElementItem2);
                }
                if (data.results[i].textElements.event.p_event_3) {
                    var textElementItem3 = {};
                    textElementItem3.event = data.results[i].textElements.event.p_event_3;
                    textElementItem3.hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a3;
                    textElementItem3.tdForecast = data.results[i].textElements.tdForecast.p_td_3;
                    textElementItem3.consenus = data.results[i].textElements.consenus.p_cons_3;
                    textElementsArray.push(textElementItem3);
                }
                if (data.results[i].textElements.event.event) {
                    var textElementItem4 = {};
                    textElementItem4.event = data.results[i].textElements.event.event;
                    textElementItem4.hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a1;
                    textElementItem4.tdForecast = data.results[i].textElements.tdForecast.p_td_1;
                    textElementItem4.consenus = data.results[i].textElements.consenus.p_cons_1;
                    textElementsArray.push(textElementItem4);
                    data.results[i].hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a1;
                }
            }
            if (textElementsArray.length > 1 && data.results[i].bluematrixXmlDescr !== REAL_TIME_COVERAGE_DOC_TYPE_BLURB) {
                data.results[i].expandable = true;
            }
            data.results[i].sectionText = (data.results[i].keyPoints && data.results[i].keyPoints !== 'null') ? data.results[i].keyPoints : "";
            if (data.results[i].bluematrixXmlDescr === REAL_TIME_COVERAGE_DOC_TYPE_BLURB) {
                if (data.results[i].textElements && data.results[i].textElements.blurbText) {
                    data.results[i].sectionText += ' ' + (data.results[i].textElements.blurbText && data.results[i].textElements.blurbText !== 'null') ? data.results[i].textElements.blurbText : "";
                }
            }
            if (data.results[i].bluematrixXmlDescr === REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW) {
                if (data.results[i].textElements && data.results[i].textElements.preview_overview) {
                    data.results[i].sectionText += ' ' + (data.results[i].textElements.preview_overview && data.results[i].textElements.preview_overview !== 'null') ? data.results[i].textElements.preview_overview : "";
                }
            }
            if (data.results[i].sectionText && data.results[i].sectionText.length > REAL_TIME_COVERAGE_KEYPOINTS_CHAR_LIMIT) {
                data.results[i].expandable = true;
            }
            if (textElementsArray.length >= 1) {
                data.results[i].hawkishdovishArrow = textElementsArray[0].hawkishdovishArrow;
            }
            if (!data.results[i].hawkishdovishArrow) {
                if (data.results[i].textElements && data.results[i].textElements.hawkishdovishArrow && data.results[i].textElements.hawkishdovishArrow.a1) {
                    data.results[i].hawkishdovishArrow = data.results[i].textElements.hawkishdovishArrow.a1;
                }
            }
            data.results[i].textElementsArray = textElementsArray;
        }
        return data;
    }
    getData() {
        if (this.props.type === REAL_TIME_COVERAGE_TYPES.PRODUCT) {
            this.setState(REAL_TIME_COVERAGE_RELOAD_STATE, this.getProductData);
        } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.REGION) {
            this.setState(REAL_TIME_COVERAGE_RELOAD_STATE, this.getRegionData);
        } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.HOME) {
            this.setState(REAL_TIME_COVERAGE_RELOAD_STATE, this.getHomeData);
        }
    }
    getProductData() {
        var params = {
            docType: this.state.docType,
            regionName: this.state.regionName.value,
            page: this.state.currentPage,
            size: REAL_TIME_COVERAGE_PAGE_SIZE,
        };
        RealTimeCoverageService.getRealTimeCoverageProductData(this.props.productType, params).then(this.getDataThen).catch(this.getDataError);
    }
    getRegionData() {
        var params = {
            page: this.state.currentPage,
            size: REAL_TIME_COVERAGE_PAGE_SIZE,
            regionName: this.props.regionType,
            docType: this.state.docType
        };
        RealTimeCoverageService.getRealTimeCoverageRegionData(this.props.regionType, params).then(this.getDataThen).catch(this.getDataError);
    }
    getHomeData() {
        var params = {
            page: this.state.currentPage,
            regionName: this.state.regionName.value,
            docType: this.state.docType,
            size: REAL_TIME_COVERAGE_PAGE_SIZE,
        };
        RealTimeCoverageService.getRealTimeCoverageHomePageData(params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            data: this.processData(data),
            loading: false,
            error: null
        });
    }
    getDataError(error) {
        this.setState({
            data: null,
            loading: false,
            error: true
        });
    }
    handleViewAllClick(event) {
        event.preventDefault();
        if (this.props.type === REAL_TIME_COVERAGE_TYPES.PRODUCT) {
            this.props.history.push(LINK_SEARCH_PAGE + "?" +
                SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
                SEARCH_RESULTS_PRODUCTS_QUERY_PARAM_NAME + '=' + this.props.productDropDownType + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY + "," +
                REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME + '=true');
        } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.REGION) {
            this.props.history.push(LINK_SEARCH_PAGE + "?" +
                SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
                SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME + '=' + UtilsService.getRegionParams(this.props.regionType) + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY + "," +
                REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW);
        } else if (this.props.type === REAL_TIME_COVERAGE_TYPES.HOME) {
            this.props.history.push(LINK_SEARCH_PAGE + "?" +
                SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY + "," +
                REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME + '=true');
        }
    }
    handleDocTypeChange(event) {
        this.setState({ docType: event.target.value }, this.goToPage(1));
        if (this.props.docTypeChange) {
            this.props.docTypeChange(event.target.value);
        }
    }
    handleRegionChange(value) {
        this.setState({ regionName: value }, this.goToPage(1));
        if (this.props.regionChange) {
            this.props.regionChange(value);
        }
    }
    isRealtimeCoverageLoadingComplete() {
        return !this.state.loading && this.state.data && this.state.data.results;
    }
    render() {
        return (
            <div className="tds-product-page-real-time-coverage-container">
                <div className="tds-product-page-real-time-coverage-preview-country-container">
                    {(this.props.type === REAL_TIME_COVERAGE_TYPES.PRODUCT || this.props.type === REAL_TIME_COVERAGE_TYPES.HOME) &&
                        <div className="tds-product-page-real-time-coverage-country">
                            <Select
                                options={this.dropdownOptions}
                                components={{ Option }}
                                value={this.state.regionName}
                                isMulti={false}
                                onChange={this.handleRegionChange}
                                styles={ReactSelectDesktopStyles}
                                placeholder={REAL_TIME_COVERAGE_REGION_DEFAULT}
                            />
                        </div>
                    }
                    <div className="tds-product-page-real-time-coverage-preview">
                        <select
                            className="tds-product-page-real-time-coverage-select-preview form-control"
                            onChange={this.handleDocTypeChange}
                            value={this.state.docType}
                            disabled={this.state.loading}
                        >
                            {REAL_TIME_COVERAGE_DOC_TYPE_DROPDOWN.map(
                                (DOC_TYPE, INDEX) => {
                                    return (
                                        <option
                                            key={DOC_TYPE.value + INDEX}
                                            value={DOC_TYPE.value}>
                                            {DOC_TYPE.label}
                                        </option>
                                    )
                                }
                            )}
                        </select>
                    </div>
                </div>
                <ShowContentComponent
                    if={this.state.loading && !this.state.error}
                >
                    <div className="tds-product-table-real-time-coverage">
                        <Spinner minHeight='2000px' />
                    </div>
                </ShowContentComponent>
                <ShowContentComponent
                    if={!this.state.loading && this.state.error}
                >
                    <div className="tds-product-table-real-time-coverage">
                        <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                    </div>
                </ShowContentComponent>
                { this.isRealtimeCoverageLoadingComplete() &&                   
                    <React.Fragment>                        
                        <div className="tds-product-table-real-time-coverage">
                            {this.state.data.results.map(
                                (sectionData, index) => {
                                    return (
                                        <RealTimeCoverageSectionComponent
                                            key={'real-time-coverage-section-component' + index}
                                            data={sectionData}
                                        />
                                    )
                                }
                            )
                            }
                            {this.state.data.results.length === 0 &&
                                <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                            }
                        </div>
                        {
                            this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                            <React.Fragment>
                                <PaginationComponent
                                    currentPage={this.state.currentPage}
                                    resultsPerPage={REAL_TIME_COVERAGE_PAGE_SIZE}
                                    total={this.state.data.total}
                                    goToPage={this.goToPage}
                                />
                                <div className='tds-product-table-real-time-coverage-view-all'>
                                    <a
                                        href="# "
                                        onClick={this.handleViewAllClick}
                                        title="View All"
                                    >View All</a>
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default withRouter(RealTimeCoverageComponent);
import React from "react";
import { Link } from "react-router-dom";
import {
  TDS_TOP_NAV_DROPDOWN_LINK,
  TDS_DROPDOWN_CONTENT,
  TDS_DROPDOWN_ACTIVE,
  LINK_DESTINATION_INTERNAL,
  LINK_DESTINATION_EXTERNAL,
  AccessCondition,
} from "../../constants/HeaderNav.constants";
import { LOG_LEVEL, logger } from "../../system/Logger";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { isFirefox } from "react-device-detect";
import UserService from "../../services/User.service";
import { isCowen, isNotEmptyArray } from "../../utils/valueUtils";
import CowenFlagContext from "../../system/CowenFlagContext";

export default class HeaderNavDropDownComponent extends React.Component {
  dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK;
  dropDownContentClassName = TDS_DROPDOWN_CONTENT;
  static contextType = CowenFlagContext;
  history = createBrowserHistory();
  mouseX = null;
  mouseY = null;
  constructor(props) {
    super(props);
    this.elementRefOuterDiv = React.createRef();
    logger.log(LOG_LEVEL.DEBUG, "HeaderNavDropDownComponent", this.props);
    this.state = {
      open: false,
      redirect: false,
      cowenEnabled: false,
      target: this.history.location.pathname,
    };
    this.dropDownClassName =
      this.props.dropDownClassName || this.dropDownClassName;
    this.dropDownContentClassName =
      this.props.dropDownContentClassName || this.dropDownContentClassName;
    this.hover = false || this.props.hover;
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.isItemVisible = this.isItemVisible.bind(this);
  }
  handleMouseMove = (e) => {
    this.mouseX = e.x;
    this.mouseY = e.y;
  };
  componentDidMount() {
    document.addEventListener("mousemove", this.handleMouseMove, false);
    isCowen().then(r =>{
      this.setState({cowenEnabled: r})
    }).catch(e =>{
      console.log(e);
    });
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.target} push />;
    }
  };
  handleOnClick(event) {
    event.preventDefault();
    if (!this.hover && this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  handleOnMouseEnter(event) {
    if (this.hover) {
      this.setState({ open: true, redirect: false });
    }
  }
  handleOnMouseLeave(event) {
    if (this.hover) {
      this.setState({ open: false });
    }
  }
  closeMenu() {
    this.setState({ open: false });
  }
  tellParentToCloseOtherMenus() {
    if (!isFirefox) {
      this.props.closeOtherMenus();
    }
  }

  isItemVisible(item) {
    if (item.hasAccess && item.showCondition) {
      if (item.showCondition === AccessCondition.AND) {
        return UserService.isBoth();
      } else {
        return UserService.hasAccess(item.hasAccess);
      }
    }
    return true;
  }

  navigate(target, hasAccess, modalType) {
    if (UserService.hasAccess(hasAccess)) {
      this.setState({ target: target });
      this.setState({redirect: true});
    } else {
      this.props.showModal(modalType);
    }
  }
  navigateExternal(target) {
    window.open(target);
  }
  handleLinkClick(e) {
    e.preventDefault();
  }
  componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove, false);
  }

  buildMenuLink(menuItem) {
    if (menuItem.destination === LINK_DESTINATION_EXTERNAL) {
      return (
        <li>
          <a
            href={menuItem.link}
            target="_blank"
            rel="noopener noreferrer"
            title={menuItem.text}
            onClick={this.navigateExternal.bind(this, menuItem.link)}
          >
            {menuItem.text}
          </a>
        </li>
      );
    }
    if (
      menuItem.destination === LINK_DESTINATION_INTERNAL &&
      this.isItemVisible(menuItem)
    ) {
      return (
        <li
          onClick={this.navigate.bind(
            this,
            menuItem.link,
            menuItem.hasAccess,
            menuItem.modalType
          )}
        >
          <Link to={menuItem.link} title={menuItem.text}>
            {menuItem.text}
          </Link>
        </li>
      );
    }
  }

  render() {
    if (this.props.dropDownData) {
      return (
        <div
          className="tds-nav-item"
          onClick={this.handleOnClick}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          ref={this.elementRefOuterDiv}
        >
          {this.renderRedirect()}
          {!this.state.open && (
            <React.Fragment>
              <a
                href="# "
                title={this.props.dropDownData.text}
                className={this.dropDownClassName}
              >
                {this.props.dropDownData.text}
                <span className="td-icon td-icon-downCaret icon-tiny"></span>
              </a>
            </React.Fragment>
          )}
          {this.state.open && (
            <React.Fragment>
              <a
                href="# "
                title={this.props.dropDownData.text}
                className={this.dropDownClassName + TDS_DROPDOWN_ACTIVE}
              >
                {this.props.dropDownData.text}
                <span className="td-icon td-icon-upCaret icon-tiny"></span>
              </a>
              <ul
                className={this.dropDownContentClassName + TDS_DROPDOWN_ACTIVE}
              >
                {this.props.dropDownData.items?.map((item, index) => (
                  <React.Fragment
                    key={
                      this.props.dropDownData.text + index + "_" + item.header
                        ? item.header
                        : item.title
                    }
                  >
                    {item.header && (
                      <div className="menu-header">{item.header}</div>
                    )}
                    {isNotEmptyArray(item.items) &&
                      item.items.map((subItem, index) => (
                        <span
                          className="sub-menu"
                          key={
                            this.props.dropDownData.text + index + "_" + subItem.text
                          }
                        >
                          {this.buildMenuLink(subItem)}
                        </span>
                      ))}
                    {this.buildMenuLink(item)}
                  </React.Fragment>
                ))}
              </ul>
            </React.Fragment>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

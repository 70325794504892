import React from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import RealTimeCoveragePreviewItemCompoonent from './RealTimeCoveragePreviewItem.component';
import ElipsisTextComponent from '../../misc/elipsis-text/ElipsisText.component';
import UtilsService, { executeInnerHtml } from '../../../services/Utils.service';
import {
    REAL_TIME_COVERAGE_KEYPOINTS_CHAR_LIMIT,
    REAL_TIME_COVERAGE_DOC_TYPE_BLURB
} from '../../../constants/RealTimeCoverage.constants';
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';
import { isNotEmptyArray } from '../../../utils/valueUtils';
import InnerHtml from '../../misc/inner-html/InnerHtml.component';

export default class RealTimeCoverageSectionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
    }
    displayRegion = () => {
        const regions = this.props.data.regions;
        if (isNotEmptyArray(regions)) {
            //GSBM-5221 display last tag region
            return regions[regions.length-1];
        }

        return regions;
    }
    handleExpandClick() {
        this.setState({ expanded: true })
    }
    handleCollapseClick() {
        this.setState({ expanded: false })
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data &&
                    <div className="tds-product-table-real-time-coverage-row-section">
                        <div className="tds-product-table-real-time-coverage-row">
                            <div className="tds-product-table-real-time-coverage-column expand">
                                {this.props.data.expandable &&
                                    <React.Fragment>
                                        {
                                            !this.state.expanded &&
                                            <span
                                                className="td-icon td-icon-expand icon-small active"
                                                onClick={this.handleExpandClick}
                                                title="Expand"
                                            >
                                            </span>
                                        }
                                        {
                                            this.state.expanded &&
                                            <span
                                                className="td-icon td-icon-collapse icon-small active"
                                                onClick={this.handleCollapseClick}
                                                title="Collapse"
                                            >
                                            </span>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            <React.Fragment>
                                {(!(UtilsService.isHawkish(this.props.data.hawkishdovishArrow) || UtilsService.isDovish(this.props.data.hawkishdovishArrow))) &&
                                    <div className="tds-product-table-real-time-coverage-column no-arrow">
                                        <span>&nbsp;</span>
                                    </div>
                                }
                                {UtilsService.isHawkish(this.props.data.hawkishdovishArrow) &&
                                    <div className="tds-product-table-real-time-coverage-column up-arrow">
                                        <img className="tds-product-table-real-time-coverage-arrow" src="/assets/img/Up-Arrow-Icon.svg" alt="TDS Arrow" />
                                    </div>
                                }
                                {UtilsService.isDovish(this.props.data.hawkishdovishArrow) &&
                                    <div className="tds-product-table-real-time-coverage-column down-arrow">
                                        <img className="tds-product-table-real-time-coverage-arrow" src="/assets/img/Down-Arrow-Icon.svg" alt="TDS Arrow" />
                                    </div>
                                }
                            </React.Fragment>
                            <div className="tds-product-table-real-time-coverage-column preview">
                                {this.props.data.documentType}
                                <div className="tds-product-preview-table-real-time-coverage">
                                    <div className="tds-product-preview-table-real-time-coverage-row date-region-row">
                                        <div className="tds-product-preview-table-real-time-coverage-column date">
                                            <FormatedDateComponent
                                                date={this.props.data.displayDateTime}
                                                format={TDS_DATE_FORMAT}
                                            />
                                        </div>
                                        <div className="tds-product-preview-table-real-time-coverage-column region">
                                            {this.displayRegion()}
                                        </div>
                                        <Link
                                            to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.parameterId}
                                            className={'tds-product-preview-table-real-time-coverage-row-title'}
                                        >
                                            {this.props.data.title}
                                        </Link>
                                    </div>
                                    {
                                        this.props.data.textElementsArray &&
                                        this.props.data.textElementsArray.length > 0 &&
                                        this.props.data.bluematrixXmlDescr !== REAL_TIME_COVERAGE_DOC_TYPE_BLURB &&
                                        <React.Fragment>
                                            <div className="tds-product-preview-table-real-time-coverage-row sub-row">
                                                <div className="tds-product-preview-table-real-time-coverage-column arrow">
                                                    &nbsp;
                                                </div>
                                                <div className="tds-product-preview-table-real-time-coverage-column event">
                                                    Event
                                                </div>
                                                <div className="tds-product-preview-table-real-time-coverage-column consensus">
                                                    Consensus
                                                </div>
                                                <div className="tds-product-preview-table-real-time-coverage-column tdforecast">
                                                    TD Forecast
                                                </div>
                                            </div>
                                            {!this.state.expanded &&
                                                <RealTimeCoveragePreviewItemCompoonent
                                                    data={this.props.data.textElementsArray[0]}
                                                />
                                            }
                                            {this.state.expanded &&
                                                this.props.data.textElementsArray.map(
                                                    (itemData, index) => {
                                                        return (
                                                            <RealTimeCoveragePreviewItemCompoonent
                                                                key={'real-time-coverage-preview-item-component' + index}
                                                                data={itemData}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        this.props.data.textElementsArray &&
                                        this.props.data.textElementsArray.length === 1 &&
                                        this.props.data.bluematrixXmlDescr === REAL_TIME_COVERAGE_DOC_TYPE_BLURB &&
                                        <div className="tds-product-preview-table-real-time-coverage-row date-region-row">
                                            <span className="tds-product-preview-table-real-time-coverage-blurb-event">
                                                <InnerHtml data={this.props.data.textElementsArray[0].event} />
                                            </span>
                                        </div>

                                    }
                                </div>
                            </div>
                            <div className="tds-product-table-real-time-coverage-column payrolls">
                                <div className="tds-product-table-real-time-coverage-expanded">
                                    {this.props.data.bluematrixXmlDescr !== REAL_TIME_COVERAGE_DOC_TYPE_BLURB &&
                                        <Link
                                            to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.parameterId}
                                            className={'tds-product-table-real-time-coverage-expanded-title'}
                                            title={this.props.data.title}
                                        >
                                            { executeInnerHtml(this.props.data.title) }
                                        </Link>
                                    }
                                    {this.props.data.bluematrixXmlDescr === REAL_TIME_COVERAGE_DOC_TYPE_BLURB &&
                                        <React.Fragment>
                                            <span
                                                className="tds-product-table-real-time-coverage-expanded-title"
                                                title={this.props.data.title}
                                            >
                                                { executeInnerHtml(this.props.data.title) }
                                            </span>
                                        </React.Fragment>
                                    }
                                    <span className="tds-product-table-real-time-coverage-expanded-description">
                                        <span className={`tds-product-table-real-time-coverage-expanded-description-content ${this.state.expanded ? ' expand' : ''}`}>
                                            {
                                                !this.state.expanded &&
                                                <ElipsisTextComponent length={REAL_TIME_COVERAGE_KEYPOINTS_CHAR_LIMIT} 
                                                    text={ executeInnerHtml(this.props.data.sectionText) }
                                                />
                                            }
                                            {
                                                this.state.expanded &&
                                                <React.Fragment>
                                                    { executeInnerHtml(this.props.data.sectionText) }
                                                </React.Fragment>
                                            }
                                        </span>
                                    </span>
                                    {this.props.data.bluematrixXmlDescr !== REAL_TIME_COVERAGE_DOC_TYPE_BLURB &&
                                        < Link
                                            to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.parameterId}
                                            className={'tds-product-real-time-coverage-read-full-article'}
                                            title="Read Full Article"
                                        >
                                            Read Full Article
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

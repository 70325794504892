import React from 'react';
import { Link } from 'react-router-dom';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import {
    TDS_CALENDAR_EVENT_DATE_FORMAT
} from '../../constants/Calendar.constants';
import {
    LINK_PATH_ANALYST_PROFILE_PAGE
} from '../../constants/LinkPaths.constants';
import {
    FORWARD_SLASH,
    FIRM_NAME_RESEARCH
} from '../../constants/Misc.constants';
import CalendarService from '../../services/Calendar.service';

export default class CalendarEarningsEventComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleAddToCalendarClick = this.handleAddToCalendarClick.bind(this);

    }
    handleAddToCalendarClick = (e) => {
        if (!this.props.event) {
            return;
        }
        CalendarService.downloadEarningsEvent(this.props.event);
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.event &&
                    <div className="tds-calender-details-event earnings">
                        <h1><FormatedDateComponent date={this.props.event.startDate} format={TDS_CALENDAR_EVENT_DATE_FORMAT} /> - Earnings Release</h1>
                        <h2>
                            {this.props.event.company && <React.Fragment>{this.props.event.company.name}</React.Fragment>} {this.props.event.company && <React.Fragment>({this.props.event.company.symbol})</React.Fragment>}; {this.props.event.recommendation}; 12-Month Target Price: {this.props.event.priceTarget}
                        </h2>
                        <div className="tds-calender-add-to-calender">
                            <button
                                className="btn btn-block td-btn-primary-light"
                                onClick={this.handleAddToCalendarClick}
                            >
                                Add to Calendar
                            </button>
                        </div>
                        <div className="tds-calender-details-event-details">
                            <h3>Conference Call</h3>
                            <span className="tds-calender-analyst-label">Analyst:</span>
                            {
                                this.props.event.analyst &&
                                <React.Fragment>
                                    <Link
                                        to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + FIRM_NAME_RESEARCH + FORWARD_SLASH + this.props.event.analyst.id}
                                        className="tds-calender-analyst-name"
                                        title={this.props.event.analyst.lastName + ', ' + this.props.event.analyst.firstName}
                                    >{this.props.event.analyst.lastName}, {this.props.event.analyst.firstName}
                                    </Link>
                                    <span className="tds-calender-analyst-phone">{this.props.event.analyst.phone}</span>
                                    <a className="tds-calender-analyst-email"
                                        href={'mailto:' + this.props.event.analyst.email}>{this.props.event.analyst.email}</a>
                                </React.Fragment>
                            }
                            {
                                this.props.event.confCallDetails &&
                                <React.Fragment>
                                    <span className="tds-calender-time-label">Time:</span>
                                    <span className="tds-calender-time-value">{this.props.event.confCallDetails.time}</span>
                                    <span className="tds-calender-call-date-label">Conference Call Date:</span>
                                    <span className="tds-calender-call-date-value">
                                        <FormatedDateComponent date={this.props.event.confCallDetails.confCallDate} format={TDS_CALENDAR_EVENT_DATE_FORMAT} />
                                    </span>
                                    <span className="tds-calender-call-time-label">Conference Time:</span>
                                    <span className="tds-calender-call-time-value">{this.props.event.confCallDetails.confCallTime}</span>
                                    <span className="tds-calender-conference-call-label">Conference Call:</span>
                                    <span className="tds-calender-phone-number">{this.props.event.confCallDetails.confCallNo}</span>
                                    <span className="tds-calender-phone-number-code-label">Code:</span>
                                    <span className="tds-calender-phone-number-code-value">{this.props.event.confCallDetails.callCallCode}</span>
                                    <span className="tds-calender-phone-number">{this.props.event.confCallDetails.otherNo}</span>
                                    <span className="tds-calender-phone-number-code-label">Code:</span>
                                    <span className="tds-calender-phone-number-code-value">{this.props.event.confCallDetails.otherCode}</span>
                                    <span className="tds-calender-replay-number-label">Replay Number:</span>
                                    <span className="tds-calender-phone-number">{this.props.event.confCallDetails.replayNo}</span>
                                    <span className="tds-calender-phone-number-code-label">Code:</span>
                                    <span className="tds-calender-phone-number-code-value">{this.props.event.confCallDetails.replayCode}</span>
                                    <span className="tds-calender-consensus-estimate-label">Consensus Estimate:</span>
                                    <span className="tds-calender-consensus-estimate-value">{this.props.event.confCallDetails.consenusEstimate}</span>
                                </React.Fragment>
                            }
                            <div className="tds-clear"></div>
                        </div>
                        {this.props.event.earnings &&
                            <div className="tds-calender-earnings-chart">
                                <h3>Earnings</h3>
                                {this.props.event.earnings.map((row, index) => {
                                    return (
                                        <div
                                            className={index === 0 ? 'tds-calender-earnings-chart-row header' : 'tds-calender-earnings-chart-row'}
                                            key={'tds-calender-earnings-chart-row' + index}
                                        >
                                            {
                                                row.map((column, columnIndex) => {
                                                    return (
                                                        <div
                                                            className={columnIndex === 0 ? 'tds-calender-earnings-chart-column quarter' : 'tds-calender-earnings-chart-column'}
                                                            key={'tds-calender-earnings-chart-column' + columnIndex + index}
                                                        >
                                                            {column.trim() === "" ? <React.Fragment>
                                                                &nbsp;&nbsp;
                                                            </React.Fragment> : column.trim()}
                                                        </div>
                                                    )
                                                }
                                                )
                                            }
                                        </div>
                                    )
                                }
                                )
                                }
                            </div>
                        }
                        <div className="tds-calender-commentary-container">
                            <h3>Commentary</h3>
                            <p>{this.props.event.staticCommentary}</p>
                            <p>{this.props.event.commentary}</p>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

import React from 'react';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    ACL_CREDIT_HIGH_YIELD
} from '../../constants/Entitlements.constants';

export default class HighYieldPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.hasAccess = UserService.hasAccess(ACL_CREDIT_HIGH_YIELD);
    }
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-container">
                        <h1>High Yield Page Goes Here</h1>
                    </div>
                }
            </React.Fragment>
        )
    }
}

import HttpClient from '../utils/HttpClient';
import { REST_PERSON_SEARCH_PATH } from '../constants/Rest.constants';


export default class PersonSearchService {
    static getResults(params) {
        return HttpClient.get(REST_PERSON_SEARCH_PATH, params);
    }
    get(params) {
        return HttpClient.get(REST_PERSON_SEARCH_PATH, params);
    }
}
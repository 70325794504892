export const ALPHA_FICM = "ALPHA_FICM";
export const ALPHA_EQ = "ALPHA_EQ";
export const TEAM_TYPE = "team";

export const CONTACT_CARD_OUTER_CSS_CLASS = 'tds-contact-card-outer';
export const CONTACT_CARD_ICON_CSS_CLASS = 'tds-contact-card-icon';
export const CONTACT_CARD_TEXT_CSS_CLASS = 'tds-contact-card-text';

export const GET = "GET";
export const BLOB = "blob";
export const FORWARD_SLASH = '/';
export const TEXT_PLAIN = 'text/plain';
export const PDF_FILE_TYPE = 'application/pdf';
export const PDF_EXTENSION = '.pdf';
export const EXCEL_FILE_TYPE = 'application/vnd.ms-excel';
export const EXCEL_EXTENSION = '.xlsx';

export const KEY_ENTER = 'Enter';
export const KEY_BACKSPACE = 'Backspace';

export const TDS_DATE_FORMAT = 'MMM DD, YYYY';

export const TDS_DATE_FORMAT_FULL_MONTH = 'MMMM DD, YYYY';
export const TDS_DAY_DATE_FORMAT = 'dddd. MMM DD, YYYY';
export const EQ_LAST_UPDATE_DATE_FORMAT = 'ddd MMM DD, YYYY';
export const PARAMS_DATE_FORMAT = 'YYYY-MM-DD';
export const ICS_DATE_FORMAT = 'YYYYMMDDTHHmmss';
export const EMAIL_DELIMITER = '%0D%0A';

export const CSS_DISPLAY_NONE = 'none';
export const CSS_DISPLAY_BLOCK = 'block';

export const SECURE_LINK_APPEND = ':6';
export const HTTPS = 'https://';

export const ALPHABET_FILTER_LIST = ['0-9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const QUERY_PARAM_FIRM_NAME = 'firmName';
export const QUERY_PARAM_TEAM = 'team';
export const FIRM_NAME_STRATEGY = 'strategy';
export const FIRM_NAME_COWEN = 'equity-research';
export const FIRM_NAME_CA = 'equity-research-ca';
export const FIRM_NAME_US = 'equity-research-us';
export const FIRM_NAME_WA = 'equity-research-wa';
export const COWEN_FIRM_ID = 23069;
export const EQ_FIRM_ID = 16321;
export const FIRM_NAME_RESEARCH = 'research';
export const LABEL_EQUITY_RESEARCH_CANADA = 'Equity Research Canada';
export const LABEL_EQUITY_RESEARCH_US = 'Equity Research US';
export const LABEL_WRG = 'Washington Research Group';

export const CURRENCIES = {
    USD: 'USD',
    CAD: 'CAD',
    EUR: 'EUR',
    GBP: 'GBP',
    CHF: 'CHF',
    AUD: 'AUD',
};

export const CURRENCY_SYMBOL_MAPPING = {
    USD: 'USD',
    CAD: 'C',
    EUR: 'EUR',
    GBP: 'GBP'
};

export const CURRENCY_SIGN_MAPPING = {
    USD: '$',
    CAD: '$',
    EUR: '€',
    GBP: '£',
    CHF: '₣',
    AUD: 'A$'
};

export const ERROR_TYPE = {
    SEARCH_RESULTS: 'tds-no-search-results',
    TRADE_IDEAS: 'tds-no-trade-ideas-results',
    NO_RECORDS: 'tds-no-results',
    ERROR_ALL: 'tds-error-all'
}

export const QUESTION_MARK = '?';
export const EQUALS = '=';
export const AND = '&';
export const AMPERSAND = '&';

export const END_OF_LINE = '\n';
export const LINE_BREAK = '\\n';

export const DEFAULT_ALL = 'ALL';

export const OVERVIEW_PAGE = 'overview';

export const URL_QUERY_PARAMS_TAB = 'tab';
export const URL_QUERY_PARAMS_TAB1 = 'tab1';
export const URL_QUERY_PARAMS_TAB2 = 'tab2';

export const TEAM = {
    US: 'us',
    CA: 'ca',
    WRG: 'wrg',
    STR: 'strategy'
};

export const COWEN_COVERAGE_UNIVERSE = "https://cowen.bluematrix.com/docs/library/TDCowenCoverageUniverse.xlsx";
export const COWEN_DISCLOSURE_LINK = "https://tdcowen.bluematrix.com/sellside/Disclosures.action";

export const CONF_CALLS_KEYWORDS_PLACEHOLDER_DESKTOP = "Keywords - Search by Title, Moderator Name, Presenter Name";
export const CONF_CALLS_COVERAGE_PAGE_SIZE = 6;
//export const CONF_CALLS_KEYWORDS_PLACEHOLDER_TABLET = "Keywords, analysts etc";
//export const CONF_CALLS_KEYWORDS_PLACEHOLDER_MOBILE = "Keywords";
export const RESEARCH_PAGE_ANALYST = 'analyst';
export const RESEARCH_PAGE_INDUSTRY = 'industry';
export const RESEARCH_PAGE_TYPE = 'type';
export const RESEARCH_PAGE_COMPANY = 'company';

export const RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE = 'researchType';
export const RESEARCH_PAGE_QUERY_PARAMS_COMPANY_ALPHBET_FILTER = 'companyAlphabetFilter';

export const RESEARCH_PAGE_TAB = {
    ANALYST: 'Analyst',
    COMPANY: 'Company',
    INDUSTRY: 'Industry'
}
import HttpClient from '../utils/HttpClient';
import { 
    SearchResultsMockupData,
} from '../sampleMockupData/searchResultsMockupData';
import {
    REST_SEARCH_DROPDOWN_DATA_PATH,
    REST_SEARCH_PATH,
} from '../constants/Rest.constants';
import { FIRM_NAME_RESEARCH } from '../constants/Misc.constants';

export default class SearchService {

    static getDropDownData() {
        return HttpClient.get(REST_SEARCH_DROPDOWN_DATA_PATH);
    }

    static filterEquitiesAnalysts(dropDownData) {
        var _dropdownData = dropDownData;
        _dropdownData = SearchService.filterAnalystsByFirmName(_dropdownData, FIRM_NAME_RESEARCH);
        return _dropdownData;
    }

    static filterAnalystsByFirmName(dropDownData, firmName) {
        if (!dropDownData) { return null; }
        var _dropdownData = dropDownData;
        _dropdownData.analysts = _dropdownData.analysts.filter( analyst => analyst.firmName === firmName);
        return _dropdownData;
    }

    static filterDocumentTypeByFirmName(dropDownData, firmName) {
        if (!dropDownData) { return null; }
        var _dropdownData = dropDownData;
        _dropdownData.documentTypes = _dropdownData.documentTypes.filter( documentType => documentType.firmName === firmName);
        return _dropdownData;
    }

    static filterDropDownDataByFirmName(dropDownData, firmName) {
        if (!dropDownData) { return null; }
        var _dropdownData = { ...dropDownData };
        _dropdownData = SearchService.filterAnalystsByFirmName(_dropdownData, firmName);
        _dropdownData = SearchService.filterDocumentTypeByFirmName(_dropdownData, firmName);
        return _dropdownData;
    }

    static getSearchResults(params) {
        return HttpClient.post(REST_SEARCH_PATH, params, {});
    }

    static getAnalystSearch() {
        const promiseMockup = new Promise((resolve, reject) => { 
            setTimeout(() => { 
                resolve(SearchResultsMockupData); 
            }, 5000); 
        }); 
        return promiseMockup;
    }

}
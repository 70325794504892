import HttpClient from '../../utils/HttpClient';
import {
    REST_HOMEPAGE_IN_DEPTHS_PATH_V2,
    REST_HOMEPAGE_MOST_RECENT_PATH_V2,
    REST_HOMEPAGE_TAILORED_FOR_YOU_PATH_V2,
    REST_EQ_OVERVIEW_PATH_V2
} from '../../constants/Rest.constants';
import { TEAM } from '../../constants/Misc.constants';

export default class EquityOverviewPageService {
    
    static getEquityOverviewPageData(eqOnly=false, team=TEAM.CA) {
        let params = {
            eqOnly: eqOnly,
            team: team
        }
        
        return HttpClient.get(REST_EQ_OVERVIEW_PATH_V2, params);
    }

    static getTailoredForYouData(eqOnly=false, team=TEAM.CA) {
        let params = {
            eqOnly: eqOnly,
            team: team
        }

        return HttpClient.get(REST_HOMEPAGE_TAILORED_FOR_YOU_PATH_V2, params);
    }

    static getInDepthsData(params) {
        return HttpClient.get(REST_HOMEPAGE_IN_DEPTHS_PATH_V2, params, {});
    }

    static getEquityOverviewPageMostRecentTabData(params, team=TEAM.CA) {
       return HttpClient.get(REST_HOMEPAGE_MOST_RECENT_PATH_V2, params, {});
    }
}
import React from 'react';
import UserService from '../../services/User.service';
import ExchangeTradedFundsService from '../../services/ExchangeTradedFunds.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import ExchangeTradedFundsSectionComponent from './ExchangeTradedFundsSection.component';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import {
    ERROR_TYPE
} from '../../constants/Misc.constants';
import {
    ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS
} from '../../constants/Entitlements.constants';
import {
    EXCHANGE_TRADED_FUNDS_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import {
    EXCHANGE_TRADED_FUNDS_TYPE
} from '../../constants/ExchangeTradedFunds.constants';

export default class ExchangeTradedFundsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            data: null,
        };
        this.hasAccess = UserService.hasAccess(ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
    }
    componentDidMount() {
        document.title = EXCHANGE_TRADED_FUNDS_PAGE_TITLE;
        this.getData();
    }
    getData() {
        ExchangeTradedFundsService.getSummary().then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            data: data,
            loading: false,
            error: false
        })
    }
    getDataError(error) {
        this.setState({
            data: null,
            loading: false,
            error: true
        })
    }
    accessDenied() {
        return !this.hasAccess && <AclAccessDeniedComponent />;
    }
    loading() {
        return this.state.loading && <SpinnerComponent />
    }
    error() {
        return this.state.error && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }
    render() {
        return (
            <React.Fragment>
                { this.accessDenied()}
                {
                    this.hasAccess &&
                    <div className="exchange-traded-funds-page">
                        <h1>Exchange Traded Funds (ETFs)</h1>
                        {this.loading()}
                        {this.error()}
                        {!this.state.loading && this.state.data &&
                            <React.Fragment>
                                <ExchangeTradedFundsSectionComponent
                                    type={EXCHANGE_TRADED_FUNDS_TYPE.WEEKLY}
                                    data={this.state.data.weekly}
                                />
                                <ExchangeTradedFundsSectionComponent
                                    type={EXCHANGE_TRADED_FUNDS_TYPE.INSIGHTS}
                                    data={this.state.data.insights}
                                />
                                <ExchangeTradedFundsSectionComponent
                                    type={EXCHANGE_TRADED_FUNDS_TYPE.STRATEGY_IDEAS}
                                    data={this.state.data.strategyIdeas}
                                />
                                <ExchangeTradedFundsSectionComponent
                                    type={EXCHANGE_TRADED_FUNDS_TYPE.EQUITY_OPTIONS}
                                    data={this.state.data.equityOptions}
                                />
                            </React.Fragment>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../misc/mailto/Mailto.component';
import DownloadFileService from '../../services/DownloadFile.service';
import UtilsService, { executeInnerHtml } from '../../services/Utils.service';
import { ReactSVG } from 'react-svg';

import {
    LINK_PUBLICATION_PAGE,
    LINK_PATH_ANALYST_PROFILE_PAGE
} from '../../constants/LinkPaths.constants';
import {
    TDS_DATE_FORMAT,
    EMAIL_DELIMITER,
    FORWARD_SLASH,
    FIRM_NAME_STRATEGY,
    FIRM_NAME_RESEARCH
} from '../../constants/Misc.constants';
import { SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES } from '../../constants/SearchResults.constants';
import { buildSecureLink } from '../../services/SecureLink.service';
import { isCowen, isNotEmpty, isCowenEnabled } from '../../utils/valueUtils';
import CowenFlagContext from "../../system/CowenFlagContext";


export default class SearchResultsItemComponent extends Component {

    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            cowenEnabled: false
        };

        if (props.item?.pdfLink) {
            props.item.pdfLink = buildSecureLink(props.item.pdfLink);
        }

        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handlePDFDownloadClick = this.handlePDFDownloadClick.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(`error is: ${e}`);
        });
    }
    handleExpandClick = (event) => {
        this.setState({ expand: !this.state.expand });
    }
    handlePDFDownloadClick = (event) => {
        event.preventDefault();
        DownloadFileService.downloadPDF(event.target.dataset.firmid, event.target.dataset.productid, event.target.dataset.filename);
    }
    render() {
        const cowenFlag = this.context;
        return (
            <div className="tds-search-result-row">
                {this.props.item.firmName !== 'research' ?
                    < div className="tds-search-results-column expand" onClick={this.handleExpandClick}>
                        {
                            this.state.expand &&
                            <span className="td-icon td-icon-collapse icon-small" title="Collapse"></span>
                        }
                        {
                            !this.state.expand &&
                            <span className="td-icon td-icon-expand icon-small" title="Expand"></span>
                        }
                    </div>
                    : ""
                }

                <div className="tds-search-results-column date">
                    <span className="tds-search-results-column-date-content">
                        <FormatedDateComponent
                            date={this.props.item.displayDateTime}
                            format={this.props.dateFormat || TDS_DATE_FORMAT}
                        />
                    </span>
                </div>
                <div className="tds-search-results-column title">
                    <React.Fragment>
                        {
                            SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES.includes(this.props.item.documentType) &&
                            <React.Fragment>
                                {
                                    this.props.item.symbol &&
                                    <React.Fragment>
                                        ( {this.props.item.symbol} )&nbsp;
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {this.props.item.title}
                    </React.Fragment>
                </div>
                <div className="tds-search-results-column analyst">
                    {
                        this.props.item.primaryAnalyst && this.props.item.primaryAnalyst.active &&
                        <Link
                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.props.item.firmName +
                                FORWARD_SLASH + this.props.item.primaryAnalyst.id}
                            title={this.props.item.primaryAnalyst.firstName + ' ' + this.props.item.primaryAnalyst.lastName}
                        >
                            {this.props.item.primaryAnalyst.firstName} {this.props.item.primaryAnalyst.lastName}
                        </Link>
                    }
                    {
                        this.props.item.primaryAnalyst && !this.props.item.primaryAnalyst.active &&
                        <React.Fragment>
                            {this.props.item.primaryAnalyst.firstName} {this.props.item.primaryAnalyst.lastName}
                        </React.Fragment>
                    }
                </div>
                <div className="tds-search-results-column document-type">
                    {this.props.item.documentType}
                </div>
                <div className="tds-search-results-column action">
                    {
                        this.props.item.firmName &&
                        this.props.item.contentId &&
                        this.props.item.firmName === FIRM_NAME_STRATEGY
                        && this.props.item.htmlLink && this.props.item.showHtml && this.props.item.isHTML5Supported === true &&
                        <Link
                            to={LINK_PUBLICATION_PAGE + this.props.item.firmName + FORWARD_SLASH + this.props.item.contentId}
                            title={'View'}
                        >
                            {
                                <span className="tds-view-icon">
                                    <ReactSVG
                                        className="tds-view-icon-svg"
                                        src="/assets/img/view-icon.svg"
                                    />
                                </span>
                            }
                        </Link>
                    }
                    {isNotEmpty(this.props.item.pdfLink) && this.props.item.firmId && this.props.item.productId && this.props.item.title &&
                        <a
                            href={buildSecureLink(this.props.item.pdfLink)}
                            title="View PDF"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span
                                className="td-icon td-icon-insights icon-small"
                                data-firmid={this.props.item.firmId}
                                data-productid={this.props.item.productId}
                                data-filename={this.props.item.title}
                            ></span>
                        </a>
                    }
                    {
                        this.props.item.htmlLink &&
                        this.props.item.firmName === FIRM_NAME_STRATEGY &&
                        <MailtoComponent
                            email={''}
                            titleText={'Share'}
                            icon={true}
                            subject={this.props.item.title}
                            body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + UtilsService.buildShareLink(this.props.item.firmName, this.props.item.contentId)}
                        />
                    }
                    {
                        this.props.item.pdfLink &&
                        this.props.item.firmName === FIRM_NAME_RESEARCH &&
                        <MailtoComponent
                            email={''}
                            titleText={'Share'}
                            icon={true}
                            subject={this.props.item.title}
                            body={UtilsService.buildEQShareText(
                                this.props.item.pdfLink,
                                this.props.item.riskText,
                                this.props.item.recommendationText,
                                this.props.item.priceTargetText,
                                this.props.item.mktCapFdText,
                                this.props.item.displayDateTime,
                                this.props.item.description,
                                isCowenEnabled(this.state.cowenEnabled, cowenFlag)

                            )}
                        />
                    }
                </div>
                {
                    this.state.expand &&
                    <div className="tds-search-results-expanded">
                        {
                            this.props.item.analysts.length > 0 &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Analyst: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.analysts &&
                                        this.props.item.analysts.map((analyst, index) => (
                                            <React.Fragment key={'tds-search-results-analyst' + index}>
                                                {analyst.active &&
                                                    <React.Fragment>
                                                        <Link
                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.props.item.firmName +
                                                                FORWARD_SLASH + analyst.id}
                                                            title={analyst.lastName + ' ' + analyst.firstName}
                                                        >
                                                            {analyst.lastName},&nbsp;{analyst.firstName}
                                                        </Link>
                                                        <span>&nbsp;&nbsp;</span>
                                                    </React.Fragment>
                                                }
                                                {!analyst.active &&
                                                    <span>{analyst.lastName},&nbsp;{analyst.firstName}&nbsp;&nbsp;</span>
                                                }

                                            </React.Fragment>
                                        ))}
                                </span>
                            </span>
                        }
                        {
                            this.props.item.documentType &&
                            <span className="tds-search-results-expanded-description document-type">
                                <span className="tds-search-results-expanded-description-title">Document Type: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.documentType}
                                </span>
                            </span>
                        }
                        {
                            !this.props.item.isFicm && this.props.item.industry &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Industry: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.industry}
                                </span>
                            </span>
                        }
                        {
                            !this.props.item.isFicm && this.props.item.company &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Companies: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.company}
                                </span>
                            </span>
                        }
                        {
                            this.props.item.summary &&
                            <span className="tds-search-results-expanded-description-summary">
                                {executeInnerHtml(this.props.item.summary)}
                            </span>
                        }
                    </div>
                }
            </div >
        )
    }
}
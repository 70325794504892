import HttpClient from '../utils/HttpClient';
import {
    REST_RESEARCH_PAGE,
    REST_FIRST_TAKE_PUBLICATIONS_PATH,
    REST_RESEARCH_LATEST_BY_TYPES
} from '../constants/Rest.constants';

export default class ResearchPageService {

    static getResearch() {
        return HttpClient.get(REST_RESEARCH_PAGE);
    }

    static async getFirstTake() {

        return HttpClient.get(REST_FIRST_TAKE_PUBLICATIONS_PATH);
    }
    static getResearchLatestByTypes() {
        return  HttpClient.get(REST_RESEARCH_LATEST_BY_TYPES);
    }

}
import React, { useState } from 'react';
import { executeInnerHtml } from '../../services/Utils.service';

const ConferenceCallsOtherMediaSection = (props) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(true);
    }
    
    const handleCollapseClick = () => {
        setExpanded(false);
    }
return (
        <React.Fragment>
            {
                props.data &&
                <div className="tds-product-table-real-time-coverage-row-section">
                    <div className="tds-product-table-real-time-coverage-row">
                        <div className="tds-conf-calls-other-media-column expand">
                            {props.data.expandable &&
                                <React.Fragment>
                                    {
                                        !expanded &&
                                        <span
                                            className="td-icon td-icon-expand icon-small active"
                                            onClick={handleExpandClick}
                                            title="Expand"
                                        >
                                        </span>
                                    }
                                    {
                                        expanded &&
                                        <span
                                            className="td-icon td-icon-collapse icon-small active"
                                            onClick={handleCollapseClick}
                                            title="Collapse"
                                        >
                                        </span>
                                    }
                                </React.Fragment>
                            }
                        </div>
                        <div className="tds-product-table-real-time-coverage-column no-arrow">
                                <span>&nbsp;</span>
                            </div>
                        <div className="tds-conf-calls-other-media-column preview">
                            <div className="tds-product-preview-table-real-time-coverage">
                                <div className="tds-product-preview-table-real-time-coverage-row date-region-row">
                                    <div className="tds-conf-calls-other-media-column date">
                                            {props.data.confDate ? props.data.confDate : ""}
                                        <br/>
                                        {props.data.confDate ? props.data.confTime : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tds-conf-calls-other-media-column payrolls">
                            <div className="tds-product-table-real-time-coverage-expanded">
                                <span
                                    className="tds-product-table-real-time-coverage-expanded-title"
                                    title={props.data?.title}
                                >
                                    { props.data?.title ? executeInnerHtml(props.data?.title) :"" }
                                </span>
                                { props?.data?.confModerator &&
                                    <React.Fragment>
                                        <div className="tds-conf-calls-other-media-label">
                                                Moderator Name(s):
                                        </div>
                                        <div className="tds-conf-calls-other-media-value">
                                            { executeInnerHtml(props.data.confModerator) }
                                        </div>
                                    </React.Fragment>
                                }
                                { props?.data?.confPresenterName &&
                                    <React.Fragment>
                                        <div className="tds-conf-calls-other-media-label">
                                            Presenter Name(s):
                                        </div>
                                        <div className="tds-conf-calls-other-media-value">
                                            { executeInnerHtml(props.data.confPresenterName) }
                                        </div>
                                    </React.Fragment>
                                }
                                { props?.data?.confPresenterBio && expanded && 
                                    <span className="tds-product-table-real-time-coverage-expanded-description">
                                        <div className="tds-conf-calls-other-media-label">
                                            Discussion Topics:
                                        </div>
                                        <div className={`tds-conf-calls-other-media-expanded-description-content ${expanded ? ' expand' : ''}`}>
                                                <React.Fragment>
                                                    { executeInnerHtml(props.data.confPresenterBio) }
                                                </React.Fragment>
                                        </div>
                                    </span>
                                }
                                {props.data.confMediaLink &&
                                <div className="tds-conf-calls-other-media-ahref">
                                    < a
                                        href={props.data.confMediaLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Click here for media"
                                        className={'tds-product-real-time-coverage-read-full-article'}
                                    >
                                        Click here for media
                                    </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default ConferenceCallsOtherMediaSection;

import "whatwg-fetch";
import { COWEN_FLAG } from "../system/CowenFlagContext";

const savedFlag = localStorage.getItem(COWEN_FLAG);
const cowenFlag = savedFlag !== null ? JSON.parse(savedFlag) === true : false;
const X_DARK_COWEN = "X-Dark-Cowen";

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;

  throw error;
}

const extractBody = (response) => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  }
  return response.text();
};

const HttpClient = {
  async get(url, params) {
    var _url = url;
    if (params) {
      _url = _url + "?" + buildQueryParams(params);
    }
    const headers = new Headers();
    headers.append(X_DARK_COWEN, cowenFlag.toString());

    const response = await checkStatus(
      await fetch(_url, {
        method: "GET",
        headers
      })
    );
    return extractBody(response);
  },
  async getArrayParams(url, params, arrayParams) {
    var _url = url;
    if (params) {
      _url = _url + "?" + buildQueryArrayParams(params, arrayParams);
    }
    const headers = new Headers();
    headers.append(X_DARK_COWEN, cowenFlag.toString());

    const response = await checkStatus(
      await fetch(_url, {
        method: "GET",
        headers
      })
    );
    return extractBody(response);
  },

  async post(url, body, { headers = {}, ...options }) {
    const response = await checkStatus(
      await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "X-Dark-Cowen": cowenFlag.toString(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        ...options,
      })
    );

    return extractBody(response);
  },
};

function buildQueryParams(params) {
  var query_string = '';
  if (!params) {
    return query_string;
  }
  var keys = Object.keys(params);
  for (var i = 0; i < keys.length; i++) {
    if (params[keys[i]]) {
      query_string += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
      if (i !== keys.length - 1 && keys.length > 1) {
        query_string += '&';
      }
    }
  }
  return query_string;
}

function buildQueryArrayParams(params, arrayParams) {
  var query_string = buildQueryParams(params);
  if (!arrayParams) {
    return query_string;
  }
  if (query_string.length === '') {
    query_string += '?';
  }
  var keys = Object.keys(arrayParams);
  for (var i = 0; i < keys.length; i++) {
    if (arrayParams[keys[i]]) {
      for (var j = 0; j < arrayParams[keys[i]].length; j++) {
        query_string += "&" + encodeURIComponent(keys[i]) + '=' + encodeURIComponent(arrayParams[keys[i]][j]);
      }
    }
  }
  return query_string;
}

export default HttpClient;

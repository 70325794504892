import React from 'react';
import { withRouter } from "react-router-dom";
import AnalystTabs from '../analyst-tabs/AnalystTabs.component';
import AnalystService from '../../../services/Analyst.service';
import Spinner from '../../spinner/Spinner.component';
import SideBar from '../../side-bar/SideBar.component';
import ErrorComponent from '../../misc/error/Error.component';
import { ANALYST_TEAMS_PAGE_TITLE } from '../../../constants/PageTitle.constants';
import { EQUALS, ERROR_TYPE, QUESTION_MARK } from '../../../constants/Misc.constants';
import { ROUTE_PATH_ANALYST_TEAMS_PAGE } from '../../../constants/RoutePaths.constants';
import queryString from 'query-string';
import UserService from '../../../services/User.service';
import { isCowen, isCowenEnabled } from "../../../utils/valueUtils";
import CowenFlagContext from "../../../system/CowenFlagContext";
import {
    ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB,
    ACL_ANALYST_TEAMS_EQUITIES_TAB
} from '../../../constants/Entitlements.constants';

class AnalystTeamsComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            analystTeams: null,
            loading: true,
            error: false,
            tab: 0,
            cowenEnabled: false,
            hasAccess: [],
        };
        let urlQueryParams = queryString.parse(this.props.location.search);
        if (!urlQueryParams.tab) {
            this.state.tab = 0;
        } else {
            this.state.tab = urlQueryParams.tab;
        }
        this.ficmAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB);
        this.eqAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_EQUITIES_TAB);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.setTabAndAccessTypes = this.setTabAndAccessTypes.bind(this);
    }
    componentDidMount() {
        document.title = ANALYST_TEAMS_PAGE_TITLE;
        this.getAnalystTeams();
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
            this.setTabAndAccessTypes();
          }).catch(e => {
            console.log(e);
          });
         
       
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname.includes(ROUTE_PATH_ANALYST_TEAMS_PAGE)) {
                let urlQueryParams = queryString.parse(location.search);
                if (!urlQueryParams.tab) {
                    this.setTabAndAccessTypes();
                } else {
                    this.setState({
                        tab: urlQueryParams.tab
                    })
                }
            }
        });
    }

    setTabAndAccessTypes() {
        const cowenFlag = this.context;
        let hasAccessTmp = [];
        let urlQueryParams = queryString.parse(this.props.location.search);
        if(UserService.isFicm()) {
            hasAccessTmp.push(this.ficmAccess);
            this.setState ({
                tab: 0,
            })
        } if(UserService.isEQ()) {
            if(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) {
                this.setState ({
                    tab: 0,
                })
                hasAccessTmp.push(this.eqAccess);
                hasAccessTmp.push(this.eqAccess);
                hasAccessTmp.push(this.eqAccess);
            } else {
                    if(UserService.isEQOnly()) {
                        this.setState ({
                            tab: 0,
                        })
                    } else {
                    this.setState ({
                        tab: 1,
                    })
                    }
                hasAccessTmp.push(this.eqAccess);
            }
        }
        if (urlQueryParams?.tab) {
            this.setState ({
                tab:  urlQueryParams.tab,
            })
        }
        this.setState ({
            hasAccess: hasAccessTmp,
        })
    }
   

    componentWillUnmount() {
        this.unlisten();
    }
    setActiveTab(index) {
        var url = QUESTION_MARK + 'tab' + EQUALS + index;
        this.setState( {
            tab: index
        },this.props.history.push(url));
    }
    getAnalystTeams() {
        AnalystService.getAnalystTeams().then((analystTeams) => {
            this.setState({
                analystTeams: analystTeams,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                analystTeams: null,
                loading: false,
                error: true
            });
        });
    }
    render() {
        return (
            <section className="tds-analyst-teams">
                <div className="tds-analyst-teams-container">
                    <div className="tds-three-column-container">
                        {
                            this.state.loading &&
                            <React.Fragment>
                                <h2>Teams</h2>
                                <Spinner minHeight={1200} marginTop={100} />
                            </React.Fragment>

                        }
                        {
                            !this.state.loading && this.state.analystTeams &&
                            <React.Fragment>
                                <h2>Teams</h2>
                                <AnalystTabs 
                                    analystTeams={this.state.analystTeams} 
                                    tab={this.state.tab}
                                    setActiveTab={this.setActiveTab}
                                    hasAccess={this.state.hasAccess}
                                />
                            </React.Fragment>
                        }
                        {   
                            !this.state.loading && this.state.error &&
                            <React.Fragment>
                                <h2>Teams</h2>
                                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                            </React.Fragment>
                        }
                    </div>
                    <SideBar />
                </div>
            </section>
        )
    }
}

export default withRouter(AnalystTeamsComponent);
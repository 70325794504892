import React from 'react';
import SideBarComponent from '../side-bar/SideBar.component';
import AdvancedFilterComponent from './AdvancedFilter.component';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    ACL_EQUITIES_COVERAGE
} from '../../constants/Entitlements.constants';
import { COVERAGE_ADVANCED_FILTER_PAGE_TITLE } from '../../constants/PageTitle.constants';
import { withRouter } from 'react-router-dom';

export class AdvancedFilterPage extends React.Component {
    constructor(props) {
        super(props);
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_COVERAGE);
    }
    componentDidMount() {
        document.title = COVERAGE_ADVANCED_FILTER_PAGE_TITLE;
    }    
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-container tds-advanced-filter-page">
                        <div className="header">
                            <h1>Coverage Universe</h1> 
                        </div>                        
                        <div className="tds-three-column-container">                            
                            <h3>Advanced Filter</h3>                            
                            <AdvancedFilterComponent />                                                       
                        </div>
                        <SideBarComponent />
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(AdvancedFilterPage);
import React from 'react';
import AnalystTabsContent from '../analyst-tabs-content/AnalystTabsContent.component';
import UserService from '../../../services/User.service';
import {
    ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB,
    ACL_ANALYST_TEAMS_EQUITIES_TAB
} from '../../../constants/Entitlements.constants';

export default class AnalystTabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.ficmAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB);
        this.eqAccess = UserService.hasAccess(ACL_ANALYST_TEAMS_EQUITIES_TAB);
    }

    setActiveTab(index) {
        if (this.props.setActiveTab) {
            this.props.setActiveTab(index);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="tds-analyst-teams-tab-container">
                    <div className="tds-analyst-teams-tab-menu">
                        <div className="tds-analyst-teams-tab-menu-item">
                            {this.props.analystTeams.map((item, index) => (
                                <React.Fragment
                                    key={'analyst-teams-tab-' + index}
                                >
                                    {
                                        this.props.hasAccess[index] &&
                                        <a
                                            tabIndex="0"
                                            onClick={this.setActiveTab.bind(this, index)}
                                            id={'analyst-teams-tab-' + index}
                                            title={item.title}
                                            className={this.props.tab === index ? 'tds-analyst-teams-tab-link tds-analyst-teams-global active' : ''}
                                        >
                                            {item.title}
                                        </a>
                                    }
                                </React.Fragment>
                            ))
                            }
                        </div>
                    </div>
                </div>
                {
                    this.props.hasAccess[this.props.tab] &&
                    <AnalystTabsContent analystTabContent={this.props.analystTeams[this.props.tab]} />
                }
            </React.Fragment>
        )
    }
}

import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV, TDS_TOP_NAV_DROPDOWN_LINK, TDS_DROPDOWN_CONTENT,
    TYPE_DROPDOWN, TYPE_LINK, LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL, LINK_DESTINATION_NONE
} from '../../constants/HeaderNav.constants';

import HeaderNavDropDownComponent from './HeaderNavDropDown.component';
import { LOG_LEVEL, logger } from '../../system/Logger';

export default class HeaderNavBarComponent extends React.Component {
    topClassName = TDS_TOP_NAV;
    dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK;
    dropDownContentClassName = TDS_DROPDOWN_CONTENT;
    menuItemsDOM = [];
    menuItemsREF = [];

    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarComponent", this.props);
        this.topClassName = this.props.topClassName || this.topClassName;
        this.dropDownClassName = this.props.dropDownClassName || this.dropDownClassName;
        this.dropDownContentClassName = this.props.dropDownContentClassName || this.dropDownContentClassName;
        this.hover = false || this.props.hover;
        this.handleTopMenuClick = this.handleTopMenuClick.bind(this);
    }
    handleTopMenuClick(e) {
        e.preventDefault();
        this.props.showModal(e.target.dataset.modaltype);
    }
    createMenuItems() {
        if (this.props.menuItems) {
            for (var menuItem of this.props.menuItems) {
                if (menuItem.destination === LINK_DESTINATION_EXTERNAL &&
                    menuItem.type === TYPE_LINK) {
                    this.menuItemsDOM.push(
                        <div className="tds-nav-item" key={menuItem.text}>
                            <a href={menuItem.link} target="_blank" rel="noopener noreferrer" title={menuItem.text}>
                                {menuItem.text}
                            </a>
                        </div>
                    )
                }
                if (menuItem.destination === LINK_DESTINATION_INTERNAL && 
                    menuItem.type === TYPE_LINK) {
                    if (menuItem.hasAccess) {
                        this.menuItemsDOM.push(
                            <div className="tds-nav-item" key={menuItem.text}>
                                <Link
                                    to={menuItem.link}
                                    title={menuItem.text}
                                >
                                    { menuItem.icon &&
                                        <span className="td-icon td-icon-homepage icon-small"></span>
                                    }
                                    {menuItem.text}
                                </Link>
                            </div>
                        )
                    } else {
                        this.menuItemsDOM.push(
                            <div className="tds-nav-item" key={menuItem.text}>
                                <Link
                                    to={menuItem.link}
                                    onClick={this.handleTopMenuClick.bind(this)}
                                    data-modaltype={menuItem.modalType}
                                    title={menuItem.text}
                                >
                                    {menuItem.text}
                                </Link>
                            </div>
                        )
                    }
                }
                if (menuItem.destination === LINK_DESTINATION_NONE && 
                    menuItem.type === TYPE_DROPDOWN) {
                    var child1 = React.createRef();
                    this.menuItemsREF.push(child1);
                    this.menuItemsDOM.push(
                        <HeaderNavDropDownComponent
                            ref={child1}
                            dropDownData={menuItem}
                            dropDownClassName={this.dropDownClassName}
                            dropDownContentClassName={this.dropDownContentClassName}
                            key={menuItem.text}
                            hover={this.hover}
                            closeOtherMenus={() => this.closeMenus()}
                            showModal={this.props.showModal}
                        />
                    )
                }                
            }
        }
    }

    getItems = () =>{
        this.menuItemsDOM = [];
        this.menuItemsREF = []
        this.createMenuItems();
        return this.menuItemsDOM;
    }
    closeMenus() {
        for (var i = 0; i < this.menuItemsREF.length; i++) {
            this.menuItemsREF[i].current.closeMenu();
        }
    }
    render() {
        if (this.props.menuItems) {
            return (
                <div className={this.topClassName}>
                    {this.getItems()}
                </div>
            )
        } else {
            return null;
        }
    }

}
import React from 'react';
import queryString from 'query-string';
import ErrorComponent from '../../misc/error/Error.component';
import ResearchListingComponent from './ResearchListing.component';
import { AND, DEFAULT_ALL, EQUALS, ERROR_TYPE, QUESTION_MARK } from '../../../constants/Misc.constants';
import { isNotEmptyArray, isEmptyArray }  from '../../../utils/valueUtils';
import { RESEARCH_PAGE_QUERY_PARAMS_COMPANY_ALPHBET_FILTER, RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE, RESEARCH_PAGE_TAB } from '../../../constants/ResearchPage.constants';
import ResearchCompanyListingComponent from './ResearchCompanyListing.component';
import { ROUTE_PATH_EQUITIES_RESEARCH_PAGE } from '../../../constants/RoutePaths.constants';
import { withRouter } from 'react-router-dom';

export class ResearchPageTabItemComponent extends React.Component {
    containerClassName = 'tds-tab-menu-container';
    constructor(props) {
        super(props);
        this.state = {
            companyAlphabetFilter: DEFAULT_ALL
        };

        const urlQueryParams = queryString.parse(this.props?.location?.search);
        if (urlQueryParams.companyAlphabetFilter) {
            this.state.companyAlphabetFilter = urlQueryParams.companyAlphabetFilter;
        }
        
        this.handleAlphabetFilterClick = this.handleAlphabetFilterClick.bind(this);
        this.updateUrl = this.updateUrl.bind(this);        
    }
    handleAlphabetFilterClick(companyAlphabetFilter) {
        this.setState(
            {
                companyAlphabetFilter: companyAlphabetFilter,
            }, this.updateUrl
        );
    }
    updateUrl() {
        const url =
        ROUTE_PATH_EQUITIES_RESEARCH_PAGE +
            QUESTION_MARK +
            RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE +
            EQUALS +
            this.props.researchType +
            AND +
            RESEARCH_PAGE_QUERY_PARAMS_COMPANY_ALPHBET_FILTER +
            EQUALS +
            this.state.companyAlphabetFilter;

        this.props.history.replace(url);    
    }
    render() {
        return (
            <React.Fragment>
                <div className="tds-research-listings-container">
                    { isEmptyArray(this.props.data) &&
                        <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />                        
                    }
                    { isNotEmptyArray(this.props.data) &&
                        <React.Fragment>
                            { [RESEARCH_PAGE_TAB.INDUSTRY, RESEARCH_PAGE_TAB.ANALYST].includes(this.props.researchType) &&
                                <div className={"tds-research-listings regular-list"}>
                                    <ResearchListingComponent
                                        type={this.props.researchType.toLowerCase()}
                                        data={this.props.data}
                                    />
                                </div>
                            }
                            { this.props.researchType === RESEARCH_PAGE_TAB.COMPANY &&
                                <div className="tds-research-listings company">
                                    <ResearchCompanyListingComponent
                                        data={this.props.data}
                                        handleAlphabetFilterClick={
                                            this.handleAlphabetFilterClick
                                        }
                                        filter={this.state.companyAlphabetFilter}
                                    />
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ResearchPageTabItemComponent);
import HttpClient from '../utils/HttpClient';
import {
    REST_HOMEPAGE_PATH,
    REST_HOMEPAGE_TAILORED_FOR_YOU_PATH,
    REST_HOMEPAGE_IN_DEPTHS_PATH_V2,
    REST_HOMEPAGE_MOST_RECENT_PATH,
    REST_HOMEPAGE_MOST_POPULAR_PATH,
    REST_HOMEPAGE_MOST_POPULAR_REGIONS_PATH
} from '../constants/Rest.constants';

export default class HomePageService {

    static getHomePageData(eqOnly = false) {
        let params = {
            eqOnly: eqOnly
        }
        return HttpClient.get(REST_HOMEPAGE_PATH, params);
    }

    static getTailoredForYouData(eqOnly = false) {
        let params = {
            eqOnly: eqOnly
        }
        return HttpClient.get(REST_HOMEPAGE_TAILORED_FOR_YOU_PATH, params);
    }

    static getInDepthsData(params) {
        return HttpClient.get(REST_HOMEPAGE_IN_DEPTHS_PATH_V2, params, {});
    }

    static getHomePageMostRecentTabData(params) {
        return HttpClient.get(REST_HOMEPAGE_MOST_RECENT_PATH, params, {});
    }

    static getHomePageMostPopularTabData(params) {
        return HttpClient.get(REST_HOMEPAGE_MOST_POPULAR_PATH, params, {});
    }

    static getHomePageMostPopularTabByRegionData(region, params) {
        return HttpClient.get(REST_HOMEPAGE_MOST_POPULAR_REGIONS_PATH + "/" + region, params, {});
    }


}
import HttpClient from '../utils/HttpClient';
import {
    REST_THEMATIC_PATH,
    REST_DATAFLOW_PATH
} from '../constants/Rest.constants';
import {
    THEMATIC_RESEARCH_TYPE,
    CENTRAL_BANK_DATA_FLOW_TYPE
} from '../constants/RegionPage.constants';

export default class ThematicDataflowService {

    static getThematicDataflowData(contentType, region, params) {
        if (contentType === THEMATIC_RESEARCH_TYPE) {
            return ThematicDataflowService.getThematicData(region, params);
        } else if (contentType === CENTRAL_BANK_DATA_FLOW_TYPE) {
            return ThematicDataflowService.getDataflowData(region, params);
        } else {
            const promiseNotFound = new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(null);
                }, 1);
            });
            return promiseNotFound;
        }
    }

    static getThematicData(region, params) {
        return HttpClient.get(REST_THEMATIC_PATH + region, params);
    }

    static getDataflowData(region, params) {
        return HttpClient.get(REST_DATAFLOW_PATH + region, params);
    }
}
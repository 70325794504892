import HttpClient from '../utils/HttpClient';
import { REST_CONTENT_PATH } from '../constants/Rest.constants';

export default class ContentService {

    static getContent(contentId, firmId) {
        return HttpClient.get(REST_CONTENT_PATH + '/' + firmId + '/' + contentId);
    }

}

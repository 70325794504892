import React from 'react';
import { LINK_TDSECURITIES_ALPHA } from '../../constants/LinkPaths.constants';
import {isCowen} from "../../utils/valueUtils";
import CowenFlagContext from "../../system/CowenFlagContext";
import {COWEN_DISCLOSURE_LINK} from "../../constants/Misc.constants";

export default class DisseminationPolicyPageComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(e);
        });
    }

    getPolicyLink() {
        return COWEN_DISCLOSURE_LINK;
    }

    render() {
        return (
            <div className="tds-container">
                <div className="tds-generic-content-page">
                    <h1>Dissemination Policy</h1>

                    <p> TDSI will disclose its research dissemination policies and procedures on its web site: <a
                        href={this.getPolicyLink()}
                        rel="noopener noreferrer"
                        title="Dissemination policies and procedures"
                        target="_blank">{this.getPolicyLink()}</a> (IIROC
                Rule
                3400 (6) & (2)(c)). The research dissemination policy for equity Research Reports reads, (or some
                variation
                of):</p>

                    <p>TD Securities makes its research products available in electronic and/or printed formats. If there are
                    any
                    subsequent material changes to the reports it publishes, TD Securities will as soon as practicable
                    distribute such reports with the relevant changes to its institutional clients who are entitled to
                    receive
                    them. Entitled institutional clients may also receive our research via third-party platforms including,
                    but
                    not limited to, Bloomberg, FactSet, Refinitiv, and S&P Capital IQ. All research is available by password
                    to
                entitled institutional clients at <a href={LINK_TDSECURITIES_ALPHA} rel="noopener noreferrer"
                            title="Equities Research" target="_bank">{LINK_TDSECURITIES_ALPHA}</a> TD Securities may also update proprietary
                models;
                these models may be obtained by entitled institutional clients by contacting the research analyst
                directly.
                There is no planned frequency of updates to these models.</p>
                </div>
            </div>
        )
    }
}

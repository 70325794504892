import React from 'react';

export default class IframeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onLoad = this.onLoad.bind(this);
    }
    onLoad() {
        console.log("Iframe loaded");
    }
    render() {
        return (
            <iframe
                title={this.props.title || 'Blue Matrix Rendered Document'}
                className={this.props.className || 'iframe'}
                id={'iframePublicationsPage'}
                src={this.props.src}   
                onLoad={this.onLoad}
            >
            </iframe>
        )
    }
}

import React from 'react';
import EnvironmentService from '../../services/Environment.service';
import IframeComponent from '../iframe/Iframe.component';
import Spinner from '../spinner/Spinner.component';
/* DISCLAIMER: This is a hack */

export default class LogoutPageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            beginLoggingUserOut: true,
            iframe1Name: 'iframe_1',
            iframe1Loaded: false,
            iframe1LoadCheckCount: 0,
            iframe1URL: EnvironmentService.logoutURL.iframe1URL,
            iframe2Name: 'iframe_2',
            iframe2Loaded: false,
            iframe2LoadCheckCount: 0,
            iframe2URL: EnvironmentService.logoutURL.iframe2URL,
            showSpinner: true
        };
        this.checkIframe1Loaded = this.checkIframe1Loaded.bind(this);
        this.checkIframe2Loaded = this.checkIframe2Loaded.bind(this);
        this.beginLogoutUser = this.beginLogoutUser.bind(this);
        this.finalizeLogoutUser = this.finalizeLogoutUser.bind(this);
    }
    componentDidMount() {
        this.checkIframe1Loaded();
        this.checkIframe2Loaded();
        this.beginLogoutUser();
    }
    checkIframe1Loaded() {
        var iframe = document.getElementById(this.state.iframe1Name);
        if (iframe) {
            this.setState({ iframe1Loaded: true }, console.log("iframe 1 loaded", this.state.iframe1URL));
        }
        if (this.state.iframe1LoadCheckCount >= 5) {
            this.setState({ iframe1Loaded: true }, console.log("iframe 1 did not load?", this.state.iframe1URL));
        }
        this.setState({
            iframe1LoadCheckCount: 1 + this.state.iframe1LoadCheckCount
        });
        if (!this.state.iframe1Loaded) {
            window.setTimeout(this.checkIframe1Loaded, 1000);
        }
    }
    checkIframe2Loaded() {
        var iframe = document.getElementById(this.state.iframe2Name);
        if (iframe) {
            this.setState({ iframe2Loaded: true }, console.log("iframe 2 loaded", this.state.iframe2URL));
        }
        if (this.state.iframe2LoadCheckCount >= 5) {
            this.setState({ iframe2Loaded: true }, console.log("iframe 2 did not load?", this.state.iframe2URL));
        }
        this.setState({
            iframe2LoadCheckCount: 1 + this.state.iframe2LoadCheckCount
        });
        if (!this.state.iframe2Loaded) {
            window.setTimeout(this.checkIframe2Loaded, 1000);
        }
    }
    beginLogoutUser() {
        console.log(this.state);
        if (this.state.iframe1Loaded && this.state.iframe2Loaded && this.state.showSpinner) {
            window.setTimeout(this.finalizeLogoutUser, 30000);
        } else {
            window.setTimeout(this.beginLogoutUser, 1000);
        }
    }
    finalizeLogoutUser() {
        this.setState( {
            showSpinner: false
        });
        if (this.props.logoutUser) {
            this.props.logoutUser();
        }
    }
    render() {
        return (
            <div className="tds-container">
                <div className="tds-container">
                    {
                        !this.state.showSpinner &&
                        <div className="tds-loggedout">
                            <h1>You have been successfully logged out</h1>
                        </div>
                    }
                    {
                        this.state.showSpinner &&
                        <Spinner minHeight={1000} marginTop={50} />
                    }
                    <div className="tds-loggedout-iframe-container">
                        <IframeComponent
                            src={this.state.iframe1URL}
                            id={this.state.iframe1Name}
                        />
                        <IframeComponent
                            src={this.state.iframe2URL}
                            id={this.state.iframe2Name}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


import React, { useEffect, useState } from "react"
import { ACL_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA } from "../../constants/Entitlements.constants";
import ConferenceCallsOtherMediaService from "../../services/ConferenceCallsOtherMedia.service";
import UserService from "../../services/User.service";
import ErrorComponent from "../misc/error/Error.component";
import { CONF_CALLS_COVERAGE_PAGE_SIZE, CONF_CALLS_KEYWORDS_PLACEHOLDER_DESKTOP, ERROR_TYPE, TEAM } from "../../constants/Misc.constants";
import SpinnerComponent from "../spinner/Spinner.component";
import AclAccessDeniedComponent from "../misc/acl-access-denied/AclAccessDenied.component";
import SearchServiceV2 from '../../services/v2/Search.service';
import Select from 'react-select';
import { ReactSelectDesktopStyles } from "../../constants/ReactSelect.constants";
import PaginationComponent from '../pagination/Paginator.component';
import ConferenceCallsOtherMediaSection from "./ConferenceCallsOtherMediaSection.component";
import { CONF_CALLS_PAGE_TITLE } from "../../constants/PageTitle.constants";

const ConferenceCallOtherMediaPageComponent = () => {
    const [data, setData] = useState();
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [keywords, setKeywords] = useState(null);
    const [sector, setSector] = useState(null);
    const [sectors, setSectors] = useState(null);
    const [page, setPage] = useState(1);
    const hasAccess = UserService.hasAccess(ACL_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA);

    useEffect(() => {
        document.title = CONF_CALLS_PAGE_TITLE;
        let sectorsData = [];
        const getError = () => {
            setHasError(true);
              setLoading(false);
              setSectors(null);
              sectorsData = null;
              setData(null);
          }
        SearchServiceV2.getDropDownData(TEAM.CA).then(caData => {
            sectorsData.push(...caData.industries);
            setLoading(false);
        }).catch(getError);
        SearchServiceV2.getDropDownData(TEAM.US).then(usData => {
            sectorsData.push(...usData.industries);
            sectorsData.sort((a, b) => a.label.localeCompare(b.label));
        }).catch(getError);
        setSectors(sectorsData);
    }, []);

    useEffect(() => {
        const getError = () => {
            setHasError(true);
              setLoading(false);
              setSectors(null);
              setData(null);
          }
        let params = {
            page: page,
            sector: sector,
            searchText: keywords,
            size: CONF_CALLS_COVERAGE_PAGE_SIZE,
        };
        setLoading(true);
        setHasError(false);
        ConferenceCallsOtherMediaService.getData(params).then(confData => {
            processData(confData);
            setLoading(false);
        }).catch(getError);

        const processData = (data) => {
            if (!data || data?.results?.length === 0) {
                setData(data);
            }
            else if(data && data?.results?.length > 0) {
                for (const result of data?.results) {
                    result.expandable = false;
                    if (result.confPresenterName || result.confModerator || result.confPresenterBio || result.title) {
                        result.expandable = true;
                    }
                }
                setData(data);
            }
        }

    }, [page, sector, keywords]);

    const goToPage = (page) => {
        setPage(page);
    }

    const showSpinner = () => loading && <SpinnerComponent />;

    const accessDenied = () => {
        return !hasAccess && <AclAccessDeniedComponent />;
    }
    const error = () => {
        return hasError && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    }
    const handleSectorChange = (obj) => {
        if(obj !== null) {
            setSector(obj.value);
            setPage(1);
        } else {
            setSector(null);
        }    
    }

    const handleKeywordSearchClick = () => {
        setKeywords(keyword); 
    }

    return (
        <React.Fragment>
            { accessDenied()}
            {
                hasAccess &&
                <section className="conf-calls-other-media">
                    <h1>Conference Calls & Other Media</h1>
                    {showSpinner()}
                    {error()}
                        <div>
                            <div className="tds-conf-calls-other-media-container form-group">
                                <input
                                    className="form-control"
                                    name="keyword"
                                    type="text"
                                    value={keyword}
                                    onChange={handleKeywordChange}
                                    placeholder={CONF_CALLS_KEYWORDS_PLACEHOLDER_DESKTOP}
                                />
                                <span
                                className="td-icon td-icon-search icon-conf-calls-other-media"
                                onClick={handleKeywordSearchClick}
                                title="Search"
                            ></span>
                                 
                            </div>
                            <div className="tds-conf-calls-other-media-select">
                                <Select
                                    key="sector"
                                    options={sectors}
                                    isMulti={false}
                                    value={this}
                                    name="sector"
                                    onChange={handleSectorChange}
                                    styles={ReactSelectDesktopStyles}
                                    placeholder='Select Sector'
                                    isClearable={true}
                                />
                                {
                                    error() &&
                                    <Select styles={ReactSelectDesktopStyles} placeholder="Select Sector" isDisabled={true} />
                                }
                            </div>     
                            <div className="tds-product-table-real-time-coverage">
                                {data?.results?.map(
                                    (sectionData, index) => {
                                        return (
                                            <ConferenceCallsOtherMediaSection
                                                key={'conf-data-other-media-section-component' + index}
                                                data={sectionData}
                                            />
                                        )
                                    }
                                )}
                                {data?.results?.length === 0 &&
                                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                                }
                            </div>
                            { 
                                !showSpinner() && data && data.total > 0 && 
                                <PaginationComponent
                                    currentPage={page ? page : 1}
                                    resultsPerPage={CONF_CALLS_COVERAGE_PAGE_SIZE}
                                    total={data.total}
                                    goToPage={goToPage}
                                />
                            }
                        </div>
                </section>
            }
        </React.Fragment>
    );
};
export default ConferenceCallOtherMediaPageComponent;
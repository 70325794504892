import React from 'react';
import CalendarDay from './CalendarDay.component';

export default class CalendarRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }
    handleEventClick(id) {
        if (this.props.handleEventClick) {
            this.props.handleEventClick(id)
        }
    }
    handleDayClick(day,week,column) {
        if (this.props.handleDayClick) {
            this.props.handleDayClick(day,week,column)
        }
    }
    render() {
        return (
            <div className="tds-calender-day-row">
                {
                    this.props.row && this.props.row.map((day, index) => {
                        return (
                            <CalendarDay
                                key={'calendar-day-' + index}
                                day={day}
                                week={this.props.week}
                                column={index}
                                handleEventClick={this.handleEventClick}
                                handleDayClick={this.handleDayClick}
                                eventTypeMarketing={this.props.eventTypeMarketing}
                                eventTypeEarnings={this.props.eventTypeEarnings}
                                eventTypeConference={this.props.eventTypeConference}
                            />
                        )
                    })
                }

            </div>
        )
    }
}

import React from "react";
import SecureLinkService from "../../../services/SecureLink.service";
import UtilsService from "../../../services/Utils.service";
import {isNotEmptyArray} from "../../../utils/valueUtils";
import ResponsiveBackgroundImageComponent from "../responsive-background-image/ResponsiveBackgroundImage.component";

const HighlightCards = (props) => {

    const highlightCards = props.data;

    const handleHighlightCardClick = (item) => e => {
        if(item.pdfLink) {
            item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
        }
        window.open(item.pdfLink || item.htmlLink || item.link);
    };

    return (
    <React.Fragment>
        { isNotEmptyArray(highlightCards) &&
            <div className="tds-highlight-cards-container">
                {
                    highlightCards.map((card, index) => { 
                        return(
                            <div className="tds-highlight-card" key={`card${index}`}>
                                <ResponsiveBackgroundImageComponent
                                    className={'tds-highlight-card-image-container'}
                                    default={card.image || UtilsService.getRandomDefaultImage() }
                                    tablet1={card.tablet1Image || UtilsService.getRandomDefaultImage() }
                                    tablet2={card.tablet2Image || UtilsService.getRandomDefaultImage() }
                                    mobile={card.mobileImage || UtilsService.getRandomDefaultImage() }
                                    ariaLabel={card.ariaLabel}
                                    title={card.title}
                                    onClick={handleHighlightCardClick(card)}
                                >
                                    <div className="content-block">
                                        <div className="content">
                                            <h3>{card.title}</h3>
                                        </div>
                                    </div>
                                </ResponsiveBackgroundImageComponent>
                            </div>
                        );
                    })

                }                                
            </div>
        }
    </React.Fragment>
    );
};

export default HighlightCards;
import {
  FIRM_NAME_STRATEGY,
  FIRM_NAME_COWEN,
  LABEL_EQUITY_RESEARCH_CANADA,
  LABEL_EQUITY_RESEARCH_US,
  TEAM,
  LABEL_WRG,
} from "../../../constants/Misc.constants";

export const SearchFirms = [
  {
    id: "radio1",
    firmName: FIRM_NAME_STRATEGY,
    team: TEAM.STR,
    label: "Strategy",
  },
  {
    id: "radio2",
    firmName: FIRM_NAME_COWEN,
    team: TEAM.CA,
    label: LABEL_EQUITY_RESEARCH_CANADA,
  },
  {
    id: "radio3",
    firmName: FIRM_NAME_COWEN,
    team: TEAM.US,
    label: LABEL_EQUITY_RESEARCH_US,
  },
  {
    id: "radio4",
    firmName: FIRM_NAME_COWEN,
    team: TEAM.WRG,
    label: LABEL_WRG,
  }
];

import React from 'react';
import { withRouter } from "react-router-dom";
import PaginationComponent from '../../pagination/Paginator.component';
import ShowContentComponent from '../../../components/misc/show-content/ShowContent.component';
import ProductTradeIdeasSectionComponent from './ProductTradeIdeasSection.component';
import ErrorComponent from '../../misc/error/Error.component';
import Spinner from "../../spinner/Spinner.component";
import TradeIdeasService from "../../../services/TradeIdeas.service";
import UtilsService from '../../../services/Utils.service';
import StickyElementComponent from '../../misc/sticky-element/StickyElement.component';
import {KEY_ENTER, TEAM} from '../../../constants/Misc.constants';
import {
    PRODUCT_TRADE_IDEAS_FILTER,
    TRADE_IDEAS_PAGE_SIZE,
    TRADE_IDEAS_DOCUMENT_TYPE_MPU
} from "../../../constants/TradeIdeas.constants";
import {
    SEARCH_RESULTS_PRODUCTS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME, SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME
} from "../../../constants/SearchResults.constants";

import {
    LINK_SEARCH_PAGE
} from "../../../constants/LinkPaths.constants";
import {
    TRADE_IDEAS_TYPES
} from "../../../constants/TradeIdeas.constants";
import {
    ERROR_TYPE
} from '../../../constants/Misc.constants';

class ProductTradeIdeasComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            tradeIdeasCurrentPage: 1,
            tradeIdeasData: null,
            tradeIdeasLoading: false,
            tradeIdeasError: true,
            tradeIdeasFilter: PRODUCT_TRADE_IDEAS_FILTER.ALL,
            tradeIdeasKeywords: "",
            tradeStatus: null,
            totalNoOfPages: null
        };
        if (this.props.initialKeywords && this.props.initialKeywords !== "") {
            this.state.tradeIdeasKeywords = this.props.initialKeywords;
        }
        if (this.props.initialStatus && this.props.initialStatus !== PRODUCT_TRADE_IDEAS_FILTER.ALL) {
            this.state.tradeIdeasFilter = this.props.initialStatus;
        }
        if (this.props.initialPage && this.props.initialPage !== 1) {
            this.state.tradeIdeasCurrentPage = this.props.initialPage;
        }
        this.goToPage = this.goToPage.bind(this);
        this.getTradeIdeas = this.getTradeIdeas.bind(this);
        this.getTradeIdeasData = this.getTradeIdeasData.bind(this);
        this.getTradeIdeasThen = this.getTradeIdeasThen.bind(this);
        this.getTradeIdeasError = this.getTradeIdeasError.bind(this);
        this.filterClicked = this.filterClicked.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.transformTradeIdeas = this.transformTradeIdeas.bind(this);
        this.handleKeywordSearchClick = this.handleKeywordSearchClick.bind(this);
        this.handleViewAllClick = this.handleViewAllClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.messageParentAboutKeywordChange = this.messageParentAboutKeywordChange.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.goToPage(this.state.tradeIdeasCurrentPage);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleKeyPress(event) {
        if (event.key === KEY_ENTER) {
            this.goToPage(1);
        }
    }
    goToPage(page) {
        this.setState(
            {
                tradeIdeasCurrentPage: page
            },
            this.getTradeIdeas
        );
        if (this.props.pageChange) {
            this.props.pageChange(page);
        }
    }
    getTradeIdeas() {
        this.setState(
            {
                tradeIdeasLoading: true,
                errorLoadingTradeIdeas: false
            },
            this.getTradeIdeasData
        );
    }
    getTradeIdeasData() {
        if (this.props.type === TRADE_IDEAS_TYPES.PRODUCT) {
            this.getTradeIdeasProductData();
        } else if (this.props.type === TRADE_IDEAS_TYPES.REGION) {
            this.getTradeIdeasRegionData();
        }

    }
    getTradeIdeasProductData() {
        var params = {
            page: this.state.tradeIdeasCurrentPage,
            size: TRADE_IDEAS_PAGE_SIZE,
            tradeStatus: this.state.tradeStatus
        };
        if (this.state.tradeIdeasKeywords !== "") {
            params.searchTxt = this.state.tradeIdeasKeywords;
        }
        TradeIdeasService.getTradeIdeasProductData(this.props.productType, params).then(this.getTradeIdeasThen).catch(this.getTradeIdeasError)
    }
    getTradeIdeasRegionData() {
        var params = {
            page: this.state.tradeIdeasCurrentPage,
            size: TRADE_IDEAS_PAGE_SIZE,
            tradeStatus: this.state.tradeStatus
        };
        if (this.state.tradeIdeasKeywords !== "") {
            params.searchTxt = this.state.tradeIdeasKeywords;
        } else {
            params.searchTxt = "";
        }
        TradeIdeasService.getTradeIdeasRegionData(this.props.regionType, params).then(this.getTradeIdeasThen).catch(this.getTradeIdeasError)
    }
    getTradeIdeasThen(tradeIdeasResults) {
        var _totalNoOfPages = Math.ceil(tradeIdeasResults.total / TRADE_IDEAS_PAGE_SIZE);
        this.setState(
            {
                tradeIdeasData: this.transformTradeIdeas(tradeIdeasResults),
                totalNoOfPages: _totalNoOfPages,
                tradeIdeasLoading: false,
                tradeIdeasError: false
            }
        );
    }
    getTradeIdeasError(error) {
        this.setState(
            {
                tradeIdeasLoading: false,
                tradeIdeasError: true
            }
        );
    }
    transformTradeIdeas(tradeIdeasData) {
        if (!tradeIdeasData) {
            return null;
        }
        if (!tradeIdeasData.results) {
            return tradeIdeasData;
        }
        var _tradeIdeasData = tradeIdeasData;
        for (var i = 0; i < _tradeIdeasData.results.length; i++) {
            var rowData = [];
            var row1Data = {};
            var textElements = _tradeIdeasData.results[i].textElements;
            row1Data.contentId = _tradeIdeasData.results[i].contentId;
            row1Data.firmId = _tradeIdeasData.results[i].firmId;
            row1Data.firmName = _tradeIdeasData.results[i].firmName;
            row1Data.date = _tradeIdeasData.results[i].date;
            row1Data.position = textElements.position.mpu_pos_1;
            row1Data.entryLevel = textElements.entryLevel.mpu_ent_1;
            row1Data.targetLevel = textElements.targetLevel.mpu_tar_1;
            row1Data.stopLoss = textElements.stopLoss.mpu_sls_1;
            row1Data.tradeSize = textElements.tradeSize.mpu_trd_1;
            row1Data.expectedHorizon = textElements.expectedHorizon.mpu_exp_1;
            row1Data.tradeStatusOpen = _tradeIdeasData.results[i].tradeStatusOpen;
            row1Data.expandable = true;
            rowData.push(row1Data);
            if (textElements.position.mpu_pos_2 && textElements.position.mpu_pos_2 !== "") {
                var row2Data = {};
                row2Data.contentId = _tradeIdeasData.results[i].contentId;
                row2Data.firmId = _tradeIdeasData.results[i].firmId;
                row2Data.firmName = _tradeIdeasData.results[i].firmName;
                row2Data.date = _tradeIdeasData.results[i].date;
                row2Data.position = textElements.position.mpu_pos_2;
                row2Data.entryLevel = textElements.entryLevel.mpu_ent_2;
                row2Data.targetLevel = textElements.targetLevel.mpu_tar_2;
                row2Data.stopLoss = textElements.stopLoss.mpu_sls_2;
                row2Data.tradeSize = textElements.tradeSize.mpu_trd_2;
                row2Data.expectedHorizon = textElements.expectedHorizon.mpu_exp_2;
                row2Data.tradeStatusOpen = _tradeIdeasData.results[i].tradeStatusOpen;
                rowData.push(row2Data);
            }
            if (textElements.position.mpu_pos_3 && textElements.position.mpu_pos_3 !== "") {
                var row3Data = {};
                row3Data.contentId = _tradeIdeasData.results[i].contentId;
                row3Data.firmId = _tradeIdeasData.results[i].firmId;
                row3Data.firmName = _tradeIdeasData.results[i].firmName;
                row3Data.date = _tradeIdeasData.results[i].date;
                row3Data.position = textElements.position.mpu_pos_3;
                row3Data.entryLevel = textElements.entryLevel.mpu_ent_3;
                row3Data.targetLevel = textElements.targetLevel.mpu_tar_3;
                row3Data.stopLoss = textElements.stopLoss.mpu_sls_3;
                row3Data.tradeSize = textElements.tradeSize.mpu_trd_3;
                row3Data.expectedHorizon = textElements.expectedHorizon.mpu_exp_3;
                row3Data.tradeStatusOpen = _tradeIdeasData.results[i].tradeStatusOpen;
                rowData.push(row3Data);
            }
            _tradeIdeasData.results[i].rowData = rowData;
            if (rowData.length > 0) {
                _tradeIdeasData.results[i]._date = rowData[0].date;
                _tradeIdeasData.results[i]._tradeStatusOpen = rowData[0].tradeStatusOpen;
            }
        }
        return _tradeIdeasData;
    }
    filterClicked(event) {
        var _tradeStatus = null;
        if (event.target.value === PRODUCT_TRADE_IDEAS_FILTER.OPEN) {
            _tradeStatus = "true";
        } else if (event.target.value === PRODUCT_TRADE_IDEAS_FILTER.CLOSED) {
            _tradeStatus = "false";
        }
        this.setState(
            {
                tradeIdeasFilter: event.target.value,
                tradeStatus: _tradeStatus
            }, this.goToPage(1)
        );
        if (this.props.statusChange) {
            this.props.statusChange(event.target.value)
        }
    }
    handleKeywordChange(event) {
        this.setState(
            {
                tradeIdeasKeywords: event.target.value
            }, this.messageParentAboutKeywordChange
        )
    }
    messageParentAboutKeywordChange() {
        if (this.props.keywordsChange) {
            this.props.keywordsChange(this.state.tradeIdeasKeywords);
        }
    }
    handleKeywordSearchClick() {
        this.goToPage(1);
    }
    handleViewAllClick(event) {
        event.preventDefault();
        if (this.props.type === TRADE_IDEAS_TYPES.PRODUCT) {
            this.props.history.push(LINK_SEARCH_PAGE + "?" +
                SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
                SEARCH_RESULTS_PRODUCTS_QUERY_PARAM_NAME + '=' + this.props.dropDownType + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + TRADE_IDEAS_DOCUMENT_TYPE_MPU + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME + '=true');
        } else if (this.props.type === TRADE_IDEAS_TYPES.REGION) {
            this.props.history.push(LINK_SEARCH_PAGE + "?" +
                SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
                SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME + '=' + UtilsService.getRegionParams(this.props.regionType) + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + TRADE_IDEAS_DOCUMENT_TYPE_MPU + "&" +
                SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME + '=true');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div
                    id={this.props.id}
                    className="tds-product-page-trade-ideas-container"
                >
                    <div className="tds-product-page-trade-ideas-search-and-sort-bar-container">
                        <div className="tds-product-page-trade-ideas-sort-bar-container">
                            <fieldset>
                                <div className="form-check form-check-inline">
                                    <legend>Status</legend>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="radiogroup1"
                                        id="radio1"
                                        value={PRODUCT_TRADE_IDEAS_FILTER.ALL}
                                        checked={this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.ALL}
                                        onChange={this.filterClicked}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="radio1">All</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="radiogroup1"
                                        id="radio2"
                                        value={PRODUCT_TRADE_IDEAS_FILTER.OPEN}
                                        checked={this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.OPEN}
                                        onChange={this.filterClicked}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="radio2">Open</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="radiogroup1"
                                        id="radio3"
                                        value={PRODUCT_TRADE_IDEAS_FILTER.CLOSED}
                                        checked={this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.CLOSED}
                                        onChange={this.filterClicked}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="radio3">Closed</label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="tds-product-page-trade-ideas-search-bar-container form-group">
                            <input className="form-control"
                                id=""
                                placeholder="Keywords"
                                value={this.state.tradeIdeasKeywords}
                                onChange={this.handleKeywordChange}
                                onKeyPress={this.handleKeyPress}
                                type="text" />
                            <span
                                className="td-icon td-icon-search icon-product-page-search"
                                onClick={this.handleKeywordSearchClick}
                                title="Search"
                            ></span>
                        </div>
                    </div>
                    <ShowContentComponent
                        if={this.state.tradeIdeasLoading}
                    >
                        <div className="tds-product-table-trade-ideas">
                            <Spinner minHeight={500} />
                        </div>
                    </ShowContentComponent>
                    <ShowContentComponent
                        if={!this.state.tradeIdeasLoading && this.state.tradeIdeasError}
                    >
                        <div className="tds-product-table-trade-ideas">
                            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                        </div>
                    </ShowContentComponent>
                    {
                        !this.state.tradeIdeasLoading && !this.state.tradeIdeasError &&
                        this.state.tradeIdeasData.results && this.state.tradeIdeasData.results.length === 0 &&
                        <ErrorComponent
                            type={ERROR_TYPE.TRADE_IDEAS}
                            handleViewAllClick={this.handleViewAllClick}
                        />
                    }
                    {
                        !this.state.tradeIdeasLoading && !this.state.tradeIdeasError &&
                        this.state.tradeIdeasData.results && this.state.tradeIdeasData.results.length > 0 &&
                        <React.Fragment>
                            <div className="tds-product-table-trade-ideas">
                                <StickyElementComponent
                                    className={'tds-product-table-trade-ideas-row header-row ' + this.state.tradeIdeasFilter.toLowerCase()}
                                    stickyClassName={'tds-product-table-trade-ideas-row header-row sticky ' + this.state.tradeIdeasFilter.toLowerCase()}
                                >
                                    <div className="tds-product-table-trade-ideas-column expand">
                                        &nbsp;
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column date">
                                        Date
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column position">
                                        Position
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column status">
                                        Status
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column entry-level">
                                        Entry Level
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column target-level">
                                        Target Level
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column stop-loss">
                                        { TradeIdeasService.getStopLossExitColumnName(this.state.tradeIdeasFilter) }
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column trade-size">
                                        Trade Size
                                    </div>
                                    <div className="tds-product-table-trade-ideas-column expected-horizon">
                                        { this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.ALL && <>Expected Horizon/PnL</> }
                                        { this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.OPEN && <>Expected Horizon</> }
                                        { this.state.tradeIdeasFilter === PRODUCT_TRADE_IDEAS_FILTER.CLOSED && <>PnL</> }
                                    </div>
                                </StickyElementComponent>
                                {this.state.tradeIdeasData.results.map(
                                    (productTradeIdeaItemSection, index) => {
                                        return (
                                            <ProductTradeIdeasSectionComponent
                                                key={'product-trade-ideas-section-component' + index}
                                                data={productTradeIdeaItemSection}
                                                tradeIdeasFilter={this.state.tradeIdeasFilter}
                                            />
                                        )
                                    }
                                )
                                }
                            </div>
                            {
                                this.state.tradeIdeasData.results.length > 0 &&
                                <React.Fragment>
                                    <PaginationComponent
                                        currentPage={this.state.tradeIdeasCurrentPage}
                                        resultsPerPage={TRADE_IDEAS_PAGE_SIZE}
                                        total={this.state.tradeIdeasData.total}
                                        goToPage={this.goToPage}
                                    />
                                    <div className='tds-product-table-trade-ideas-view-all'>
                                        <a
                                            href="# "
                                            onClick={this.handleViewAllClick}
                                            title="View All"
                                        >View All</a>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(ProductTradeIdeasComponent);
import HttpClient from '../utils/HttpClient';
import { 
    REST_REGION_PAGE_CARDS_PATH,
    REST_REGION_PAGE_TRADE_IDEAS_PATH,
    REST_REGION_PAGE_FORECAST_SNAPSHOT_PATH
} from '../constants/Rest.constants';

export default class RegionPageService {
    
    static getRegionCardData(type) {
        return HttpClient.get(REST_REGION_PAGE_CARDS_PATH + type);
    }

    static getForecastSnapshotData(type) {
        return HttpClient.get(REST_REGION_PAGE_FORECAST_SNAPSHOT_PATH + type );
    }
    
    static getTradeIdeasData(type, params) {
        return HttpClient.get(REST_REGION_PAGE_TRADE_IDEAS_PATH + type, params);
    }
    
}
import React from 'react';
import { Link } from "react-router-dom";
import {
    RESEARCH_PAGE_ANALYST,
    RESEARCH_PAGE_INDUSTRY,
    RESEARCH_PAGE_TYPE
} from '../../../../constants/ResearchPage.constants';
import {
    LINK_SEARCH_PAGE,
} from '../../../../constants/LinkPaths.constants';
import {
    QUESTION_MARK,
    EQUALS,
    AMPERSAND,
    QUERY_PARAM_FIRM_NAME,
    TEAM_TYPE,
    FIRM_NAME_COWEN
} from '../../../../constants/Misc.constants';
import {
    SEARCH_RESULTS_ANALYSTS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME,
    SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME
} from '../../../../constants/SearchResults.constants';
import { DOCUMENT_TYPE_NAME_MAPPING } from '../../../../constants/DocumentType.constants';

export default class ResearchListingComponent extends React.Component {

    getDocumentTypeByTypeName(name) {
        if( DOCUMENT_TYPE_NAME_MAPPING[name] ) return DOCUMENT_TYPE_NAME_MAPPING[name];
        return name;
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.data && this.props.data.map(
                        (item, index) => {
                            return (
                                <React.Fragment
                                    key={'tds-research-listings' + this.props.type + index}
                                >
                                    {
                                        this.props.type === RESEARCH_PAGE_ANALYST &&
                                        <Link
                                            to={LINK_SEARCH_PAGE + QUESTION_MARK + SEARCH_RESULTS_ANALYSTS_QUERY_PARAM_NAME + EQUALS + item.firstName + ' ' + item.lastName + AMPERSAND + QUERY_PARAM_FIRM_NAME +
                                            EQUALS + FIRM_NAME_COWEN + AMPERSAND + TEAM_TYPE + EQUALS + this.props.team}
                                            title={item.lastName + ', ' + item.firstName}
                                        >
                                            {item.lastName}, {item.firstName} {item.initials}
                                        </Link>
                                    }
                                    {
                                        this.props.type === RESEARCH_PAGE_INDUSTRY &&
                                        <Link
                                            to={LINK_SEARCH_PAGE + QUESTION_MARK + SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME + EQUALS + item.id + AMPERSAND + QUERY_PARAM_FIRM_NAME +
                                                EQUALS + FIRM_NAME_COWEN + AMPERSAND + TEAM_TYPE + EQUALS + this.props.team}
                                            title={item.name}
                                        >
                                            {item.name}
                                        </Link>
                                    }
                                    {
                                        this.props.type === RESEARCH_PAGE_TYPE &&
                                        <Link
                                            to={LINK_SEARCH_PAGE + QUESTION_MARK + SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + EQUALS + this.getDocumentTypeByTypeName(item.name) + AMPERSAND + QUERY_PARAM_FIRM_NAME +
                                                EQUALS + FIRM_NAME_COWEN + AMPERSAND + TEAM_TYPE + EQUALS + this.props.team}
                                            title={item.name}
                                        >
                                            {item.name}
                                        </Link>
                                    }
                                </React.Fragment>
                            )
                        }
                    )}
            </React.Fragment>
        )
    }
}

export var HOME_PAGE_MOST_RECENT_TAB = "mostRecent";
export var HOME_PAGE_MOST_POPULAR_TAB = "mostPopular";
export var HOME_PAGE_REAL_TIME_COVERAGE_TAB = "realTimeCoverage";
export var HOME_PAGE_MOST_POPULAR_BY_REGION_TAB = "mostPopularByRegion";
export var HOME_PAGE_IN_DEPTHS_TAB = "inDepths";
export var HOME_PAGE_TAB_PAGE_SIZE = 6;
export var SPOTLIGHT_CHAR_LIMIT = 140;
export var TAILORED_FOR_YOU_CHAR_LIMIT = 75;
export var HOME_PAGE_TAB_CHAR_LIMIT = 245;
export var HOME_PAGE_MOST_RECENT_PAGE = "mostRecentPage";
export var HOME_PAGE_MOST_POPULAR_PAGE = "mostPopularPage";
export var HOME_PAGE_REAL_TIME_COVERAGE_PAGE = "realTimeCoveragePage";
export var HOME_PAGE_MOST_POPULAR_BY_REGION_PAGE = "mostPopularByRegionPage";
export var HOME_PAGE_IN_DEPTHS_PAGE = "inDepthsPage";

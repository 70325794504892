import React from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import ShowContentComponent from '../../misc/show-content/ShowContent.component';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
import TradeIdeasService from '../../../services/TradeIdeas.service';
import {isNotEmpty} from '../../../utils/valueUtils';
import InnerHtml from '../../misc/inner-html/InnerHtml.component';

export default class ProductTradeIdeasItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleExpandClick() {
        if (this.props.handleExpandClick) {
            this.props.handleExpandClick();
        }
    }
    handleCollapseClick() {
        if (this.props.handleCollapseClick) {
            this.props.handleCollapseClick();
        }
    }
    render() {
        return (
            <div className="tds-product-table-trade-ideas-row">
                {
                    this.props.data &&
                    <React.Fragment>
                        <div className="tds-product-table-trade-ideas-column expand">
                            <ShowContentComponent
                                if={this.props.data.expandable}
                            >
                                <ShowContentComponent
                                    if={!this.props.expanded}
                                >
                                    <span
                                        className="td-icon td-icon-expand icon-small"
                                        onClick={this.handleExpandClick}
                                        title="Expand"
                                    ></span>
                                </ShowContentComponent>
                                <ShowContentComponent
                                    if={this.props.expanded}
                                >
                                    <span
                                        className="td-icon td-icon-collapse icon-small"
                                        onClick={this.handleCollapseClick}
                                        title="Collapse"
                                    ></span>
                                </ShowContentComponent>
                            </ShowContentComponent>
                            <ShowContentComponent
                                if={!this.props.data.expandable}
                            >
                                <span>&nbsp;</span>
                            </ShowContentComponent>
                        </div>
                        <div className="tds-product-table-trade-ideas-column date mobile-hide">
                            <FormatedDateComponent
                                date={this.props.data.date}
                                format={TDS_DATE_FORMAT}
                            />
                        </div>
                        <div className="tds-product-table-trade-ideas-column position">
                            <ShowContentComponent
                                if={isNotEmpty(this.props?.data?.position)} elseShowEmpty
                            >
                                
                                {
                                    <Link
                                        to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.contentId}
                                        title={<InnerHtml data={this.props.data.position}/>}
                                    >
                                        <InnerHtml data={this.props.data.position}/>
                                    </Link>
                                }
                            </ShowContentComponent>
                        </div>
                        <ShowContentComponent
                            if={this.props.data.tradeStatusOpen}
                        >
                            <div className="tds-product-table-trade-ideas-column status open mobile-hide">
                                Open
                            </div>
                        </ShowContentComponent>
                        <ShowContentComponent
                            if={!this.props.data.tradeStatusOpen}
                        >
                            <div className="tds-product-table-trade-ideas-column status closed mobile-hide">
                                Closed
                            </div>
                        </ShowContentComponent>
                        <div className="tds-product-table-trade-ideas-column position-mobile">
                            <ShowContentComponent
                                if={isNotEmpty(this.props?.data?.position)} elseShowEmpty
                            >
                                <Link
                                    to={LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.contentId}
                                    title={<InnerHtml data={this.props.data.position}/>}
                                >
                                    <InnerHtml data={this.props.data.position}/>
                                </Link>
                            </ShowContentComponent>
                        </div>
                        <div className="tds-product-table-trade-ideas-column entry-level">
                            <span className="label-mobile">Entry Level</span>
                            <InnerHtml data={this.props.data.entryLevel}/>
                        </div>
                        <div className="tds-product-table-trade-ideas-column target-level">
                            <span className="label-mobile">Target Level</span>
                            <InnerHtml data={this.props.data.targetLevel}/>
                        </div>
                        <div className="tds-product-table-trade-ideas-column stop-loss">
                            <span className="label-mobile">{TradeIdeasService.getStopLossExitColumnName(this.props.tradeIdeasFilter)}</span>
                            <InnerHtml data={this.props.data.stopLoss}/>
                        </div>
                        <div className="tds-product-table-trade-ideas-column trade-size">
                            <InnerHtml data={this.props.data.tradeSize}/>
                        </div>
                        <div className="tds-product-table-trade-ideas-column expected-horizon">
                            <InnerHtml data={this.props.data.expectedHorizon}/>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default class LoginService {
    static environment = null;
    static getLoginURL() {
        var loginURL = "/";
        const url = document.createElement('a');
        url.setAttribute('href', window.location.href);
        loginURL = url.protocol +
            '//' +
            url.hostname +
            ':' +
            url.port +
            '/login';
        return loginURL;
    }

    static getLogoutURL() {
        var logoutURL = "/";
        const url = document.createElement('a');
        url.setAttribute('href', window.location.href);
        logoutURL = url.protocol +
            '//' +
            url.hostname +
            ':' +
            url.port +
            '/logout';
        return logoutURL;
    }

}
import React from 'react';
import AnalystService from '../../services/Analyst.service';
import Spinner from '../spinner/Spinner.component';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { 
    ReactSelectDesktopStyles,
    ReactSelectDesktopStylesError
} from '../../constants/ReactSelect.constants';
import MailtoComponent from '../misc/mailto/Mailto.component';
import FormErrorComponent from '../misc/form-error/FormError.component';
import ErrorComponent from '../misc/error/Error.component';
import moment from 'moment';
import {
    RM_FORM_TITLE_STRATEGIST_REQUESTED,
    //RM_FORM_TITLE_TD_REQUESTER,
    //RM_FORM_TITLE_DESK_HEAD,
    RM_FORM_TITLE_PREFERRED_MEETING_DATE,
    RM_FORM_TITLE_PREFERRED_MEETING_TYPE,
    RM_FORM_TITLE_PREFERRED_MEETING_TYPE_SELECT,
    RM_FORM_TITLE_OTHER_MEETING_TYPE,
    RM_FORM_TITLE_LOCATION,
    RM_FORM_TITLE_CLIENT_ACCOUNT_NAME,
    RM_FORM_TITLE_FIRST_AND_LAST_NAME,
    RM_FORM_TITLE_PHONE_NUMBER,
    RM_FORM_TITLE_PREFERRED_EMAIL,
    RM_FORM_TITLE_NOTES,
    RM_FORM_MEETING_TYPE_FACE_TO_FACE,
    RM_FORM_MEETING_TYPE_CONFERANCE_CALL,
    RM_FORM_MEETING_TYPE_CONFERENCE,
    RM_FORM_MEETING_TYPE_OTHER,
    RM_EMAIL_COLON_SPACE,
    RM_EMAIL_SUBJECT_LINE,
    RM_FORM_DEFAULT_EMAIL,
    RM_EMAIL_DELIMITER
} from '../../constants/RequestMeeting.constants';
import {
    ANALYST_SEARCH_ALL,
    ANALYST_SEARCH_LAST_NAME,
    ANALYST_SEARCH_ASC,
    ANALYST_NAME_DELIMITER,
    ANALYST_ROLE_FILTER,
    ANALYST_ROLE_DELIMITER
} from '../../constants/Analyst.constants';

import {
    TDS_DATE_FORMAT,
    ERROR_TYPE,
    FIRM_NAME_STRATEGY
} from '../../constants/Misc.constants';

export default class RequestMeetingComponent extends React.Component {
    _isMounted = false;
    minDate = new Date();
    constructor(props) {
        super(props);
        this.mailto = React.createRef();
        this.calender = React.createRef();
        this.state = {
            dropDownLoading: true,
            errorLoadingDropDownData: false,
            dropDownData: null,
            analysts: null,
            subject: RM_EMAIL_SUBJECT_LINE,
            body: "",
            formValid: null,
            formPristine: true,
            formErrors: {}
        };
        this.getData = this.getData.bind(this);
        this.handleAnalystsChange = this.handleAnalystsChange.bind(this);
        // this.handleRequestorChange = this.handleRequestorChange.bind(this);
        // this.handleApprovalChange = this.handleApprovalChange.bind(this);
        this.handleMeetingTypeChange = this.handleMeetingTypeChange.bind(this);
        this.handleOtherMeetingTypeChange = this.handleOtherMeetingTypeChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleAccountNameChange = this.handleAccountNameChange.bind(this);
        this.handleFirstAndLastNameChange = this.handleFirstAndLastNameChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handlePreferredEmailChange = this.handlePreferredEmailChange.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildBody = this.buildBody.bind(this);
        this.clickMailTo = this.clickMailTo.bind(this);
        this.handleCalenderIconClick = this.handleCalenderIconClick.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.validateIfNotPristine = this.validateIfNotPristine.bind(this);
        this.getDropDownDataAnalyst = this.getDropDownDataAnalyst.bind(this);
        this.getDropDownDataAnalystThen = this.getDropDownDataAnalystThen.bind(this);
        this.getDropDownDataAnalystCatch = this.getDropDownDataAnalystCatch.bind(this);
        this.filterAndTransformAnalystData = this.filterAndTransformAnalystData.bind(this);
        this.checkFilterRoleString = this.checkFilterRoleString.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        if (!this.props.analyst) {
            this.getData();
        } else {
            this.setState({
                dropDownLoading: false
            })
        }
    }
    getData() {
        this.setState({
            dropDownLoading: true,
            errorLoadingDropDownData: false
        }, this.getDropDownDataAnalyst)
    }
    getDropDownDataAnalyst() {
        var params = {
            searchType: ANALYST_SEARCH_ALL,
            orderBy: ANALYST_SEARCH_LAST_NAME,
            direction: ANALYST_SEARCH_ASC,
            firm: FIRM_NAME_STRATEGY,
            page: 0,
            size: 1000
        };
        AnalystService.getAnalysts(params)
            .then(this.getDropDownDataAnalystThen)
            .catch(this.getDropDownDataAnalystCatch);
    }
    getDropDownDataAnalystThen(analystData) {
        if (this._isMounted) {
            var dropDownData = {
                analysts : this.filterAndTransformAnalystData(analystData)
            };
            this.setState({
                dropDownData: dropDownData,
                dropDownLoading: false
            });
        }
    }
    getDropDownDataAnalystCatch(error) {
        this.setState({
            dropDownData: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true
        });
    }
    checkFilterRoleString(roleIds) {
        if (!roleIds || (roleIds && roleIds.length === 0)) { return false; }
        var roles = roleIds.split(ANALYST_ROLE_DELIMITER);
        if (roles.length === 0) { return false; }
        for (var i = 0; i < roles.length; i++) {
            if (roles[i] === ANALYST_ROLE_FILTER) {
                return true;
            }
        }
        return false;
    }
    filterAndTransformAnalystData(analystData) {
        var _analystData = [];
        for (var i = 0; i < analystData.length; i++) {
            if (analystData[i].roleIds && !this.checkFilterRoleString(analystData[i].roleIds)) {
                var _analyst = {
                    value: analystData[i].personId,
                    label: analystData[i].firstName + ANALYST_NAME_DELIMITER + analystData[i].lastName
                };
                _analystData.push(_analyst);
            }
        }
        return _analystData;
    }
    handleAnalystsChange = (value) => {
        this.setState({ analysts: value }, this.validateIfNotPristine);
    }
    // handleRequestorChange = (event) => {
    //     this.setState({ requestor: event.target.value }, this.validateIfNotPristine);
    // }
    // handleApprovalChange = (event) => {
    //     this.setState({ approval: event.target.value }, this.validateIfNotPristine);
    // }
    handleMeetingTypeChange = (event) => {
        this.setState({ meetingType: event.target.value }, this.validateIfNotPristine);
    }
    handleLocationChange = (event) => {
        this.setState({ location: event.target.value }, this.validateIfNotPristine);
    }
    handleAccountNameChange = (event) => {
        this.setState({ accountName: event.target.value }, this.validateIfNotPristine);
    }
    handleNotesChange = (event) => {
        this.setState({ notes: event.target.value }, this.validateIfNotPristine);
    }
    handleOtherMeetingTypeChange = (event) => {
        this.setState({ otherMeetingType: event.target.value }, this.validateIfNotPristine);
    }
    handleFirstAndLastNameChange = (event) => {
        this.setState({ firstAndLastName: event.target.value }, this.validateIfNotPristine);
    }
    handlePhoneNumberChange = (event) => {
        this.setState({ phoneNumber: event.target.value }, this.validateIfNotPristine);
    }
    handlePreferredEmailChange = (event) => {
        this.setState({ preferredEmail: event.target.value }, this.validateIfNotPristine);
    }
    handleDateChange = date => {
        this.setState(
            {
                date: date,
                minEndDate: date
            }, this.validateIfNotPristine
        );
    };
    validateIfNotPristine = () => {
        if (!this.state.formPristine) {
            this.validateForm();
        }
    }
    validateForm = () => {
        var formValid = true;
        var formErrors = {};
        if (!this.props.analystFullName) {
            if (!this.state.analysts) {
                formValid = false;
                formErrors.analysts = true;
            } else {
                formErrors.analysts = false;
            }
        }
        // if (!this.state.requestor) {
        //     formValid = false;
        //     formErrors.requestor = true;
        // } else {
        //     formErrors.requestor = false;
        // }
        // if (!this.state.approval) {
        //     formValid = false;
        //     formErrors.approval = true;
        // }
        if (!this.state.date) {
            formValid = false;
            formErrors.date = true;
        } else {
            formErrors.date = false;
        }
        if (!this.state.meetingType) {
            formValid = false;
            formErrors.meetingType = true;
        } else {
            formErrors.meetingType = false;
        }
        if (this.state.meetingType === RM_FORM_MEETING_TYPE_OTHER && !this.state.otherMeetingType) {
            formValid = false;
            formErrors.otherMeetingType = true;
        } else {
            formErrors.otherMeetingType = false;
        }
        if (!this.state.location) {
            formValid = false;
            formErrors.location = true;
        } else {
            formErrors.location = false;
        }
        if (!this.state.accountName) {
            formValid = false;
            formErrors.accountName = true;
        } else {
            formErrors.accountName = false;
        }
        if (!this.state.firstAndLastName) {
            formValid = false;
            formErrors.firstAndLastName = true;
        } else {
            formErrors.firstAndLastName = false;
        }
        if (!this.state.phoneNumber) {
            formValid = false;
            formErrors.phoneNumber = true;
        } else {
            formErrors.phoneNumber = false;
        }
        if (!this.state.preferredEmail) {
            formValid = false;
            formErrors.preferredEmail = true;
        } else {
            formErrors.preferredEmail = false;
        }
        this.setState({
            formValid: formValid,
            formErrors: formErrors,
            formPristine: false
        });
        return formValid;

    }
    handleSubmit() {
        if (this.validateForm()) {
            var body = this.buildBody();
            this.setState({
                body: body
            }, this.clickMailTo);
        }

    }
    clickMailTo() {
        this.mailto.current.click();
        if (this.props.hideMeeting) {
            this.props.hideMeeting();
        }
    }
    buildBody() {
        var body = "";
        var bodyText = [];
        if (this.props.analystFullName) {
            bodyText.push(RM_FORM_TITLE_STRATEGIST_REQUESTED + RM_EMAIL_COLON_SPACE + this.props.analystFullName);
        } else if (this.state.analysts) {
            bodyText.push(RM_FORM_TITLE_STRATEGIST_REQUESTED + RM_EMAIL_COLON_SPACE + this.state.analysts.label);
        }
        // if (this.state.requestor) {
        //     bodyText.push(RM_FORM_TITLE_TD_REQUESTER + RM_EMAIL_COLON_SPACE + this.state.requestor);
        // }
        if (this.state.date) {
            bodyText.push(RM_FORM_TITLE_PREFERRED_MEETING_DATE + RM_EMAIL_COLON_SPACE + moment(this.state.date).format(TDS_DATE_FORMAT));
        }
        if (this.state.otherMeetingType) {
            bodyText.push(RM_FORM_TITLE_PREFERRED_MEETING_TYPE + RM_EMAIL_COLON_SPACE + this.state.otherMeetingType);
        } else if (this.state.meetingType) {
            bodyText.push(RM_FORM_TITLE_PREFERRED_MEETING_TYPE + RM_EMAIL_COLON_SPACE + this.state.meetingType);
        }
        if (this.state.location) {
            bodyText.push(RM_FORM_TITLE_LOCATION + RM_EMAIL_COLON_SPACE + this.state.location);
        }
        if (this.state.accountName) {
            bodyText.push(RM_FORM_TITLE_CLIENT_ACCOUNT_NAME + RM_EMAIL_COLON_SPACE + this.state.accountName);
        }
        if (this.state.firstAndLastName) {
            bodyText.push(RM_FORM_TITLE_FIRST_AND_LAST_NAME + RM_EMAIL_COLON_SPACE + this.state.firstAndLastName);
        }
        if (this.state.phoneNumber) {
            bodyText.push(RM_FORM_TITLE_PHONE_NUMBER + RM_EMAIL_COLON_SPACE + this.state.phoneNumber);
        }
        if (this.state.preferredEmail) {
            bodyText.push(RM_FORM_TITLE_PREFERRED_EMAIL + RM_EMAIL_COLON_SPACE + this.state.preferredEmail);
        }
        if (this.state.notes) {
            bodyText.push(RM_FORM_TITLE_NOTES + RM_EMAIL_COLON_SPACE + this.state.notes);
        }
        body = bodyText.join(RM_EMAIL_DELIMITER);

        return body;
    }
    handleCalenderIconClick = (event) => {
        this.calender.current.setOpen(true);
    }
    render() {
        return (
            <React.Fragment>
                <h2>Request a Meeting</h2>
                {
                    !this.state.dropDownLoading && this.state.errorLoadingDropDownData &&
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                }
                {
                    this.state.dropDownLoading &&
                    <Spinner minHeight={500} />
                }
                {
                    !this.state.dropDownLoading && !this.state.errorLoadingDropDownData &&
                    <div className="tds-request-meeting-container">
                        <div className="tds-request-meeting-description">
                            <p>
                                Fill in this form to request a meeting with a FICM Strategist. Your request will be emailed to the Strategy Team who will respond within 2 business days.
                            </p>
                        </div>
                        <div className="tds-request-meeting-form">
                            <div className="tds-request-meeting-item tds-request-meeting-item-strategist form-group">
                                {!this.props.analystFullName &&
                                    <label className="tds-request-meeting-item-label"
                                        htmlFor="strategist">{RM_FORM_TITLE_STRATEGIST_REQUESTED}<span className="tds-required">*</span></label>
                                }
                                {this.props.analystFullName &&
                                    <label className={`tds-request-meeting-item-label ${this.state.formErrors.analysts ? "is-invalid" : ""}`}
                                        htmlFor="strategist">{RM_FORM_TITLE_STRATEGIST_REQUESTED}: {this.props.analystFullName}</label>
                                }
                                {!this.props.analystFullName &&
                                    <Select
                                        options={this.state.dropDownData.analysts}
                                        isMulti={false}
                                        value={this.state.analysts}
                                        name="Analyst"
                                        onChange={this.handleAnalystsChange}
                                        styles={this.state.formErrors.analysts ? ReactSelectDesktopStylesError : ReactSelectDesktopStyles}
                                        placeholder='Select Strategist'
                                    />

                                }
                                {
                                    !this.props.analystFullName &&
                                    this.state.formErrors.analysts &&
                                    <FormErrorComponent />
                                }
                            </div>
                            {/* <div className="tds-request-meeting-item tds-request-meeting-item-requestor form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="requestor">{RM_FORM_TITLE_TD_REQUESTER}<span className="tds-error">*</span></label>
                                <input 
                                    className={`tds-request-meeting-item-input form-control ${this.state.formErrors.requestor ? "is-invalid" : ""}`}
                                    name="requestor"
                                    type="text"
                                    maxLength="50"
                                    value={this.state.requestor || ''}
                                    onChange={this.handleRequestorChange}
                                    placeholder={RM_FORM_TITLE_TD_REQUESTER} />
                                {
                                    this.state.formErrors.requestor &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-approval form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="approval">{RM_FORM_TITLE_DESK_HEAD}</label>
                                <input 
                                    className={`tds-request-meeting-item-input form-control ${this.state.formErrors.approval ? "is-invalid" : ""}`}
                                    name="approval"
                                    type="text"
                                    disabled={true}
                                    value={this.state.approval || ''}
                                    onChange={this.handleApprovalChange}
                                    placeholder={RM_FORM_TITLE_DESK_HEAD} />
                                {
                                    this.state.formErrors.approval &&
                                    <FormErrorComponent />
                                }
                            </div> */}
                            <div className="tds-request-meeting-item tds-request-meeting-item-date form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="date">{RM_FORM_TITLE_PREFERRED_MEETING_DATE}<span className="tds-required">*</span></label>
                                <DatePicker
                                    ref={this.calender}
                                    className={`tds-request-meeting-item-input form-control ${this.state.formErrors.date ? "is-invalid" : ""}`}
                                    selected={this.state.date}
                                    placeholderText='MM/DD/YYYY'
                                    onChange={this.handleDateChange}
                                    minDate={this.minDate}
                                />
                                <span
                                    className="td-icon td-icon-bookAppointment"
                                    onClick={this.handleCalenderIconClick}
                                ></span>
                                {
                                    this.state.formErrors.date &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-type form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="type">{RM_FORM_TITLE_PREFERRED_MEETING_TYPE}<span className="tds-required">*</span></label>
                                <div className="td-select">
                                    <select
                                        className={`tds-request-meeting-item-select form-control ${this.state.formErrors.meetingType ? "is-invalid" : ""}`}
                                        value={this.state.meetingType || ''}
                                        onChange={this.handleMeetingTypeChange}
                                    >
                                        <option>{RM_FORM_TITLE_PREFERRED_MEETING_TYPE_SELECT}</option>
                                        <option value={RM_FORM_MEETING_TYPE_FACE_TO_FACE}>{RM_FORM_MEETING_TYPE_FACE_TO_FACE}</option>
                                        <option value={RM_FORM_MEETING_TYPE_CONFERANCE_CALL}>{RM_FORM_MEETING_TYPE_CONFERANCE_CALL}</option>
                                        <option value={RM_FORM_MEETING_TYPE_CONFERENCE}>{RM_FORM_MEETING_TYPE_CONFERENCE}</option>
                                        <option value={RM_FORM_MEETING_TYPE_OTHER}>{RM_FORM_MEETING_TYPE_OTHER}</option>
                                    </select>
                                </div>
                                {
                                    this.state.formErrors.meetingType &&
                                    <FormErrorComponent />
                                }
                            </div>
                            {this.state.meetingType === RM_FORM_MEETING_TYPE_OTHER &&
                                <div className="tds-request-meeting-item form-group">
                                    <label className={`tds-request-meeting-item-label ${this.state.formErrors.otherMeetingType ? "is-invalid" : ""}`}
                                        htmlFor="other">{RM_FORM_TITLE_OTHER_MEETING_TYPE}<span className="tds-required">*</span></label>
                                    <input className="tds-request-meeting-item-input form-control"
                                        name="other"
                                        type="text"
                                        maxLength="50"
                                        value={this.state.otherMeetingType || ''}
                                        onChange={this.handleOtherMeetingTypeChange}
                                        placeholder="Enter other Meeting Type" />
                                    {
                                        this.state.formErrors.otherMeetingType &&
                                        <FormErrorComponent />
                                    }
                                </div>

                            }
                            <div className="tds-request-meeting-item tds-request-meeting-item-location form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="location">{RM_FORM_TITLE_LOCATION}<span className="tds-required">*</span></label>
                                <input className={`tds-request-meeting-item-input form-control ${this.state.formErrors.location ? "is-invalid" : ""}`}
                                    name="location"
                                    type="text"
                                    maxLength="50"
                                    value={this.state.location || ''}
                                    onChange={this.handleLocationChange}
                                    placeholder="Enter Location" />
                                {
                                    this.state.formErrors.location &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-account form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="account">{RM_FORM_TITLE_CLIENT_ACCOUNT_NAME}<span className="tds-required">*</span></label>
                                <input className={`tds-request-meeting-item-input form-control ${this.state.formErrors.accountName ? "is-invalid" : ""}`}
                                    name="account"
                                    type="text"
                                    maxLength="100"
                                    value={this.state.accountName || ''}
                                    onChange={this.handleAccountNameChange}
                                    placeholder="Enter Company Name" />
                                {
                                    this.state.formErrors.accountName &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-first-and-last-name form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="account">{RM_FORM_TITLE_FIRST_AND_LAST_NAME}<span className="tds-required">*</span></label>
                                <input className={`tds-request-meeting-item-input form-control ${this.state.formErrors.firstAndLastName ? "is-invalid" : ""}`}
                                    name="firstAndLastName"
                                    type="text"
                                    maxLength="100"
                                    value={this.state.firstAndLastName || ''}
                                    onChange={this.handleFirstAndLastNameChange}
                                    placeholder="Enter First and Last Name" />
                                {
                                    this.state.formErrors.accountName &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-phone-number form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="account">{RM_FORM_TITLE_PHONE_NUMBER}<span className="tds-required">*</span></label>
                                <input className={`tds-request-meeting-item-input form-control ${this.state.formErrors.phoneNumber ? "is-invalid" : ""}`}
                                    name="phoneNumber"
                                    type="text"
                                    maxLength="100"
                                    value={this.state.phoneNumber || ''}
                                    onChange={this.handlePhoneNumberChange}
                                    placeholder="Enter Phone Number" />
                                {
                                    this.state.formErrors.phoneNumber &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-preferred-email form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="account">{RM_FORM_TITLE_PREFERRED_EMAIL}<span className="tds-required">*</span></label>
                                <input className={`tds-request-meeting-item-input form-control ${this.state.formErrors.preferredEmail ? "is-invalid" : ""}`}
                                    name="preferredEmail"
                                    type="text"
                                    maxLength="100"
                                    value={this.state.preferredEmail || ''}
                                    onChange={this.handlePreferredEmailChange}
                                    placeholder="Enter Preferred Email" />
                                {
                                    this.state.formErrors.preferredEmail &&
                                    <FormErrorComponent />
                                }
                            </div>
                            <div className="tds-request-meeting-item tds-request-meeting-item-notes form-group">
                                <label className="tds-request-meeting-item-label"
                                    htmlFor="notes">{RM_FORM_TITLE_NOTES}</label>
                                <textarea name="firstName"
                                    rows="4"
                                    maxLength="500"
                                    className="tds-request-meeting-item-input form-control"
                                    value={this.state.notes || ''}
                                    onChange={this.handleNotesChange}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div className="tds-request-meeting-submit-button">
                            {
                                this.state.formValid === false &&
                                <span className="tds-error">Please complete all fields before submitting the request form</span>
                            }
                            <div className="tds-submit-button">
                                <button
                                    className="btn btn-block td-btn-primary-light"
                                    onClick={this.handleSubmit}
                                >Submit</button>
                                <MailtoComponent
                                    className="tds-request-meeting-mailto-link"
                                    ref={this.mailto}
                                    email={RM_FORM_DEFAULT_EMAIL}
                                    subject={this.state.subject}
                                    cc={this.state.approval}
                                    body={this.state.body}
                                    text={'Mail me'} />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
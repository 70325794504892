import UserService from './User.service';
import { isEmpty } from '../utils/valueUtils'

export default class SecureLinkService {

    static buildSecureLink(link) {
        if (isEmpty(link)) { 
            return null;  
        }
        let _link = link.toString();
        if (_link.toLowerCase().includes('?id=') || _link.toLowerCase().includes('&id=')) {
            return _link;
        }
        _link += "?id=" + UserService.getSecureLinkEmail();
        return _link;
    }
}

const buildSecureLink = SecureLinkService.buildSecureLink;
export {
    buildSecureLink
};
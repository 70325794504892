/* ACCESS CONTROL LISTS AKA Entitelments */

export const ACL_EQUITIES_OVERVIEW = { FICM: true, EQ: true };
export const ACL_EQUITIES_COVERAGE = { FICM: false, EQ: true };
export const ACL_EQUITIES_RESEARCH = { FICM: false, EQ: true };
export const ACL_EQUITIES_ACTION_LIST = { FICM: false, EQ: true };
export const ACL_EQUITIES_COMPANY_DETAILS = { FICM: false, EQ: true };
export const ACL_EQUITIES_INDEX_MARKET_STRUCTURES = { FICM: false, EQ: true };
export const ACL_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA = { FICM: false, EQ: true };


export const ACL_REGIONS_PAGE = { FICM: true, EQ: false };
export const ACL_REGIONS_US = { FICM: true, EQ: false };
export const ACL_REGIONS_CANADA = { FICM: true, EQ: false };
export const ACL_REGIONS_ASIA_PACIFIC = { FICM: true, EQ: false };
export const ACL_REGIONS_EUROPE = { FICM: true, EQ: false };
export const ACL_REGIONS_EMEA = { FICM: true, EQ: false };
export const ACL_REGIONS_ASIA = { FICM: true, EQ: false };
export const ACL_REGIONS_LATIN_AMERICA = { FICM: true, EQ: false };

export const ACL_PRODUCT_PAGE = { FICM: true, EQ: false };
export const ACL_PRODUCT_MACRO = { FICM: true, EQ: false };
export const ACL_PRODUCT_RATES = { FICM: true, EQ: false };
export const ACL_PRODUCT_EMERGING_MARKETS = { FICM: true, EQ: false };
export const ACL_PRODUCT_FX = { FICM: true, EQ: false };
export const ACL_PRODUCT_COMMODITIES = { FICM: true, EQ: false };

export const ACL_CREDIT_HIGH_YIELD = { FICM: false, EQ: false };
export const ACL_CREDIT_INVESTMENT_GRADE = { FICM: false, EQ: false };
export const ACL_ANALYTICS_AND_TRADES_FIXED_INCOME = { FICM: true, EQ: false };
export const ACL_DESK_RESEARCH_ASSET_SECURITIZATION = { FICM: true, EQ: false };
export const ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS = { FICM: false, EQ: true };

/* comented out acl is useless because it's handled at page level - so it's commented out */

// export const ACL_HOMEAGE_SPOTLIGHT_TIER1 = { FICM: true, EQ: true};
// export const ACL_HOMEAGE_SPOTLIGHT_TIER2 = { FICM: true, EQ: true};
// export const ACL_HOMEAGE_SPOTLIGHT_TIER3 = { FICM: true, EQ: true};
// export const ACL_HOMEAGE_SPOTLIGHT_TAILORED_FOR_YOU = { FICM: true, EQ: true};
// export const ACL_HOMEAGE_SPOTLIGHT_MOST_POPULAR = { FICM: true, EQ: true};
// export const ACL_HOMEAGE_SPOTLIGHT_MOST_RECENT = { FICM: true, EQ: true};
export const ACL_HOMEAGE_SPOTLIGHT_MOST_REAL_TIME_COVERAGE = { FICM: true, EQ: false};

// export const ACL_PRODUCT_PAGE_HIGHLIGHT_BLOCK1 = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_HIGHLIGHT_BLOCK2 = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_HIGHLIGHT_BLOCK3 = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_HIGHLIGHT_BLOCK4 = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_HIGHLIGHT_BLOCK5 = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_PUBLICATIONS = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_TRADE_IDEAS = { FICM: true, EQ: false};
// export const ACL_PRODUCT_PAGE_REAL_TIME_COVERAGE = { FICM: true, EQ: false};

// export const ACL_REGIONS_PAGE_BLOCK1 = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_BLOCK2 = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_BLOCK3 = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_FORECAST_SNAPSHOT = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_THEMATIC_RESEARCH = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_DATA_FLOW_CENTRAL_BANKS = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_REAL_TIME_COVERAGE = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_MOST_POPULAR = { FICM: true, EQ: false};
// export const ACL_REGIONS_PAGE_TRADE_IDEAS = { FICM: true, EQ: false};


export const ACL_ANALYST_PAGE = { FICM: true, EQ: true};
export const ACL_ANALYST_TEAMS_PAGE = { FICM: true, EQ: true};
export const ACL_ANALYST_ANALYST_CARDS = { FICM: true, EQ: true};
export const ACL_ANALYST_TEAMS_GLOBAL_STRATEGY_TAB = { FICM: true, EQ: false};
export const ACL_ANALYST_TEAMS_EQUITIES_TAB = { FICM: false, EQ: true};

// export const ACL_ANALYST_PROFILE_ANALYST_CONTACT_INFO = { FICM: true, EQ: true};
// export const ACL_ANALYST_PROFILE_ANALYST_BACKGROUND = { FICM: true, EQ: true};
export const ACL_ANALYST_PROFILE_BOOK_MEETING = { FICM: true, EQ: false};
// export const ACL_ANALYST_PROFILE_PUBLICATIONS_FILTER = { FICM: true, EQ: true};
// export const ACL_ANALYST_PROFILE_RESULTS_TABLE = { FICM: true, EQ: true};

export const ACL_SIDEBAR_BOOK_MEETING = { FICM: true, EQ: false};
export const ACL_TOP_NAV_BOOK_MEETING = { FICM: true, EQ: false};
// export const ACL_SIDEBAR_UPCOMING_EVENTS = { FICM: true, EQ: true};
// export const ACL_SIDEBAR_QUICK_LINKS = { FICM: true, EQ: true};
export const ACL_SIDEBAR_TRENDING_NOW = { FICM: true, EQ: false};
// export const ACL_SIDEBAR_RECENTLY_VIEWED = { FICM: true, EQ: true};

export const ACL_SIDEBAR_QUICK_LINKS_SALES_LITERATURE_LINK = { FICM: false, EQ: true};
export const ACL_SIDEBAR_QUICK_LINKS_DISSEMINATION_POLICY_LINK = { FICM: false, EQ: true};

export const ACL_SEARCH_SYMBOL = { FICM: false, EQ: true};
export const ACL_SEARCH_INDUSTRY = { FICM: false, EQ: true};

export const ACL_TRADE_ANALYTICS_GLOBAL_MARKET_SNAPSHOT = { FICM: false, EQ: false }; 

export const ACL_CALENDAR = { FICM: false, EQ: true }; 
export const ACL_LOGGED_OUT = { FICM: true, EQ: true }; 
import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchResultsComponent from './SearchResults.component';
import Select from 'react-select';
import Spinner from '../spinner/Spinner.component';
import SearchService from '../../services/Search.service';
import PaginationComponent from '../pagination/Paginator.component';
import queryString from 'query-string';
import { ERROR_TYPE, FIRM_NAME_RESEARCH, FIRM_NAME_STRATEGY, KEY_ENTER } from '../../constants/Misc.constants';
import ErrorComponent from '../misc/error/Error.component';
import UserService from '../../services/User.service';
import {
    ACL_SEARCH_SYMBOL,
    ACL_SEARCH_INDUSTRY
} from '../../constants/Entitlements.constants';
import {
    ROUTE_SEARCH_PAGE
} from '../../constants/RoutePaths.constants';
import {
    isTablet,
    isMobileOnly
} from "react-device-detect";
import {
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_DESKTOP,
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_TABLET,
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_MOBILE,
    SEARCH_RESULTS_PAGE_SIZE,
    SEARCH_RESULTS_DESC,
    SEARCH_RESULTS_ASC,
    SEARCH_RESULTS_ORDER_BY_DATE,
    SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE,
    SEARCH_RESULTS_ORDER_BY_ANALYST,
    SEARCH_RESULTS_ORDER_BY_TITLE,
    SEARCH_RESULTS_SORTBY
} from '../../constants/SearchResults.constants';
import {
    SEARCH_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import { ReactSelectDesktopStyles } from '../../constants/ReactSelect.constants';

const intialState = {
    currentPage: 1,
    isNoSearchYet: true,
    isFirstSearch: true,
    isParamSearch: false,
    keywords: null,
    symbol: null,
    showFilter: false,
    startDate: null,
    endDate: null,
    maxStartDate: new Date(),
    direction: SEARCH_RESULTS_DESC,
    minEndDate: null,
    analysts: null,
    products: null,
    documentTypes: null,
    regions: null,
    industry: null,
    searchResults: null,
    dropDownData: null,
    filteredDropDownData: null,
    dropDownLoading: true,
    searchLoading: false,
    errorLoadingDropDownData: false,
    errorLoadingSearchResultsData: false,
    orderBy: SEARCH_RESULTS_ORDER_BY_DATE,
    firmName: FIRM_NAME_STRATEGY
}

class SearchComponent extends React.Component {

    _isMounted = false;
    maxDate = new Date();

    constructor(props) {
        super(props);

        this.state = intialState;

        this.hasAccessSymbol = UserService.hasAccess(ACL_SEARCH_SYMBOL);
        this.hasAccessIndustry = UserService.hasAccess(ACL_SEARCH_INDUSTRY);
        this.hasAccessFicmEqToggle = UserService.isBoth();

        this.setNullState = this.setNullState.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateCalenderIconClick = this.handleStartDateCalenderIconClick.bind(this);
        this.handleEndDateCalenderIconClick = this.handleEndDateCalenderIconClick.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleDocumentTypesChange = this.handleDocumentTypesChange.bind(this);
        this.handleAnalystsChange = this.handleAnalystsChange.bind(this);
        this.handleRegionsChange = this.handleRegionsChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.goToPageOne = this.goToPageOne.bind(this);
        this.getDropDown = this.getDropDown.bind(this);
        this.getDropDownData = this.getDropDownData.bind(this);
        this.getDropDownDataThen = this.getDropDownDataThen.bind(this);
        this.getDropDownDataError = this.getDropDownDataError.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchData = this.handleSearchData.bind(this);
        this.handleSearchDataThen = this.handleSearchDataThen.bind(this);
        this.handleSearchDataError = this.handleSearchDataError.bind(this);
        this.handleFicmEqToggle = this.handleFicmEqToggle.bind(this);
        this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
        this.convertLabelValues = this.convertLabelValues.bind(this);

        if (UserService.isEQOnly()) {
            this.state.firmName = FIRM_NAME_RESEARCH;
        }

        this.changeSort = this.changeSort.bind(this);
        if (isMobileOnly) {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_MOBILE;
        } else if (isTablet) {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_TABLET;
        } else {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_DESKTOP;
        }
        if (this.props.hideSearchBar) {
            this.props.hideSearchBar();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = SEARCH_PAGE_TITLE;
        let urlQueryParams = queryString.parse(this.props.location.search);
        if ((urlQueryParams.keywords || urlQueryParams.products || urlQueryParams.documentTypes || urlQueryParams.industries || urlQueryParams.analysts)
            && this.state.isFirstSearch) {
            var state = {
                showFilter: true,
                dropDownData: {},
                triggerSearchAfterDropdown: true,
                isParamSearch: true
            }
            if (urlQueryParams.keywords) {
                state.keywords = urlQueryParams.keywords;
            }
            if (urlQueryParams.analysts) {
                state.analysts = urlQueryParams.analysts;
            }
            if (urlQueryParams.products) {
                state.products = [urlQueryParams.products];
            }
            if (urlQueryParams.documentTypes) {
                state.documentTypes = urlQueryParams.documentTypes;
            }
            if (urlQueryParams.documentTypesMatchExact) {
                state.documentTypesMatchExact = urlQueryParams.documentTypesMatchExact;
            }
            if (urlQueryParams.regions) {
                state.regions = urlQueryParams.regions;
            }
            if (urlQueryParams.industries) {
                state.industry = urlQueryParams.industries;
            }
            if (urlQueryParams.firmName && urlQueryParams.firmName !== this.state.firmName) {
                state.firmName = urlQueryParams.firmName;
            }
            this.setState(state, this.getDropDown);
        } else {
            this.setState({
                triggerSearchAfterDropdown: false
            }, this.getDropDown);

        }

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    setNullState = () => {
        this.setState(intialState);
    }
    getDropDown() {
        this.setState({
            dropDownLoading: false,
            errorLoadingDropDownData: false
        }, this.getDropDownData);
    }
    getDropDownData() {
        SearchService.getDropDownData().then(this.getDropDownDataThen).catch(this.getDropDownDataError);
    }
    // filterDropdownValuesByAccess(dropDownData) {
    //     var _dropDownData = dropDownData;
    //     var _documentTypes = dropDownData.documentTypes;
    //     var _analysts = dropDownData.analysts;
    //     if (UserService.isFicm() && !UserService.isEQ()) {
    //         _documentTypes = _documentTypes.filter(documentType => documentType.firmName === FIRM_NAME_STRATEGY);
    //         _analysts = _analysts.filter(analysts => analysts.firmName === FIRM_NAME_STRATEGY);
    //     } else if (!UserService.isFicm() && UserService.isEQ()) {
    //         _documentTypes = _documentTypes.filter(documentType => documentType.firmName === FIRM_NAME_RESEARCH);
    //         _analysts = _analysts.filter(analysts => analysts.firmName === FIRM_NAME_RESEARCH);
    //     }
    //     _dropDownData.documentTypes = _documentTypes;
    //     _dropDownData.analysts = _analysts;
    //     return _dropDownData;
    // }
    getDropDownDataThen(dropDownData) {
        var _filteredDropDownData = SearchService.filterDropDownDataByFirmName(dropDownData, this.state.firmName);
        this.state.dropDownData = dropDownData; // eslint-disable-line
        this.state.filteredDropDownData = _filteredDropDownData; // eslint-disable-line
        if (this._isMounted) {
            if (this.state.triggerSearchAfterDropdown) {
                this.setState({
                    dropDownData: dropDownData,
                    filteredDropDownData: _filteredDropDownData,
                    dropDownLoading: false,
                    triggerSearchAfterDropdown: false
                }, this.goToPage(1));
            } else {
                this.setState({
                    dropDownData: dropDownData,
                    filteredDropDownData: _filteredDropDownData,
                    dropDownLoading: false
                });
            }
        }
    }
    getDropDownDataError(error) {
        this.setState({
            dropDownData: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true
        });
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.handleSearch
        );
    }
    goToPageOne() {
        this.goToPage(1);
    }
    handleFilterButtonClick = (event) => {
        this.setState({
            showFilter: !this.state.showFilter,
            startDate: null,
            endDate: null,
            analysts: null,
            products: null,
            documentTypes: null,
            regions: null,
            industry: null
        });
    }
    changeSort(sort) {
        var _direction = SEARCH_RESULTS_DESC;
        if (sort === SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE ||
            sort === SEARCH_RESULTS_ORDER_BY_TITLE ||
            sort === SEARCH_RESULTS_ORDER_BY_ANALYST) {
            _direction = SEARCH_RESULTS_ASC;
        }
        this.setState(
            {
                orderBy: sort,
                direction: _direction

            },
            this.goToPage(1)
        );
    }
    updateUrl() {
        var url = ROUTE_SEARCH_PAGE;
        var params = {};
        var paramsString = "";
        if (this.state.keywords) {
            params.keywords = this.state.keywords;
        }
        var paramsKeys = Object.keys(params);
        for (var i = 0; i < paramsKeys.length; i++) {
            paramsString += paramsKeys[i] + "=" + params[paramsKeys[i]];
        }
        url = url + "?" + paramsString;
        this.props.history.push(url);
    }
    handleKeywordsChange = (event) => {
        this.setState(
            { keywords: event.target.value },
            this.updateUrl
        );
    }
    handleSymbolChange = value => {
        this.setState({ symbol: value });
    }
    handleStartDateChange = date => {
        this.setState(
            {
                startDate: date,
                minEndDate: date
            }
        );
    };
    handleEndDateChange = date => {
        this.setState(
            {
                endDate: date,
                maxStartDate: date
            }
        );
    };
    handleStartDateCalenderIconClick = (event) => {
        this.startDateCalendar.setOpen(true);
    }
    handleEndDateCalenderIconClick = (event) => {
        this.endDateCalendar.setOpen(true);
    }
    handleProductChange = value => {
        this.setState({ products: value });
    }
    handleRegionChange = value => {
        this.setState({ regions: value });
    }
    handleIndustryChange = value => {
        this.setState({ industry: value });
    }
    handleDocumentTypesChange = value => {
        this.setState({ documentTypes: value });
    }
    handleAnalystsChange = value => {
        this.setState({ analysts: value });
    }
    handleRegionsChange = value => {
        this.setState({ regions: value });
    }
    handleCancel = () => {
        this.maxDate = new Date();
        this.setState({
            keywords: null,
            symbol: null,
            startDate: null,
            endDate: null,
            analysts: null,
            products: null,
            documentTypes: null,
            minEndDate: null,
            maxStartDate: new Date(),
            regions: null,
            industry: null,
            results: null,
            sort: SEARCH_RESULTS_SORTBY
        });
    }
    handleSearch() {
        var params = {
            value: this.state.keywords,
            pubFrom: this.state.startDate,
            pubTo: this.state.endDate,
            page: this.state.currentPage,
            size: SEARCH_RESULTS_PAGE_SIZE,
            direction: this.state.direction,
            contentSearchTypeOrder: this.state.orderBy,
            firm: this.state.firmName
        };
        if (UserService.isFicm() && !UserService.isEQ()) {
            params.firm = FIRM_NAME_STRATEGY;
        } else if (UserService.isEQ() && !UserService.isFicm()) {
            params.firm = FIRM_NAME_RESEARCH;
        }
        if (this.state.isParamSearch) {
            if (this.state.symbols) {
                // no parameter handling yet
            } else {
                params.symbols = null;
                this.setState({ symbol: null });
            }
            if (this.state.analysts) {
                var _analystsFirstSearch = this.convertLabelValues(this.state.analysts, this.state.dropDownData.analysts, false, true);
                params.analysts = _analystsFirstSearch.params;
                this.setState({
                    analysts: _analystsFirstSearch.state
                });
            } else {
                params.analysts = null;
                this.setState({ analysts: null });
            }
            if (this.state.products) {
                var _productFirstSearch = this.convertLabelValues(this.state.products, this.state.dropDownData.products);
                params.products = _productFirstSearch.params;
                this.setState({
                    products: _productFirstSearch.state
                });
            } else {
                params.products = null;
                this.setState({ products: null });
            }
            if (this.state.documentTypes) {
                var _documentTypeState = this.state.documentTypes;
                if (_documentTypeState === 'Action Notes') {
                    _documentTypeState = 'Action Note';
                }
                if (_documentTypeState === 'Research Reports/ Industry Notes') {
                    _documentTypeState = 'Research Report,Industry Note';
                }
                var _documentTypesFirstSearch = {};
                if (this.state.documentTypesMatchExact) {
                    _documentTypesFirstSearch = this.convertLabelValues(_documentTypeState, this.state.dropDownData.documentTypes, false, true);
                } else {
                    _documentTypesFirstSearch = this.convertLabelValues(_documentTypeState, this.state.dropDownData.documentTypes, true, true);
                }
                params.documentTypes = _documentTypesFirstSearch.params;
                this.setState({
                    documentTypes: _documentTypesFirstSearch.state
                });
            } else {
                params.documentTypes = null;
                this.setState({ documentTypes: null });
            }
            if (this.state.regions) {
                var _regionsFirstSearch = this.convertLabelValues(this.state.regions, this.state.dropDownData.regions);
                params.regions = _regionsFirstSearch.params;
                this.setState({
                    regions: _regionsFirstSearch.state
                });
            } else {
                params.regions = null;
                this.setState({ regions: null });
            }
            if (this.state.industry) {
                var _industryFirstSearch = this.convertLabelValuesByNumber(this.state.industry, this.state.dropDownData.industries);
                params.industries = _industryFirstSearch.params;
                this.setState({
                    industry: _industryFirstSearch.state
                });
            } else {
                params.industries = null;
                this.setState({ industry: null });
            }
            if (this.state.products || this.state.documentTypes || this.state.regions || this.state.industry || this.state.analysts) {
                this.setState({
                    firstSearch: false,
                    isParamSearch: false,
                    isNoSearchYet: false
                });
            }
            if (this.state.keywords) {
                this.setState({
                    firstSearch: false,
                    isParamSearch: false,
                    isNoSearchYet: false
                });
            }
        } else {
            params.symbols = this.convertValues(this.state.symbol);
            params.analysts = this.convertValues(this.state.analysts, true);
            params.products = this.convertValues(this.state.products);
            params.documentTypes = this.convertValues(this.state.documentTypes, true);
            params.regions = this.convertValues(this.state.regions);
            params.industries = this.convertValues(this.state.industry);
        }
        // Apply Entitlements
        if (!this.hasAccessSymbol) {
            params.symbols = null;
        }
        if (!this.hasAccessIndustry) {
            params.industries = null;
        }
        this.setState({
            searchLoading: true,
            searchResults: null,
            firstSearch: false,
            isNoSearchYet: false
        }, this.handleSearchData(params));
    }
    handleSearchData(params) {
        SearchService.getSearchResults(params).then(this.handleSearchDataThen).catch(this.handleSearchDataError);
    }
    handleSearchDataThen(searchResults) {
        this.setState({
            searchResults: searchResults,
            searchLoading: false
        });
    }
    handleSearchDataError(error) {
        this.setState({
            searchResults: null,
            searchLoading: false,
            errorLoadingSearchData: true
        });
    }
    handleKeyPress(event) {
        if (event.key === KEY_ENTER) {
            this.goToPage(1);
        }
    }
    convertValues = (dropDownValues, isDocumentTypeOrAnalyst) => {
        if (!dropDownValues || dropDownValues.length === 0) {
            return null;
        }
        var dropDownValuesArray = [];
        for (var i = 0; i < dropDownValues.length; i++) {
            if (isDocumentTypeOrAnalyst) {
                dropDownValuesArray.push({
                    code: dropDownValues[i].value,
                    firmName: dropDownValues[i].firmName,
                });
            } else {
                dropDownValuesArray.push(dropDownValues[i].value);
            }
        }
        return dropDownValuesArray;
    }
    convertLabelValues = (dropDownLabelValues, dropDownValues, contains, firmNameRequired) => {
        if (!dropDownLabelValues || dropDownLabelValues.length === 0) {
            return null;
        }
        var _dropDownLabelValues = dropDownLabelValues + "";
        var dropDownLabelValuesArray = _dropDownLabelValues.split(",");
        var dropDownValuesArray = [];
        var stateValues = [];
        for (var i = 0; i < dropDownValues.length; i++) {
            for (var j = 0; j < dropDownLabelValuesArray.length; j++) {
                if (!contains) {
                    if (dropDownLabelValuesArray[j] === dropDownValues[i].label) {
                        if (firmNameRequired) {
                            dropDownValuesArray.push({ code: dropDownValues[i].value, firmName: dropDownValues[i].firmName });
                        } else {
                            dropDownValuesArray.push(dropDownValues[i].value);
                        }
                        stateValues.push(dropDownValues[i]);
                    }
                } else {
                    if (dropDownValues[i].label.match(dropDownLabelValuesArray[j])) {
                        if (firmNameRequired) {
                            dropDownValuesArray.push({ code: dropDownValues[i].value, firmName: dropDownValues[i].firmName });
                        } else {
                            dropDownValuesArray.push(dropDownValues[i].value);
                        }
                        stateValues.push(dropDownValues[i]);
                    }
                }
            }
        }
        return {
            params: dropDownValuesArray,
            state: stateValues
        };
    }
    convertLabelValuesByNumber = (dropDownLabelValues, dropDownValues) => {
        if (!dropDownLabelValues || dropDownLabelValues.length === 0) {
            return null;
        }
        var _dropDownLabelValues = dropDownLabelValues + "";
        var dropDownLabelValuesArray = _dropDownLabelValues.split(",");
        var dropDownValuesArray = [];
        var stateValues = [];
        for (var i = 0; i < dropDownValues.length; i++) {
            for (var j = 0; j < dropDownLabelValuesArray.length; j++) {
                if (dropDownLabelValuesArray[j] == dropDownValues[i].value) { // eslint-disable-line
                    dropDownValuesArray.push(dropDownValues[i].value);
                    stateValues.push(dropDownValues[i]);
                }
            }
        }
        return {
            params: dropDownValuesArray,
            state: stateValues
        };
    }
    handleFicmEqToggle(e) {
        if (e.target.value === FIRM_NAME_STRATEGY) {
            this.setState({
                firmName: e.target.value,
                filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_STRATEGY),
                symbol: null,
                industry: null,
                analysts: null,
                documentTypes: null
            });
        } else if (e.target.value === FIRM_NAME_RESEARCH) {
            this.setState({
                firmName: e.target.value,
                filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_RESEARCH),
                regions: null,
                analysts: null,
                documentTypes: null
            })
        }
    }
    render() {
        return (
            <section className="tds-section-search">
                <div className="tds-container">
                    <h1>Search</h1>
                    {
                        this.state.dropDownLoading &&
                        <Spinner minHeight={500} />
                    }
                    {
                        !this.state.dropDownLoading &&
                        <React.Fragment>
                            {
                                this.hasAccessFicmEqToggle && !this.state.dropDownLoading &&
                                <div className="tds-search-row firm-name-filter">
                                    <fieldset>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="radiogroup1"
                                                   id="radio1"
                                                   onChange={this.handleFicmEqToggle}
                                                   checked={this.state.firmName === FIRM_NAME_STRATEGY}
                                                   value={FIRM_NAME_STRATEGY} />
                                            <label className="form-check-label"
                                                   htmlFor="radio1">Strategy</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="radiogroup1"
                                                   id="radio2"
                                                   onChange={this.handleFicmEqToggle}
                                                   checked={this.state.firmName === FIRM_NAME_RESEARCH}
                                                   value={FIRM_NAME_RESEARCH} />
                                            <label className="form-check-label"
                                                   htmlFor="radio2">Research</label>
                                        </div>
                                    </fieldset>
                                </div>
                            }
                            <div className="tds-search-row first-row">
                                <div className="tds-search-item search-item-keywords form-group">
                                    <input
                                        className="tds-search-item-input form-control"
                                        name="keywords"
                                        type="text"
                                        value={this.state.keywords || ''}
                                        placeholder={this.keywordsPlaceholder}
                                        onChange={this.handleKeywordsChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <span className="td-icon td-icon-search icon-small"></span>
                                </div>
                                <div className="tds-search-item search-item-symbol-look-up form-group">
                                    {
                                        this.hasAccessSymbol && this.state.firmName === FIRM_NAME_RESEARCH &&
                                        <React.Fragment>
                                            {
                                                !this.state.dropDownLoading && !this.state.errorLoadingDropDownData && this.state.dropDownData &&
                                                <Select
                                                    key="symbol"
                                                    options={this.state.dropDownData.symbols}
                                                    isMulti={true}
                                                    value={this.state.symbol}
                                                    name="symbol"
                                                    onChange={this.handleSymbolChange}
                                                    styles={ReactSelectDesktopStyles}
                                                    placeholder='Symbol(s)'
                                                />
                                            }
                                            {
                                                this.state.errorLoadingDropDownData &&
                                                <Select styles={ReactSelectDesktopStyles} placeholder="Symbol(s)" isDisabled={true} />
                                            }
                                        </React.Fragment>
                                    }
                                    {(!this.hasAccessSymbol || this.state.firmName === FIRM_NAME_STRATEGY) &&
                                        <Select styles={ReactSelectDesktopStyles} placeholder="Symbol(s)" isDisabled={true} />
                                    }
                                </div>
                                <div className="tds-search-item search-item-filter-btn-ctn form-group">
                                    <button
                                        className="btn btn-block td-btn-secondary-clear"
                                        onClick={this.handleFilterButtonClick}
                                        title={this.state.showFilter ? "Hide Filters" : "Show Filters"}
                                    >
                                        <img className="tds-filter-icon"
                                             src="/assets/img/filter.svg"
                                             alt="filter" />
                                        <span className="tds-filter-text">Filter</span>
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.showFilter &&
                                <div className="tds-search-row">
                                    <div className="tds-search-item search-item-date-range form-group">
                                        <div className="tds-search-date-from-ctn">
                                            <label className="tds-search-item-label" htmlFor="date-range">Date Range</label>
                                            <DatePicker
                                                ref={(c) => this.startDateCalendar = c}
                                                className="tds-search-item-input form-control text-right"
                                                selected={this.state.startDate}
                                                placeholderText='MM/DD/YYYY'
                                                onChange={this.handleStartDateChange}
                                                maxDate={this.state.maxStartDate}
                                            />
                                            <span className="td-icon td-icon-bookAppointment"
                                                  onClick={this.handleStartDateCalenderIconClick}></span>
                                        </div>
                                        <div className="tds-search-item-date-range-divider">-</div>
                                        <div className="tds-search-date-to-ctn">
                                            <label className="tds-search-item-label" htmlFor="date-range-from">&nbsp;</label>
                                            <DatePicker
                                                ref={(c) => this.endDateCalendar = c}
                                                className="tds-search-item-input form-control text-right"
                                                selected={this.state.endDate}
                                                placeholderText='MM/DD/YYYY'
                                                onChange={this.handleEndDateChange}
                                                maxDate={this.maxDate}
                                                minDate={this.state.minEndDate}
                                            />
                                            <span className="td-icon td-icon-bookAppointment"
                                                  onClick={this.handleEndDateCalenderIconClick}></span>
                                        </div>
                                    </div>
                                    <div className="tds-search-item search-item-analyst form-group">
                                        <label className="tds-search-item-label" htmlFor="analyst">Analyst</label>
                                        {
                                            !this.state.errorLoadingDropDownData && this.state.filteredDropDownData && this.state.filteredDropDownData.analysts &&
                                            <Select
                                                key="SearchAnalyst"
                                                options={this.state.filteredDropDownData.analysts}
                                                isMulti={true}
                                                value={this.state.analysts}
                                                name="SearchAnalyst"
                                                onChange={this.handleAnalystsChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Analyst(s)'
                                            />
                                        }
                                        {
                                            this.state.errorLoadingDropDownData &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Analyst(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-products form-group">
                                        <label className="tds-search-item-label" htmlFor="products">Products</label>
                                        {
                                            !this.state.errorLoadingDropDownData && this.state.firmName === FIRM_NAME_STRATEGY &&
                                            <Select
                                                key="SearchProducts"
                                                options={this.state.dropDownData.products}
                                                isMulti={true}
                                                value={this.state.products}
                                                name="SearchProducts"
                                                onChange={this.handleProductChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Product(s)'
                                            />
                                        }
                                        {
                                            this.state.errorLoadingDropDownData &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Product(s)" isDisabled={true} />
                                        }
                                        {(this.state.firmName === FIRM_NAME_RESEARCH) &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Product(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-industries form-group">
                                        <label className="tds-search-item-label" htmlFor="industries">Industries</label>
                                        {
                                            this.hasAccessSymbol && this.state.firmName === FIRM_NAME_RESEARCH &&
                                            <React.Fragment>
                                                {
                                                    !this.state.errorLoadingDropDownData &&
                                                    <Select
                                                        key="SearchIndustry"
                                                        options={this.state.dropDownData.industries}
                                                        isMulti={true}
                                                        value={this.state.industry}
                                                        name="SearchIndustry"
                                                        onChange={this.handleIndustryChange}
                                                        styles={ReactSelectDesktopStyles}
                                                        placeholder='Select Industries'
                                                    />
                                                }
                                                {
                                                    this.state.errorLoadingDropDownData &&
                                                    <Select styles={ReactSelectDesktopStyles} placeholder="Select Industries" isDisabled={true} />
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            (!this.hasAccessSymbol || this.state.firmName === FIRM_NAME_STRATEGY) &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Industries" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-regions form-group">
                                        <label className="tds-search-item-label" htmlFor="regions">Regions</label>
                                        {
                                            this.state.firmName === FIRM_NAME_STRATEGY &&
                                            <React.Fragment>
                                                {
                                                    !this.state.errorLoadingDropDownData &&
                                                    <Select
                                                        key="SearchRegion"
                                                        options={this.state.dropDownData.regions}
                                                        isMulti={true}
                                                        value={this.state.regions}
                                                        name="SearchRegion"
                                                        onChange={this.handleRegionsChange}
                                                        styles={ReactSelectDesktopStyles}
                                                        placeholder='Select Region(s)'
                                                    />
                                                }
                                                {
                                                    this.state.errorLoadingDropDownData &&
                                                    <Select styles={ReactSelectDesktopStyles} placeholder="Select Region(s)" isDisabled={true} />
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.firmName === FIRM_NAME_RESEARCH &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Region(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-document-type form-group">
                                        <label className="tds-search-item-label" htmlFor="document-type">Document Type</label>
                                        {
                                            !this.state.errorLoadingDropDownData && this.state.filteredDropDownData && this.state.filteredDropDownData.documentTypes &&
                                            <Select
                                                options={this.state.filteredDropDownData.documentTypes}
                                                isMulti={true}
                                                value={this.state.documentTypes}
                                                name="SearchDocumentType"
                                                onChange={this.handleDocumentTypesChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Document Type(s)'
                                            />
                                        }
                                        {
                                            this.state.errorLoadingDropDownData &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Document Type(s)" isDisabled={true} />
                                        }
                                    </div>
                                </div>
                            }
                            <div className="tds-search-row search-buttons">
                                <div className="tds-search-button">
                                    <button
                                        className="btn btn-block td-btn-primary-light"
                                        title="Search"
                                        onClick={this.goToPageOne}>Search</button>
                                </div>
                                <div className="tds-clear-button">
                                    <button className="btn btn-block td-btn-secondary-clear"
                                            title="Clear"
                                            onClick={this.handleCancel}>Clear</button>
                                </div>
                            </div>
                            {
                                this.state.searchLoading &&
                                <Spinner minHeight={500} />
                            }
                            {
                                !this.state.searchLoading &&
                                this.state.searchResults &&
                                this.state.searchResults.results &&
                                this.state.searchResults.results.length > 0 &&
                                <React.Fragment>
                                    <SearchResultsComponent
                                        results={this.state.searchResults.results}
                                        changeSort={this.changeSort}
                                        orderBy={this.state.orderBy}
                                    />
                                    <PaginationComponent
                                        currentPage={this.state.currentPage}
                                        resultsPerPage={SEARCH_RESULTS_PAGE_SIZE}
                                        total={this.state.searchResults.total}
                                        goToPage={this.goToPage}
                                    />
                                </React.Fragment>
                            }
                            {
                                !this.state.isNoSearchYet && !this.state.searchLoading && !this.state.errorLoadingSearchData &&
                                (!this.state.searchResults || !this.state.searchResults.results ||
                                    (this.state.searchResults.results &&
                                        this.state.searchResults.results.length === 0
                                    )
                                ) &&
                                <div className="tds-search-no-results">
                                    <ErrorComponent type={ERROR_TYPE.SEARCH_RESULTS} />
                                </div>
                            }
                            {
                                this.state.isFirstSearch && !this.state.errorLoadingSearchData &&
                                <div className="tds-search-no-results"></div>
                            }
                            {
                                !this.state.searchLoading && this.state.errorLoadingSearchData &&
                                <div className="tds-search-no-results error">
                                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                                </div>
                            }

                        </React.Fragment>
                    }
                </div>
            </section >
        )
    }
}

export { SearchComponent as UndecoratedSearchComponent }; //for testing purposes
export default withRouter(SearchComponent);
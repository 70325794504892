import React from "react";
import { Link } from "react-router-dom";
import {
  LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
import {
  FIRM_NAME_COWEN,
  FIRM_NAME_RESEARCH,
  FIRM_NAME_STRATEGY,
  FORWARD_SLASH,
  TDS_DATE_FORMAT
} from '../../../constants/Misc.constants';
import {
  SPOTLIGHT_CHAR_LIMIT
} from '../../../constants/HomePage.constants';
import ElipsisTextComponent from "../../misc/elipsis-text/ElipsisText.component";
import FormatedDateComponent from "../../misc/formated-date/FormatedDate.component";
import SecureLinkService from "../../../services/SecureLink.service";

export default class SpotlightComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="top-spotlight-container-column">
          <div className="tds-spotlight-container">
            <h2>Spotlight</h2>
            <ul>
              {this.props.spotlightData &&
                this.props.spotlightData.map((spotlightItem, index) => {
                  spotlightItem.pdfLink = SecureLinkService.buildSecureLink(spotlightItem.pdfLink);
                  return (
                    <li key={"spot-light-item-" + index}>
                      { spotlightItem.firmName === FIRM_NAME_STRATEGY &&
                        <Link
                          to={LINK_PUBLICATION_PAGE + spotlightItem.firmName + FORWARD_SLASH + spotlightItem.contentId}
                          title={spotlightItem.title}
                        >
                          <h3>
                            <ElipsisTextComponent length={SPOTLIGHT_CHAR_LIMIT} text={spotlightItem.title} />
                          </h3>
                        </Link>
                      }
                      {(spotlightItem.firmName === FIRM_NAME_RESEARCH || spotlightItem.firmName === FIRM_NAME_COWEN) &&
                        <a
                          href={spotlightItem.pdfLink || spotlightItem.htmlLink || spotlightItem.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h3>
                            <ElipsisTextComponent length={SPOTLIGHT_CHAR_LIMIT} text={spotlightItem.title} />
                          </h3>
                        </a>
                      }
                      <div className="tds-spotlight-container-equities-by-line-date">
                        <span className="tds-spotlight-container-equities-by-line">
                          {
                            spotlightItem.primaryAnalyst &&
                            <React.Fragment>
                              {spotlightItem.primaryAnalyst.firstName} {spotlightItem.primaryAnalyst.lastName}
                            </React.Fragment>
                          }
                        </span>
                        <span className="tds-spotlight-container-equities-date">
                          {
                            spotlightItem.date &&
                            <FormatedDateComponent
                              date={spotlightItem.date}
                              format={TDS_DATE_FORMAT}
                            />
                          }
                        </span>
                      </div>
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import AlphabetFilterComponent from '../misc/alphabet-filter/AlphabetFilter.component';
import PaginationComponent from '../pagination/Paginator.component';
import Spinner from '../spinner/Spinner.component';
import CoveragePageService from '../../services/CoveragePage.service';
import FormatedNumberComponent from '../misc/formated-number/FormatedNumber.component';
import SearchService from '../../services/Search.service';
import SearchServiceV2 from '../../services/v2/Search.service';
import UtilsService from '../../services/Utils.service';
import {
    isMobileOnly
} from "react-device-detect";
import {
    COVERAGE_ADVANCED_FILTER_PAGE_SIZE,
    OPERATOR_GREATER_THAN,
    OPERATOR_LESS_THAN,
    OPERATOR_GREATER_THAN_EQUALS,
    OPERATOR_LESS_THAN_EQUALS,
    OPERATOR_EQUALS,
    RISK_RATING_VALUE_LOW,
    RISK_RATING_VALUE_MEDIUM,
    RISK_RATING_VALUE_HIGH,
    RISK_RATING_VALUE_SPECULATIVE,
    ADVANCED_FILTER_ORDER_BY_COMPANY_NAME,
    ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL,
    ADVANCED_FILTER_ORDER_BY_REC,
    ADVANCED_FILTER_ORDER_BY_RISK_RATING,
    ADVANCED_FILTER_ORDER_BY_CURRENCY,
    ADVANCED_FILTER_ORDER_BY_PRICE_TARGET,
    ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE,
    ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD,
    ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN,
    ADVANCED_FILTER_ORDER_BY_AVG_VOLUME,
    ADVANCED_FILTER_ORDER_BY_MKT_CAP,
    ADVANCED_FILTER_ORDER_BY_ANALYST,
    ADVANCED_FILTER_SORT_TYPE_ASC,
    ADVANCED_FILTER_SORT_TYPE_DESC,
    REC_VALUE_TENDER_BUY,
    REC_VALUE_TENDER_HOLD,
    REC_VALUE_TENDER_SELL, REC_VALUE_TENDER_SUSPENDED
} from '../../constants/CoveragePage.constants';
import {
    CSS_DISPLAY_BLOCK,
    CSS_DISPLAY_NONE,
    FORWARD_SLASH,
    FIRM_NAME_RESEARCH,
    KEY_ENTER,
    KEY_BACKSPACE,
    CURRENCIES,
    ERROR_TYPE,
    TEAM,
    FIRM_NAME_COWEN
} from '../../constants/Misc.constants';
import {
    LINK_EQUITIES_COMPANY_DETAILS_PAGE,
    LINK_PATH_ANALYST_PROFILE_PAGE
} from '../../constants/LinkPaths.constants';
import { ReactSelectDesktopStyles } from '../../constants/ReactSelect.constants';
import ErrorComponent from '../misc/error/Error.component';
import { isCowen, isCowenEnabled } from "../../utils/valueUtils";
import CowenFlagContext from "../../system/CowenFlagContext";

export default class AdvancedFilter extends React.Component {

    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);

        this.state = {
            analyst: null,
            industry: null,
            recommendationOperator: OPERATOR_EQUALS,
            recommendationValue: '',
            totalReturnOperator: OPERATOR_EQUALS,
            totalReturnValue: '',
            avgVolOperator: OPERATOR_EQUALS,
            avgVolValue: '',
            yieldOperator: OPERATOR_EQUALS,
            yieldValue: '',
            riskRatingOperator: OPERATOR_EQUALS,
            riskRatingValue: '',
            mktCapOperator: OPERATOR_EQUALS,
            mktCapValue: '',
            sortBy: null,
            sortType: null,
            dropDownData: null,
            dropDownLoading: true,
            errorLoadingDropDownData: false,
            currentPage: 1,
            loading: true,
            error: false,
            data: null,
            filter: '',
            optionalMetricsMobileExpand: !isMobileOnly,
            cowenEnabled: false,
            dropDownDataAnalysts: null,
            dropDownDataIndustries: null,
        };

        this.handleAlphabetFilterClick = this.handleAlphabetFilterClick.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.handleOptionalMetricsMobileExpandClick = this.handleOptionalMetricsMobileExpandClick.bind(this);
        this.getDropDownData = this.getDropDownData.bind(this);
        this.getDropDownDataThen = this.getDropDownDataThen.bind(this);
        this.getDropDownDataError = this.getDropDownDataError.bind(this);
        this.handleRecommendationsOperatorChange = this.handleRecommendationsOperatorChange.bind(this);
        this.handleRecommendationsValueChange = this.handleRecommendationsValueChange.bind(this);
        this.handleYieldOperatorChange = this.handleYieldOperatorChange.bind(this);
        this.handleYieldValueChange = this.handleYieldValueChange.bind(this);
        this.handleYieldKeyDown = this.handleYieldKeyDown.bind(this);
        this.handleTotalReturnOperatorChange = this.handleTotalReturnOperatorChange.bind(this);
        this.handleTotalReturnValueChange = this.handleTotalReturnValueChange.bind(this);
        this.handleTotalReturnKeyDown = this.handleTotalReturnKeyDown.bind(this);
        this.handleAvgVolOperatorChange = this.handleAvgVolOperatorChange.bind(this);
        this.handleAvgVolValueChange = this.handleAvgVolValueChange.bind(this);
        this.handleAvgVolKeyDown = this.handleAvgVolKeyDown.bind(this);
        this.handleMktCapOperatorChange = this.handleMktCapOperatorChange.bind(this);
        this.handleMktCapValueChange = this.handleMktCapValueChange.bind(this);
        this.handleMktCapKeyDown = this.handleMktCapKeyDown.bind(this);
        this.handleRiskRatingOperatorChange = this.handleRiskRatingOperatorChange.bind(this);
        this.handleRiskRatingValueChange = this.handleRiskRatingValueChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleAnalystChange = this.handleAnalystChange.bind(this);
        this.handleSortBy = this.handleSortBy.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
            this.getDropDownData();
            this.goToPage(1);
        }).catch(e => {
            console.log(e);
        });
    }
    getDropDownData() {
        const cowenFlag = this.context;
        if(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) {
            SearchServiceV2.getDropDownData(TEAM.CA).then(caData => {
                this.setState({dropDownDataAnalysts: [...caData.analysts],
                    dropDownLoading: false});
                this.setState({dropDownDataIndustries: [...caData.industries],
                    dropDownLoading: false});
            }).catch(this.getDropDownDataError);
            SearchServiceV2.getDropDownData(TEAM.US).then(usData => {
                this.state.dropDownDataAnalysts.push(...usData.analysts);
                this.state.dropDownDataAnalysts.sort((a, b) => a.lastName.localeCompare(b.lastName));
                this.state.dropDownDataIndustries.push(...usData.industries);
                this.state.dropDownDataIndustries.sort((a, b) => a.label.localeCompare(b.label));
            }).catch(this.getDropDownDataError);
        }
         else {
            SearchService.getDropDownData().then(this.getDropDownDataThen).catch(this.getDropDownDataError);
        }
    }

    getDropDownDataThen(dropDownData) {
        this.setState({
            dropDownData: SearchService.filterEquitiesAnalysts(dropDownData),
            dropDownLoading: false
        });
    }
    getDropDownDataError(error) {
        this.setState({
            dropDownData: null,
            dropDownDataAnalysts: null,
            dropDownDataIndustries: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true
        });
    }
    getData() {
        var byLetter = this.state.filter;
        const cowenFlag = this.context;
        if (byLetter === 'ALL') {
            byLetter = ''
        }
        var params = {
            page: this.state.currentPage,
            size: COVERAGE_ADVANCED_FILTER_PAGE_SIZE,
            recomendationComparator: this.state.recommendationOperator,
            recomendationValue: this.state.recommendationValue,
            totalReturnComparator: this.state.totalReturnOperator,
            totalReturnValue: this.state.totalReturnValue,
            avgVolumeComparator: this.state.avgVolOperator,
            avgVolumeValue: this.state.avgVolValue,
            yieldComparator: this.state.yieldOperator,
            yieldValue: this.state.yieldValue,
            orderBy: this.state.sortBy,
            orderType: this.state.sortType,
            byLetter: byLetter
        };

        if (!isCowenEnabled(this.state.cowenEnabled, cowenFlag)) {
            params = {
                ...params,
                marketCapitalComparator: this.state.mktCapOperator,
                marketCapitalValue: this.state.mktCapValue,
                riskComparator: this.state.riskRatingOperator,
                riskValue: this.state.riskRatingValue,
            }
        }
        var arrayParams = {
            industryIds: this.convertValues(this.state.industry),
            analystIds: this.convertValues(this.state.analyst)
        }
        CoveragePageService.getAdvancedFilter(params, arrayParams).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            loading: false,
            data: data,
        });
    }
    getDataError(error) {
        this.setState({
            loading: false,
            data: null,
            error: true
        });
    }
    handleAlphabetFilterClick(filter) {
        this.setState({
            loading: true,
            data: null,
            error: false,
            filter: filter
        }, this.goToPage(1));
    }
    handleSearch() {
        this.setState({
            loading: true,
            data: null,
            error: false
        }, this.getData);
    }
    handleSearchClick() {
        this.setState({
            currentPage: 1
        }, this.handleSearch);
    }
    handleClear() {
        this.setState({
            analyst: null,
            industry: null,
            recommendationOperator: OPERATOR_EQUALS,
            recommendationValue: '',
            totalReturnOperator: OPERATOR_EQUALS,
            totalReturnValue: '',
            avgVolOperator: OPERATOR_EQUALS,
            avgVolValue: '',
            yieldOperator: OPERATOR_EQUALS,
            yieldValue: '',
            riskRatingOperator: OPERATOR_EQUALS,
            riskRatingValue: '',
            mktCapOperator: OPERATOR_EQUALS,
            mktCapValue: '',
            sortBy: null,
            sortType: null
        });
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.handleSearch
        );
    }
    handleIndustryChange = value => {
        this.setState({ industry: value });
    }
    handleAnalystChange = value => {
        this.setState({ analyst: value });
    }
    handleOptionalMetricsMobileExpandClick() {
        this.setState({
            optionalMetricsMobileExpand: !this.state.optionalMetricsMobileExpand
        })
    }
    handleKeyDown(event, type) {
        if (event.key === KEY_ENTER) {
            this.goToPage(1);
            return;
        }
        var stateValue = this.state[type];
        if (event.key === KEY_BACKSPACE) {
            if (stateValue.length > 0) {
                var stateVar = {};
                stateVar[type] = stateValue.slice(0, -1);
                this.setState(stateVar);
            }
            return;
        }
        if (UtilsService.isValueNumeric(stateValue, event.key)) {
            var _stateVar = {};
            _stateVar[type] = stateValue + event.key;
            this.setState(_stateVar);
            return;
        }
    }
    handleRecommendationsOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    recommendationOperator: event.target.value
                }
            );
        }
    }
    handleRecommendationsValueChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    recommendationValue: event.target.value
                }
            );
        }
    }
    handleYieldOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    yieldOperator: event.target.value
                }
            );
        }
    }
    handleYieldValueChange(event) { return; /* Nothing to do here */ }

    handleYieldKeyDown(event) {
        return this.handleKeyDown(event, "yieldValue");
    }

    handleTotalReturnOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    totalReturnOperator: event.target.value
                }
            );
        }
    }

    handleTotalReturnValueChange(event) { return; /* Nothing to do here */ }

    handleTotalReturnKeyDown(event) {
        return this.handleKeyDown(event, "totalReturnValue");
    }

    handleRiskRatingOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    riskRatingOperator: event.target.value
                }
            );
        }
    }
    handleRiskRatingValueChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    riskRatingValue: event.target.value
                }
            );
        }
    }
    handleAvgVolOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    avgVolOperator: event.target.value
                }
            );
        }
    }
    handleAvgVolValueChange(event) { return; /* Nothing to do here */ }
    handleAvgVolKeyDown(event) {
        return this.handleKeyDown(event, "avgVolValue");
    }
    handleMktCapOperatorChange(event) {
        if (event && event.target) {
            this.setState(
                {
                    mktCapOperator: event.target.value
                }
            );
        }
    }
    handleMktCapValueChange(event) { return; /* Nothing to do here */ }
    handleMktCapKeyDown(event) {
        return this.handleKeyDown(event, "mktCapValue");
    }
    handleSortBy(event) {
        if (event && event.target) {
            if (this.state.sortBy === event.target.dataset.sort) {
                if (this.state.sortType === ADVANCED_FILTER_SORT_TYPE_ASC) {
                    this.setState(
                        {
                            sortBy: event.target.dataset.sort,
                            sortType: ADVANCED_FILTER_SORT_TYPE_DESC
                        }, this.goToPage(1)
                    );
                } else {
                    this.setState(
                        {
                            sortBy: event.target.dataset.sort,
                            sortType: ADVANCED_FILTER_SORT_TYPE_ASC
                        }, this.goToPage(1)
                    );
                }
            } else {
                this.setState(
                    {
                        sortBy: event.target.dataset.sort,
                        sortType: ADVANCED_FILTER_SORT_TYPE_ASC
                    }, this.goToPage(1)
                );
            }
        }
    }
    convertValues = (dropDownValues) => {
        if (!dropDownValues || dropDownValues.length === 0) {
            return null;
        }
        var dropDownValuesArray = [];
        for (var i = 0; i < dropDownValues.length; i++) {
            dropDownValuesArray.push(dropDownValues[i].value);
        }
        return dropDownValuesArray;
    }
    isRestricted = (item) => {
        return item.restricted || item.coverted;
    }

    render() {
        const cowenFlag = this.context;
        return (
            <React.Fragment>
                <div className="tds-coverage-advanced-filter-main-container">
                    <div className="tds-coverage-advanced-filter-analysts-container form-group td-form-group-padding">
                        <label htmlFor="analysts">Analysts</label>
                        {
                            this.state.dropDownLoading &&
                            <div className="tds-loading">
                                <Spinner paddingTop={'20px'} />
                            </div>
                        }
                        {
                            !this.state.dropDownLoading && this.state.errorLoadingDropDownData &&
                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Analyst(s)" isDisabled={true} />
                        }
                        {
                            !this.state.dropDownLoading && !this.state.errorLoadingDropDownData &&
                            <Select
                                options={isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? this.state.dropDownDataAnalysts : this.state.dropDownData.analysts}
                                isMulti={true}
                                value={this.state.analyst}
                                name="SearchAnalyst"
                                onChange={this.handleAnalystChange}
                                styles={ReactSelectDesktopStyles}
                                placeholder='Select Analyst(s)'
                            />
                        }
                    </div>
                    <div className="tds-coverage-advanced-filter-industries-container form-group td-form-group-padding">
                        <label htmlFor="industries">Industries</label>
                        {
                            this.state.dropDownLoading &&
                            <div className="tds-loading">
                                <Spinner paddingTop={'20px'} />
                            </div>
                        }
                        {
                            !this.state.dropDownLoading && this.state.errorLoadingDropDownData &&
                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Industries" isDisabled={true} />
                        }
                        {
                            !this.state.dropDownLoading && !this.state.errorLoadingDropDownData &&
                            <Select
                                options={isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? this.state.dropDownDataIndustries : this.state.dropDownData.industries}
                                isMulti={true}
                                value={this.state.industry}
                                name="SearchIndustry"
                                onChange={this.handleIndustryChange}
                                styles={ReactSelectDesktopStyles}
                                placeholder='Select Industries'
                            />
                        }
                    </div>
                    <div className="tds-coverage-advanced-filter-optional-metrics-container">
                        <span className="tds-coverage-advanced-filter-optional-metrics-label">
                            <span className={this.state.optionalMetricsMobileExpand ? 'td-icon td-icon-expand icon-small' : ' td-icon td-icon-expand icon-small active'}
                                onClick={this.handleOptionalMetricsMobileExpandClick}
                            ></span>

                            <span className={this.state.optionalMetricsMobileExpand ? 'td-icon td-icon-collapse icon-small active' : 'td-icon td-icon-expand icon-small'}
                                onClick={this.handleOptionalMetricsMobileExpandClick}
                            >
                            </span>Optional Metrics</span>
                        <div
                            className="tds-coverage-advanced-filter-column recommendations-yield"
                            style={
                                {
                                    'display': this.state.optionalMetricsMobileExpand ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <div
                                className="tds-coverage-advanced-filter-recommendations-container form-group td-form-group-padding">
                                <label htmlFor="recommendations">Recommendations</label>
                                <div className="td-select tds-coverage-advanced-filter-recommendations-operator">
                                    <select
                                        className="form-control"
                                        id="recommendations_operator"
                                        value={this.state.recommendationOperator}
                                        onChange={this.handleRecommendationsOperatorChange}
                                    >
                                        <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                        <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                        <option value={OPERATOR_EQUALS}>=</option>
                                        <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                        <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>

                                    </select>
                                </div>
                                <div className="td-select tds-coverage-advanced-filter-recommendations">
                                    <select
                                        className="form-control"
                                        id="recommendations"
                                        value={this.state.recommendationValue}
                                        onChange={this.handleRecommendationsValueChange}
                                    >
                                        <option value={''}>--</option>
                                        <option value={REC_VALUE_TENDER_BUY}>BUY</option>
                                        <option value={REC_VALUE_TENDER_HOLD}>HOLD</option>
                                        <option value={REC_VALUE_TENDER_SELL}>SELL</option>
                                        <option value={REC_VALUE_TENDER_SUSPENDED}>SUSPENDED</option>
                                    </select>
                                </div>
                            </div>
                            <div className="tds-coverage-advanced-filter-yield-container form-group td-form-group-padding">
                                <label htmlFor="yield">Yield</label>
                                <div className="td-select tds-coverage-advanced-filter-yield-operator">
                                    <select
                                        className="form-control"
                                        id="yield_operator"
                                        value={this.state.yieldOperator}
                                        onChange={this.handleYieldOperatorChange}
                                    >
                                        <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                        <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                        <option value={OPERATOR_EQUALS}>=</option>
                                        <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                        <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>
                                    </select>
                                </div>
                                <div className="tds-coverage-advanced-filter-yield-input">
                                    <input
                                        name="yield"
                                        className="form-control"
                                        value={this.state.yieldValue}
                                        onChange={this.handleYieldValueChange}
                                        onKeyDown={this.handleYieldKeyDown}
                                        id="yield"
                                        type="text" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="tds-coverage-advanced-filter-column total-return-risk-rating"
                            style={
                                {
                                    'display': this.state.optionalMetricsMobileExpand ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <div
                                className="tds-coverage-advanced-filter-total-return-container form-group td-form-group-padding">
                                <label htmlFor="total_return">Total Return</label>
                                <div className="td-select tds-coverage-advanced-filter-total-return-operator">
                                    <select
                                        className="form-control"
                                        id="total_return_operator"
                                        value={this.state.totalReturnOperator}
                                        onChange={this.handleTotalReturnOperatorChange}
                                    >
                                        <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                        <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                        <option value={OPERATOR_EQUALS}>=</option>
                                        <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                        <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>
                                    </select>
                                </div>
                                <div className="tds-coverage-advanced-filter-total-return-input">
                                    <input
                                        name="total_return"
                                        className="form-control"
                                        id="total_return"
                                        type="text"
                                        value={this.state.totalReturnValue}
                                        onKeyDown={this.handleTotalReturnKeyDown}
                                        onChange={this.handleTotalReturnValueChange}
                                    />
                                </div>
                            </div>
                            {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                <div
                                    className="tds-coverage-advanced-filter-risk-rating-container form-group td-form-group-padding">
                                    <label htmlFor="risk_rating">Risk Rating</label>
                                    <div className="td-select tds-coverage-advanced-filter-risk-rating-operator">
                                        <select
                                            className="form-control"
                                            id="risk_rating_operator"
                                            value={this.state.riskRatingOperator}
                                            onChange={this.handleRiskRatingOperatorChange}
                                        >
                                            <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                            <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                            <option value={OPERATOR_EQUALS}>=</option>
                                            <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                            <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>
                                        </select>
                                    </div>
                                    <div className="tds-coverage-advanced-filter-risk-rating-input">
                                        <select
                                            name="risk_rating"
                                            className="form-control"
                                            id="risk_rating"
                                            value={this.state.riskRatingValue}
                                            onChange={this.handleRiskRatingValueChange}
                                        >
                                            <option value={''}>--</option>
                                            <option value={RISK_RATING_VALUE_HIGH}>High</option>
                                            <option value={RISK_RATING_VALUE_MEDIUM}>Medium</option>
                                            <option value={RISK_RATING_VALUE_LOW}>Low</option>
                                            <option value={RISK_RATING_VALUE_SPECULATIVE}>Speculative</option>
                                        </select>
                                    </div>
                                </div>}
                        </div>
                        <div
                            className="tds-coverage-advanced-filter-column avg-vol-mkt-cap"
                            style={
                                {
                                    'display': this.state.optionalMetricsMobileExpand ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                }
                            }
                        >
                            <div
                                className="tds-coverage-advanced-filter-avg-vol-container form-group td-form-group-padding">
                                <label htmlFor="avg_vol">Avg Volume (000s)</label>
                                <div className="td-select tds-coverage-advanced-filter-avg-vol-operator">
                                    <select
                                        className="form-control"
                                        id="avg_voln_operator"
                                        value={this.state.avgVolOperator}
                                        onChange={this.handleAvgVolOperatorChange}
                                    >
                                        <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                        <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                        <option value={OPERATOR_EQUALS}>=</option>
                                        <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                        <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>
                                    </select>
                                </div>
                                <div className="tds-coverage-advanced-filter-avg-vol-input">
                                    <input
                                        name="avg_vol"
                                        className="form-control"
                                        id="avg_vol"
                                        type="text"
                                        value={this.state.avgVolValue}
                                        onChange={this.handleAvgVolValueChange}
                                        onKeyDown={this.handleAvgVolKeyDown}
                                    />
                                </div>
                            </div>
                            {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                <div
                                    className="tds-coverage-advanced-filter-mkt-cap-container form-group td-form-group-padding">
                                    <label htmlFor="mkt_cap">Mkt Cap ($mm)</label>
                                    <div className="td-select tds-coverage-advanced-filter-mkt-cap-operator">
                                        <select
                                            className="form-control"
                                            id="mkt_cap_operator"
                                            value={this.state.mktCapOperator}
                                            onChange={this.handleMktCapOperatorChange}
                                        >
                                            <option value={OPERATOR_LESS_THAN}>&lt;</option>
                                            <option value={OPERATOR_LESS_THAN_EQUALS}>&lt;=</option>
                                            <option value={OPERATOR_EQUALS}>=</option>
                                            <option value={OPERATOR_GREATER_THAN}>&gt;</option>
                                            <option value={OPERATOR_GREATER_THAN_EQUALS}>&gt;=</option>
                                        </select>
                                    </div>
                                    <div className="tds-coverage-advanced-filter-mkt-cap-input">
                                        <input
                                            name="mkt_cap"
                                            className="form-control"
                                            id="mkt_cap"
                                            type="text"
                                            value={this.state.mktCapValue}
                                            onChange={this.handleMktCapValueChange}
                                            onKeyDown={this.handleMktCapKeyDown}
                                        />
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="tds-coverage-advanced-filter-search-bar">
                        <div className="tds-coverage-advanced-filter-search-bar-cancel-ctn">
                            <button
                                title="Clear"
                                className="btn btn-block td-btn-secondary-clear"
                                onClick={this.handleClear}
                            >Clear</button>
                        </div>
                        <div className="tds-coverage-advanced-filter-search-bar-search-ctn">
                            <button
                                title="Search"
                                className="btn btn-block td-btn-primary-light"
                                onClick={this.handleSearchClick}
                            >Search</button>
                        </div>
                    </div>
                </div>
                <div className="tds-coverage-advanced-filter-results-container">
                    <AlphabetFilterComponent
                        handleAlphabetFilterClick={this.handleAlphabetFilterClick}
                        disabled={this.state.loading}
                    />
                    {
                        this.state.loading &&
                        <div className="tds-loading">
                            <Spinner paddingTop={'20px'} />
                        </div>
                    }
                    {
                        !this.state.loading && this.state.error &&
                        <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                    }
                    {
                        !this.state.loading && !this.state.error && this.state.data && this.state.data.results && this.state.data.results.length === 0 &&
                        <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                    }
                    {
                        !this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                        <div className="tds-coverage-advanced-filter-results-table">
                            <React.Fragment>
                                <div className="tds-coverage-advanced-filter-results-row header">
                                    <div
                                        className="tds-coverage-advanced-filter-results-column name"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                            title="Sort by Name"
                                        >
                                            Name
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column symbol"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                            title="Sort by Symbol"
                                        >
                                            Symbol
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column rec"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                            title="Sort by Recommendation"
                                        >
                                            REC
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                        ></span>
                                    </div>
                                    {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) && <div
                                        className="tds-coverage-advanced-filter-results-column risk-rating"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                            title="Sort by Risk Rating"
                                        >
                                            Risk Rating
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                        ></span>
                                    </div>}
                                    <div
                                        className="tds-coverage-advanced-filter-results-column currency"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                            title="Sort by Currency"
                                        >
                                            Currency
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column target"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                            title="Sort by Target"
                                        >
                                            Target
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column price"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                            title="Sort by Price"
                                        >
                                            Price
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column yield"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                            title="Sort by Yield"
                                        >
                                            Yield
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column total-return"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                            title="Sort by Total Return"
                                        >
                                            Total Return
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column avg-vol"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                            title="Sort by Average Volume"
                                        >
                                            Avg Vol (000s)
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                        ></span>
                                    </div>
                                    {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                        <div
                                            className="tds-coverage-advanced-filter-results-column mkt-cap"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                            onClick={this.handleSortBy}
                                        >
                                            <span
                                                className="tds-coverage-advanced-filter-results-column-text"
                                                data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                                title="Sort by Market Cap"
                                            >
                                                Mkt Cap ($mm)
                                            </span>
                                            <span
                                                className="td-icon td-icon-sort"
                                                data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                            ></span>
                                        </div>}
                                    <div
                                        className="tds-coverage-advanced-filter-results-column analyst"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                            title="Sort by Analyst"
                                        >
                                            Analyst
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                        ></span>
                                    </div>
                                </div>
                                {this.state.data.results.map(
                                    (item, index) => {
                                        return (
                                            <div
                                                className="tds-coverage-advanced-filter-results-row"
                                                key={'tds-coverage-advanced-filter-results-row' + index}
                                            >
                                                <div className="tds-coverage-advanced-filter-results-column name">
                                                    <Link
                                                        to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                        className={"tds-coverage-advanced-filter-results-column-text-link"}
                                                        title={item.companyName}
                                                    >
                                                        {item.companyName}
                                                    </Link>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column symbol">
                                                    <Link
                                                        to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                        className={"tds-coverage-advanced-filter-results-column-text-link"}
                                                        title={item.companySymbol}
                                                    >
                                                        {item.companySymbol}
                                                    </Link>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column rec">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {item.recommendation}
                                                    </span>
                                                </div>
                                                {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                                    <div className="tds-coverage-advanced-filter-results-column risk-rating">
                                                        <span className="tds-coverage-advanced-filter-results-column-text">
                                                            {
                                                                (!this.isRestricted(item)) &&
                                                                <React.Fragment>
                                                                    {item.riskRating}
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                (this.isRestricted(item)) &&
                                                                <React.Fragment>
                                                                    RESTRICTED
                                                                </React.Fragment>
                                                            }
                                                        </span>
                                                    </div>}
                                                <div className="tds-coverage-advanced-filter-results-column currency">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {item.currency}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column target">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) && item.priceTarget && item.priceTarget >= 0 &&
                                                            <React.Fragment>
                                                                <React.Fragment>
                                                                    {item.currencySymbol}
                                                                    {UtilsService.formatNumber(item.priceTarget, 2)}
                                                                </React.Fragment>
                                                                {
                                                                    item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) &&
                                                            <React.Fragment>
                                                                RESTRICTED
                                                            </React.Fragment>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column price">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) && <>
                                                                {item.currentPrice &&
                                                                    <React.Fragment>
                                                                        {item.currencySymbol}
                                                                        {UtilsService.formatNumber(item.currentPrice, 2)}
                                                                    </React.Fragment>
                                                                }
                                                                {
                                                                    item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) && <>&nbsp;</>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column yield">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) && <>
                                                                {item.dividendYield > 0 &&
                                                                    <React.Fragment>
                                                                        <FormatedNumberComponent
                                                                            number={item.dividendYield}
                                                                            decimal={'2'}
                                                                        />%
                                                                    </React.Fragment>
                                                                }
                                                                {
                                                                    (item.dividendYield === 0 || item.dividendYield === "0") &&
                                                                    <React.Fragment>0.0%</React.Fragment>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) && <>&nbsp;</>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column total-return">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {!this.isRestricted(item) && <>
                                                            {(item.totalReturn > 0 || item.totalReturn < 0) &&
                                                                <React.Fragment>
                                                                    <FormatedNumberComponent
                                                                        number={item.totalReturn}
                                                                        decimal={'2'}
                                                                    />%
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                (item.totalReturn === 0 || item.totalReturn === "0") &&
                                                                <React.Fragment>0.0%</React.Fragment>
                                                            }
                                                        </>
                                                        }
                                                        {this.isRestricted(item) && <>&nbsp;</>}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column avg-vol">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        <FormatedNumberComponent
                                                            number={item.avgVolume}
                                                            decimal={'0'}
                                                            commas={true}
                                                        />
                                                    </span>
                                                </div>
                                                {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                                    <div className="tds-coverage-advanced-filter-results-column mkt-cap">
                                                        <span className="tds-coverage-advanced-filter-results-column-text">
                                                            <FormatedNumberComponent
                                                                number={item.marketCapitalFd}
                                                                decimal={1}
                                                                commas={true}
                                                            />
                                                        </span>
                                                    </div>}
                                                <div className="tds-coverage-advanced-filter-results-column analyst">
                                                    {item.analyst &&
                                                        <Link
                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                                                (isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? FIRM_NAME_COWEN : FIRM_NAME_RESEARCH) + FORWARD_SLASH + item.analyst.id}
                                                            className={"tds-coverage-advanced-filter-results-column-text"}
                                                            title={item.analyst.lastName + ' ' + item.analyst.firstName}
                                                        >
                                                            {item.analyst.lastName}, {item.analyst.firstName}
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                )}

                            </React.Fragment>

                        </div>
                    }
                    {
                        !this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                        <div className="tds-coverage-advanced-filter-results-table-mobile">
                            <div className="tds-coverage-advanced-filter-results-mobile-frozen-column">
                                <div className="tds-coverage-advanced-filter-results-row header">
                                    <div
                                        className="tds-coverage-advanced-filter-results-column name"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                        onClick={this.handleSortBy}
                                        title="Sort by Name"
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                        >
                                            Name
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_NAME}
                                        ></span>
                                    </div>
                                </div>
                                {this.state.data.results.map(
                                    (item, index) => {
                                        return (
                                            <div
                                                key={'tds-coverage-advanced-filter-results-column-header' + index}
                                                className="tds-coverage-advanced-filter-results-column name">
                                                <Link
                                                    to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                    className={"tds-coverage-advanced-filter-results-column-text-link"}
                                                    title={item.companyName}
                                                >
                                                    {item.companyName}
                                                </Link>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                            <div className="tds-coverage-advanced-filter-results-mobile-unfrozen-columns">
                                <div className="tds-coverage-advanced-filter-results-row-mobile-unfrozen header">
                                    <div
                                        className="tds-coverage-advanced-filter-results-column symbol"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                            title="Sort by Symbol"
                                        >
                                            Symbol
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_COMPANY_SYMBOL}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column rec"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                            title="Sort by Recommendation"
                                        >
                                            REC
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_REC}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column risk-rating"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                            title="Sort by Risk Rating"
                                        >
                                            Risk Rating
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_RISK_RATING}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column currency"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                            title="Sort by Currency"
                                        >
                                            Currency
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENCY}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column target"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                            title="Sort by Target"
                                        >
                                            Target
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_PRICE_TARGET}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column price"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                            title="Sort by Price"
                                        >
                                            Price
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_CURRENT_PRICE}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column yield"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                            title="Sort by Yield"
                                        >
                                            Yield
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_DIVIDEND_YIELD}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column total-return"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                            title="Sort by Total Return"
                                        >
                                            Total Return
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_TOTAL_RETURN}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column avg-vol"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                            title="Sort by Average Volume"
                                        >
                                            Avg Vol (000s)
                                        </span>
                                        <span
                                            className="td-icon td-icon-sort"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_AVG_VOLUME}
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column mkt-cap"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                            title="Sort by Market Cap"
                                        >
                                            Mkt Cap ($mm)
                                        </span>
                                        <span
                                            data-sort={ADVANCED_FILTER_ORDER_BY_MKT_CAP}
                                            className="td-icon td-icon-sort"
                                        ></span>
                                    </div>
                                    <div
                                        className="tds-coverage-advanced-filter-results-column analyst"
                                        data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                        onClick={this.handleSortBy}
                                    >
                                        <span
                                            className="tds-coverage-advanced-filter-results-column-text"
                                            data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                            title="Sort by Analyst"
                                        >
                                            Analyst
                                        </span>
                                        <span
                                            data-sort={ADVANCED_FILTER_ORDER_BY_ANALYST}
                                            className="td-icon td-icon-sort"></span>
                                    </div>
                                </div>
                                {this.state.data.results.map(
                                    (item, index) => {
                                        return (
                                            <div
                                                key={'tds-coverage-advanced-filter-results-row-mobile-unfrozen' + index}
                                                className="tds-coverage-advanced-filter-results-row-mobile-unfrozen">
                                                <div className="tds-coverage-advanced-filter-results-column symbol">
                                                    <Link
                                                        to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                        className={"tds-coverage-advanced-filter-results-column-text-link"}
                                                        title={item.companySymbol}
                                                    >
                                                        {item.companySymbol}
                                                    </Link>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column rec">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {item.recommendation}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column risk-rating">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) &&
                                                            <React.Fragment>
                                                                {item.riskRating}
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) &&
                                                            <React.Fragment>
                                                                RESTRICTED
                                                            </React.Fragment>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column currency">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {item.currency}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column target">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) && item.priceTarget && item.priceTarget >= 0 &&
                                                            <React.Fragment>
                                                                <React.Fragment>
                                                                    {item.currencySymbol}
                                                                    {UtilsService.formatNumber(item.priceTarget, 2)}
                                                                </React.Fragment>
                                                                {
                                                                    item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) &&
                                                            <React.Fragment>
                                                                RESTRICTED
                                                            </React.Fragment>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column price">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) &&
                                                            <>
                                                                {item.currentPrice &&
                                                                    <React.Fragment>${item.currentPrice}</React.Fragment>
                                                                }
                                                                {
                                                                    item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            (this.isRestricted(item)) && <>&nbsp;</>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column yield">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {
                                                            (!this.isRestricted(item)) && <>
                                                                {item.dividendYield > 0 &&
                                                                    <React.Fragment>
                                                                        <FormatedNumberComponent
                                                                            number={item.dividendYield}
                                                                            decimal={'2'}
                                                                        />%
                                                                    </React.Fragment>
                                                                }
                                                                {
                                                                    (!item.dividendYield || item.dividendYield === 0 || item.dividendYield === "0") &&
                                                                    <React.Fragment>0.0%</React.Fragment>
                                                                }
                                                            </>
                                                        }
                                                        {(this.isRestricted(item)) && <>&nbsp;</>}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column total-return">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        {!this.isRestricted(item) && <>
                                                            {item.totalReturn > 0 &&
                                                                <React.Fragment>
                                                                    <FormatedNumberComponent
                                                                        number={item.totalReturn}
                                                                        decimal={'2'}
                                                                    />%
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                (!item.totalReturn || item.totalReturn === 0 || item.totalReturn === "0") &&
                                                                <React.Fragment>0.0%</React.Fragment>
                                                            }
                                                        </>
                                                        }
                                                        {this.isRestricted(item) && <>&nbsp;</>}
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column avg-vol">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        <FormatedNumberComponent
                                                            number={item.avgVolume}
                                                            decimal={'0'}
                                                            commas={true}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column mkt-cap">
                                                    <span className="tds-coverage-advanced-filter-results-column-text">
                                                        <FormatedNumberComponent
                                                            number={item.marketCapitalFd}
                                                            decimal={2}
                                                            commas={true}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="tds-coverage-advanced-filter-results-column analyst">
                                                    {item.analyst &&
                                                        <Link
                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                                                (isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? FIRM_NAME_COWEN : FIRM_NAME_RESEARCH) + FORWARD_SLASH + item.analyst.id}
                                                            className={"tds-coverage-advanced-filter-results-column-text"}
                                                            title={item.analyst.lastName + ' ' + item.analyst.firstName}
                                                        >
                                                            {item.analyst.lastName}, {item.analyst.firstName}
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    }
                </div>
                {
                    !this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                    <div className="tds-advanced-filter-pagination-container">
                        <PaginationComponent
                            currentPage={this.state.currentPage}
                            resultsPerPage={COVERAGE_ADVANCED_FILTER_PAGE_SIZE}
                            total={this.state.data.total}
                            goToPage={this.goToPage}
                        />
                    </div>
                }
            </React.Fragment>
        )
    }
}

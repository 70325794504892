import React from 'react';
import { REQUEST_ACCESS_MAIL_FROM_MAILTO, REQUEST_ACCESS_MAIL_FROM } from '../../../constants/LandingPage.constants';
import EnvironmentService from '../../../services/Environment.service';
import IframeComponent from '../../iframe/Iframe.component';
export default class AccessDeniedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframe1Name: 'iframe_1',
            iframe1URL: EnvironmentService.logoutURL.iframe1URL,
            iframe2Name: 'iframe_2',
            iframe2URL: EnvironmentService.logoutURL.iframe2URL
        };
    }
    render() {
        return (
            <div className="tds-container tds-access-denied" style={
                {
                    'marginTop': '50px',
                }
            }>
                <h1>Access Denied</h1>
                <p>
                    To request access, please contact <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a>
                </p>
                {!this.props.shouldLogout &&
                    <div className="tds-loggedout-iframe-container">
                        <IframeComponent
                            src={this.state.iframe1URL}
                            id={this.state.iframe1Name}
                        />
                        <IframeComponent
                            src={this.state.iframe2URL}
                            id={this.state.iframe2Name}
                        />
                    </div>
                }

            </div>
        )
    }
}
import React from 'react';

export default class ResponsiveImageComponent extends React.Component {
    constructor(props) {
        super(props);
        var imgsrc = this.getImage(window.innerWidth);
        this.state = { 
            imgsrc: imgsrc
        };
        this.resizeListener = this.resizeListener.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', this.resizeListener);
    }
    getImage(width) {
        if (width > 1299) {
            return this.props.default;
        } else if (width > 1023 && width < 1300) {
            return this.props.tablet1;
        } else if (width > 767 && width < 1024) {
            return this.props.tablet2;
        } else if (width < 768) {
            return this.props.mobile;
        } 
    }
    resizeListener() {
        var imgsrc = this.getImage(window.innerWidth);
        this.setState({
            imgsrc: imgsrc
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
    }
    render() {
        return (
            <img 
                src={this.state.imgsrc}
                alt={this.props.alt}
                title={this.props.alt}
                className={this.props.className}
            />
        )
    }
}
import React from 'react';
import {
    ALPHABET_FILTER_LIST, DEFAULT_ALL
} from '../../../constants/Misc.constants';

export default class AlphabetFilterComponent extends React.Component {
    rootClassName = 'tds-alphabet-filter';
    constructor(props) {
        super(props);
        let filter = DEFAULT_ALL;
        if (this.props.filter) {
            filter = this.props.filter;
        }
        this.state = {
            filter
        };
        if (this.props.noBorder) {
            this.rootClassName += ' no-border';
        }
        if (this.props.noPadding) {
            this.rootClassName += ' no-padding';
        }
        this.handleAlphabetFilterClick = this.handleAlphabetFilterClick.bind(this);
        this.handleAlphabetFilterDropdownChange = this.handleAlphabetFilterDropdownChange.bind(this);
    }
    handleAlphabetFilterClick(event) {
        if (this.props.disabled) {
            return;
        }
        if (event) {
            event.preventDefault();
        }
        if (event && event.target && event.target.dataset) {
            this.setState(
                {
                    filter: event.target.dataset.value
                }
            );
            if (this.props.handleAlphabetFilterClick) {
                this.props.handleAlphabetFilterClick(event.target.dataset.value);
            }
        }
    }
    handleAlphabetFilterDropdownChange(event) {
        if (this.props.disabled) {
            return;
        }
        if (event) {
            event.preventDefault();
        }
        if (event && event.target) {
            this.setState(
                {
                    filter: event.target.value
                }
            );
            if (this.props.handleAlphabetFilterClick) {
                this.props.handleAlphabetFilterClick(event.target.value);
            }
        }
    }
    render() {
        return (
            <div className={this.rootClassName}>
                <a
                    href="# "
                    className={'tds-coverage-advanced-filter-results-alphabet-filter-show-all-label' + (this.state.filter === 'ALL' ? ' active' : '')}
                    data-value={'ALL'}
                    title="Show All"
                    onClick={this.handleAlphabetFilterClick}
                >
                    Show All
                </a>
                {ALPHABET_FILTER_LIST.map(
                    (alphabet, index) => {
                        return (
                            <a
                                href="# "
                                key={'tds-alphabet-filter-' + index}
                                className={'tds-alphabet-filter-alphabet-label' + (this.state.filter === alphabet ? ' active' : '')}
                                data-value={alphabet}
                                title={alphabet}
                                onClick={this.handleAlphabetFilterClick}
                            >
                                {alphabet}
                            </a>
                        )
                    }
                )}
                <div className="tds-alphabet-filter-mobile">
                    <label htmlFor="alphabet-filter">Filter Results</label>
                    <div className="td-select tds-alphabet-filter-dropdown">
                        <select 
                            className="form-control"
                            id="alphabet-filter"
                            onChange={this.handleAlphabetFilterDropdownChange}
                            value={this.state.filter}
                        >
                            <option vlaue={''}>Show All</option>
                            {ALPHABET_FILTER_LIST.map(
                                (alphabet, index) => {
                                    return (
                                        <option
                                            value={alphabet}
                                            key={'tds-alphabet-filter-option-' + index}
                                        >
                                            {alphabet}
                                        </option>
                                    )
                                }
                            )}
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

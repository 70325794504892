import React from 'react';
import {
    TDS_CALENDAR_EVENT_TYPE
} from '../../constants/Calendar.constants';
export default class CalendarDayEventComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (this.props.event && this.props.event.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE 
            && this.props.eventTypeEarnings) {
                this.companyName = '';
                if (this.props.event.company && this.props.event.company.name) {
                    this.companyName = this.props.event.company.name;
                }
            }
        this.handleEventClick = this.handleEventClick.bind(this);
    }
    handleEventClick(e) {
        if (this.props.handleEventClick) {
            this.props.handleEventClick(e.target.dataset.eventid)
        }
    }
    render() {
        return (
            <React.Fragment>
                { this.props.event &&
                    <React.Fragment>
                        {
                            this.props.event.eventType === TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT && this.props.eventTypeMarketing &&
                            <span
                                className="tds-calender-event-label marketing"
                                title={this.props.event.description || TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT}
                                data-eventid={this.props.event.eventId}
                                onClick={this.handleEventClick}
                            >
                                {this.props.event.description || TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT}
                            </span>
                        }
                        {
                            this.props.event.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE && this.props.eventTypeEarnings &&
                            <span
                                className="tds-calender-event-label earnings"
                                title={this.companyName}
                                data-eventid={this.props.event.eventId}
                                onClick={this.handleEventClick}
                            >
                                {this.companyName}
                            </span>
                        }
                        {
                            this.props.event.eventType === TDS_CALENDAR_EVENT_TYPE.CONFERENCE && this.props.eventTypeConference &&
                            <span
                                className="tds-calender-event-label conference"
                                title={this.props.event.description || TDS_CALENDAR_EVENT_TYPE.CONFERENCE}
                                data-eventid={this.props.event.eventId}
                                onClick={this.handleEventClick}
                            >
                                {this.props.event.description || TDS_CALENDAR_EVENT_TYPE.CONFERENCE}
                            </span>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

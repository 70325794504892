import HttpClient from '../utils/HttpClient';
import {
    REST_COVERAGE_PAGE_COMPANIES,
    REST_COVERAGE_PAGE_ACTION_LIST,
    REST_COVERAGE_PAGE_ADVANCED_FILTER,
    REST_EQUITIES_HOMEPAGE_PATH,
    REST_LATEST_ACTION_LIST_FULL_REPORT_PATH
} from '../constants/Rest.constants';

export default class CoveragePageService {

    static getCompanies(params) {
        return HttpClient.get(REST_COVERAGE_PAGE_COMPANIES, params);
    }

    static getActionList(params) {
        return HttpClient.get(REST_COVERAGE_PAGE_ACTION_LIST, params);
    }

    static getAdvancedFilter(params, arrayParams) {
        return HttpClient.getArrayParams(REST_COVERAGE_PAGE_ADVANCED_FILTER, params, arrayParams);
    }

    static getHighlightPublications() {
        return HttpClient.get(REST_EQUITIES_HOMEPAGE_PATH);
    }

    static getLatestActionListFullReport() {
        return HttpClient.get(REST_LATEST_ACTION_LIST_FULL_REPORT_PATH);
    }
}
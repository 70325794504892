import React from "react";
import { withRouter } from "react-router-dom";
import SideBarComponent from "../../side-bar/SideBar.component";
import Spinner from "../../spinner/Spinner.component";
import ErrorComponent from "../../misc/error/Error.component";
import UserService from "../../../services/User.service";
import AclAccessDeniedComponent from "../../misc/acl-access-denied/AclAccessDenied.component";
import FirstTakePublicationsComponent from "./FirstTakePublications.component";
import { ERROR_TYPE } from "../../../constants/Misc.constants";
import { ACL_EQUITIES_RESEARCH } from "../../../constants/Entitlements.constants";
import ResearchPageService from "../../../services/v2/ResearchPage.service";
import { RESEARCH_PAGE_TITLE } from "../../../constants/PageTitle.constants";
import ResearchContentListComponent from "./ResearchContentList.component";
import ResearchPageTabsComponent from "./tabs/ResearchPageTabs.component";
import queryString from "query-string";

class ResearchPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this._mounted = false;
    this.state = {
      loading: true,
      error: false,
      data: null,
      latestContentByTypes: null,
      team: "",
    };
    let urlQueryParams = queryString.parse(this.props?.location?.search);
    this.state.team = urlQueryParams.team ? urlQueryParams.team : "";
    this.hasAccess = UserService.hasAccess(ACL_EQUITIES_RESEARCH);
    this.getData = this.getData.bind(this);
    this.getDataThen = this.getDataThen.bind(this);
    this.getDataError = this.getDataError.bind(this);
  }

  componentDidMount() {
    document.title = RESEARCH_PAGE_TITLE;
    if (!this._mounted && this.hasAccess) {
      this._mounted = true;
      this.getData();
    }

    this.unlisten = this.props.history.listen((location, action) => {
      if (this._mounted && this.hasAccess) {
        let urlQueryParams = queryString.parse(location?.search);
        this.setState(
          {
            team: urlQueryParams.team ? urlQueryParams.team : "",
          },
          this.getData
        );
      }
    });
  }

  getData() {
    ResearchPageService.getResearchLatestByTypes(this.state.team)
      .then(this.getDataThen)
      .catch(this.getDataError);
  }
  getDataThen(data) {
    this.setState({
      loading: false,
      latestContentByTypes: data,
    });
  }
  getDataError(error) {
    this.setState({
      loading: false,
      data: null,
      latestContentByTypes: null,
      error: true,
    });
  }
  render() {
    return (
      <React.Fragment>
        {!this.hasAccess && <AclAccessDeniedComponent />}
        {
          <div className="tds-container tds-research-page">
            <h1>Research</h1>
            {!this.state.loading && this.state.latestContentByTypes && (
              <ResearchContentListComponent
                latestContentByTypes={this.state.latestContentByTypes}
                region={this.state.team}
              ></ResearchContentListComponent>
            )}
            <FirstTakePublicationsComponent team={this.state.team}/>
            <div className="tds-three-column-container">
              {this.state.loading && (
                <Spinner noContainer={true} outerWidth={"100%"} />
              )}
              {!this.state.loading && this.state.error && (
                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
              )}
              <ResearchPageTabsComponent />
            </div>
            <SideBarComponent />
          </div>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(ResearchPageComponent);

import React from 'react';
import CompanyListSectionComponent from './CompanyListSection.component';
export default class CompanyListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="tds-companies-list">
                { this.props.data && this.props.data.map(
                    (section, index) => {
                        return (
                            <CompanyListSectionComponent 
                                key={ 'company-list-section-component' + index}
                                data={section} 
                                symbol={this.props.symbol}
                                singleLetter={this.props.singleLetter}
                            />
                        )
                    }
                )}
            </div>
        )
    }
}

import React from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import UserService from '../../services/User.service';
import AssetSecuritizationService from '../../services/AssetSecuritization.service';
import UtilsService from '../../services/Utils.service';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import ASGTrustReportComponent from './ASGTrustReport.component';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from 'query-string';
import {
    ASSET_SECURITIZATION_QUERY_PARAM_START_DATE,
    ASSET_SECURITIZATION_QUERY_PARAM_END_DATE,
    ASSET_SECURITIZATION_QUERY_PARAM_PAGE,
    ASSET_SECURITIZATION_QUERY_PARAM_REGION,
    ASSET_SECURITIZATION_QUERY_PARAM_DATE_FORMAT,
    ASSET_SECURITIZATION_PAGE_SIZE,
    ASSET_SECURITIZATION_TYPE
} from '../../constants/AssetSecuritization.constants';
import {
    ERROR_TYPE,
    TDS_DATE_FORMAT
} from '../../constants/Misc.constants';
import {
    ACL_DESK_RESEARCH_ASSET_SECURITIZATION
} from '../../constants/Entitlements.constants';
import { ROUTE_PATH_ASSET_SECURITIZATION } from '../../constants/RoutePaths.constants';
import { PARAMS_DATE_FORMAT } from '../../constants/Misc.constants';
import { ASSET_SECURITIZATION_PAGE_TITLE } from '../../constants/PageTitle.constants';

class AssetSecuritizationPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = false;
        this.maxDate = new Date();
        this.state = {
            currentPage: 1,
            startDate: null,
            endDate: null,
            loading: true,
            error: false,
            data: null,
            regionType: ASSET_SECURITIZATION_TYPE.ALL,
            maxStartDate: new Date(),
            minEndDate: null,
            newsLettersExpand: true,
            trustReportsExpand: true
        };
        let { match } = this.props;
        if (match && match.params && match.params.type) {
            this.state.type = match.params.type
            this.state.title = this.getPageTitle(match.params.type);
        }
        this.applyUrlParams(this.props.location);
        this.hasAccess = UserService.hasAccess(ACL_DESK_RESEARCH_ASSET_SECURITIZATION);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleRegionTypeChange = this.handleRegionTypeChange.bind(this);
        this.triggerGetData = this.triggerGetData.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.handleNewsletterExpand = this.handleNewsletterExpand.bind(this);
        this.handleTrustreportsExpand = this.handleTrustreportsExpand.bind(this);
    }
    componentDidMount() {
        document.title = ASSET_SECURITIZATION_PAGE_TITLE;
        if (!this._mounted) {
            this._mounted = true;
            this.triggerGetData();
        }
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname.includes(ROUTE_PATH_ASSET_SECURITIZATION)) {
                if (this._mounted) {
                    this.applyUrlParams(location);
                    this.triggerGetData();
                }
            }
        });
    }
    applyUrlParams(location) {
        let urlQueryParams = queryString.parse(location.search);
        const startDateParam = urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_START_DATE] ? urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_START_DATE] : null; // eslint-disable-line
        const endDateParam = urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_END_DATE] ? urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_END_DATE] : null; // eslint-disable-line
        if (startDateParam) {
            this.state.startDate = new Date(startDateParam); // eslint-disable-line
            this.state.minEndDate = this.state.startDate; // eslint-disable-line
        }
        if (endDateParam) {
            this.state.endDate = new Date(endDateParam); // eslint-disable-line
            this.state.maxStartDate = this.state.endDate; // eslint-disable-line
        }
        this.state.regionType = urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_REGION] ? urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_REGION] : ASSET_SECURITIZATION_TYPE.ALL; // eslint-disable-line
        this.state.currentPage = urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_PAGE] ? parseInt(urlQueryParams[ASSET_SECURITIZATION_QUERY_PARAM_PAGE]) : 1; // eslint-disable-line
    }
    componetWillUnMount() {
        this._mounted = false;
        this.unlisten();
    }
    handleStartDateChange(date) {
        this.setState(
            {
                startDate: date,
                minEndDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    handleEndDateChange(date) {
        this.setState(
            {
                endDate: date,
                maxStartDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    triggerGetData() {
        this.setState(
            {
                loading: true,
                error: false,
                data: null,
            }, this.getData
        )
    }
    getData() {
        const params = {
            page: this.state.currentPage,
            size: ASSET_SECURITIZATION_PAGE_SIZE
        }
        if (this.state.startDate) {
            params.pubDateFrom = moment.utc(this.state.startDate).format(PARAMS_DATE_FORMAT)
        }
        if (this.state.endDate) {
            params.pubDateTo = moment.utc(this.state.endDate).format(PARAMS_DATE_FORMAT)
        }
        AssetSecuritizationService.getASGData(this.state.regionType, params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            data: data,
            loading: false,
            error: false
        })
    }
    getDataError(error) {
        this.setState({
            data: null,
            loading: false,
            error: true
        })
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            }, this.updateUrl
        );
    }
    handleRegionTypeChange(e) {
        this.setState(
            {
                regionType: e.target.value
            }, this.updateUrl
        );
    }
    updateUrl() {
        var params = {};
        params[ASSET_SECURITIZATION_QUERY_PARAM_START_DATE] = this.state.startDate ? moment(this.state.startDate).format(ASSET_SECURITIZATION_QUERY_PARAM_DATE_FORMAT) : null;
        params[ASSET_SECURITIZATION_QUERY_PARAM_END_DATE] = this.state.endDate ? moment(this.state.endDate).format(ASSET_SECURITIZATION_QUERY_PARAM_DATE_FORMAT) : null;
        params[ASSET_SECURITIZATION_QUERY_PARAM_REGION] = this.state.regionType ? this.state.regionType : null;
        if (this.state.currentPage > 1) {
            params[ASSET_SECURITIZATION_QUERY_PARAM_PAGE] = this.state.currentPage;
        }
        this.props.history.push(this.props.location.pathname + UtilsService.buildURLParams(params));
    }
    accessDenied() {
        return !this.hasAccess && <AclAccessDeniedComponent />;
    }
    loading() {
        return this.state.loading && <SpinnerComponent />
    }
    error() {
        return this.state.error && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }
    noNewsletters() {
        return this.state.data && this.state.data.newsLetters && this.state.data.newsLetters.length === 0 && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    noTrustReports() {
        return this.state.data && this.state.data.trustReports && this.state.data.trustReports.length === 0 && <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    handleNewsletterExpand() {
        this.setState(
            {
                newsLettersExpand: !this.state.newsLettersExpand
            }
        )
    }
    handleTrustreportsExpand() {
        this.setState(
            {
                trustReportsExpand: !this.state.trustReportsExpand
            }
        )
    }
    render() {
        return (
            <React.Fragment>
                { this.accessDenied()}
                {
                    this.hasAccess &&
                    <div className="tds-asset-securitization-page">
                        <h1>Asset Securitization</h1>
                        <div className="tds-asset-securitization-page-region-date-filter-container">
                            <div className="tds-asset-securitization-page-region-filter-container">
                                <fieldset>
                                    <div className="form-check form-check-inline header">
                                        <legend>Regions</legend>
                                    </div>
                                    <div className="form-check form-check-inline canada">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="radiogroup1"
                                            id="radio1"
                                            onChange={this.handleRegionTypeChange}
                                            checked={this.state.regionType === ASSET_SECURITIZATION_TYPE.CANADA}
                                            disabled={this.state.loading}
                                            value={ASSET_SECURITIZATION_TYPE.CANADA} />
                                        <label className="form-check-label"
                                            htmlFor="radio1">Canada</label>
                                    </div>
                                    <div className="form-check form-check-inline united-states">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="radiogroup1"
                                            id="radio2"
                                            onChange={this.handleRegionTypeChange}
                                            checked={this.state.regionType === ASSET_SECURITIZATION_TYPE.US}
                                            disabled={this.state.loading}
                                            value={ASSET_SECURITIZATION_TYPE.US} />
                                        <label className="form-check-label"
                                            htmlFor="radio2">United States</label>
                                    </div>
                                    <div className="form-check form-check-inline all">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="radiogroup1"
                                            id="radio3"
                                            onChange={this.handleRegionTypeChange}
                                            checked={this.state.regionType === ASSET_SECURITIZATION_TYPE.ALL}
                                            disabled={this.state.loading}
                                            value={ASSET_SECURITIZATION_TYPE.ALL} />
                                        <label className="form-check-label"
                                            htmlFor="radio3">All</label>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="tds-asset-securitization-page-date-filter-container">
                                <div className="tds-asset-securitization-item item-date-range form-group">
                                    <div className="tds-asset-securitization-label-ctn">
                                        <label className="tds-asset-securitization-item-label"
                                            htmlFor="date-range">Date Range</label>
                                    </div>
                                    <div className="tds-asset-securitization-date-from-ctn">
                                        <DatePicker
                                            ref={(c) => this.startDateCalendar = c}
                                            className="tds-search-item-input form-control text-right"
                                            selected={this.state.startDate}
                                            placeholderText='MM/DD/YYYY'
                                            onChange={this.handleStartDateChange}
                                            maxDate={this.state.maxStartDate}
                                            disabled={this.state.loading}
                                        />
                                        <span className="td-icon td-icon-bookAppointment"></span>
                                    </div>
                                    <div className="tds-asset-securitization-item-date-range-divider">-</div>
                                    <div className="tds-asset-securitization-date-to-ctn">
                                        <DatePicker
                                            ref={(c) => this.endDateCalendar = c}
                                            className="tds-search-item-input form-control text-right"
                                            selected={this.state.endDate}
                                            placeholderText='MM/DD/YYYY'
                                            onChange={this.handleEndDateChange}
                                            maxDate={this.maxDate}
                                            minDate={this.state.minEndDate}
                                            disabled={this.state.loading}
                                        />
                                        <span className="td-icon td-icon-bookAppointment"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.loading()}
                        {this.error()}
                        {!this.state.loading && this.state.data &&
                            <div className="tds-asset-securitization-page-results-container">
                                <div className="tds-asset-securitization-page-results-section-container">
                                    <div className={`tds-asset-securitization-page-results-section-header + ${this.state.newsLettersExpand ? " expand" : ""}`}>
                                        <div className="tds-asset-securitization-page-results-section-header-column expand">
                                            {!this.state.newsLettersExpand && <span className="td-icon td-icon-expand" onClick={this.handleNewsletterExpand}></span>}
                                            {this.state.newsLettersExpand && <span className="td-icon td-icon-collapse" onClick={this.handleNewsletterExpand}></span>}
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-header-column title">
                                            <h2>Monthly Newsletters</h2>
                                        </div>
                                    </div>
                                    {this.noNewsletters()}
                                    {this.state.data && this.state.data.newsLetters && this.state.data.newsLetters.length > 0 && this.state.newsLettersExpand && !this.state.loading &&
                                        <React.Fragment>
                                            <div className="tds-asset-securitization-page-results-section-data">
                                                <div className="tds-asset-securitization-page-results-section-data-row header">
                                                    <div className="tds-asset-securitization-page-results-section-data-column expand">
                                                        &nbsp;
                                                    </div>
                                                    <div className="tds-asset-securitization-page-results-section-data-column title">
                                                        Title
                                                    </div>
                                                    <div className="tds-asset-securitization-page-results-section-data-column region">
                                                        Region
                                                    </div>
                                                    <div className="tds-asset-securitization-page-results-section-data-column date">
                                                        Date
                                                    </div>
                                                    <div className="tds-asset-securitization-page-results-section-data-column action">
                                                        Action
                                                    </div>
                                                </div>
                                                {this.state.data.newsLetters.map((item, index) => {
                                                    return (
                                                        <div className="tds-asset-securitization-page-results-section-data-row" key={index}>
                                                            <div className="tds-asset-securitization-page-results-section-data-column expand">
                                                                &nbsp;
                                                            </div>
                                                            <div className="tds-asset-securitization-page-results-section-data-column title">
                                                                {!item.title.match(/untitled/i) &&
                                                                    <React.Fragment>{item.title}</React.Fragment>}
                                                                {item.title.match(/untitled/i) &&
                                                                    <React.Fragment>{item.documentType} {item.region}</React.Fragment>}
                                                            </div>
                                                            <div className="tds-asset-securitization-page-results-section-data-column region">
                                                                {item.region === 'US' ? 'United States' : item.region}
                                                            </div>
                                                            <div className="tds-asset-securitization-page-results-section-data-column date">
                                                                <FormatedDateComponent
                                                                    date={item.date}
                                                                    format={TDS_DATE_FORMAT}
                                                                />
                                                            </div>
                                                            <div className="tds-asset-securitization-page-results-section-data-column action">
                                                                { item.showHtml &&
                                                                    <a
                                                                        href={item.secureHtmlLink || item.htmlLink}
                                                                        title="View"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            className="tds-view-icon"
                                                                            alt={'View'}
                                                                            src="/assets/img/view-icon.svg" />
                                                                    </a>
                                                                }
                                                                <a
                                                                    href={item.securePdfLink || item.pdfLink}
                                                                    title="View PDF"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <span className="td-icon td-icon-insights"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="tds-asset-securitization-page-results-section-container">
                                    <div className={`tds-asset-securitization-page-results-section-header + ${this.state.trustReportsExpand ? " expand" : ""}`}>
                                        <div className="tds-asset-securitization-page-results-section-header-column expand">
                                            {!this.state.trustReportsExpand && <span className="td-icon td-icon-expand" onClick={this.handleTrustreportsExpand}></span>}
                                            {this.state.trustReportsExpand && <span className="td-icon td-icon-collapse" onClick={this.handleTrustreportsExpand}></span>}
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-header-column title">
                                            <h2>Trust Reports</h2>
                                        </div>
                                    </div>
                                    {this.noTrustReports()}
                                    {this.state.data && this.state.data.trustReports && this.state.data.trustReports.length > 0 && this.state.trustReportsExpand && !this.state.loading &&
                                        <div className="tds-asset-securitization-page-results-section-data">
                                            <div className="tds-asset-securitization-page-results-section-data-row header">
                                                <div className="tds-asset-securitization-page-results-section-data-column expand">
                                                    &nbsp;
                                                </div>
                                                <div className="tds-asset-securitization-page-results-section-data-column title">
                                                    Title
                                                </div>
                                                <div className="tds-asset-securitization-page-results-section-data-column region">
                                                    Region
                                                </div>
                                                <div className="tds-asset-securitization-page-results-section-data-column date">
                                                    Date
                                                </div>
                                                <div className="tds-asset-securitization-page-results-section-data-column action">
                                                    Action
                                                </div>
                                            </div>
                                            {
                                                this.state.data.trustReports.map((item, index) => {
                                                    return (
                                                        <ASGTrustReportComponent
                                                            data={item}
                                                            key={index}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(AssetSecuritizationPageComponent);
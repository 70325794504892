import React from 'react';
import SearchResultsItemComponent from './SearchResultsItem.component';
import {
    SEARCH_RESULTS_ORDER_BY_DATE,
    SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE,
    SEARCH_RESULTS_ORDER_BY_ANALYST,
    SEARCH_RESULTS_ORDER_BY_TITLE,
    SEARCH_RESULTS_SORTBY
} from '../../constants/SearchResults.constants';
import { FIRM_NAME_STRATEGY } from '../../constants/Misc.constants';
import { isNotEmptyArray } from '../../utils/valueUtils';

export default class SearchResultsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderBy: this.props.orderBy
        };
        this.createItems();
        this.changeSort = this.changeSort.bind(this);
    }
    changeSort(event) {
        this.setState({ orderBy: event.target.value });
        if (this.props.changeSort) {
            this.props.changeSort(event.target.value);
        }
    }
    createItems() {
        if (this.props.results) {
            this.items = [];
            for (var i = 0; i < this.props.results.length; i++) {
                this.items.push(
                    <SearchResultsItemComponent
                        key={'tds-search-results-item' + i}
                        item={this.props.results[i]}
                        dateFormat={this.props.dateFormat}
                    />
                )
            }
        }
    }
    isStrategyResultSet() {
        return isNotEmptyArray(this.props.results) && this.props.results[0].firmName === FIRM_NAME_STRATEGY
    }
    render() {
        return (
            <React.Fragment>
                { (!this.props.hideTitle || !this.props.hideSort) &&
                    <div className="tds-search-results-title-ctn">
                        {!this.props.hideTitle &&
                            <h1 className="tds-search-results-title">Search Results</h1>
                        }
                        {!this.props.hideSort &&
                            <div className="tds-search-results-sort-ctn">
                                <span>{this.state.sort}</span>
                                <select
                                    className="tds-search-results-sort form-control"
                                    onChange={this.changeSort}
                                    value={this.state.orderBy}
                                >
                                    <option value={SEARCH_RESULTS_SORTBY}>Sort By</option>
                                    <option value={SEARCH_RESULTS_ORDER_BY_DATE}>Date</option>
                                    <option value={SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE}>Document Type</option>
                                    {
                                        !this.props.hideOrderByAnalystOption &&
                                        <option value={SEARCH_RESULTS_ORDER_BY_ANALYST}>Analyst</option>
                                    }
                                    <option value={SEARCH_RESULTS_ORDER_BY_TITLE}>Title</option>
                                </select>
                            </div>
                        }
                    </div>
                }
                <div className="tds-search-table">
                    <div className="tds-search-result-row header">
                        { this.isStrategyResultSet() &&
                            <div className="tds-search-results-column expand">
                                &nbsp;
                            </div>
                        }                        
                        <div className="tds-search-results-column date">
                            Date
                        </div>
                        <div className="tds-search-results-column title">
                            Title
                        </div>
                        <div className="tds-search-results-column analyst">
                            Analyst
                        </div>
                        <div className="tds-search-results-column document-type">
                            Document Type
                        </div>
                        <div className="tds-search-results-column action">
                            Action
                        </div>
                    </div>
                    {this.items}
                </div>
            </React.Fragment>
        )
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_EQUITIES_COMPANY_DETAILS_PAGE } from '../../../constants/LinkPaths.constants';
import { FORWARD_SLASH } from '../../../constants/Misc.constants';
import {isNotEmptyArray} from '../../../utils/valueUtils';

export default class AnalystProfileCoverageListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.createItems();
    }
    createItems() {
        if (isNotEmptyArray(this.props.items)) {
            this.firstColumnItems = [];
            this.secondColumnItems = [];
            const sizeSplitInTwo = Math.round( this.props.items.length/2 );
            
            for (let i = 0; i < sizeSplitInTwo; i++) {
                this.firstColumnItems.push(
                    <li key={this.props.items[i].name + i}>
                        <Link to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + this.props.items[i].id}>
                            {this.props.items[i].name} ({this.props.items[i].symbol})
                        </Link>
                    </li>
                );
            }
            for (let i = sizeSplitInTwo; i < this.props.items.length; i++) {
                this.secondColumnItems.push(
                    <li key={this.props.items[i].name + i}>
                        <Link to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + this.props.items[i].id}>
                            {this.props.items[i].name} ({this.props.items[i].symbol})
                        </Link>
                    </li>
                );
            }
        }
    }
    render() {        
        return (
            <React.Fragment>
            { isNotEmptyArray(this.props.items) &&
                <div className="tds-three-column-container tds-analyst-profile-coverage-container">
                    <div className="tds-analyst-profile-coverage-list">
                        <h3>Coverage List</h3>
                        <div className="coverage-list">
                            <div className="column1">
                                <ul className="tds-analyst-profile-list">
                                    {this.firstColumnItems}
                                </ul>
                            </div>
                            <div className="column2">
                                <ul className="tds-analyst-profile-list">
                                    {this.secondColumnItems}
                                </ul>
                            </div>
                        </div>                        
                    </div>
                </div>
            }            
            </React.Fragment>
        )
    }
}

import React from "react";
import { withRouter } from "react-router-dom";
import UtilsService from "../../../services/Utils.service";
import ResponsiveBackgroundImageComponent
    from '../../misc/responsive-background-image/ResponsiveBackgroundImage.component';
import FormatedDateComponent from "../../misc/formated-date/FormatedDate.component";
import {
    FIRM_NAME_COWEN,
    TDS_DATE_FORMAT
} from '../../../constants/Misc.constants';
import SecureLinkService from "../../../services/SecureLink.service";
import InnerHtmlComponent from "../../misc/inner-html/InnerHtml.component";

class MainCardsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultImage: UtilsService.getRandomDefaultImage(),
            middleCardDefaultImage : [UtilsService.getRandomDefaultImage(), UtilsService.getRandomDefaultImage()]
        };
        this.handleMainCardClick = this.handleMainCardClick.bind(this);
    }
    handleMainCardClick = (item) => e => {
        if(item.pdfLink) {
            item.pdfLink = SecureLinkService.buildSecureLink(item.pdfLink);
        }
        if (item.firmName === FIRM_NAME_COWEN) {
            window.open(item.pdfLink || item.htmlLink || item.link);
        }
    };
    render() {
        return (
            <React.Fragment>
                {this.props.mainCardData && this.props.mainCardData.mainCard && (
                    <div
                        className="top-left-main-promo-container-column"
                    >
                        <ResponsiveBackgroundImageComponent
                            className={'tds-card-bottom-left-container'}
                            default={this.props.mainCardData.mainCard.image || this.state.defaultImage }
                            tablet1={this.props.mainCardData.mainCard.tablet1Image || this.state.defaultImage }
                            tablet2={this.props.mainCardData.mainCard.tablet2Image || this.state.defaultImage }
                            mobile={this.props.mainCardData.mainCard.mobileImage || this.state.defaultImage }
                            ariaLabel={this.props.mainCardData.mainCard.ariaLabel}
                            title={this.props.mainCardData.mainCard.title}
                            onClick={this.handleMainCardClick(this.props.mainCardData.mainCard)}
                        >
                            <div className="tds-card-bottom-left">
                                <h2>{this.props.mainCardData.mainCard.title}</h2>
                                <span className="tds-card-bottom-left-byline">
                                    {this.props.mainCardData.mainCard.byline}
                                </span>
                                <span className="tds-card-bottom-left-date">
                                    <FormatedDateComponent
                                        date={this.props.mainCardData.mainCard.date}
                                        format={TDS_DATE_FORMAT}
                                    />
                                </span>
                                {
                                    this.props.mainCardData.mainCard.description &&
                                    <React.Fragment>
                                        <span className="tds-card-bottom-left-description">
                                            <InnerHtmlComponent data={this.props.mainCardData.mainCard.description} />
                                        </span>
                                        <span className="tds-card-bottom-left-read-full-article">Read Full Article</span>
                                    </React.Fragment>
                                }

                            </div>
                        </ResponsiveBackgroundImageComponent>
                    </div>
                )}
                {this.props.mainCardData && this.props.mainCardData.middleCards && (
                    <div className="tds-top-middle-promo-container-column">
                        {this.props.mainCardData.middleCards.map(
                            (middleCardItem, index) => {
                                return (
                                    <ResponsiveBackgroundImageComponent
                                        className={'tds-top-middle-promo-container'}
                                        key={"middle-card-" + index}
                                        default={middleCardItem.image || this.state.middleCardDefaultImage[index]}
                                        tablet1={middleCardItem.tablet1Image || this.state.middleCardDefaultImage[index]}
                                        tablet2={middleCardItem.tablet2Image || this.state.middleCardDefaultImage[index]}
                                        mobile={middleCardItem.mobileImage || this.state.middleCardDefaultImage[index]}
                                        ariaLabel={middleCardItem.ariaLabel}
                                        title={middleCardItem.title}
                                        onClick={this.handleMainCardClick(middleCardItem)}
                                    >
                                        <div className="tds-card-middle">
                                            <h2>{middleCardItem.title}</h2>
                                            <span className="tds-card-middle-byline">
                                                {middleCardItem.byline}
                                            </span>
                                            <span className="tds-card-middle-date">
                                                <FormatedDateComponent
                                                    date={middleCardItem.date}
                                                    format={TDS_DATE_FORMAT}
                                                />

                                            </span>
                                        </div>
                                    </ResponsiveBackgroundImageComponent>
                                );
                            }
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default withRouter(MainCardsComponent);
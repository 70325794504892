import React from 'react';
import { Link } from "react-router-dom";
import { Redirect  } from 'react-router-dom';
import {
    TDS_TOP_NAV_DROPDOWN_LINK_MOBILE,
    TDS_DROPDOWN_CONTENT_MOBILE,
    TDS_DROPDOWN_ACTIVE,
    LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL
} from '../../../constants/HeaderNav.constants';
import UserService from '../../../services/User.service';
import { createBrowserHistory } from "history";
import { LOG_LEVEL, logger } from '../../../system/Logger';

export default class HeaderNavDropDownMobileSubHeadingComponent extends React.Component {
    dropDownClassName = TDS_TOP_NAV_DROPDOWN_LINK_MOBILE;
    dropDownContentClassName = TDS_DROPDOWN_CONTENT_MOBILE;
    history = createBrowserHistory()
    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavDropDownMobileComponent", this.props);
        this.state = {
            open: false,
            redirect: false,
            target: this.history.location.pathname
        }
        this.dropDownItem = this.props.dropDownData;
        this.hover = false || this.props.hover;
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    handleOnClick(event) {
        event.preventDefault();
        if (this.state.open) {
            this.setState({ open: false });
        } else {
            this.setState({ open: true });
        }
    }
    closeMenu() {
        this.setState({ open: false });
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.target} />
        }
    }
    navigate(target, hasAccess, modalType) {
        if (this.props.closeNavPanel) {
            this.props.closeNavPanel();
        }
        if (UserService.hasAccess(hasAccess)) {
            this.setState(
                { target: target },
                this.setState(
                    {
                        redirect: true
                    }
                )
            );
        } else {
            this.props.showModal(modalType);
        }
    }
    navigateExternal(target) {
        window.open(target);
    }
    handleLinkClick(e) {
        e.preventDefault();
    }
    render() {
        if (this.props.dropDownData) {
            return (
                <div className="tds-mobile-navbar-item">
                    {this.renderRedirect()}
                    <a href="/"
                        title={this.dropDownItem.text}
                        className={this.dropDownClassName + `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`}
                        onClick={this.handleOnClick}
                    >
                        {this.dropDownItem.text}
                        {
                            !this.state.open &&
                            <span className="td-icon td-icon-downCaret icon-mobile"></span>
                        }
                        {
                            this.state.open &&
                            <span className="td-icon td-icon-upCaret icon-mobile"></span>
                        }

                    </a>
                    <ul
                        className={this.dropDownContentClassName + ' sub-heading ' + `${this.state.open ? TDS_DROPDOWN_ACTIVE : ""}`}>
                        {
                            this.props.dropDownData.items.map((itemHeading, index) => (
                                <React.Fragment
                                    key={this.dropDownItem.text + index + '_' + itemHeading.subHeading}
                                >
                                    <li className="sub-heading-title">{itemHeading.subHeading}</li>
                                    { itemHeading.items.map((item, index) => (
                                        <React.Fragment
                                            key={this.dropDownItem.text + index + '_' + item.text}
                                        >
                                            {item.destination === LINK_DESTINATION_EXTERNAL &&
                                                <li>
                                                    <a
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="menu-item"
                                                        title={item.text}
                                                        onClick={this.navigateExternal.bind(this, item.link)}
                                                    >
                                                        {item.text}
                                                    </a>
                                                </li>
                                            }
                                            {item.destination === LINK_DESTINATION_INTERNAL &&
                                                <li
                                                    onClick={this.navigate.bind(this, item.link, item.hasAccess, item.modalType)}
                                                >
                                                    <Link
                                                        to={item.link}
                                                        className="menu-item"
                                                        title={item.text}
                                                        onClick={this.handleLinkClick}
                                                    >
                                                        {item.text}
                                                    </Link>
                                                </li>
                                            }
                                        </React.Fragment>
                                    ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </ul>
                </div>
            )
        } else {
            return null;
        }
    }
}

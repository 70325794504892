import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { LINK_EQUITIES_CA_OVERVIEW_PAGE } from "../../constants/LinkPaths.constants";

class EqOverviewPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.renderRedirect = this.renderRedirect.bind(this);
  }
  renderRedirect = () => {
    return <Redirect to={LINK_EQUITIES_CA_OVERVIEW_PAGE} />
  }
  render() {
    
    return (
     <section>{this.renderRedirect()}</section> 
    );
  }
}
export default withRouter(EqOverviewPageComponent);
import React from 'react';
import SideBarComponent from '../side-bar/SideBar.component';
import queryString from 'query-string';
import CoverageCompaniesComponent from './CoverageCompanies.component';
import CoverageActionListComponenent from './CoverageActionList.component';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    COVERAGE_PAGE_TABS
} from '../../constants/CoveragePage.constants';
import {
    CSS_DISPLAY_BLOCK,
    CSS_DISPLAY_NONE
} from '../../constants/Misc.constants';
import {
    ACL_EQUITIES_COVERAGE
} from '../../constants/Entitlements.constants';
import { COVERAGE_PAGE_TITLE } from '../../constants/PageTitle.constants';
import { Link, withRouter } from 'react-router-dom';
import { ROUTE_PATH_EQUITIES_COVERAGE_PAGE } from '../../constants/RoutePaths.constants';
import { LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE } from '../../constants/LinkPaths.constants';
import HighlightBlockComponent from './HighlightBlock.component';

export class CoveragePageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: COVERAGE_PAGE_TABS.COMPANIES,
            importantDisclosures: (props.location?.state?.importantDisclosures === true) ? true : false
        };

        //remove importantDisclosures state from history location
        if(props.location?.state?.importantDisclosures === true) {
            props.history.replace();
        }

        const urlQueryParams = queryString.parse(this.props.location.search);
        if (urlQueryParams.tab && [COVERAGE_PAGE_TABS.COMPANIES, COVERAGE_PAGE_TABS.ACTION_LIST].includes(urlQueryParams.tab)) {
            this.state.currentTab = urlQueryParams.tab;
        }
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_COVERAGE);
        this.handleTabClick = this.handleTabClick.bind(this);
    }
    componentDidMount() {
        document.title = COVERAGE_PAGE_TITLE;
        this.listenURLChange();
    }
    handleTabClick(event) {
        if (event && event.target) {
            this.setState(
                {
                    currentTab: event.target.value
                }
            );
        }
    }
    listenURLChange() {
        this.props.history.listen((location, action) => {
            if (location.pathname.includes(ROUTE_PATH_EQUITIES_COVERAGE_PAGE)) {
                const urlQueryParams = queryString.parse(location.search);                
                if(urlQueryParams.tab && [COVERAGE_PAGE_TABS.COMPANIES, COVERAGE_PAGE_TABS.ACTION_LIST].includes(urlQueryParams.tab)) {
                    this.setState({
                        currentTab: urlQueryParams.tab
                    });
                }
            }
        });
    }
    render() {
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-container tds-coverage-page">
                        <h1>Coverage Universe</h1>
                        <div className="highlight-block">
                            <HighlightBlockComponent/>
                        </div>
                        <div className="tds-coverage-page-two-column-container"> 
                            <div className="main-column">                      
                                <div className="tds-coverage-page-tab-container">
                                    <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                                        <div className="td-multi-single-select-buttons-group">
                                            <div className="td-multi-single-select-button-wrapper">
                                                <input
                                                    type="radio"
                                                    onChange={this.handleTabClick}
                                                    value={COVERAGE_PAGE_TABS.COMPANIES}
                                                    checked={this.state.currentTab === COVERAGE_PAGE_TABS.COMPANIES}
                                                    name="selectButtonSegmented3Group"
                                                    id="selectButtonSegmented3-1" />
                                                <label htmlFor="selectButtonSegmented3-1" title="Companies">Companies</label>
                                            </div>
                                            <div className="td-multi-single-select-button-wrapper">
                                                <input
                                                    type="radio"
                                                    onChange={this.handleTabClick}
                                                    value={COVERAGE_PAGE_TABS.ACTION_LIST}
                                                    checked={this.state.currentTab === COVERAGE_PAGE_TABS.ACTION_LIST}
                                                    name="selectButtonSegmented3Group"
                                                    id="selectButtonSegmented3-2" />
                                                <label htmlFor="selectButtonSegmented3-2"
                                                    className="action-list" title="Action List - Our Best Ideas">Action List - Our Best Ideas</label>
                                            </div>                                        
                                        </div>
                                    </fieldset>                                                                                                   
                                </div>
                            </div>
                            <div className="side-column">
                                <Link className="tds-coverage-page-advanced-filter-button" to={LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE}>
                                    <button className="btn btn-block td-btn-secondary-clear"
                                        title="Advanced Filter"
                                    >
                                        <span className="tds-filter-text">Advanced Filter</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="tds-three-column-container">
                            <div className="tds-coverage-page-tab-content"
                                style={
                                    {
                                        'display': this.state.currentTab === COVERAGE_PAGE_TABS.COMPANIES ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                    }
                                }
                            >
                                <CoverageCompaniesComponent />
                            </div>
                            <div className="tds-coverage-page-tab-content"
                                style={
                                    {
                                        'display': this.state.currentTab === COVERAGE_PAGE_TABS.ACTION_LIST ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                    }
                                }
                            >
                                <CoverageActionListComponenent />
                            </div>                                                       
                        </div>          
                        <SideBarComponent importantDisclosures={this.state.importantDisclosures}/>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(CoveragePageComponent);
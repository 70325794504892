import {
    INPUT_BORDER_COLOR, WHITE_COLOR, TD_PRIMARY_GREEN
} from './Color.constants';

const NONE = 'none';

export const ReactSelectDesktopStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        borderColor: INPUT_BORDER_COLOR,
        minHeight: '42px',
        paddingLeft: '7px',
        outline: NONE,
        boxShadow: NONE,
        '&:hover': {
            minHeight: '42px',
            outline: NONE,
            boxShadow: NONE
        }
    }),

    placeholder: (provided, state) => ({
        fontWeight: state.selectProps.fontWeight ? state.selectProps.fontWeight : '',
      }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const fontWeight = state.selectProps.fontWeight ? state.selectProps.fontWeight : '';
        return { ...provided, opacity, transition, fontWeight };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,

        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: WHITE_COLOR,
        backgroundColor: TD_PRIMARY_GREEN,
        borderRadius: '0px'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: WHITE_COLOR,
        backgroundColor: TD_PRIMARY_GREEN,
        borderRadius: '0px',
        ':hover': {
            backgroundColor: TD_PRIMARY_GREEN,
            color: 'red',
            cursor: 'pointer'
        },
    }),
}

export const ReactSelectDesktopStylesError = {
    option: (provided, state) => ({
        ...provided,
        color: state.selectProps.fontColor,
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        borderColor: INPUT_BORDER_COLOR,
        minHeight: '42px',
        paddingLeft: '7px',
        outline: NONE,
        boxShadow: NONE,
        borderBottom: '3px solid #AE1100',
        '&:hover': {
            minHeight: '42px',
            outline: NONE,
            boxShadow: NONE
        }
    }),

    placeholder: (provided, state) => ({
        fontWeight: state.selectProps.fontWeight ? state.selectProps.fontWeight : '',
        color: state.selectProps.fontColor,
      }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,

        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: WHITE_COLOR,
        backgroundColor: TD_PRIMARY_GREEN,
        borderRadius: '0px'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: WHITE_COLOR,
        backgroundColor: TD_PRIMARY_GREEN,
        borderRadius: '0px',
        ':hover': {
            backgroundColor: TD_PRIMARY_GREEN,
            color: 'red',
            cursor: 'pointer'
        },
    }),
}

import React from 'react';
import { withRouter } from "react-router-dom";
import UtilsService from "../../../services/Utils.service";
import ResponsiveBackgroundImageComponent
    from '../../misc/responsive-background-image/ResponsiveBackgroundImage.component';
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';

class RegionCardItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultImage: UtilsService.getRandomDefaultImage()
        };
        this.handleMainCardClick = this.handleMainCardClick.bind(this);
    }
    handleMainCardClick = (link) => e => {
        this.props.history.push(link);
    };
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data &&
                    <div className={'tds-regions-promo-card-container-column ' + (this.props.type || '') }>
                        <div className="tds-regions-promo-card-container">
                            <ResponsiveBackgroundImageComponent
                                className={'tds-regions-card'}
                                default={this.props.data.image || this.state.defaultImage}
                                tablet1={this.props.data.tablet1Image || this.state.defaultImage}
                                tablet2={this.props.data.tablet2Image || this.state.defaultImage}
                                mobile={this.props.data.mobileImage || this.state.defaultImage}
                                ariaLabel={this.props.data.title}
                                title={this.props.data.title}
                                onClick={this.handleMainCardClick(LINK_PUBLICATION_PAGE + this.props.data.firmName + FORWARD_SLASH + this.props.data.contentId)}
                            >
                                <div className="tds-regions-card-text-container">
                                    <h2>{this.props.data.title}</h2>
                                    {this.props.data.primaryAnalyst &&
                                        <span className="tds-regions-card-byline">
                                            {this.props.data.primaryAnalyst.firstName} {this.props.data.primaryAnalyst.lastName}
                                        </span>
                                    }
                                    <span className="tds-regions-card-date">
                                        <FormatedDateComponent
                                            date={this.props.data.date}
                                            format={TDS_DATE_FORMAT}
                                        />
                                    </span>
                                </div>
                            </ResponsiveBackgroundImageComponent>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(RegionCardItemComponent);
import React from 'react';

export default class SpinnerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                { !this.props.noContainer &&
                    <React.Fragment>
                        <div className="tds-container" style={
                            {
                                'width': this.props.outerWidth
                            }
                        }>
                            <div className="tds-spinner" style={
                                {
                                    'minHeight': this.props.minHeight,
                                    'marginTop': this.props.marginTop,
                                    'paddingTop': this.props.paddingTop,
                                    'textAlign': this.props.textAlign,
                                    'minWidth' : this.props.minWidth
                                }
                            }>
                                <img src="/assets/img/loading-spinner-small.gif"
                                    alt="Loading...." />
                            </div>
                        </div>
                        <div className="tds-clear"></div>
                    </React.Fragment>
                }
                { this.props.noContainer &&
                    <React.Fragment>

                        <div className="tds-spinner" style={
                            {
                                'minHeight': this.props.minHeight,
                                'marginTop': this.props.marginTop,
                                'textAlign': this.props.textAlign,
                                'minWidth' : this.props.minWidth,
                                'width': this.props.outerWidth
                            }
                        }>
                            <img src="/assets/img/loading-spinner-small.gif"
                                alt="Loading...." />
                        </div>

                        <div className="tds-clear"></div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

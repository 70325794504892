import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { COOKIE_CONSENT_COOKIE_EXPIRATION, COOKIE_CONSENT_COOKIE_NAME } from "../../constants/GoogleAnalytics.constants";
import { LINK_TDSECURITIES_PRIVACY } from "../../constants/RoutePaths.constants";
import { enableDisableGA } from "../../services/GoogleAnalytics.service";

const Preferences = () => {
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_COOKIE_NAME]);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const cookieConsent = cookies[COOKIE_CONSENT_COOKIE_NAME] === "true" ? true : false
    setConsent(cookieConsent);
  }, [cookies]);

  const handleConsentChange = () => {
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + COOKIE_CONSENT_COOKIE_EXPIRATION);

    const newConsentValue = cookies[COOKIE_CONSENT_COOKIE_NAME] === "true" ? false : true;

    setCookie(
      COOKIE_CONSENT_COOKIE_NAME,
      `${newConsentValue}`,
      { path: "/", expires: expiryDate }
    );

    enableDisableGA();

    if (newConsentValue === false) {
      window.location.reload();
    }
  };
  
  return (
    <>
      <div className="tds-homepage-container preferences">
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h1>Ad Choices & Personalization</h1>
          <p>Through the use of cookies and other technologies, we can enhance your experience with personalized content and offers that may be relevant to you. This technology also enables our third-party advertising partners to assist us in presenting ads specific to your interests across the internet. We make this tool available to provide you with control over the use of your information.</p>
          <div className="tds-sn-tabs-container">
            <div className="tds-container">
              <nav>
                <ol>
                  {/* <li className="">
                    <button title="Add Choices">Ad Choices</button>
                  </li> */}
                  <li className="active">
                    <button title="Personalization">
                      Personalization
                    </button>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="tds-divider-space-10">&nbsp;</div>
          <p>Information is collected in different ways.</p>
          <div className="rte">
            <ul>
              <li>
                <strong>Personalization of TD Securities Content</strong>
                <br />
                We may show content and advertisements based on your current or past use of our TD Securities branded sites. If you have a product with us and log in, we also personalize your experience based on your relationship with us. This can occur even after you log out.
                <p></p>
                <div className="td-form-stacked">
                  <input
                    id="check_per"
                    name="check"
                    type="checkbox"
                    value="ens_perCheck"
                    aria-hidden="true"
                    className={consent ? "" : "checked"}
                  />
                  <label
                    onClick={() => handleConsentChange()}
                    className="label-checked"
                  >
                    <span
                      className="td-label-content-wrapper"
                      aria-checked="false"
                      role="checkbox"
                    >
                      <span className="td-label-check"></span>
                      <span className="td-label-content">
                        I do not want personalized content and offers on TD Securities-branded websites.
                      </span>
                    </span>
                  </label>
                </div>
              </li>

              <li>
                <strong>
                Essential cookies
                </strong>
                <br />
                Some cookies are required for various features of our websites. They also help ensure the security of your browsing experience. You cannot opt-out of the use of essential cookies, unless you disable your browser from accepting them.
                <p></p>
                If you choose to disable the cookies feature on your browser, some of our websites might not work properly and your experience may be less than optimal.
              </li>
            </ul>

            <div className="td-row">
              <div className="td-col-xs-12">
                <hr className="td-thick-divider-line" />
              </div>
            </div>

            <p>
              To learn more about what information we collect and how we use it, please see our&nbsp; 
              <a href={LINK_TDSECURITIES_PRIVACY}>
                Privacy and Security Policies
              </a>
              .<br />
            </p>

            <div className="tds-divider-space-10">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
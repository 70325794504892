const countryISOCodes = [
  {value: "AD", label: "Andorra", width: "auto", fontWeight: "bold"},
  {value: "AE", label: "United Arab Emirates", width: "auto", fontWeight: "bold"},
  {value: "AF", label: "Afghanistan", width: "auto", fontWeight: "bold"},
  {value: "AG", label: "Antigua and Barbuda", width: "auto", fontWeight: "bold"},
  {value: "AI", label: "Anguilla", width: "auto", fontWeight: "bold"},
  {value: "AL", label: "Albania", width: "auto", fontWeight: "bold"},
  {value: "AM", label: "Armenia", width: "auto", fontWeight: "bold"},
  {value: "AO", label: "Angola", width: "auto", fontWeight: "bold"},
  {value: "AQ", label: "Antarctica", width: "auto", fontWeight: "bold"},
  {value: "AR", label: "Argentina", width: "auto", fontWeight: "bold"},
  {value: "AS", label: "American Samoa", width: "auto", fontWeight: "bold"},
  {value: "AT", label: "Austria", width: "auto", fontWeight: "bold"},
  {value: "AU", label: "Australia", width: "auto", fontWeight: "bold"},
  {value: "AW", label: "Aruba", width: "auto", fontWeight: "bold"},
  {value: "AX", label: "Åland Islands", width: "auto", fontWeight: "bold"},
  {value: "AZ", label: "Azerbaijan", width: "auto", fontWeight: "bold"},
  {value: "BA", label: "Bosnia and Herzegovina", width: "auto", fontWeight: "bold"},
  {value: "BB", label: "Barbados", width: "auto", fontWeight: "bold"},
  {value: "BD", label: "Bangladesh", width: "auto", fontWeight: "bold"},
  {value: "BE", label: "Belgium", width: "auto", fontWeight: "bold"},
  {value: "BF", label: "Burkina Faso", width: "auto", fontWeight: "bold"},
  {value: "BG", label: "Bulgaria", width: "auto", fontWeight: "bold"},
  {value: "BH", label: "Bahrain", width: "auto", fontWeight: "bold"},
  {value: "BI", label: "Burundi", width: "auto", fontWeight: "bold"},
  {value: "BJ", label: "Benin", width: "auto", fontWeight: "bold"},
  {value: "BL", label: "Saint Barthélemy", width: "auto", fontWeight: "bold"},
  {value: "BM", label: "Bermuda", width: "auto", fontWeight: "bold"},
  {value: "BN", label: "Brunei Darussalam", width: "auto", fontWeight: "bold"},
  {value: "BO", label: "Bolivia", width: "auto", fontWeight: "bold"},
  {value: "BQ", label: "Caribbean Netherlands ", width: "auto", fontWeight: "bold"},
  {value: "BR", label: "Brazil", width: "auto", fontWeight: "bold"},
  {value: "BS", label: "Bahamas", width: "auto", fontWeight: "bold"},
  {value: "BT", label: "Bhutan", width: "auto", fontWeight: "bold"},
  {value: "BV", label: "Bouvet Island", width: "auto", fontWeight: "bold"},
  {value: "BW", label: "Botswana", width: "auto", fontWeight: "bold"},
  {value: "BY", label: "Belarus", width: "auto", fontWeight: "bold"},
  {value: "BZ", label: "Belize", width: "auto", fontWeight: "bold"},
  {value: "CA", label: "Canada", width: "auto", fontWeight: "bold"},
  {value: "CC", label: "Cocos (Keeling) Islands", width: "auto", fontWeight: "bold"},
  {value: "CD", label: "Congo, Democratic Republic of", width: "auto", fontWeight: "bold"},
  {value: "CF", label: "Central African Republic", width: "auto", fontWeight: "bold"},
  {value: "CG", label: "Congo", width: "auto", fontWeight: "bold"},
  {value: "CH", label: "Switzerland", width: "auto", fontWeight: "bold"},
  {value: "CI", label: "Côte d'Ivoire", width: "auto", fontWeight: "bold"},
  {value: "CK", label: "Cook Islands", width: "auto", fontWeight: "bold"},
  {value: "CL", label: "Chile", width: "auto", fontWeight: "bold"},
  {value: "CM", label: "Cameroon", width: "auto", fontWeight: "bold"},
  {value: "CN", label: "China", width: "auto", fontWeight: "bold"},
  {value: "CO", label: "Colombia", width: "auto", fontWeight: "bold"},
  {value: "CR", label: "Costa Rica", width: "auto", fontWeight: "bold"},
  {value: "CU", label: "Cuba", width: "auto", fontWeight: "bold"},
  {value: "CV", label: "Cape Verde", width: "auto", fontWeight: "bold"},
  {value: "CW", label: "Curaçao", width: "auto", fontWeight: "bold"},
  {value: "CX", label: "Christmas Island", width: "auto", fontWeight: "bold"},
  {value: "CY", label: "Cyprus", width: "auto", fontWeight: "bold"},
  {value: "CZ", label: "Czech Republic", width: "auto", fontWeight: "bold"},
  {value: "DE", label: "Germany", width: "auto", fontWeight: "bold"},
  {value: "DJ", label: "Djibouti", width: "auto", fontWeight: "bold"},
  {value: "DK", label: "Denmark", width: "auto", fontWeight: "bold"},
  {value: "DM", label: "Dominica", width: "auto", fontWeight: "bold"},
  {value: "DO", label: "Dominican Republic", width: "auto", fontWeight: "bold"},
  {value: "DZ", label: "Algeria", width: "auto", fontWeight: "bold"},
  {value: "EC", label: "Ecuador", width: "auto", fontWeight: "bold"},
  {value: "EE", label: "Estonia", width: "auto", fontWeight: "bold"},
  {value: "EG", label: "Egypt", width: "auto", fontWeight: "bold"},
  {value: "EH", label: "Western Sahara", width: "auto", fontWeight: "bold"},
  {value: "ER", label: "Eritrea", width: "auto", fontWeight: "bold"},
  {value: "ES", label: "Spain", width: "auto", fontWeight: "bold"},
  {value: "ET", label: "Ethiopia", width: "auto", fontWeight: "bold"},
  {value: "FI", label: "Finland", width: "auto", fontWeight: "bold"},
  {value: "FJ", label: "Fiji", width: "auto", fontWeight: "bold"},
  {value: "FK", label: "Falkland Islands", width: "auto", fontWeight: "bold"},
  {value: "FM", label: "Micronesia, Federated States of", width: "auto", fontWeight: "bold"},
  {value: "FO", label: "Faroe Islands", width: "auto", fontWeight: "bold"},
  {value: "FR", label: "France", width: "auto", fontWeight: "bold"},
  {value: "GA", label: "Gabon", width: "auto", fontWeight: "bold"},
  {value: "GB", label: "United Kingdom", width: "auto", fontWeight: "bold"},
  {value: "GD", label: "Grenada", width: "auto", fontWeight: "bold"},
  {value: "GE", label: "Georgia", width: "auto", fontWeight: "bold"},
  {value: "GF", label: "French Guiana", width: "auto", fontWeight: "bold"},
  {value: "GG", label: "Guernsey", width: "auto", fontWeight: "bold"},
  {value: "GH", label: "Ghana", width: "auto", fontWeight: "bold"},
  {value: "GI", label: "Gibraltar", width: "auto", fontWeight: "bold"},
  {value: "GL", label: "Greenland", width: "auto", fontWeight: "bold"},
  {value: "GM", label: "Gambia", width: "auto", fontWeight: "bold"},
  {value: "GN", label: "Guinea", width: "auto", fontWeight: "bold"},
  {value: "GP", label: "Guadeloupe", width: "auto", fontWeight: "bold"},
  {value: "GQ", label: "Equatorial Guinea", width: "auto", fontWeight: "bold"},
  {value: "GR", label: "Greece", width: "auto", fontWeight: "bold"},
  {value: "GS", label: "South Georgia and the South Sandwich Islands", width: "auto", fontWeight: "bold"},
  {value: "GT", label: "Guatemala", width: "auto", fontWeight: "bold"},
  {value: "GU", label: "Guam", width: "auto", fontWeight: "bold"},
  {value: "GW", label: "Guinea-Bissau", width: "auto", fontWeight: "bold"},
  {value: "GY", label: "Guyana", width: "auto", fontWeight: "bold"},
  {value: "HK", label: "Hong Kong", width: "auto", fontWeight: "bold"},
  {value: "HM", label: "Heard and McDonald Islands", width: "auto", fontWeight: "bold"},
  {value: "HN", label: "Honduras", width: "auto", fontWeight: "bold"},
  {value: "HR", label: "Croatia", width: "auto", fontWeight: "bold"},
  {value: "HT", label: "Haiti", width: "auto", fontWeight: "bold"},
  {value: "HU", label: "Hungary", width: "auto", fontWeight: "bold"},
  {value: "ID", label: "Indonesia", width: "auto", fontWeight: "bold"},
  {value: "IE", label: "Ireland", width: "auto", fontWeight: "bold"},
  {value: "IL", label: "Israel", width: "auto", fontWeight: "bold"},
  {value: "IM", label: "Isle of Man", width: "auto", fontWeight: "bold"},
  {value: "IN", label: "India", width: "auto", fontWeight: "bold"},
  {value: "IO", label: "British Indian Ocean Territory", width: "auto", fontWeight: "bold"},
  {value: "IQ", label: "Iraq", width: "auto", fontWeight: "bold"},
  {value: "IR", label: "Iran", width: "auto", fontWeight: "bold"},
  {value: "IS", label: "Iceland", width: "auto", fontWeight: "bold"},
  {value: "IT", label: "Italy", width: "auto", fontWeight: "bold"},
  {value: "JE", label: "Jersey", width: "auto", fontWeight: "bold"},
  {value: "JM", label: "Jamaica", width: "auto", fontWeight: "bold"},
  {value: "JO", label: "Jordan", width: "auto", fontWeight: "bold"},
  {value: "JP", label: "Japan", width: "auto", fontWeight: "bold"},
  {value: "KE", label: "Kenya", width: "auto", fontWeight: "bold"},
  {value: "KG", label: "Kyrgyzstan", width: "auto", fontWeight: "bold"},
  {value: "KH", label: "Cambodia", width: "auto", fontWeight: "bold"},
  {value: "KI", label: "Kiribati", width: "auto", fontWeight: "bold"},
  {value: "KM", label: "Comoros", width: "auto", fontWeight: "bold"},
  {value: "KN", label: "Saint Kitts and Nevis", width: "auto", fontWeight: "bold"},
  {value: "KP", label: "North Korea", width: "auto", fontWeight: "bold"},
  {value: "KR", label: "South Korea", width: "auto", fontWeight: "bold"},
  {value: "KW", label: "Kuwait", width: "auto", fontWeight: "bold"},
  {value: "KY", label: "Cayman Islands", width: "auto", fontWeight: "bold"},
  {value: "KZ", label: "Kazakhstan", width: "auto", fontWeight: "bold"},
  {value: "LA", label: "Lao People's Democratic Republic", width: "auto", fontWeight: "bold"},
  {value: "LB", label: "Lebanon", width: "auto", fontWeight: "bold"},
  {value: "LC", label: "Saint Lucia", width: "auto", fontWeight: "bold"},
  {value: "LI", label: "Liechtenstein", width: "auto", fontWeight: "bold"},
  {value: "LK", label: "Sri Lanka", width: "auto", fontWeight: "bold"},
  {value: "LR", label: "Liberia", width: "auto", fontWeight: "bold"},
  {value: "LS", label: "Lesotho", width: "auto", fontWeight: "bold"},
  {value: "LT", label: "Lithuania", width: "auto", fontWeight: "bold"},
  {value: "LU", label: "Luxembourg", width: "auto", fontWeight: "bold"},
  {value: "LV", label: "Latvia", width: "auto", fontWeight: "bold"},
  {value: "LY", label: "Libya", width: "auto", fontWeight: "bold"},
  {value: "MA", label: "Morocco", width: "auto", fontWeight: "bold"},
  {value: "MC", label: "Monaco", width: "auto", fontWeight: "bold"},
  {value: "MD", label: "Moldova", width: "auto", fontWeight: "bold"},
  {value: "ME", label: "Montenegro", width: "auto", fontWeight: "bold"},
  {value: "MF", label: "Saint-Martin (France)", width: "auto", fontWeight: "bold"},
  {value: "MG", label: "Madagascar", width: "auto", fontWeight: "bold"},
  {value: "MH", label: "Marshall Islands", width: "auto", fontWeight: "bold"},
  {value: "MK", label: "Macedonia", width: "auto", fontWeight: "bold"},
  {value: "ML", label: "Mali", width: "auto", fontWeight: "bold"},
  {value: "MM", label: "Myanmar", width: "auto", fontWeight: "bold"},
  {value: "MN", label: "Mongolia", width: "auto", fontWeight: "bold"},
  {value: "MO", label: "Macau", width: "auto", fontWeight: "bold"},
  {value: "MP", label: "Northern Mariana Islands", width: "auto", fontWeight: "bold"},
  {value: "MQ", label: "Martinique", width: "auto", fontWeight: "bold"},
  {value: "MR", label: "Mauritania", width: "auto", fontWeight: "bold"},
  {value: "MS", label: "Montserrat", width: "auto", fontWeight: "bold"},
  {value: "MT", label: "Malta", width: "auto", fontWeight: "bold"},
  {value: "MU", label: "Mauritius", width: "auto", fontWeight: "bold"},
  {value: "MV", label: "Maldives", width: "auto", fontWeight: "bold"},
  {value: "MW", label: "Malawi", width: "auto", fontWeight: "bold"},
  {value: "MX", label: "Mexico", width: "auto", fontWeight: "bold"},
  {value: "MY", label: "Malaysia", width: "auto", fontWeight: "bold"},
  {value: "MZ", label: "Mozambique", width: "auto", fontWeight: "bold"},
  {value: "NA", label: "Namibia", width: "auto", fontWeight: "bold"},
  {value: "NC", label: "New Caledonia", width: "auto", fontWeight: "bold"},
  {value: "NE", label: "Niger", width: "auto", fontWeight: "bold"},
  {value: "NF", label: "Norfolk Island", width: "auto", fontWeight: "bold"},
  {value: "NG", label: "Nigeria", width: "auto", fontWeight: "bold"},
  {value: "NI", label: "Nicaragua", width: "auto", fontWeight: "bold"},
  {value: "NL", label: "The Netherlands", width: "auto", fontWeight: "bold"},
  {value: "NO", label: "Norway", width: "auto", fontWeight: "bold"},
  {value: "NP", label: "Nepal", width: "auto", fontWeight: "bold"},
  {value: "NR", label: "Nauru", width: "auto", fontWeight: "bold"},
  {value: "NU", label: "Niue", width: "auto", fontWeight: "bold"},
  {value: "NZ", label: "New Zealand", width: "auto", fontWeight: "bold"},
  {value: "OM", label: "Oman", width: "auto", fontWeight: "bold"},
  {value: "PA", label: "Panama", width: "auto", fontWeight: "bold"},
  {value: "PE", label: "Peru", width: "auto", fontWeight: "bold"},
  {value: "PF", label: "French Polynesia", width: "auto", fontWeight: "bold"},
  {value: "PG", label: "Papua New Guinea", width: "auto", fontWeight: "bold"},
  {value: "PH", label: "Philippines", width: "auto", fontWeight: "bold"},
  {value: "PK", label: "Pakistan", width: "auto", fontWeight: "bold"},
  {value: "PL", label: "Poland", width: "auto", fontWeight: "bold"},
  {value: "PM", label: "St. Pierre and Miquelon", width: "auto", fontWeight: "bold"},
  {value: "PN", label: "Pitcairn", width: "auto", fontWeight: "bold"},
  {value: "PR", label: "Puerto Rico", width: "auto", fontWeight: "bold"},
  {value: "PS", label: "Palestine, State of", width: "auto", fontWeight: "bold"},
  {value: "PT", label: "Portugal", width: "auto", fontWeight: "bold"},
  {value: "PW", label: "Palau", width: "auto", fontWeight: "bold"},
  {value: "PY", label: "Paraguay", width: "auto", fontWeight: "bold"},
  {value: "QA", label: "Qatar", width: "auto", fontWeight: "bold"},
  {value: "RE", label: "Réunion", width: "auto", fontWeight: "bold"},
  {value: "RO", label: "Romania", width: "auto", fontWeight: "bold"},
  {value: "RS", label: "Serbia", width: "auto", fontWeight: "bold"},
  {value: "RU", label: "Russian Federation", width: "auto", fontWeight: "bold"},
  {value: "RW", label: "Rwanda", width: "auto", fontWeight: "bold"},
  {value: "SA", label: "Saudi Arabia", width: "auto", fontWeight: "bold"},
  {value: "SB", label: "Solomon Islands", width: "auto", fontWeight: "bold"},
  {value: "SC", label: "Seychelles", width: "auto", fontWeight: "bold"},
  {value: "SD", label: "Sudan", width: "auto", fontWeight: "bold"},
  {value: "SE", label: "Sweden", width: "auto", fontWeight: "bold"},
  {value: "SG", label: "Singapore", width: "auto", fontWeight: "bold"},
  {value: "SH", label: "Saint Helena", width: "auto", fontWeight: "bold"},
  {value: "SI", label: "Slovenia", width: "auto", fontWeight: "bold"},
  {value: "SJ", label: "Svalbard and Jan Mayen Islands", width: "auto", fontWeight: "bold"},
  {value: "SK", label: "Slovakia", width: "auto", fontWeight: "bold"},
  {value: "SL", label: "Sierra Leone", width: "auto", fontWeight: "bold"},
  {value: "SM", label: "San Marino", width: "auto", fontWeight: "bold"},
  {value: "SN", label: "Senegal", width: "auto", fontWeight: "bold"},
  {value: "SO", label: "Somalia", width: "auto", fontWeight: "bold"},
  {value: "SR", label: "Suriname", width: "auto", fontWeight: "bold"},
  {value: "SS", label: "South Sudan", width: "auto", fontWeight: "bold"},
  {value: "ST", label: "Sao Tome and Principe", width: "auto", fontWeight: "bold"},
  {value: "SV", label: "El Salvador", width: "auto", fontWeight: "bold"},
  {value: "SX", label: "Sint Maarten (Dutch part)", width: "auto", fontWeight: "bold"},
  {value: "SY", label: "Syria", width: "auto", fontWeight: "bold"},
  {value: "SZ", label: "Swaziland", width: "auto", fontWeight: "bold"},
  {value: "TC", label: "Turks and Caicos Islands", width: "auto", fontWeight: "bold"},
  {value: "TD", label: "Chad", width: "auto", fontWeight: "bold"},
  {value: "TF", label: "French Southern Territories", width: "auto", fontWeight: "bold"},
  {value: "TG", label: "Togo", width: "auto", fontWeight: "bold"},
  {value: "TH", label: "Thailand", width: "auto", fontWeight: "bold"},
  {value: "TJ", label: "Tajikistan", width: "auto", fontWeight: "bold"},
  {value: "TK", label: "Tokelau", width: "auto", fontWeight: "bold"},
  {value: "TL", label: "Timor-Leste", width: "auto", fontWeight: "bold"},
  {value: "TM", label: "Turkmenistan", width: "auto", fontWeight: "bold"},
  {value: "TN", label: "Tunisia", width: "auto", fontWeight: "bold"},
  {value: "TO", label: "Tonga", width: "auto", fontWeight: "bold"},
  {value: "TR", label: "Turkey", width: "auto", fontWeight: "bold"},
  {value: "TT", label: "Trinidad and Tobago", width: "auto", fontWeight: "bold"},
  {value: "TV", label: "Tuvalu", width: "auto", fontWeight: "bold"},
  {value: "TW", label: "Taiwan", width: "auto", fontWeight: "bold"},
  {value: "TZ", label: "Tanzania", width: "auto", fontWeight: "bold"},
  {value: "UA", label: "Ukraine", width: "auto", fontWeight: "bold"},
  {value: "UG", label: "Uganda", width: "auto", fontWeight: "bold"},
  {value: "UM", label: "United States Minor Outlying Islands", width: "auto", fontWeight: "bold"},
  {value: "US", label: "United States", width: "auto", fontWeight: "bold"},
  {value: "UY", label: "Uruguay", width: "auto", fontWeight: "bold"},
  {value: "UZ", label: "Uzbekistan", width: "auto", fontWeight: "bold"},
  {value: "VA", label: "Vatican", width: "auto", fontWeight: "bold"},
  {value: "VC", label: "Saint Vincent and the Grenadines", width: "auto", fontWeight: "bold"},
  {value: "VE", label: "Venezuela", width: "auto", fontWeight: "bold"},
  {value: "VG", label: "Virgin Islands (British)", width: "auto", fontWeight: "bold"},
  {value: "VI", label: "Virgin Islands (U.S.)", width: "auto", fontWeight: "bold"},
  {value: "VN", label: "Vietnam", width: "auto", fontWeight: "bold"},
  {value: "VU", label: "Vanuatu", width: "auto", fontWeight: "bold"},
  {value: "WF", label: "Wallis and Futuna Islands", width: "auto", fontWeight: "bold"},
  {value: "WS", label: "Samoa", width: "auto", fontWeight: "bold"},
  {value: "YE", label: "Yemen", width: "auto", fontWeight: "bold"},
  {value: "YT", label: "Mayotte", width: "auto", fontWeight: "bold"},
  {value: "ZA", label: "South Africa", width: "auto", fontWeight: "bold"},
  {value: "ZM", label: "Zambia", width: "auto", fontWeight: "bold"},
  {value: "ZW", label: "Zimbabwe", width: "auto", fontWeight: "bold"}
];

//Cuba, Iran, Syria, North Korea
const sanctionedCountryISOCodes = ["CU", "IR", "SY", "KP"];

const countriesOnTop = ["CA", "US", "GB", "IE", "SG"];

export const getCountries = () => {
  //filter out sanctioned country codes
  let filteredCountries = countryISOCodes.filter(
    (item) => !sanctionedCountryISOCodes.includes(item)
  );

  //sort by label
  filteredCountries.sort((a, b) => {
    return a.label >= b.label ? 1 : -1;
  });
  return addPrimaryCountriesToTopOfList(countriesOnTop, filteredCountries);
};

const addPrimaryCountriesToTopOfList =  (primaryCountryCodes, countryList) =>{
  //filter out and sort countries to display on top of dropdown menu
  const primaryCountries=countryList.filter(ct=>primaryCountryCodes.indexOf(ct.value) >=0)
  const nonPrimaryCountries=countryList.filter(ct=>primaryCountryCodes.indexOf(ct.value) < 0)
  primaryCountries.sort(function (a, b) {
    return countriesOnTop.indexOf(a.value) - countriesOnTop.indexOf(b.value);
  });
 return [...primaryCountries, ...nonPrimaryCountries];
}

export const getPhoneInputCountries = () => {
  return countryISOCodes.filter(
      (item) => !sanctionedCountryISOCodes.includes(item)
  );
};

import React from 'react';

export default class PaginatorComponent extends React.Component {


    constructor(props) {
        super(props);
        var goToStart = false;
        var previous = false;
        var next = true;
        var goToEnd = true;
        var totalNoOfPages = Math.ceil(this.props.total / this.props.resultsPerPage);
        var current = true;
        var plusOne = false;
        var plusTwo = false;
        var minusOne = false;
        var minusTwo = false;
        var plusOnePage = null;
        var plusTwoPage = null;
        var minusOnePage = null;
        var minusTwoPage = null;

        if (totalNoOfPages === 1) {
            goToStart = false;
            previous = false;
            next = false;
            goToEnd = false;
            current = true;
            plusOne = false;
            plusTwo = false;
            minusOne = false;
            minusTwo = false;
        }

        if (totalNoOfPages > 1) {
            if (this.props.currentPage === 1) {
                if (this.props.currentPage === totalNoOfPages) {
                    goToStart = false;
                    previous = false;
                    next = false;
                    goToEnd = false;
                } else {
                    goToStart = false;
                    previous = false;
                    next = true;
                    goToEnd = true;
                }
            }
            if (this.props.currentPage > 1) {
                if (this.props.currentPage === totalNoOfPages) {
                    goToStart = true;
                    previous = true;
                    next = false;
                    goToEnd = false;
                    plusOne = false;
                    plusTwo = false;
                } else {
                    goToStart = true;
                    previous = true;
                    next = true;
                    goToEnd = true;
                }
            }
            if (this.props.currentPage - 1 >= 1) {
                minusOne = true;
                minusOnePage = this.props.currentPage - 1;
            }
            if (this.props.currentPage - 2 >= 1) {
                minusTwo = true;
                minusTwoPage = this.props.currentPage - 2;
            }
            if (this.props.currentPage + 1 <= totalNoOfPages) {
                plusOne = true;
                plusOnePage = this.props.currentPage + 1;
            }
            if (this.props.currentPage + 2 <= totalNoOfPages) {
                plusTwo = true;
                plusTwoPage = this.props.currentPage + 2;
            }

        }

        this.state = {
            total: this.props.total,
            totalNoOfPages: totalNoOfPages,
            resultsPerPage: this.props.resultsPerPage,
            currentPage: this.props.currentPage,
            goToStart: goToStart,
            previous: previous,
            next: next,
            goToEnd: goToEnd,
            current: current,
            plusOne: plusOne,
            plusTwo: plusTwo,
            minusOne: minusOne,
            minusTwo: minusTwo,
            plusOnePage: plusOnePage,
            plusTwoPage: plusTwoPage,
            minusOnePage: minusOnePage,
            minusTwoPage: minusTwoPage
        }
        this.goToPage = this.goToPage.bind(this);
    }
    goToPage(active, page) {
        if (active) {
            if (this.props.goToPage) {
                this.props.goToPage(page);
            }
        }

    }
    render() {
        return (
            <React.Fragment>
                <div className="tds-pagination" >
                    {this.state.totalNoOfPages > 1 &&
                        <React.Fragment>
                            <span
                                className={this.state.goToStart ? 'active' : 'inactive'}
                                title={this.state.goToStart ? 'First Page' : ''}
                                onClick={() => { this.goToPage(this.state.goToStart, 1) }}
                            >&lt;&lt;</span>
                            <span
                                className={this.state.previous ? 'active' : 'inactive'}
                                title={this.state.previous ? 'Previous Page' : ''}
                                onClick={() => { this.goToPage(this.state.previous, this.state.currentPage - 1) }}
                            >&lt;</span>
                            {
                                this.state.minusTwo &&
                                <span
                                    className="active"
                                    title={'Go to Page ' + this.state.minusTwoPage}
                                    onClick={() => { this.goToPage(this.state.minusTwo, this.state.minusTwoPage) }}
                                >{this.state.minusTwoPage}</span>
                            }
                            {
                                this.state.minusOne &&
                                <span
                                    className="active"
                                    title={'Go to Page ' + this.state.minusOnePage}
                                    onClick={() => { this.goToPage(this.state.minusOne, this.state.minusOnePage) }}
                                >{this.state.minusOnePage}</span>
                            }
                            {
                                this.state.current &&
                                <span
                                    className="selected"
                                    onClick={() => { this.goToPage(this.state.current, this.state.currentPage) }}
                                >{this.state.currentPage}</span>
                            }
                            {
                                this.state.plusOne &&
                                <span
                                    className="active"
                                    title={'Go to Page ' + this.state.plusOnePage}
                                    onClick={() => { this.goToPage(this.state.plusOne, this.state.plusOnePage) }}
                                >{this.state.plusOnePage}</span>
                            }
                            {
                                this.state.plusTwo &&
                                <span
                                    className="active"
                                    title={'Go to Page ' + this.state.plusTwoPage}
                                    onClick={() => { this.goToPage(this.state.plusTwo, this.state.plusTwoPage) }}
                                >{this.state.plusTwoPage}</span>
                            }
                            <span
                                className={this.state.next ? 'active' : ''}
                                title={this.state.next ? 'Next Page' : ''}
                                onClick={() => { this.goToPage(this.state.next, this.state.currentPage + 1) }}
                            >&gt;</span>
                            <span
                                className={this.state.goToEnd ? 'active' : ''}
                                title={this.state.next ? 'Last Page' : ''}
                                onClick={() => { this.goToPage(this.state.goToEnd, this.state.totalNoOfPages) }}
                            >&gt;&gt;</span>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}
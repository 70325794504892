import HttpClient from '../utils/HttpClient';
import { 
    REST_PRODUCT_PAGE_BASE_PATH
} from '../constants/Rest.constants';

export default class ProductPageService {
    
    static getProductPageData(type) {
        return HttpClient.get(REST_PRODUCT_PAGE_BASE_PATH + type);
    }
    
}
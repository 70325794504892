import React from 'react';
import moment from 'moment';
import { withRouter, Link } from "react-router-dom";
import UserService from '../../services/User.service';
import ExchangeTradedFundsService from '../../services/ExchangeTradedFunds.service';
import queryString from 'query-string';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    ERROR_TYPE, PARAMS_DATE_FORMAT
} from '../../constants/Misc.constants';
import {
    ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS
} from '../../constants/Entitlements.constants';
import {
    EXCHANGE_TRADED_FUNDS_QUERY_PARAM_START_DATE,
    EXCHANGE_TRADED_FUNDS_QUERY_PARAM_END_DATE,
    EXCHANGE_TRADED_FUNDS_QUERY_PARAM_PAGE,
    EXCHANGE_TRADED_FUNDS_QUERY_PARAM_DATE_FORMAT,
    EXCHANGE_TRADED_FUNDS_PAGE_SIZE,
    EXCHANGE_TRADED_FUNDS_TYPE
} from '../../constants/ExchangeTradedFunds.constants';
import FundDetailsComponent from '../fund-details/FundDetails.component';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import UtilsService from '../../services/Utils.service';
import { EXCHANGE_TRADED_FUNDS_DETAILS_PAGE_TITLE } from '../../constants/PageTitle.constants';
import {
    LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE
} from '../../constants/LinkPaths.constants';

class ETFDetailsPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = false;
        this.maxDate = new Date();
        this.state = {
            currentPage: 1,
            startDate: null,
            endDate: null,
            loading: true,
            error: false,
            data: null,
            maxStartDate: new Date(),
            minEndDate: null
        };
        let { match } = this.props;
        if (match && match.params && match.params.type) {
            this.state.type = match.params.type
            this.state.title = this.getPageTitle(match.params.type);
        }
        this.applyUrlParams(this.props.location);
        this.hasAccess = UserService.hasAccess(ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.triggerGetData = this.triggerGetData.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }
    componentDidMount() {
        document.title = EXCHANGE_TRADED_FUNDS_DETAILS_PAGE_TITLE;
        if (!this._mounted) {
            this._mounted = true;
            this.triggerGetData();
        }
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname.includes('/desk-research/exchange-traded-funds/details/'+this.state.type)) {
                if (this._mounted) {
                    this.applyUrlParams(location);
                    this.triggerGetData();
                }
            }
        });
    }
    componentDidUpdate(prevProps) {
        var change = false;
        if (prevProps.match) {
            if (prevProps.match.params.type !== this.props.match.params.type) {
                change = true;
            }
            if (change) {
                const title = this.getPageTitle(this.props.match.params.type);
                document.title = EXCHANGE_TRADED_FUNDS_DETAILS_PAGE_TITLE + '|' + title;
                this.setState({
                    type: this.props.match.params.type,
                    title: title
                }, this.triggerGetData);
            }
        }
    }
    getPageTitle(type) {
        const types = Object.keys(EXCHANGE_TRADED_FUNDS_TYPE);
        for (let i=0; i<types.length; i++) {
            if ( EXCHANGE_TRADED_FUNDS_TYPE[types[i]].path === type) {

                return EXCHANGE_TRADED_FUNDS_TYPE[types[i]].title;
            }
        }
    }
    applyUrlParams(location) {
        let urlQueryParams = queryString.parse(location.search);
        const startDateParam = urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_START_DATE] ? urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_START_DATE] : null; // eslint-disable-line
        const endDateParam = urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_END_DATE] ? urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_END_DATE] : null; // eslint-disable-line
        if (startDateParam) {
            this.state.startDate = new Date(startDateParam); // eslint-disable-line
            this.state.minEndDate = this.state.startDate; // eslint-disable-line
        }
        if (endDateParam) {
            this.state.endDate = new Date(endDateParam); // eslint-disable-line
            this.state.maxStartDate = this.state.endDate; // eslint-disable-line
        }
        this.state.currentPage = urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_PAGE] ? parseInt(urlQueryParams[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_PAGE]) : 1; // eslint-disable-line
    }
    componetWillUnMount() {
        this._mounted = false;
        this.unlisten();
    }
    handleStartDateChange(date) {
        this.setState(
            {
                startDate: date,
                minEndDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    handleEndDateChange(date) {
        this.setState(
            {
                endDate: date,
                maxStartDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    triggerGetData() {
        this.setState(
            {
                loading: true,
                error: false,
                data: null,
            }, this.getData
        )
    }
    getData() {
        const params = {
            page: this.state.currentPage,
            size: EXCHANGE_TRADED_FUNDS_PAGE_SIZE
        }
        if (this.state.startDate) {
            params.pubDateFrom = moment.utc(this.state.startDate).format(PARAMS_DATE_FORMAT)
        }
        if (this.state.endDate) {
            params.pubDateTo = moment.utc(this.state.endDate).format(PARAMS_DATE_FORMAT)
        }
        ExchangeTradedFundsService.getDetails(this.state.type, params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            data: data,
            loading: false,
            error: false
        })
    }
    getDataError(error) {
        this.setState({
            data: null,
            loading: false,
            error: true
        })
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            }, this.updateUrl
        );
    }
    updateUrl() {
        var params = {};
        params[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_START_DATE] = this.state.startDate ? moment(this.state.startDate).format(EXCHANGE_TRADED_FUNDS_QUERY_PARAM_DATE_FORMAT) : null;
        params[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_END_DATE] = this.state.endDate ? moment(this.state.endDate).format(EXCHANGE_TRADED_FUNDS_QUERY_PARAM_DATE_FORMAT) : null;
        if (this.state.currentPage > 1) {
            params[EXCHANGE_TRADED_FUNDS_QUERY_PARAM_PAGE] = this.state.currentPage;
        }
        this.props.history.push(this.props.location.pathname + UtilsService.buildURLParams(params));
    }
    accessDenied() {
        return !this.hasAccess && <AclAccessDeniedComponent />;
    }
    loading() {
        return this.state.loading && <SpinnerComponent />
    }
    error() {
        return this.state.error && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }
    
    handleClickBackButton(e) {
        e.preventDefault();
        window.history.back()
    }
    render() {
        return (
            <React.Fragment>
                { this.accessDenied()}
                {
                    this.hasAccess &&
                    <section className="etf-details-page">
                        <Link
                            to={LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE}
                            className="back-button"
                            title="Back"
                        >
                            &lt; Back
                        </Link>
                        <h1>{this.state.title}</h1>
                        {this.loading()}
                        {this.error()}
                        {this.state.data &&
                            <FundDetailsComponent
                                handleStartDateChange={this.handleStartDateChange}
                                handleEndDateChange={this.handleEndDateChange}
                                data={this.state.data}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                maxDate={this.maxDate}
                                maxStartDate={this.state.maxStartDate}
                                minEndDate={this.state.minEndDate}
                                currentPage={this.state.currentPage}
                                size={EXCHANGE_TRADED_FUNDS_PAGE_SIZE}
                                goToPage={this.goToPage}
                            />
                        }
                    </section>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(ETFDetailsPageComponent);


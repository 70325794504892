import React, { useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import MailService from "../../services/Mail.service";
import MailtoComponent from "../misc/mailto/Mailto.component";
import LandingPageFooterComponent from "./footer/LandingPageFooter.component";
import LandingPageHeaderComponent from "./header/LandingPageHeader.component";
import RequestAccessFormComponent from "./request-access-form/RequestAccessForm.component";
import RequestAccessFormConfirmationComponent from "./request-access-form/RequestAccessFormConfirmation.component";
import { REQUEST_ACCESS_MAIL_FROM, REQUEST_ACCESS_MAIL_SUBJECT } from "../../constants/LandingPage.constants";

const LandingPageComponent = (props) => {
  
  const [
    isRequestAccessFormConfirmationOpen,
    setRequestAccessFormConfirmationOpen,
  ] = useState(false);
  const [requestAccessMailBody, setRequestAccessMailBody] = useState(undefined);
  const [requestAccessMailSubject, setRequestAccessMailSubject] = useState('');
  const mailRef = useRef(null);
  const isInitialMount = useRef(true);
  const location = useLocation();
  //const registerFlag = location.pathname.toLowerCase().includes("?register=true");  
  const registerFlag = window.location.href.toLowerCase().endsWith("?register=true");
  const [isRequestAccessFormOpen, setRequestAccessFormOpen] = useState(registerFlag);
  console.log(location.pathname.toLowerCase());
  console.log(window.location.href.toLowerCase()); 
  console.log(" REGSITER FLAG=  " + registerFlag);

  /*
    TODO: temporary workaround to counter the logic for making
    the site invisible on initial render. To be removed once
    landing page is configured to be decoupled from the main app
  */
  useEffect(() => {
    document.body.style.visibility = "visible";
    /*console.log(location.pathname.toLowerCase());
    console.log(" REGSITER FLAG=  " + registerFlag);
    if(registerFlag){
      setRequestAccessFormOpen(true);
    }*/
  }, [])

  // handling sending the request access email
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Only run after state update, not after initial mount
      mailRef.current.click();
    }
  }, [requestAccessMailBody])

  const handleRequestAccessFormSubmit = (data) => {
    setRequestAccessFormOpen(false);
    setRequestAccessFormConfirmationOpen(true);
    setRequestAccessMailSubject(REQUEST_ACCESS_MAIL_SUBJECT(data.firstName, data.lastName));
    const mailBody = MailService.formatRequestAccessMailBody(data);
    setRequestAccessMailBody(mailBody);
  };

  return (
    <div className="tds-landing-container">
      <LandingPageHeaderComponent
        onRequestFormOpen={() => setRequestAccessFormOpen(true)}
      />
      <main>
        <p className="tds-landing-main-message">
          Gain the advantage with TD Securities by accessing thought-provoking
          insights and expansive coverage
        </p>
      </main>
      <LandingPageFooterComponent />
      {isRequestAccessFormOpen && (
        <RequestAccessFormComponent
          onCancel={() => setRequestAccessFormOpen(false)}
          onSubmit={handleRequestAccessFormSubmit}
        />
      )}
      {isRequestAccessFormConfirmationOpen && (
        <RequestAccessFormConfirmationComponent
          onClose={() => setRequestAccessFormConfirmationOpen(false)}
        />
      )}
      <MailtoComponent
        className="tds-request-meeting-mailto-link"
        ref={mailRef}
        email={REQUEST_ACCESS_MAIL_FROM}
        subject={requestAccessMailSubject}
        body={requestAccessMailBody}
        text={"Mail me"}
      />
    </div>
  );
};

export default LandingPageComponent;

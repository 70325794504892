import React from 'react';
import { Link } from "react-router-dom";
import ContactCardComponent from "../../misc/contact-card/ContactCard.component";
import AnalystProfileCardPicComponent from "../../misc/analyst-profile-card-pic/AnalystProfileCardPic.component";
import {
    LINK_PATH_ANALYST_PROFILE_PAGE
} from "../../../constants/LinkPaths.constants";
import {
    FORWARD_SLASH,
    FIRM_NAME_STRATEGY
} from '../../../constants/Misc.constants';
export default class AnalystCardComponent extends React.Component {
    constructor(props) {
        super(props);
        var contactCardOrg = "";
        if (this.props.analyst && this.props.analyst.division) {
            contactCardOrg = this.props.analyst.division.name;
        }
        this.state = {
            contactCardOrg: contactCardOrg
        };
    }
    render() {
        return (
            <div className="tds-analyst-card-container">
                <div className="tds-analyst-leadership">
                    <div 
                        className="tds-analyst-profile-card-photo-wrapper" 
                        title={this.props.analyst.firstName + " " + this.props.analyst.lastName}
                    >
                        <AnalystProfileCardPicComponent
                            className={'tds-analyst-card-profile-photo'}
                            defaultImage={this.props.analyst.photo || "/assets/img/Placeholder_Silhouette.jpg"}
                            defaultImageClass={'tds-analyst-card-profile-photo-default'}
                            link={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                this.props.analyst.firmName + FORWARD_SLASH + this.props.analyst.personId}
                        />
                    </div>
                    <div className="tds-analyst-card-container-content">
                        <div className="tds-analyst-leadership-name">
                            <h3>
                                <Link to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                    this.props.analyst.firmName + FORWARD_SLASH + this.props.analyst.personId}
                                    activeclassname="td-link-standalone"
                                    title={this.props.analyst.firstName + " " + this.props.analyst.lastName}    
                                >
                                    {this.props.analyst.firstName} {this.props.analyst.lastName}
                                </Link>
                            </h3>
                        </div>
                        <span className="tds-analyst-card-container-jobtitle">{this.props.analyst.title}</span>
                        <div className="tds-analyst-company-links-container">
                            <ul>
                                { this.props.analyst.firmName === FIRM_NAME_STRATEGY && this.props.analyst.division && this.props.analyst.division.name &&
                                    <li>
                                        {
                                            this.props.analyst.division.name
                                        }
                                    </li>
                                }
                                {
                                    this.props.analyst.firmName !== FIRM_NAME_STRATEGY && this.props.analyst.industries &&
                                    <React.Fragment>
                                        <div className="scroll-bar">
                                        { 
                                            this.props.analyst.industries.map((industryItem, index) => {
                                                return <li 
                                                    key={'analyst-industry-' + index}
                                                >
                                                    { industryItem.name }
                                                </li>
                                            })
                                        }
                                        </div>
                                    </React.Fragment>
                                }
                            </ul>
                        </div>
                        {
                            !this.props.hidePhone && this.props.analyst.phone &&
                            <span className="tds-analyst-card-container-phone">
                                <strong>P:&nbsp;</strong>{this.props.analyst.phone}
                            </span>
                        }
                        <span className="tds-analyst-card-container-city">
                            {this.props.analyst.location}
                        </span>
                        {
                            !this.props.hideEmail &&
                            <span className="tds-analyst-card-container-email">
                                <a href={'mailto:' + this.props.analyst.email}>{this.props.analyst.email}</a></span>
                        }
                        {
                            !this.props.hideAddToContacts &&
                            <ContactCardComponent
                                outerCssClass='tds-analyst-card-container-contact'
                                iconCssClass='td-add-contact-icon'
                                textCssClass='tds-analyst-card-container-contact-text'
                                lastName={this.props.analyst.lastName}
                                firstName={this.props.analyst.firstName}
                                org={this.state.contactCardOrg}
                                title={this.props.analyst.title}
                                phone={this.props.analyst.phone}
                                email={this.props.analyst.email}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

import React from "react";
import { sanitizeInnerHtml } from "../../../services/Utils.service";

const InnerHtml = (props) => {

    return (
    <React.Fragment>
        <div dangerouslySetInnerHTML={sanitizeInnerHtml(props.data)} className="inner-html"/>
    </React.Fragment>
    );
};

export default InnerHtml;
import React from 'react';
import UserService from '../../../services/User.service';
import queryString from "query-string";
import { RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE, RESEARCH_PAGE_TAB } from '../../../constants/ResearchPage.constants';
import { ROUTE_PATH_EQUITIES_RESEARCH_PAGE } from '../../../constants/RoutePaths.constants';
import { ALPHABET_FILTER_LIST, EQUALS, QUESTION_MARK } from '../../../constants/Misc.constants';
import { isNotEmptyArray } from '../../../utils/valueUtils';
import ResearchPageService from '../../../services/ResearchPage.service';
import ResearchPageTabItemComponent from './ResearchPageTabItem.component';
import { ACL_EQUITIES_RESEARCH } from '../../../constants/Entitlements.constants';
import { withRouter } from 'react-router-dom';

export class ResearchPageTabsComponent extends React.Component {
    containerClassName = 'tds-tab-menu-container';

    constructor(props) {
        super(props);

        this.state = {
            tabName: RESEARCH_PAGE_TAB.ANALYST,
            data: null,
            loading: true,
            error: false,
            researchType: RESEARCH_PAGE_TAB.ANALYST
        };
        const urlQueryParams = queryString.parse(this.props?.location?.search);
        if (urlQueryParams.researchType && [RESEARCH_PAGE_TAB.ANALYST, RESEARCH_PAGE_TAB.COMPANY, RESEARCH_PAGE_TAB.INDUSTRY].includes(urlQueryParams.researchType)) {
            this.state.researchType = urlQueryParams.researchType;
            this.state.tabName = urlQueryParams.researchType;
        }
        
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_RESEARCH);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.sendTabChangeParent = this.sendTabChangeParent.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }
    listenURLChange() {
        this.props.history.listen((location, action) => {
            if (location?.pathname?.includes(ROUTE_PATH_EQUITIES_RESEARCH_PAGE)) {
                const urlQueryParams = queryString.parse(location?.search);                
                if(urlQueryParams.researchType && [RESEARCH_PAGE_TAB.ANALYST, RESEARCH_PAGE_TAB.COMPANY, RESEARCH_PAGE_TAB.INDUSTRY].includes(urlQueryParams.researchType)) {
                    this.setState({
                        researchType: urlQueryParams.researchType,
                        tabName: urlQueryParams.researchType
                    });
                }
            }
        });
    }
    componentDidMount() {        
        if (this.hasAccess) {
            this.getData();
        }
        this.listenURLChange();
    }

    getData() {
        ResearchPageService.getResearch().then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            loading: false,
            data: this.convertData(data)
        });
    }
    convertData(data) {
        var _data = data;
        if (isNotEmptyArray(data.issuers)) {
            var companies = data.issuers;
            var _companies = [];
            for (var i = 0; i < ALPHABET_FILTER_LIST.length; i++) {
                var alphabet_list = {};
                alphabet_list = {
                    group: ALPHABET_FILTER_LIST[i] + "",
                    companies: [],
                };
                for (var j = 0; j < companies.length; j++) {
                    var companyName = companies[j].name.toUpperCase();
                    if (i === 0 && companyName.match(/^\d/)) {
                        alphabet_list.companies.push(companies[j]);
                    } else if (companyName.charAt(0) === ALPHABET_FILTER_LIST[i]) {
                        alphabet_list.companies.push(companies[j]);
                    }
                }
                _companies.push(alphabet_list);
            }
            _data.companies = _companies;
        } else {
            _data.companies = [];
        }
        return _data;
    }
    getDataError(error) {
        this.setState({
            loading: false,
            data: null,
            latestContentByTypes: null,
            error: true,
        });
    }

    onChangeValue(event) {
        this.setState({
            tabName: event.target.value,
            researchType: event.target.value
        }, this.sendTabChangeParent(event.target.value));
    }
    sendTabChangeParent(value) {
        this.updateUrl(value);
        if (this.props.tabChange) {
            this.props.tabChange(value)
        }
    }
    updateUrl(researchType) {
        const url =
            ROUTE_PATH_EQUITIES_RESEARCH_PAGE +
            QUESTION_MARK +
            RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE +
            EQUALS +
            researchType;
        this.props.history.replace(url);
    }
    selectResearchData(researchType){
        
        switch(researchType) {
            case RESEARCH_PAGE_TAB.ANALYST: return this.state.data?.analysts;
            case RESEARCH_PAGE_TAB.COMPANY: return this.state.data?.companies;
            case RESEARCH_PAGE_TAB.INDUSTRY: return this.state.data?.industries;
            default: return this.state.data?.analysts;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.containerClassName + ' three-tabs'}>
                    <div className="tds-research-page-tabs">
                        <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                            <div className="td-multi-single-select-buttons-group" >
                                { (UserService.isEQ() || UserService.isBoth()) &&
                                    <div className="td-multi-single-select-button-wrapper">
                                        <input
                                            onChange={this.onChangeValue}
                                            type="radio"
                                            value={RESEARCH_PAGE_TAB.ANALYST}
                                            name="selectButtonSegmented3Group"
                                            id="selectButtonSegmented3-1"
                                            checked={this.state.tabName === RESEARCH_PAGE_TAB.ANALYST}
                                        />
                                        <label htmlFor="selectButtonSegmented3-1" title={RESEARCH_PAGE_TAB.ANALYST}>{RESEARCH_PAGE_TAB.ANALYST}</label>
                                    </div>
                                }
                                { (UserService.isEQ() || UserService.isBoth()) &&
                                    <div className="td-multi-single-select-button-wrapper">
                                        <input
                                            onChange={this.onChangeValue}
                                            type="radio"
                                            value={RESEARCH_PAGE_TAB.COMPANY}
                                            name="selectButtonSegmented3Group"
                                            id="selectButtonSegmented3-2"
                                            checked={this.state.tabName === RESEARCH_PAGE_TAB.COMPANY}
                                        />
                                        <label htmlFor="selectButtonSegmented3-2" title={RESEARCH_PAGE_TAB.COMPANY}>{RESEARCH_PAGE_TAB.COMPANY}</label>
                                    </div>
                                }
                                { (UserService.isEQ() || UserService.isBoth()) &&
                                    <div className="td-multi-single-select-button-wrapper">
                                        <input
                                            onChange={this.onChangeValue}
                                            type="radio"
                                            value={RESEARCH_PAGE_TAB.INDUSTRY}
                                            name="selectButtonSegmented3Group"
                                            id="selectButtonSegmented3-3"
                                            checked={this.state.tabName === RESEARCH_PAGE_TAB.INDUSTRY}
                                        />
                                        <label htmlFor="selectButtonSegmented3-3" title={RESEARCH_PAGE_TAB.INDUSTRY}>{RESEARCH_PAGE_TAB.INDUSTRY}</label>
                                    </div>
                                }
                            </div>
                        </fieldset>
                    </div>
                    { (UserService.isEQ() || UserService.isBoth()) &&
                        <ResearchPageTabItemComponent
                            researchType={this.state.researchType}
                            history={this.props.history}
                            data={this.selectResearchData(this.state.researchType)}
                        />
                    }
                </div>                
            </React.Fragment>
        )
    }
}

export default withRouter(ResearchPageTabsComponent);
import React from 'react';

export default class PersonSearchResultsItemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <tr>
                <td>{this.props.item.firstName}</td>
                <td>{this.props.item.lastName}</td>
                <td>{this.props.item.title}</td>
                <td>{this.props.item.phone}</td>
                <td>{this.props.item.email}</td>
            </tr>
        )
    }
}

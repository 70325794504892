import React from "react";
import FormatedNumberComponent from "../misc/formated-number/FormatedNumber.component";

const EqOverviewInfoComponent = (props) => {
  return (
    <div className={props.className}>
      <div className="tds-three-column-single-container-equities-info">
        <dl>
          {props.item.recommendation && (
            <>
              <dt>Recommendation:</dt>
              <dd>{props.item.recommendation}</dd>
            </>
          )}
          {props.item.risk && (
            <>
              <dt>Risk:</dt>
              <dd>{props.item.risk}</dd>
            </>
          )}
          {props.item.priceTargetValue && (
            <>
              <dt>12-Month Target Price:</dt>
              <dd>
                {props.item.priceTargetValue && (
                  <FormatedNumberComponent
                    number={props.item.priceTargetValue}
                    decimal={2}
                    currency={props.item.currency}
                  />
                )}
              </dd>
            </>
          )}
          {props.item.marketCap && (
            <>
              <dt>Mkt Cap: </dt>
              <dd>{props.item.marketCap}</dd>
            </>
          )}
          {props.item.marketCap && !props.noTrailingSpace && <>&nbsp;</>}
        </dl>
      </div>
    </div>
  );
};

export default EqOverviewInfoComponent;

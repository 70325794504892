import HttpClient from '../utils/HttpClient';

import {
    REST_EXCHANGE_TRADED_FUNDS_SUMMARY_PATH,
    REST_EXCHANGE_TRADED_FUNDS_DETAILS_PATH
} from '../constants/Rest.constants';

import {
    FORWARD_SLASH
} from '../constants/Misc.constants';

export default class ExchangeTradedFundsService {

    static getSummary() {
        return HttpClient.get(REST_EXCHANGE_TRADED_FUNDS_SUMMARY_PATH);
    }

    static getDetails(type, params) {
        return HttpClient.get(REST_EXCHANGE_TRADED_FUNDS_DETAILS_PATH + FORWARD_SLASH + type, params);
    }
    
}
import React from 'react';
import { Link } from "react-router-dom";
import {
    TDS_TOP_NAV_MOBILE,
    TYPE_DROPDOWN, 
    TYPE_DROPDOWN_SUB_HEADING,
    TYPE_LINK, 
    LINK_DESTINATION_INTERNAL,
    LINK_DESTINATION_EXTERNAL, 
    LINK_DESTINATION_NONE
} from '../../../constants/HeaderNav.constants';

import HeaderNavDropDownMobileComponent from './HeaderNavDropDownMobile.component';
import HeaderNavDropDownMobileSubHeadingComponent from './HeaderNavDropDownMobileSubHeading.component';
import { LOG_LEVEL, logger } from '../../../system/Logger';
import LoginService from "../../../services/Login.service";


export default class HeaderNavBarMobileComponent extends React.Component {
    topClassName = TDS_TOP_NAV_MOBILE;
    menuItemsDOM = [];
    menuItemsREF = [];

    constructor(props) {
        super(props);
        logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarMobileComponent", this.props);
        this.handleMenuItemLinkClick = this.handleMenuItemLinkClick.bind(this);
    }
    handleMenuItemLinkClick = (event) => {
        if (event && event.target && event.target.dataset &&
            event.target.dataset.link && event.target.dataset.link === '/logout') {
            window.location.href = LoginService.getLogoutURL();
        } else {
            if (this.props.closeNavPanel) {
                this.props.closeNavPanel();
            }
            if (event.target.dataset.hasaccess === "false") {
                event.preventDefault();
                this.props.showModal(event.target.modaltype);
            }
        }
    }
    createMenuItems() {
        if (this.props.menuItems) {
            for (var menuItem of this.props.menuItems) {
                if (menuItem.destination === LINK_DESTINATION_EXTERNAL && menuItem.type === TYPE_LINK) {
                    this.menuItemsDOM.push(
                        <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link" key={menuItem.text}>
                            <a 
                                href={menuItem.link}
                                target="_blank"
                                title={menuItem.text}
                                rel="noopener noreferrer"
                            >
                                {menuItem.text}
                            </a>
                        </div>
                    )
                } else if (menuItem.destination === LINK_DESTINATION_INTERNAL && menuItem.type === TYPE_LINK) {
                    this.menuItemsDOM.push(
                        <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link" key={menuItem.text}>
                            <Link
                                to={menuItem.link}
                                data-link={menuItem.link}
                                data-hasaccess={menuItem.hasAccess}
                                data-modaltype={menuItem.modalType}
                                title={menuItem.text}
                                onClick={this.handleMenuItemLinkClick}
                            >{menuItem.text}</Link>
                        </div>
                    )
                } else if (menuItem.destination === LINK_DESTINATION_NONE && menuItem.type === TYPE_DROPDOWN) {
                    var child1 = React.createRef();
                    this.menuItemsREF.push(child1);
                    this.menuItemsDOM.push(
                        <HeaderNavDropDownMobileComponent
                            dropDownData={menuItem}
                            key={menuItem.text}
                            closeNavPanel={this.props.closeNavPanel}
                            showModal={this.props.showModal}
                        />
                    )
                } else if (menuItem.destination === LINK_DESTINATION_NONE && menuItem.type === TYPE_DROPDOWN_SUB_HEADING) {
                    var child2 = React.createRef();
                    this.menuItemsREF.push(child2);
                    this.menuItemsDOM.push(
                        <HeaderNavDropDownMobileSubHeadingComponent
                            dropDownData={menuItem}
                            key={menuItem.text}
                            closeNavPanel={this.props.closeNavPanel}
                            showModal={this.props.showModal}
                        />
                    )
                } else {
                    logger.log(LOG_LEVEL.DEBUG, "HeaderNavBarMobileComponent did not do anything with this", menuItem);
                }
            }
        }
    }
    getItems = () =>{
        this.menuItemsDOM = [];
        this.menuItemsREF = [];
        this.createMenuItems();
      return this.menuItemsDOM;
    }
    render() {
        if (this.props.menuItems) {
            return (
                <div className={this.topClassName}>
                    { this.getItems()}
                </div>
            )
        } else {
            return null;
        }
    }

}
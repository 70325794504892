import HttpClient from '../utils/HttpClient';
import moment from "moment";
import { 
    REST_CALENDAR_PATH 
} from '../constants/Rest.constants';
import {
    TDS_CALENDAR_EVENT_TYPE,
    TDS_CALENDAR_EVENT_DATE_FORMAT,
    TDS_CALENDAR_EARNING_DATE_FORMAT
} from '../constants/Calendar.constants';
import {
    LINE_BREAK,
    ICS_DATE_FORMAT
} from '../constants/Misc.constants';
import DownloadFileService from './DownloadFile.service';
import UtilsService from './Utils.service';

export default class CalendarService {

    static getCalendar() {
        return HttpClient.get(REST_CALENDAR_PATH);
    }
    static downloadEvent(event) {
        if (event.eventType === TDS_CALENDAR_EVENT_TYPE.MARKETING_EVENT || event.eventType === TDS_CALENDAR_EVENT_TYPE.CONFERENCE) {
            CalendarService.downloadMarketingConferenceEvent(event);
        } else if (event.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE) {
            CalendarService.downloadEarningsEvent(event);
        }
    }

    static downloadMarketingConferenceEvent(event) {
        if (!event) {
            return;
        }
        var title =  event.eventType + ': ' + event.description;
        var description = event.description + LINE_BREAK;
        if (event.staticCommentary) { 
            description += "Commentary: " + event.staticCommentary;
        }
        var eventDetails = {
            title: title,
            eventDate: moment.utc(event.startDate).format(ICS_DATE_FORMAT),
            eventDateEnd: moment.utc(event.endDate).format(ICS_DATE_FORMAT),
            description: description
        }
        var eventFile = DownloadFileService.generateVcalender(eventDetails);
        DownloadFileService.download(title + ".ics", eventFile);
    }

    static downloadEarningsEvent(event) {
        var title = TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE + ': ';
        if (event.company) {
            title += event.company.name;
            if (event.company.symbol) {
                title += ' (' + event.company.name + ')';
            }
            title += ' ' + event.recommendation;
            title += ' 12-Month Target Price: ' + event.priceTarget;
        }
        var description =  "";
        if (event.analyst) {
            description += event.analyst.lastName + ', ' + event.analyst.firstName + LINE_BREAK;
            description += event.analyst.phone + LINE_BREAK;
            description += event.analyst.email + LINE_BREAK;
        }
        if (event.confCallDetails) {
            if (event.confCallDetails.time) {
                description += "Time: " + event.confCallDetails.time + LINE_BREAK;
            }
            if (event.confCallDetails.confCallDate) {
                description += "Conference Call Date: " + moment.utc(event.confCallDetails.confCallDate).format(TDS_CALENDAR_EVENT_DATE_FORMAT) + LINE_BREAK;
            }
            if (event.confCallDetails.confCallNo) {
                description += "Conference Time: " + event.confCallDetails.confCallNo + LINE_BREAK;
            }
            if (event.confCallDetails.confCallNo) {
                description += "Conference Call: " + event.confCallDetails.confCallNo + LINE_BREAK;
            }
            if (event.confCallDetails.confCallNo) {
                description += "Code: " + event.confCallDetails.confCallNo + LINE_BREAK;
            }
            if (event.confCallDetails.otherNo) {
                description += "Other Number: " + event.confCallDetails.otherNo + LINE_BREAK;
            }
            if (event.confCallDetails.otherCode) {
                description += "Code: " + event.confCallDetails.otherCode + LINE_BREAK;
            }
            if (event.confCallDetails.replayNo) {
                description += "Replace Number: " + event.confCallDetails.replayNo + LINE_BREAK;
            }
            if (event.confCallDetails.replayCode) {
                description += "Code: " + event.confCallDetails.replayCode + LINE_BREAK;
            }
            if (event.confCallDetails.consenusEstimate) {
                description += "Consensus Estimate: " + event.confCallDetails.consenusEstimate + LINE_BREAK;
            }
        }
        if (event.earnings) {
            for (var row=0; row<event.earnings.length; row++) {
                for (var col=0; col<event.earnings[row].length; col++) {
                    description += UtilsService.addSpace(event.earnings[row][col], 25);
                }
                description += LINE_BREAK;
            }
        }
        if (event.staticCommentary) { 
            description += "Commentary: " + event.staticCommentary;
        }
        var eventDetails = {
            title: title,
            eventDate: moment.utc(event.startDate).format(ICS_DATE_FORMAT),
            eventDateEnd: moment.utc(event.endDate).format(ICS_DATE_FORMAT),
            description: description
        }
        var eventFile = DownloadFileService.generateVcalender(eventDetails);
        DownloadFileService.download(title + ".ics", eventFile);

    }

    static getEarningsMappings(event) {
        if ((!event && !event.earnings) || event.earnings.length == 0) { // eslint-disable-line
            return {
                earningsKeys: null,
                earningsValues: null
            }
        }
        var earningsKeys = Object.keys(event.earnings[0]);
        if (earningsKeys.length != 6) { // eslint-disable-line
            return;
        }
        var earningsValues = [];
        var splicedEarningsKeys = [];
        for (var j = 0; j < earningsKeys.length; j++) {
            if (!earningsKeys[j].match('date')) {
                splicedEarningsKeys.push(earningsKeys[j]);
            }
        }
        earningsValues.push(splicedEarningsKeys);
        for (var i = 0; i < event.earnings.length; i++) {
            var earningsRow = [];
            var column1 = event.earnings[i][earningsKeys[0]];
            if (event.earnings[i][earningsKeys[1]]) {
                var earningsColumnDate = moment.utc(event.earnings[i][earningsKeys[1]]).format(TDS_CALENDAR_EARNING_DATE_FORMAT);
                column1 += ' (' + earningsColumnDate + ')';
            }
            earningsRow.push(column1);
            earningsRow.push(event.earnings[i][earningsKeys[2]]);
            earningsRow.push(event.earnings[i][earningsKeys[3]]);
            earningsRow.push(event.earnings[i][earningsKeys[4]]);
            earningsRow.push(event.earnings[i][earningsKeys[5]]);
            earningsValues.push(earningsRow);
        }
        return {
            earningsKeys: earningsKeys,
            earningsValues: earningsValues
        }
    }
    
}
import React from 'react';
import CalendarDayEventComponent from './CalendarDayEvent.component';
import {
    isMobileOnly
} from "react-device-detect";
export default class CalendarDayComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.columnClass = '';
        if (this.props.day && this.props.day.events) {
            if (this.props.day.events.length == 1) { // eslint-disable-line
                this.columnClass = ' single';
            }
            if (this.props.day.events.length == 2) { // eslint-disable-line
                this.columnClass = ' double';
            }
            if (this.props.day.events.length > 3) { // eslint-disable-line
                this.columnClass = ' over-three';
            }
        }
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }
    handleEventClick(id) {
        if (this.props.handleEventClick) {
            this.props.handleEventClick(id)
        }
    }
    handleDayClick(day, week, column) {
        if (isMobileOnly) {
            if (this.props.handleDayClick) {
                this.props.handleDayClick(day, week, column);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                { this.props.day && this.props.day.dayOfMonth &&
                    <div
                        className={'tds-calender-day-column' + this.columnClass}
                        onClick={() => this.handleDayClick(this.props.day.dayOfMonth, this.props.week, this.props.column)}
                    >
                        <span 
                            className={this.props.day.today ? 'tds-calender-day-label today' : 'tds-calender-day-label'}
                            
                        >
                            {this.props.day.dayOfMonth}
                        </span>
                        {
                            this.props.day.events && this.props.day.events.length > 0 &&
                            this.props.day.events.map((event, index) => {
                                return (
                                    <CalendarDayEventComponent
                                        key={'calendar-day-event-' + index}
                                        event={event}
                                        handleEventClick={this.handleEventClick}
                                        eventTypeMarketing={this.props.eventTypeMarketing}
                                        eventTypeEarnings={this.props.eventTypeEarnings}
                                        eventTypeConference={this.props.eventTypeConference}
                                    />
                                )
                            })
                        }
                    </div>
                }
                { this.props.day && !this.props.day.dayOfMonth &&
                    <div className="tds-calender-day-column">&nbsp;</div>
                }
            </React.Fragment>
        )
    }
}

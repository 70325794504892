import React from 'react';
import { Link } from "react-router-dom";
import Spinner from '../spinner/Spinner.component';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import ActionListFullReportComponent from './ActionListFullReport.component';
import CoveragePageService from '../../services/CoveragePage.service';
import PaginationComponent from '../pagination/Paginator.component';
import ErrorComponent from '../misc/error/Error.component';
import {
    COVERAGE_ACTION_LIST_PAGE_SIZE
} from '../../constants/CoveragePage.constants';
import {
    TDS_DATE_FORMAT,
    FORWARD_SLASH,
    CURRENCIES,
    ERROR_TYPE
} from '../../constants/Misc.constants';
import {
    LINK_EQUITIES_COMPANY_DETAILS_PAGE
} from '../../constants/LinkPaths.constants';
import SecureLinkService from '../../services/SecureLink.service';
export default class CoverageActionListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            loading: true,
            error: false,
            data: null
        };
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        var params = {
            page: this.state.currentPage,
            size: COVERAGE_ACTION_LIST_PAGE_SIZE
        };
        CoveragePageService.getActionList(params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            loading: false,
            data: data
        });
    }
    getDataError(error) {
        this.setState({
            loading: false,
            data: null,
            error: true
        });
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.getData
        );
    }
    render() {
        return (
            <div className="tds-coverage-page-tab-content-action-list">
                {
                    this.state.loading && !this.state.error &&
                    <div className="tds-loading">
                        <Spinner />
                    </div>
                }
                {
                    !this.state.loading && this.state.error &&
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                }
                {
                    !this.state.loading && !this.state.error && this.state.data && this.state.data.results && this.state.data.results.length === 0 &&
                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                }
                {
                    !this.state.loading && !this.state.error && this.state.data && this.state.data.results.length > 0 &&
                    <React.Fragment>
                        <div className="tds-action-list-tab">
                            <div className="tds-action-list-tab-row header">
                                <div className="tds-action-list-tab-column company">
                                    Company
                                </div>
                                <div className="tds-action-list-tab-column symbol">
                                    Symbol
                                </div>
                                <div className="tds-action-list-tab-column target">
                                    12 Month Target
                                </div>
                                <div className="tds-action-list-tab-column current-price">
                                    Current Price
                                </div>
                                <div className="tds-action-list-tab-column return-since-added">
                                    Return Since Added
                                </div>
                                <div className="tds-action-list-tab-column last-action-note">
                                    Last Action Note
                                </div>
                                <div className="tds-action-list-tab-column action">
                                    Date Added To Action List
                                </div>
                            </div>
                            {
                                this.state.data.results.map(
                                    (item, index) => {
                                        const lastActionNotePdfLink = SecureLinkService.buildSecureLink(item.lastActionNotePdfLink);
                                        return (
                                            <div
                                                key={'tds-action-list-tab-row-' + index}
                                                className="tds-action-list-tab-row"
                                            >
                                                <div className="tds-action-list-tab-column company">
                                                    <Link
                                                        title={item.companyName}
                                                        to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                    >
                                                        {item.companyName}
                                                    </Link>
                                                </div>
                                                <div className="tds-action-list-tab-column symbol">
                                                    <Link
                                                        title={item.companyName}
                                                        to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                    >
                                                        {item.companySymbol}
                                                    </Link>
                                                </div>
                                                <div className="tds-action-list-tab-column target">
                                                    {
                                                        item.twelveMonthTarget &&
                                                        <React.Fragment>
                                                            ${Number(item.twelveMonthTarget).toFixed(2)}
                                                            {item.currency === CURRENCIES.USD &&
                                                                <React.Fragment>
                                                                    (US)
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div className="tds-action-list-tab-column current-price">
                                                    {
                                                        item.currentPrice &&
                                                        <React.Fragment>
                                                            ${item.currentPrice}
                                                            {item.currency === CURRENCIES.USD &&
                                                                <React.Fragment>
                                                                    (US)
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div className="tds-action-list-tab-column return-since-added">
                                                    {
                                                        item.returnSinceAdded &&
                                                        <React.Fragment>{item.returnSinceAdded}%</React.Fragment>
                                                    }
                                                </div>
                                                <div className="tds-action-list-tab-column last-action-note">
                                                    <a
                                                        href={lastActionNotePdfLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Last Action Note"
                                                    >
                                                        <FormatedDateComponent
                                                            date={item.lastActionNoteDate}
                                                            format={this.props.dateFormat || TDS_DATE_FORMAT}
                                                        />
                                                    </a>
                                                </div>
                                                <div className="tds-action-list-tab-column action">
                                                    <FormatedDateComponent
                                                        date={item.addedToActionListDate}
                                                        format={this.props.dateFormat || TDS_DATE_FORMAT}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </div>
                        <div className="tds-action-list-tab-mobile">
                            <div className="tds-action-list-tab-row-mobile-frozen-column">
                                <div className="tds-action-list-tab-row-mobile-frozen header">
                                    <div className="tds-action-list-tab-column company">
                                        Company
                                    </div>
                                </div>
                                {
                                    this.state.data.results.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    key={'tds-action-list-tab-row-mobile-frozen' + index}
                                                    className="tds-action-list-tab-row-mobile-frozen"
                                                >
                                                    <div className="tds-action-list-tab-column company">
                                                        <Link
                                                            title={item.companyName}
                                                            to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                        >
                                                            {item.companyName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                            </div>
                            <div className="tds-action-list-tab-row-mobile-unfrozen-columns">
                                <div className="tds-action-list-tab-row-mobile-unfrozen header">
                                    <div className="tds-action-list-tab-column symbol">
                                        Symbol
                                    </div>
                                    <div className="tds-action-list-tab-column target">
                                        12 Month Target
                                    </div>
                                    <div className="tds-action-list-tab-column current-price">
                                        Current Price
                                    </div>
                                    <div className="tds-action-list-tab-column return-since-added">
                                        Return Since Added
                                    </div>
                                    <div className="tds-action-list-tab-column last-action-note">
                                        Last Action Note
                                    </div>
                                    <div className="tds-action-list-tab-column action">
                                        Date Added To Action List
                                    </div>
                                </div>
                                {
                                    this.state.data.results.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    key={'tds-action-list-tab-row-mobile-unfrozen' + index}
                                                    className="tds-action-list-tab-row-mobile-unfrozen"
                                                >
                                                    <div className="tds-action-list-tab-column symbol">
                                                        <Link
                                                            title={item.companyName}
                                                            to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + item.issuerId}
                                                        >
                                                            {item.companySymbol}
                                                        </Link>
                                                    </div>
                                                    <div className="tds-action-list-tab-column target">
                                                        {
                                                            item.twelveMonthTarget &&
                                                            <React.Fragment>
                                                                ${item.twelveMonthTarget}
                                                                {item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="tds-action-list-tab-column current-price">
                                                        {
                                                            item.currentPrice &&
                                                            <React.Fragment>
                                                                ${item.currentPrice}
                                                                {item.currency === CURRENCIES.USD &&
                                                                    <React.Fragment>
                                                                        (US)
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="tds-action-list-tab-column return-since-added">
                                                        {
                                                            item.returnSinceAdded &&
                                                            <React.Fragment>${item.returnSinceAdded}</React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="tds-action-list-tab-column last-action-note">
                                                        <a
                                                            href={item.lastActionNotePdfLink}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Last Action Note"
                                                        >
                                                            <FormatedDateComponent
                                                                date={item.lastActionNoteDate}
                                                                format={this.props.dateFormat || TDS_DATE_FORMAT}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="tds-action-list-tab-column action">
                                                        <FormatedDateComponent
                                                            date={item.addedToActionListDate}
                                                            format={this.props.dateFormat || TDS_DATE_FORMAT}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                    <React.Fragment>
                        <PaginationComponent
                            currentPage={this.state.currentPage}
                            resultsPerPage={COVERAGE_ACTION_LIST_PAGE_SIZE}
                            total={this.state.data.total}
                            goToPage={this.goToPage}
                        />
                    </React.Fragment>
                }
                <ActionListFullReportComponent />
            </div>
        )
    }
}

import React from 'react';

export default class SalesLiteraturePageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="tds-container">
                <div className="tds-generic-content-page">
                    <h1>Sales Literature Disclaimer Summary</h1>

                    <p>This communication has been prepared by sales personnel of the Institutional Equities, Foreign Exchange,
                    Listed & Over-the-Counter (OTC) Derivatives and/or Fixed Income sales and trading desks of TD Securities
                    solely for the use of sophisticated institutional and/or commercial clients. This communication shall
                    not be distributed or duplicated in whole or in part by any means without the prior written consent of
                TD Securities.</p>

                    <p>This communication is for informational purposes only. It is not intended as an offer or solicitation for
                    the purchase or sale of any financial instrument, investment product or service, or as an official
                    confirmation of any transaction. TD Securities is neither making any investment recommendation nor
                    providing any professional or advisory services relating to the matters described herein. Neither TD
                    Securities nor any of its businesses or representatives makes any representation or warranty, express or
                    implied, as to the accuracy or completeness of any information contained herein. TD Securities does not
                    accept any liability for losses, whether direct or consequential, resulting from the use of this
                communication.</p>

                    <p>This communication is not a research report and, accordingly, is not subject to certain rules applicable
                    to the publication and distribution of research reports. For important information and disclosures
                    relating to TD Securities' research reports, please click here. Views or opinions expressed herein
                    represent the personal views of the writer, do not necessarily reflect the views of TD Securities or its
                    affiliates, and may differ from the views and opinions expressed by the TD Securities Research
                Department.</p>

                    <p>TD Securities and/or its affiliates may have already effected transactions based upon the information
                    contained in this communication, or may effect transactions contrary to the information contained in
                    this communication. TD Securities may fill client orders based on information contained in this
                    communication from its proprietary positions, including positions acquired in anticipation of such
                    client orders. In addition, in the ordinary course of business, TD Securities may have positions (long
                    or short) and effect transactions or make markets in securities or other instruments mentioned herein,
                    or provide advice or loans to, or participate in the underwriting or restructuring of the obligations
                    of, issuers mentioned herein, all without regard to the effect of such activities on the information
                    contained in this communication. TD Securities or its affiliates may have provided or may provide
                    investment banking, capital markets or other services to any companies referred to in this
                communication.</p>

                    <p>This communication does not provide a sufficient basis for an investment decision and TD Securities makes
                    no representation as to the suitability of any investment described herein. You should conduct
                    independent due diligence when making an investment decision. For further information, please contact
                your sales representative.</p>

                    <p>All information contained in this communication is subject to change without notice and TD Securities
                    does not assume any obligation to update the information. Subsequent events may have an adverse effect
                upon the outcome of any trade based on the information contained in this communication.</p>

                    <p>The products described herein are (i) not insured by the FDIC, (ii) not a deposit or other obligation of,
                    or guaranteed by, a depository institution, and (iii) subject to investment risks, including possible
                loss of the principal amount invested.</p>

                    <p>TD Securities is a trademark of The Toronto-Dominion Bank, representing TD Securities Inc., TD Securities
                    (USA) LLC, TD Securities Limited, London and certain investment activities of the Toronto-Dominion Bank.
                Copyright 2021 by TD Securities. All rights reserved.</p>

                    <p><strong>If this is in relation to a swap and going to a "US person" as defined under the Commodity
                    Futures Trading Commission rules and regulations, the following disclaimers apply:</strong></p>

                    <p>The information is for discussion purposes only and is not an offer, commitment, recommendation or
                    solicitation by TD Securities to enter into any transaction, including a swap with you, nor is it an
                official confirmation of terms.</p>

                    <p>In the event that you seek to amend or extend any transaction or enter into any swap transaction referred
                    to herein, (x) to the extent that you are not an "eligible contract participant" ("ECP") within the
                    meaning of Section 1a(18) of the Commodity Exchange Act, as amended (the "Act"), (y) to the extent you
                    have not provided a written representation specifying the provision of the Act or the paragraph of the
                    rule promulgated thereunder that describes its status as an ECP or (z) based on our credit determination
                    at that time, we may not be able to enter into a swap transaction or amendment to the swap transaction
                which may result in you incurring additional costs and payments</p>
                </div>
            </div>
        )
    }
}

import React from 'react';
import { Link } from "react-router-dom";
import {
    LINK_EQUITIES_COMPANY_DETAILS_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    ERROR_TYPE,
    FORWARD_SLASH
} from '../../../constants/Misc.constants';
import ErrorComponent from '../error/Error.component';
export default class CompanyListSectionComponent extends React.Component {

    hasCompanies() {
        return this.props.data && this.props.data.companies && this.props.data.companies.length > 0
    }
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className={this.props.singleLetter ? 'tds-company-list-letter-container single-letter' : 'tds-company-list-letter-container'}>
                { 
                    this.hasCompanies() &&
                    <>
                        <h2 className="tds-company-list-title">{this.props.data.group}</h2>
                        {                 
                            this.props.data.companies.map(
                                (company, index) => {
                                    return (
                                        <Link
                                            key={'company-list-section-item-' + company.issuerId + '-' + index}
                                            className="tds-company-list-item"
                                            title={company.name}
                                            to={LINK_EQUITIES_COMPANY_DETAILS_PAGE + FORWARD_SLASH + company.issuerId}
                                        >
                                            {company.name} ({company.symbol})
                                        </Link>
                                    )
                                }
                            )
                        }                                            
                    </>
                }
                {
                    this.props.singleLetter && !this.hasCompanies() &&
                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                }
            </div>
        )
    }
}
import React from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import ShowContentComponent from '../../misc/show-content/ShowContent.component';
import ErrorComponent from '../../misc/error/Error.component';
import Spinner from "../../spinner/Spinner.component";
import ThematicDataflowService from '../../../services/ThematicDataflow.service';
import PaginationComponent from '../../pagination/Paginator.component';
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../../misc/mailto/Mailto.component';
import DownloadFileService from '../../../services/DownloadFile.service';
import EnvironmentService from '../../../services/Environment.service';
import UtilsService from '../../../services/Utils.service';
import {
    TDS_DATE_FORMAT,
    EMAIL_DELIMITER,
    FORWARD_SLASH,
    HTTPS,
    KEY_ENTER,
    ERROR_TYPE, TEAM
} from '../../../constants/Misc.constants';
import {
    RESEARCH_DATA_PAGE_SIZE,
    RESEARCH_CENTRAL_BANK_TYPES,
    THEMATIC_RESEARCH_SEARCH_PARAMS_DOCUMENT_TYPE,
    CENTRAL_BANK_SEARCH_PARAMS_DOCUMENT_TYPE
} from '../../../constants/RegionPage.constants';
import {
    LINK_PUBLICATION_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME,
    SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME,
    SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME
} from "../../../constants/SearchResults.constants";

import {
    LINK_SEARCH_PAGE
} from "../../../constants/LinkPaths.constants";
import { buildSecureLink } from '../../../services/SecureLink.service';

class ThematicResearchDataflowComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            keywords: '',
            currentPage: 1,
            loading: true,
            error: false,
            data: null
        };
        this.goToPage = this.goToPage.bind(this);
        this.getData = this.getData.bind(this);
        this.getThematicsDataflowData = this.getThematicsDataflowData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.handleViewAllClick = this.handleViewAllClick.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleKeywordSearchClick = this.handleKeywordSearchClick.bind(this);
        this.handlePDFDownloadClick = this.handlePDFDownloadClick.bind(this);
        this.buildShareLink = this.buildShareLink.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }
    handleKeyPress(event) {
        if (event.key === KEY_ENTER) {
            this.goToPage(1);
        }
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.getData
        );
    }
    getData() {
        this.setState(
            {
                loading: true,
                error: false
            },
            this.getThematicsDataflowData
        );
    }
    getThematicsDataflowData() {
        var params = {
            page: this.state.currentPage,
            size: RESEARCH_DATA_PAGE_SIZE
        };
        if (this.state.keywords !== "") {
            params.searchTxt = this.state.keywords;
        } else {
            params.searchTxt = "";
        }
        ThematicDataflowService.getThematicDataflowData(this.props.type, this.props.region, params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(_data) {
        this.setState(
            {
                data: _data,
                loading: false
            }
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false
            }
        );
    }
    handleViewAllClick(event) {
        event.preventDefault();
        var _documentType = "";
        console.log("UtilsService.getRegionParams(this.props.regionType)",UtilsService.getRegionParams(this.props.region) ,this.props.region )
        if (this.props.type === RESEARCH_CENTRAL_BANK_TYPES.CENTRAL_BANK) {
            _documentType = CENTRAL_BANK_SEARCH_PARAMS_DOCUMENT_TYPE;
        } else if (this.props.type === RESEARCH_CENTRAL_BANK_TYPES.THEMATIC_RESEARCH) {
            _documentType = THEMATIC_RESEARCH_SEARCH_PARAMS_DOCUMENT_TYPE
        }
        this.props.history.push(LINK_SEARCH_PAGE + "?" +
            SEARCH_RESULTS_REGIONS_QUERY_PARAM_NAME + '=' + UtilsService.getRegionParams(this.props.region) + "&" +
            SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME + '=' + _documentType + "&" +
            SEARCH_RESULTS_TEAM_QUERY_PARAM_NAME + '=' + TEAM.STR + "&" +
            SEARCH_RESULTS_DOCUMENT_TYPE_MATCH_QUERY_PARAM_NAME + '=true');
    }
    handleKeywordChange(event) {
        this.setState(
            {
                keywords: event.target.value
            }
        )
    }
    handleKeywordSearchClick() {
        this.goToPage(1);
    }
    handlePDFDownloadClick = (event) => {
        event.preventDefault();
        DownloadFileService.downloadPDF(event.target.dataset.firmid, event.target.dataset.productid, event.target.dataset.filename);
    }
    buildShareLink = (firmName, contentId) => {
        if (firmName && contentId) {
            return HTTPS + EnvironmentService.hostname + LINK_PUBLICATION_PAGE + firmName + FORWARD_SLASH + contentId;
        } else {
            return "";
        }
    }
    render() {
        return (
            <React.Fragment>
                <ShowContentComponent
                    if={this.state.loading}
                >
                    <div className="tds-thematic-research-container">
                        <Spinner textAlign='center' outerWidth='100%' />
                    </div>
                </ShowContentComponent>
                <ShowContentComponent
                    if={!this.state.loading && this.state.error}
                >
                    <div className="tds-thematic-research-container">
                        <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                    </div>
                </ShowContentComponent>
                { !this.state.loading && this.state.data && this.state.data.results &&
                    <React.Fragment>
                        <div className="tds-thematic-research-container">
                            <div className="tds-thematic-research-search-container">
                                <div className="tds-thematic-research-search-bar form-group">
                                    <span
                                        className="td-icon td-icon-search icon-thematic-research-search"
                                        title="Search"
                                        onClick={this.handleKeywordSearchClick}
                                    ></span>
                                    <input className="form-control"
                                        id={'tds-thematic-research' + this.props.type}
                                        placeholder="Keywords"
                                        type="text"
                                        value={this.state.keywords}
                                        onChange={this.handleKeywordChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>
                            </div>
                            {
                                !this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length === 0 && !this.state.keywords &&
                                <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />

                            }
                            {
                                !this.state.loading && this.state.data && this.state.data.results && this.state.data.results.length === 0 && this.state.keywords &&
                                <ErrorComponent type={ERROR_TYPE.SEARCH_RESULTS} />

                            }
                            {
                                this.state.data.results.length > 0 &&
                                <React.Fragment>


                                    <div className="tds-thematic-research-row header-row">
                                        <div className="tds-thematic-research-row-column date">
                                            Date
                                </div>
                                        <div className="tds-thematic-research-row-column title">
                                            Title
                                </div>
                                        <div className="tds-thematic-research-row-column product">
                                            Product/Country
                                </div>
                                        <div className="tds-thematic-research-row-column action">
                                            Action
                                </div>
                                    </div>

                                    {this.state.data.results.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    className="tds-thematic-research-row"
                                                    key={'tds-thematic-research-row-' + this.props.type + index}
                                                >
                                                    <div className="tds-thematic-research-row-column date">
                                                        <FormatedDateComponent
                                                            date={item.date}
                                                            format={TDS_DATE_FORMAT}
                                                        />
                                                    </div>
                                                    <div className="tds-thematic-research-row-column title">
                                                        {item.title}
                                                    </div>
                                                    <div className="tds-thematic-research-row-column product">
                                                        {item.documentType}
                                                    </div>
                                                    <div className="tds-thematic-research-row-column action">
                                                        { item.showHtml &&
                                                            <ShowContentComponent
                                                                if={item.firmName && item.contentId}
                                                            >
                                                                <Link
                                                                    to={LINK_PUBLICATION_PAGE + item.firmName + FORWARD_SLASH + item.contentId}
                                                                    title={'View'}
                                                                >
                                                                    <img alt={'View'} className="tds-view-icon" src="/assets/img/view-icon.svg" />
                                                                </Link>
                                                            </ShowContentComponent>
                                                        }
                                                        <ShowContentComponent
                                                            if={item.firmId && item.productId && item.title}
                                                        >
                                                            <a
                                                                href={buildSecureLink(item.securePdfLink)}
                                                                title="View PDF"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <span
                                                                    className="td-icon td-icon-insights icon-small"
                                                                    data-firmid={item.firmId}
                                                                    data-productid={item.productId}
                                                                    data-filename={item.title}
                                                                ></span>
                                                            </a>
                                                        </ShowContentComponent>
                                                        <ShowContentComponent
                                                            if={item.htmlLink}
                                                        >
                                                            <MailtoComponent
                                                                email={''}
                                                                titleText={'Share'}
                                                                icon={true}
                                                                iconType={'share'}
                                                                subject={item.title}
                                                                body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + this.buildShareLink(item.firmName, item.contentId)}
                                                            />
                                                        </ShowContentComponent>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                    }
                                </React.Fragment>
                            }
                        </div>
                        {
                            this.state.data.results && this.state.data.results.length > 0 &&
                            <React.Fragment>
                                <PaginationComponent
                                    currentPage={this.state.currentPage}
                                    resultsPerPage={RESEARCH_DATA_PAGE_SIZE}
                                    total={this.state.data.total}
                                    goToPage={this.goToPage}
                                />
                                <div className='tds-thematic-research-view-all'>
                                    <a
                                        href="# "
                                        onClick={this.handleViewAllClick}
                                        title="View All"
                                    >View All</a>
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(ThematicResearchDataflowComponent);
export const REGION_PAGE_TYPE_UNITED_STATES = 'US';
export const REGION_PAGE_TYPE_CANADA = 'Canada';
export const REGION_PAGE_TYPE_ASIA_PACIFIC = 'Asia-Pacific';
export const REGION_PAGE_TYPE_JAPAN = 'Japan';
export const REGION_PAGE_TYPE_NEW_ZEALAND = 'New-Zealand';
export const REGION_PAGE_TYPE_AUSTRALIA = 'Australia';
export const REGION_PAGE_TYPE_EUROPE = 'Europe';
export const REGION_PAGE_TYPE_EUROZONE = 'Eurozone';
export const REGION_PAGE_TYPE_UNITED_KINGDOM = 'United-Kingdom';
export const REGION_PAGE_TYPE_SCANDIES = 'Scandies';
export const REGION_PAGE_TYPE_EMEA = 'EMEA';
export const REGION_PAGE_TYPE_ASIA = 'Asia';
export const REGION_PAGE_TYPE_CHINA = 'China';
export const REGION_PAGE_TYPE_ASIA_EX_CHINA = 'Asia-Ex-China';
export const REGION_PAGE_TYPE_LATIN_AMERICA = 'Latam';


export const REGION_PAGE_TYPES = {
    UNITED_STATES: REGION_PAGE_TYPE_UNITED_STATES,
    CANADA: REGION_PAGE_TYPE_CANADA,
    ASIA_PACIFIC: REGION_PAGE_TYPE_ASIA_PACIFIC,
    JAPAN: REGION_PAGE_TYPE_JAPAN,
    NEW_ZEALAND: REGION_PAGE_TYPE_NEW_ZEALAND,
    AUSTRALIA: REGION_PAGE_TYPE_AUSTRALIA,
    EUROPE: REGION_PAGE_TYPE_EUROPE,
    EUROZONE: REGION_PAGE_TYPE_EUROZONE,
    UNITED_KINGDOM: REGION_PAGE_TYPE_UNITED_KINGDOM,
    SCANDIES: REGION_PAGE_TYPE_SCANDIES,
    EMEA: REGION_PAGE_TYPE_EMEA,
    ASIA: REGION_PAGE_TYPE_ASIA,
    CHINA: REGION_PAGE_TYPE_CHINA,
    ASIA_EX_CHINA: REGION_PAGE_TYPE_ASIA_EX_CHINA,
    LATIN_AMERICA: REGION_PAGE_TYPE_LATIN_AMERICA,
};

export const REGION_PAGE_VIEW_ALL_PARAMS = {
    UNITED_STATES: "US",
    CANADA: "Canada",
    ASIA_PACIFIC: "Asia-Pacific",
    JAPAN: "Japan",
    NEW_ZEALAND: "New Zealand",
    AUSTRALIA: "Australia",
    EUROPE: "Eurozone,UK,Norway,Sweden",
    EUROZONE: "Eurozone",
    UNITED_KINGDOM: "UK",
    SCANDIES: "Norway,Sweden",
    EMEA: "EMEA",
    ASIA: "Asia",
    CHINA: "China",
    ASIA_EX_CHINA: "Hong Kong,India,Indonesia,Malaysia,Philippines,Singapore,South Korea,Taiwan,Thailand",
    LATIN_AMERICA: "Latam",
};

export const REGION_PAGE_TOP_TABS = [
    { 
        regions: [ REGION_PAGE_TYPE_ASIA_PACIFIC, REGION_PAGE_TYPE_JAPAN, REGION_PAGE_TYPE_NEW_ZEALAND, REGION_PAGE_TYPE_AUSTRALIA ],
        labels: [ 'All', REGION_PAGE_TYPE_JAPAN, "New Zealand", REGION_PAGE_TYPE_AUSTRALIA ],
    },
    { 
        regions: [ REGION_PAGE_TYPE_EUROPE, REGION_PAGE_TYPE_EUROZONE, REGION_PAGE_TYPE_UNITED_KINGDOM, REGION_PAGE_TYPE_SCANDIES ],
        labels: [ 'All', REGION_PAGE_TYPE_EUROZONE, "United Kingdom", REGION_PAGE_TYPE_SCANDIES ]
    },
    { 
        regions: [ REGION_PAGE_TYPE_ASIA, REGION_PAGE_TYPE_CHINA,  REGION_PAGE_TYPE_ASIA_EX_CHINA ],
        labels: [ 'All', REGION_PAGE_TYPE_CHINA,  'Asia Ex-China' ]
    }
];

export const REGION_PAGE_TABS = {
    REAL_TIME_COVERAGE: 'REAL_TIME_COVERAGE',
    MOST_POPULAR: 'MOST_POPULAR',
    TRADE_IDEAS: 'TRADE_IDEAS'
}

export const RESEARCH_CENTRAL_BANK_TABS = {
    THEMATIC_RESEARCH: 'THEMATIC_RESEARCH',
    CENTRAL_BANK: 'CENTRAL_BANK'
}

export const REGION_ASIA_TABS = {

}

export const THEMATIC_RESEARCH_TYPE = 'thematic';
export const CENTRAL_BANK_DATA_FLOW_TYPE = 'dataflow';

export const RESEARCH_CENTRAL_BANK_TYPES = {
    THEMATIC_RESEARCH: THEMATIC_RESEARCH_TYPE,
    CENTRAL_BANK: CENTRAL_BANK_DATA_FLOW_TYPE
}

export const RESEARCH_DATA_PAGE_SIZE = 6;

export const THEMATIC_RESEARCH_SEARCH_PARAMS_DOCUMENT_TYPE = 'Market Musings,Chart Logic,Model Portfolio Update';
export const CENTRAL_BANK_SEARCH_PARAMS_DOCUMENT_TYPE = 'Central Bank Playbook,Preview,Commentary';
import React from 'react';
import { REQUEST_ACCESS_MAIL_FROM, REQUEST_ACCESS_MAIL_FROM_MAILTO } from '../../../constants/LandingPage.constants';
export default class AclAccessDeniedComponent extends React.Component {
    render() {
        return (
            <div className="tds-container tds-acl-access-denied" style={
                {
                    'marginTop': '50px',
                }
            }>
                <h1>Access Denied</h1>
                <p>
                    To request access, please contact <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a>
                </p>
            </div>
        )
    }
}
import React from 'react';
import ResearchContentComponent from "./ResearchContent.component";
import {isNotEmptyArray} from "../../utils/valueUtils";

function ResearchContentListComponent({latestContentByTypes}) {
    return (
        <div className="tds-research-content-list">
            {
                isNotEmptyArray(latestContentByTypes?.actionNotes) &&
                <ResearchContentComponent name="Action Notes" type="Action Note" content={latestContentByTypes?.actionNotes[0]} ></ResearchContentComponent>
            }
            {
                isNotEmptyArray(latestContentByTypes?.bulletins) &&
                <ResearchContentComponent name="Bulletins" type="Company Bulletin,Industry Bulletin" content={latestContentByTypes?.bulletins[0]} ></ResearchContentComponent>
            }
            {
                isNotEmptyArray(latestContentByTypes?.industryResearch) &&
                <ResearchContentComponent name="Research Reports / Industry Notes" type="Research Report,Industry Note" content={latestContentByTypes?.industryResearch[0]} ></ResearchContentComponent>
            }
            {
                isNotEmptyArray(latestContentByTypes?.actionList) &&
                <ResearchContentComponent name="Action List" type="Action List" content={latestContentByTypes?.actionList[0]} ></ResearchContentComponent>
            }
            {
                isNotEmptyArray(latestContentByTypes?.investmentStrategy) &&
                <ResearchContentComponent name="Investment Strategy" type="Investment Strategy" content={latestContentByTypes?.investmentStrategy[0]} ></ResearchContentComponent>
            }
            {
                isNotEmptyArray(latestContentByTypes?.supplementalResearch) &&
                <ResearchContentComponent name="Supplemental Research" type="Comp Tables" content={latestContentByTypes?.supplementalResearch[0]} ></ResearchContentComponent>
            }
        </div>
    );
}

export default ResearchContentListComponent;
import React from 'react';
import { withRouter } from "react-router-dom";
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import {
    TDS_DATE_FORMAT
} from '../../constants/Misc.constants';

class ASGTrustReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false
        }
        this.handleExpand = this.handleExpand.bind(this);
    }
    handleExpand() {
        this.setState(
            {
                expand: !this.state.expand
            }
        )
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data &&
                    <div className="tds-asset-securitization-page-results-section-data-row trust-report">
                        <div className="tds-asset-securitization-page-results-section-data-column expand">
                            {!this.state.expand && <span className="td-icon td-icon-expand" onClick={this.handleExpand}></span>}
                            {this.state.expand && <span className="td-icon td-icon-collapse" onClick={this.handleExpand}></span>}
                        </div>
                        <div className="tds-asset-securitization-page-results-section-data-column title">
                            {this.props.data.trust}
                        </div>
                        <div className="tds-asset-securitization-page-results-section-data-column region">
                            {this.props.data.region}
                        </div>
                        {
                            this.props.data && this.props.data.trustDocs && this.props.data.trustDocs.length > 0 && this.state.expand &&
                            this.props.data.trustDocs.map((item, index) => {
                                return (
                                    <div className="tds-asset-securitization-page-results-section-data-row-expanded hidden" key={index}>
                                        <div className="tds-asset-securitization-page-results-section-data-column expand">
                                            &nbsp;
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-data-column title">
                                            {item.trustType} {item.documentType}
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-data-column region">
                                            {item.region === 'US' ? 'United States' : item.region}
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-data-column date">
                                            <FormatedDateComponent
                                                date={item.date}
                                                format={TDS_DATE_FORMAT}
                                            />
                                        </div>
                                        <div className="tds-asset-securitization-page-results-section-data-column action">
                                            { item.showHtml &&
                                                <a
                                                href={item.secureHtmlLink || item.htmlLink}
                                                title="View"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                >
                                                    <img
                                                        className="tds-view-icon"
                                                        alt={'View'}
                                                        src="/assets/img/view-icon.svg" />
                                                </a>
                                            }
                                            <a
                                                href={item.securePdfLink || item.pdfLink}
                                                title="View PDF"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className="td-icon td-icon-insights"></span>
                                            </a>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(ASGTrustReportComponent);
import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import SideBarComponent from "../side-bar/SideBar.component";
import Spinner from "../spinner/Spinner.component";
import ErrorComponent from "../misc/error/Error.component";
import UserService from "../../services/User.service";
import AclAccessDeniedComponent from "../misc/acl-access-denied/AclAccessDenied.component";
import FirstTakePublicationsComponent from "./FirstTakePublications.component";
import {
    ERROR_TYPE
} from "../../constants/Misc.constants";
import { ACL_EQUITIES_RESEARCH } from "../../constants/Entitlements.constants";
import ResearchPageService from "../../services/ResearchPage.service";
import { RESEARCH_PAGE_TITLE } from "../../constants/PageTitle.constants";
import ResearchContentListComponent from "./ResearchContentList.component";
import ResearchPageTabsComponent from "./tabs/ResearchPageTabs.component";
import queryString from "query-string";
import { LINK_EQUITIES_CA_RESEARCH_ANALYST_PAGE, LINK_EQUITIES_CA_RESEARCH_COMPANY_PAGE, LINK_EQUITIES_CA_RESEARCH_INDUSTRY_PAGE } from "../../constants/LinkPaths.constants";

class ResearchPageComponent extends React.Component {
    constructor(props) {
        super(props);
        const urlQueryParams = queryString.parse(this.props?.location?.search);
        this.state = {
            loading: true,
            error: false,
            data: null,
            latestContentByTypes: null,
            researchType: urlQueryParams?.researchType
        };
         
        this.renderRedirect = this.renderRedirect.bind(this);
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_RESEARCH);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
    }
    componentDidMount() {
        document.title = RESEARCH_PAGE_TITLE;
        if (this.hasAccess) {
            this.getData();
        }
    }
    getData() {
        ResearchPageService.getResearchLatestByTypes().then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            loading: false,
            latestContentByTypes: data,
        });
    }
    getDataError(error) {
        this.setState({
            loading: false,
            data: null,
            latestContentByTypes: null,
            error: true,
        });
    }
    renderRedirect = () => {
        if(this.state.researchType === 'Analyst') {
            return <Redirect to={LINK_EQUITIES_CA_RESEARCH_ANALYST_PAGE} />
        } else if(this.state.researchType === 'Company') {
            return <Redirect to={LINK_EQUITIES_CA_RESEARCH_COMPANY_PAGE} />
        }else if(this.state.researchType === 'Industry') {
            return <Redirect to={LINK_EQUITIES_CA_RESEARCH_INDUSTRY_PAGE} />
        }
        
      }
    render() {
        return (
            <React.Fragment>
                {this.renderRedirect()}
                {!this.hasAccess && <AclAccessDeniedComponent />}
                {this.hasAccess && (
                    <div className="tds-container tds-research-page">
                        <h1>Research</h1>                        
                        {!this.state.loading && this.state.latestContentByTypes && (
                            <ResearchContentListComponent
                                latestContentByTypes={this.state.latestContentByTypes}
                            ></ResearchContentListComponent>
                        )}
                        <FirstTakePublicationsComponent />
                        <div className="tds-three-column-container">
                            {this.state.loading && (
                                <Spinner noContainer={true} outerWidth={"100%"} />
                            )}
                            {!this.state.loading && this.state.error && (
                                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                            )}
                            <ResearchPageTabsComponent />
                        </div>
                        <SideBarComponent />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(ResearchPageComponent);

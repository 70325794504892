export const TRADE_IDEAS_PRODUCT_TYPE = 'Product';
export const TRADE_IDEAS_REGION_TYPE = 'Region';

export const TRADE_IDEAS_TYPES = {
    PRODUCT: TRADE_IDEAS_PRODUCT_TYPE,
    REGION: TRADE_IDEAS_REGION_TYPE
};

export const TRADE_IDEAS_STOP_LOSS_COLUMN_LABEL = {
    STOP_LOSS: 'Stop Loss',
    STOP_LOSS_EXIT: 'Stop Loss/Exit',
    EXIT: 'Exit'
}

export const PRODUCT_TRADE_IDEAS_FILTER = {
    ALL: 'ALL',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
};

export const TRADE_IDEAS_PAGE_SIZE = 10;

export const TRADE_IDEAS_DOCUMENT_TYPE_MPU = 'Model Portfolio Update';

export const TRADE_IDEAS_STATUS_URL_PARAM = 'tradeIdeasStatus';
export const TRADE_IDEAS_KEYWORDS_URL_PARAM = 'tradeIdeasKeywords';
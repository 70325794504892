import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_PATH_ANALYST_PROFILE_PAGE, LINK_SEARCH_PAGE } from '../../constants/LinkPaths.constants';
import { FORWARD_SLASH, QUESTION_MARK } from '../../constants/Misc.constants';
import { SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME,SEARCH_RESULTS_FIRM_NAME_QUERY_PARAM_NAME } from '../../constants/SearchResults.constants';
import { FIRM_NAME_RESEARCH } from '../../constants/Misc.constants';
import UtilsService, { executeInnerHtml } from '../../services/Utils.service';
import { isNotEmpty } from '../../utils/valueUtils';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import SecureLinkService from '../../services/SecureLink.service';

function ResearchContentComponent({name, type, content}) {
    const getImageUrl = () => {
        const width = window.innerWidth;
        const randomImageUrl = UtilsService.getRandomDefaultImage();
        
        if (width > 1299) {
            return content.image || randomImageUrl;
        } else if (width > 1023 && width < 1300) {
            return content.tablet1Image || randomImageUrl;
        } else if (width > 767 && width < 1024) {
            return content.tablet2Image || randomImageUrl;
        } else if (width < 768) {
            return content.mobileImage || randomImageUrl;
        } 
    }

    const getDisplayPrimaryAnalyst = (content) => {
        let primaryAnalyst = '';
        if (isNotEmpty(content?.primaryAnalyst?.firstName)) primaryAnalyst += content?.primaryAnalyst.firstName;
        if (primaryAnalyst.length > 0) primaryAnalyst += ' ';
        if (isNotEmpty(content?.primaryAnalyst?.lastName)) primaryAnalyst += content?.primaryAnalyst.lastName;
        if (isNotEmpty(content?.primaryAnalyst?.position)) {
            if (primaryAnalyst.length > 0) primaryAnalyst += ', ';
            primaryAnalyst += content?.primaryAnalyst.position;
        }        

        return primaryAnalyst;
    }

    const getViewAllLink = () => {
        return LINK_SEARCH_PAGE + QUESTION_MARK + `${SEARCH_RESULTS_DOCUMENT_TYPE_QUERY_PARAM_NAME}=${type}&${SEARCH_RESULTS_FIRM_NAME_QUERY_PARAM_NAME}=${FIRM_NAME_RESEARCH}`;
    }

    function getTitle(content) {
        const companySymbol = content.symbol;
        const title = content.title;
        if (isNotEmpty(companySymbol)) {

          return `(${companySymbol}) ${title}`;
        }

        return title;
    }

    return (
        <div className="tds-research-content">
            <h3 className="type-label">{name}</h3>
            <Link className="view-all-link" to={getViewAllLink()}>View All &gt;</Link>
            <img alt={content.title} className='image' src={getImageUrl()}></img>
            <a
                className="title"
                href={SecureLinkService.buildSecureLink(content.pdfLink)}
                title={getTitle(content)}
                target="_blank"
                rel="noopener noreferrer">
                    {getTitle(content)}
            </a>
            {
                content.primaryAnalyst?.active &&
                <Link 
                    className="author" 
                    to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + content.firmName + FORWARD_SLASH + content.primaryAnalyst.id}
                    title={getDisplayPrimaryAnalyst(content)}>{getDisplayPrimaryAnalyst(content)}</Link>
            }
            {   !content.primaryAnalyst?.active &&
                <span className="author">{getDisplayPrimaryAnalyst(content)}</span>
            }
            <span className="date"><FormatedDateComponent date={content.displayDateTime} format="MMM D, YYYY"></FormatedDateComponent></span>
            <p className="description">{executeInnerHtml(content.description)}</p>
        </div>
    );
}

export default ResearchContentComponent;
import React from 'react';
import { Link } from "react-router-dom";
import ContactCardComponent from "../../misc/contact-card/ContactCard.component";
import AnalystProfileCardPicComponent from "../../misc/analyst-profile-card-pic/AnalystProfileCardPic.component";
import ModalComponent from "../../misc/modal/Modal.component";
import RequestMeetingComponent from "../../request-meeting/RequestMeeting.component";
import {
    LINK_PATH_ANALYST_PROFILE_PAGE
} from "../../../constants/LinkPaths.constants";
import {
    FORWARD_SLASH,
    FIRM_NAME_STRATEGY,
    FIRM_NAME_RESEARCH, FIRM_NAME_COWEN
} from "../../../constants/Misc.constants";
import {isCowen, isCowenEnabled} from '../../../utils/valueUtils';
import CowenFlagContext from "../../../system/CowenFlagContext";
import UserService from "../../../services/User.service";

export default class AnalystProfileCardComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.requestMeetingModal = React.createRef();
        let contactCardOrg = "";
        if (this.props.analyst && this.props.analyst.division) {
            contactCardOrg = this.props.analyst.division.name;
        }
        this.state = {
            contactCardOrg: contactCardOrg,
            cowenEnabled: false,
        };
        this.showMeeting = this.showMeeting.bind(this);
        this.hideMeeting = this.hideMeeting.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(`error is: ${e}`);
        });
    }
    showMeeting() {
        this.requestMeetingModal.current.showModal();
    }
    hideMeeting() {
        this.requestMeetingModal.current.hideModal();
    }
    render() {
        const cowenFlag = this.context;
        return (
            <React.Fragment>
                <div className={`tds-analyst-profile-card-container ${window.location.pathname.includes("profile") ? 
                    ((window.location.pathname.includes("strategy") || UserService.isFicmOnly()) ? 
                        "" 
                        : ((isCowenEnabled(this.state.cowenEnabled,cowenFlag) ? 
                            "no-meeting" 
                            : (this.props.nomeeting) ? 
                                "no-meeting" 
                                : ""))) 
                    : "no-meeting"}`}>
                    <div className="tds-analyst-profile-card">
                        <div className="tds-analyst-profile-card-pic-container">
                            <AnalystProfileCardPicComponent
                                className={'tds-analyst-profile-card-pic'}
                                defaultImage={this.props.analyst.photo || "/assets/img/Placeholder_Silhouette.jpg"}
                                link={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                    this.props.analyst.firmName + FORWARD_SLASH + this.props.analyst.personId}
                                linkDisabled={!this.props.link}
                            />
                        </div>
                        <div className="tds-analyst-profile-card-description-container">
                            {
                                this.props.link &&
                                <h1 className="tds-analyst-profile-card-title">

                                    <Link to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH +
                                        this.props.analyst.firmName + FORWARD_SLASH + this.props.analyst.personId}
                                        activeclassname="td-link-standalone"
                                        title={this.props.analyst.firstName + " " + this.props.analyst.lastName}
                                    >
                                        {this.props.analyst.firstName} {this.props.analyst.lastName}
                                    </Link>
                                </h1>
                            }
                            {
                                !this.props.link &&
                                <h1 className="tds-analyst-profile-card-title">
                                    {this.props.analyst.firstName} {this.props.analyst.lastName}
                                </h1>
                            }
                            <span className="tds-analyst-profile-card-jobtitle">{this.props.analyst.title}</span>
                            {
                                this.props.analyst.firmName === FIRM_NAME_STRATEGY &&
                                <span className="tds-analyst-profile-card-desc">
                                    {
                                        this.props.analyst.division &&
                                        this.props.analyst.division.name
                                    }
                                </span>
                            }
                            {
                                (this.props.analyst.firmName === FIRM_NAME_RESEARCH || this.props.analyst.firmName === FIRM_NAME_COWEN) && this.props.analyst.industries &&
                                <React.Fragment>
                                    {
                                        this.props.analyst.industries.map((industryItem, index) => {
                                            return <span
                                                className="tds-analyst-profile-card-desc"
                                                key={'analyst-industry-' + index}
                                            >
                                                {industryItem.name}
                                            </span>
                                        })
                                    }
                                </React.Fragment>
                            }
                            {
                                this.props.analyst.phone &&
                                <span className="tds-analyst-profile-card-phone">
                                    <span className="tds-analyst-profile-card-phone-abr">P:&nbsp;</span>
                                    <span>{this.props.analyst.phone}</span>
                                </span>

                            }
                            <span className="tds-analyst-profile-card-location">
                                {this.props.analyst.location}
                            </span>
                            <a className="tds-analyst-profile-card-email"
                                href={'mailto:' + this.props.analyst.email} >{this.props.analyst.email}</a>
                            <ContactCardComponent
                                outerCssClass='tds-analyst-profile-card-add'
                                iconCssClass='tds-analyst-profile-card-add-icon'
                                textCssClass='tds-analyst-profile-card-add-text'
                                lastName={this.props.analyst.lastName}
                                firstName={this.props.analyst.firstName}
                                org={this.state.contactCardOrg}
                                title={this.props.analyst.title}
                                phone={this.props.analyst.phone}
                                email={this.props.analyst.email}
                            />
                        </div>
                    </div>
                    {!this.props.nomeeting &&
                        <div className="tds-analyst-profile-meeting-container">
                            <div className="tds-analyst-profile-booking-text-wrapper">
                                <div className="tds-analyst-profile-booking-icon-text-wrapper">
                                    <span className="td-icon td-icon-bookAppointment"></span>
                                    <span
                                        className="tds-analyst-profile-booking-text">Book a meeting with {this.props.analyst.firstName}!</span>
                                </div>
                                <div className="tds-analyst-profile-booking-button-wrapper">
                                    <button
                                        className="btn btn-block td-btn-primary-light"
                                        onClick={this.showMeeting}
                                        title="Book Now"
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {!this.props.nomeeting &&
                    <ModalComponent
                        ref={this.requestMeetingModal}
                        id={'requestMeetingModal'}
                        className={'tds-request-meeting'}
                    >
                        <RequestMeetingComponent
                            analystFullName={this.props.analyst.firstName + ' ' + this.props.analyst.lastName}
                            hideMeeting={this.hideMeeting}
                        />
                    </ModalComponent>
                }
            </React.Fragment>
        )
    }
}

import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AllAnalystsComponent from "./analysts/all-analysts/AllAnalysts.component";
import AnalystProfileComponent from "./analysts/analyst-profile/AnalystProfile.component";
import AnalystTeamsComponent from "./analysts/analyst-teams/AnalystTeams.component";
import AssetSecuritizationPageComponent from './asset-securitization-page/AssetSecuritizationPage.component';
import CalendarPageComponent from './calendar-page/CalendarPage.component';
import CoveragePageComponent from './coverage-page/CoveragePage.component';
import CompanyDetailsPage from './company-details-page/CompanyDetailsPage.component';
import EventsComponent from "./Events.component";
import ExchangeTradedFundsPageComponent from './exchange-traded-funds-page/ExchangeTradedFundsPage.component';
import HeaderNavComponent from "./header/HeaderNav.component";
import HomePageComponent from "./home-page/HomePage.component";
import HighYieldPageComponent from './high-yield-page/HighYieldPage.component';
import InvestmentGradePageComponent from './investment-grade-page/InvestmentGradePage.component';
import IndexMarketStructuresPageComponent from './index-market-structures-page/IndexMarketStructuresPage.component';
import GlobalStrategyPageComponent from './global-strategy-page/GlobalStrategyPage.component';
import FourOFourPageComponent from "./four-o-four-page/FourOFourPage.component";
import SalesLiteraturePageComponent from './genertic-content-pages/SalesLiteraturePage.component';
import DisseminationPolicyPageComponent from './genertic-content-pages/DisseminationPolicyPage.component';
import FiccResearchCreditStrategyComponent from './genertic-content-pages/FiccResearchCreditStrategyPage.component';
import PublicationPageComponent from './publication-page/PublicationPage.component';
import FooterComponent from "./footer/Footer.component";
import ProductPageComponent from "../components/product-page/ProductPage.component";
import RegionPageComponent from "../components/region-page/RegionPage.component";
import ResearchPageComponent from '../components/research-page/ResearchPage.component';
import ResearchPageComponentV2 from '../components/research-page/v2/ResearchPage.component';
import SearchComponent from "./search/Search.component";
import SearchComponentV2 from "./search/v2/Search.component";
import LandingPageFooterComponent from "./landing-page/footer/LandingPageFooter.component";
import LandingSubPageHeaderComponent from "./landing-page/header/LandingSubPageHeader.component";
import PersonSearchComponent from "./person-search/PersonSearch.component";
import LandingPageComponent from "./landing-page/LandingPage.component";
import LoggedoutPageComponent from "./loggedout-page/LoggedoutPage.component";
import LoggedoutCompletedPageComponent from "./loggedout-page/LoggedoutCompletedPage.component";
import RouteChangeDetectorComponent from './misc/route-change-detector/RouteChangeDetector.component';
import AccessDeniedComponent from './misc/access-denied/AccessDenied.component';
import EnvironmentService from "../services/Environment.service";
import UserService from "../services/User.service";
import Cookies from 'js-cookie';
import {
  COOKIE_USER_PROFILE,
  COOKIE_SAML_PROFILE,
  COOKIE_USER_PROFILE_TOKEN,
  COOKIE_CONNECT_SID,
  COOKIE_ARRAFFINITY,
  COOKIE_ARRAFFINITY_SAME_SITE
} from '../constants/Cookies.constants';
import {
  RATES_TITLE,
  RATES_PAGE_TITLE,
  FX_TITLE,
  FX_PAGE_TITLE,
  COMMODITIES_TITLE,
  COMMODITIES_PAGE_TITLE,
  MACRO_TITLE,
  MACRO_PAGE_TITLE,
  EMERGING_MARKETS_TITLE,
  EMERGING_MARKETS_PAGE_TITLE,
  UNITED_STATES_TITLE,
  CANADA_TITLE,
  ASIA_PACIFIC_TITLE,
  JAPAN_TITLE,
  NEW_ZEALAND_TITLE,
  AUSTRALIA_TITLE,
  EUROPE_TITLE,
  EUROZONE_TITLE,
  UNITED_KINGDOM_TITLE,
  SCANDIES_TITLE,
  ASIA_TITLE,
  CHINA_TITLE,
  ASIA_EX_CHINA_TITLE,
  LATIN_AMERICA_TITLE,
  EMEA_TITLE,
  UNITED_STATES_PAGE_TITLE,
  CANADA_PAGE_TITLE,
  ASIA_PACIFIC_PAGE_TITLE,
  JAPAN_PAGE_TITLE,
  NEW_ZEALAND_PAGE_TITLE,
  AUSTRALIA_PAGE_TITLE,
  EUROPE_PAGE_TITLE,
  EUROZONE_PAGE_TITLE,
  UNITED_KINGDOM_PAGE_TITLE,
  SCANDIES_PAGE_TITLE,
  ASIA_PAGE_TITLE,
  CHINA_PAGE_TITLE,
  ASIA_EX_CHINA_PAGE_TITLE,
  LATIN_AMERICA_PAGE_TITLE,
  EMEA_PAGE_TITLE
} from '../constants/PageTitle.constants';

import {
  PRODUCT_PAGE_TYPE,
  PRODUCT_PAGE_DROPDOWN_TYPES
} from '../constants/ProductPage.constants';

import {
  REGION_PAGE_TYPES
} from '../constants/RegionPage.constants';

import {
  ROUTE_PATH_404_PAGE,
  ROUTE_LANDING_PAGE,
  ROUTE_PATH_ANALYST_PROFILE_PAGE,
  ROUTE_PATH_ANALYSTS_PAGE,
  ROUTE_PATH_ANALYST_TEAMS_PAGE,
  ROUTE_PATH_ANALYTICS_TRADE_GLOBAL_STRATEGY_PAGE,
  ROUTE_PATH_ASSET_SECURITIZATION,
  ROUTE_DISSEMINATION_POLICY_PAGE,
  ROUTE_CREDIT_HIGH_YIELD_PAGE,
  ROUTE_CREDIT_INVESTMENT_GRADE_PAGE,
  ROUTE_PATH_EXCHANGE_TRADED_FUNDS,
  ROUTE_PATH_EXCHANGE_TRADED_FUNDS_DETAILS,
  ROUTE_PATH_EQUITIES_COVERAGE_PAGE,
  ROUTE_PATH_EQUITIES_COMPANY_DETAILS_PAGE,
  ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE,
  ROUTE_PUBLICATION_PAGE,
  ROUTE_COMMODITIES_PAGE,
  ROUTE_CALENDAER_PAGE,
  ROUTE_EVENTS_PAGE,
  ROUTE_EMERGING_MARKETS_PAGE,
  ROUTE_FX_PAGE,
  ROUTE_LOGGEDOUT,
  ROUTE_MACRO_PAGE,
  ROUTE_MVI,
  ROUTE_RATES_PAGE,
  ROUTE_REGIONS_PAGE_UNITED_STATES,
  ROUTE_REGIONS_PAGE_CANADA,
  ROUTE_REGIONS_PAGE_ASIA_PACIFIC,
  ROUTE_REGIONS_PAGE_JAPAN,
  ROUTE_REGIONS_PAGE_NEW_ZEALAND,
  ROUTE_REGIONS_PAGE_AUSTRALIA,
  ROUTE_REGIONS_PAGE_EUROPE,
  ROUTE_REGIONS_PAGE_EUROZONE,
  ROUTE_REGIONS_PAGE_UNITED_KINGDOM,
  ROUTE_REGIONS_PAGE_SCANDIES,
  ROUTE_REGIONS_PAGE_EMEA,
  ROUTE_REGIONS_PAGE_ASIA,
  ROUTE_REGIONS_PAGE_CHINA,
  ROUTE_REGIONS_PAGE_ASIA_EX_CHINA,
  ROUTE_REGIONS_PAGE_LATIN_AMERICA,
  ROUTE_PATH_EQUITIES_RESEARCH_PAGE,
  ROUTE_SEARCH_PAGE,
  ROUTE_SALE_LITERATURE_PAGE,
  ROUTE_HOME_PAGE,
  ROUTE_HOME,
  ROUTE_ACCESS_DENIED,
  ROUTE_ROOT,
  ROUTE_PATH_EQUITIES_OVERVIEW_PAGE,
  ROUTE_PATH_EQUITIES_ADVANCED_FILTER,
  ROUTE_FICC_RESEARCH_CREDIT_STRATEGY_PAGE,
  ROUTE_PATH_PREFERENCES,
  ROUTE_PATH_EQUITIES_US_OVERVIEW_PAGE,
  ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_OVERVIEW_PAGE,
  ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE,
  ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE,
  ROUTE_PATH_EQUITIES_US_RESEARCH_PAGE,
  ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_PAGE,
  ROUTE_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE,
} from '../constants/RoutePaths.constants';
import {
  isTablet,
  isMobileOnly
} from "react-device-detect";

import {
  ACL_TOP_NAV_BOOK_MEETING
} from '../constants/Entitlements.constants';
import ETFDetailsPageComponent from "./exchange-traded-funds-page/ETFDetailsPage.component";
import EqOverviewPageComponent from "./home-page/EqOverviewPage.component";
import EqOverviewPageComponentV2 from "./equity-overview//EqOverviewPage.component";
import AdvancedFilterPageComponent from "./advanced-filter-page/AdvancedFilterPage.component";
import { Fragment } from "react";
import CookieConsentBanner from "./cookie-consent-banner/CookieConsentBanner.component";
import Preferences from "./preferences/Preferences.component";
import { reportWebVitals } from "../reportWebVitals";
import { disableGAByDeclinedCookieConsent, enableDisableGA, enableGA } from "../services/GoogleAnalytics.service";
import { TEAM } from "../constants/Misc.constants";
import {isCowen, isCowenEnabled} from "../utils/valueUtils";
import CowenFlagContext from "../system/CowenFlagContext";
import ConferenceCallOtherMediaPageComponent from "./conference-calls-other-media-page/ConferenceCallsOtherMediaPage.component";

// function useCalendars() {
//   const [calendars, setCalendar] = useState([]);

//   useEffect(() => {
//     HttpClient.get("/api/v1/calendars").then((calendars) => {
//       setCalendar(calendars);
//     });
//   }, []);

//   return [calendars, setCalendar];
// }

// const App = () => (
//   <div>
//     <AddTodo />
//     <VisibleTodoList />
//     <Footer />
//   </div>
// )

// export default App

export default class AppComponent extends React.Component {
  user = null;
  authenticated = false;
  static contextType = CowenFlagContext;
  constructor(props) {
    console.log("loading appcomponent");
    super(props);
    EnvironmentService.initialize();
    this.state = {
      hideSearchBar: false,
      hideMeetingIcon: true,
      scrollToTop: true,
      cowenEnabled: false
    }
    var user_profile = "";
    if (EnvironmentService.isLocal()) {
      user_profile = EnvironmentService.getUserMockProfile();
    } else {
      user_profile = Cookies.get(COOKIE_USER_PROFILE);
    }
    if (user_profile) {
      this.state.user = JSON.parse(decodeURIComponent(user_profile).replace("j:", ""));
      UserService.setUser(this.state.user);
      this.state.authenticated = true;
    }

    /* on a hard refresh */
    if (EnvironmentService.isSearch()) {
      this.state.hideSearchBar = true;
    } else {
      this.state.hideSearchBar = false;
    }
    /* on a hard refresh */
    if ((isTablet || isMobileOnly) && this.state.authenticated && UserService.hasAccess(ACL_TOP_NAV_BOOK_MEETING)) {
      this.state.hideMeetingIcon = false;
    } else {
      this.state.hideMeetingIcon = true;
    }

    //Google Analytics initialization
    enableDisableGA();

    this.hideSearchBar = this.hideSearchBar.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.hideSearchBar = this.hideSearchBar.bind(this);
    this.showSearchBar = this.showSearchBar.bind(this);
    this.showMeetingIcon = this.showMeetingIcon.bind(this);
    this.hideMeetingIcon = this.hideMeetingIcon.bind(this);
    this.showWebsite = this.showWebsite.bind(this);
    this.renderHomePage = this.renderHomePage.bind(this);
    this.renderEqOverviewPage = this.renderEqOverviewPage.bind(this);
    this.turnOnScrollToTop = this.turnOnScrollToTop.bind(this);
    this.turnOffScrollToTop = this.turnOffScrollToTop.bind(this);    
  }
  componentDidMount() {
    setTimeout(this.showWebsite, 1000);
    isCowen().then(r => {
      this.setState({ cowenEnabled: r });
    }).catch(e => {
      console.log(e);
    });
    /* why is this done? */
    /* GSBM-1253 This is a hack to give the website time to load the fonts 
       If you have a better solution please lead the way but this is also done on TD.com
    */
    /* why is this done? */
  }
  showWebsite() {
    document.body.style.visibility = "visible";        
  }
  hideSearchBar() {
    this.setState({
      hideSearchBar: true
    });
  }
  showSearchBar() {
    this.setState({
      hideSearchBar: false
    });
  }
  hideMeetingIcon() {
    this.setState({
      hideMeetingIcon: true
    });
  }
  showMeetingIcon() {
    this.setState({
      hideMeetingIcon: false
    });
  }
  turnOnScrollToTop() {
    this.setState({
      scrollToTop: true
    });
  }
  turnOffScrollToTop() {
    this.setState({
      scrollToTop: false
    });
  }
  logoutUser() {
    this.setState({
      user: null,
      authenticated: false
    });
    Cookies.remove(COOKIE_USER_PROFILE);
    Cookies.remove(COOKIE_SAML_PROFILE);
    Cookies.remove(COOKIE_USER_PROFILE_TOKEN);
    Cookies.remove(COOKIE_CONNECT_SID);
    Cookies.remove(COOKIE_ARRAFFINITY);
    Cookies.remove(COOKIE_ARRAFFINITY_SAME_SITE);
  }
  renderHomePage() {
    const isEQOnly = UserService.isEQOnly();
    return (
      <HomePageComponent eqOnly={isEQOnly}/>
    )
  }

  renderEqOverviewPage() {
    return (
      <EqOverviewPageComponent></EqOverviewPageComponent>
    )
  }

  cookieConsentAccepted() {
    enableGA();
  }

  cookieConsentDeclined() {
    disableGAByDeclinedCookieConsent();
  }

  render() {
    const cowenFlag = this.context;
    reportWebVitals(); 
    if (this.state.scrollToTop) {
      window.scrollTo({ top: 0 });
    }
    return (
      <Router>
        { !this.state.authenticated &&
          <Switch>
            {
              console.log("AppComponent: Rendering Unauthenticated portion")
            }            
            <Route
              path={ROUTE_LANDING_PAGE}
              component={LandingPageComponent}
            />
            <Route path={ROUTE_DISSEMINATION_POLICY_PAGE}>
              <div className="tds-landing-sub-page-container">
                <LandingSubPageHeaderComponent />
                <main>
                  <DisseminationPolicyPageComponent />
                </main>
                <LandingPageFooterComponent />
              </div>
            </Route>
            <Route path={ROUTE_FICC_RESEARCH_CREDIT_STRATEGY_PAGE}>
              <div className="tds-landing-sub-page-container">
                <LandingSubPageHeaderComponent />
                <main>
                  <FiccResearchCreditStrategyComponent />
                </main>
                <LandingPageFooterComponent />
              </div>
            </Route>
            <Route path={ROUTE_SALE_LITERATURE_PAGE}>
              <div className="tds-landing-sub-page-container">
                <LandingSubPageHeaderComponent />
                <main>
                  <SalesLiteraturePageComponent />
                </main>
                <LandingPageFooterComponent />
              </div>
            </Route>
            <Route path={ROUTE_LOGGEDOUT}>
              <LoggedoutCompletedPageComponent />
            </Route>
            <Route path={ROUTE_PATH_404_PAGE}>
              <div className="tds-landing-sub-page-container">
                <LandingSubPageHeaderComponent />
                <main>
                  <FourOFourPageComponent unauthenticated={true} />
                </main>
                <LandingPageFooterComponent />
              </div>
            </Route>
            <Route path={ROUTE_PATH_PREFERENCES} exact component={Preferences}/>
            <Redirect from={'*'} to={ROUTE_PATH_404_PAGE} />
          </Switch>
        }
        { this.state.authenticated &&
          <>
            {
              console.log("AppComponent: Rendering Authenticated portion")
            }
            <HeaderNavComponent
              user={this.state.user}
              authenticated={this.state.authenticated}
              hideSearchBar={this.state.hideSearchBar}
              hideMeetingIcon={this.state.hideMeetingIcon}
            />
            <RouteChangeDetectorComponent
              hideSearchBar={this.hideSearchBar}
              showSearchBar={this.showSearchBar}
              hideMeetingIcon={this.hideMeetingIcon}
              showMeetingIcon={this.showMeetingIcon}
              turnOnScrollToTop={this.turnOnScrollToTop}
              turnOffScrollToTop={this.turnOffScrollToTop}
            />
          </>
        }
        { this.state.authenticated &&
          <Switch>
            <Route
              path={ROUTE_PATH_404_PAGE}
              component={FourOFourPageComponent}
            />
          </Switch>
        }
        { this.state.authenticated &&
          <div className="tds-container">
            <Switch>
              <Redirect exact from={ROUTE_LANDING_PAGE} to={ROUTE_HOME} />
              <Redirect exact from={ROUTE_ROOT} to={ROUTE_HOME} />
              <Route path={ROUTE_PATH_ANALYST_PROFILE_PAGE}
                component={AnalystProfileComponent}
              >
              </Route>
              <Route path={ROUTE_PATH_ANALYSTS_PAGE}>
                <AllAnalystsComponent />
              </Route>
              <Route path={ROUTE_PATH_ANALYST_TEAMS_PAGE}
                component={AnalystTeamsComponent}>
              </Route>
              <Route path={ROUTE_PATH_ASSET_SECURITIZATION}>
                <AssetSecuritizationPageComponent />
              </Route>
              <Route path={ROUTE_PATH_ANALYTICS_TRADE_GLOBAL_STRATEGY_PAGE}>
                <GlobalStrategyPageComponent />
              </Route>
              <Route path={ROUTE_DISSEMINATION_POLICY_PAGE}>
                <DisseminationPolicyPageComponent />
              </Route>
              <Route path={ROUTE_FICC_RESEARCH_CREDIT_STRATEGY_PAGE}>
                <FiccResearchCreditStrategyComponent />
              </Route>
              <Route path={ROUTE_PATH_EXCHANGE_TRADED_FUNDS_DETAILS}
                component={ETFDetailsPageComponent}
              >
              </Route>
              <Route path={ROUTE_PATH_EXCHANGE_TRADED_FUNDS}>
                <ExchangeTradedFundsPageComponent />
              </Route>
              <Route path={ROUTE_PUBLICATION_PAGE}
                component={PublicationPageComponent}>
              </Route>
              <Route path={ROUTE_PATH_EQUITIES_COMPANY_DETAILS_PAGE}
                component={CompanyDetailsPage}>
              </Route>
              <Route path={ROUTE_COMMODITIES_PAGE}>
                <ProductPageComponent
                  key={COMMODITIES_TITLE}
                  pageTitle={COMMODITIES_PAGE_TITLE}
                  title={COMMODITIES_TITLE}
                  type={PRODUCT_PAGE_TYPE.COMMODITIES}
                  dropDownType={PRODUCT_PAGE_DROPDOWN_TYPES.COMMODITIES}
                  routePath={ROUTE_COMMODITIES_PAGE}
                />
              </Route>
              <Route path={ROUTE_CALENDAER_PAGE}>
                <CalendarPageComponent />
              </Route>
              <Route path={ROUTE_PATH_EQUITIES_COVERAGE_PAGE}>
                <CoveragePageComponent />
              </Route>
              <Route path={ROUTE_PATH_EQUITIES_ADVANCED_FILTER}>
                <AdvancedFilterPageComponent />
              </Route>
              <Route path={ROUTE_EVENTS_PAGE}>
                <EventsComponent />
              </Route>
              <Route path={ROUTE_EMERGING_MARKETS_PAGE}>
                <ProductPageComponent
                  key={EMERGING_MARKETS_TITLE}
                  pageTitle={EMERGING_MARKETS_PAGE_TITLE}
                  title={EMERGING_MARKETS_TITLE}
                  type={PRODUCT_PAGE_TYPE.EMERGING_MARKETS}
                  dropDownType={PRODUCT_PAGE_DROPDOWN_TYPES.EMERGING_MARKETS}
                  routePath={ROUTE_EMERGING_MARKETS_PAGE}
                />
              </Route>
              <Route path={ROUTE_CREDIT_HIGH_YIELD_PAGE}>
                <HighYieldPageComponent />
              </Route>
              <Route path={ROUTE_CREDIT_INVESTMENT_GRADE_PAGE}>
                <InvestmentGradePageComponent />
              </Route>
              <Route path={ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE}>
                <IndexMarketStructuresPageComponent />
              </Route>
              <Route path={ROUTE_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE}>
                <ConferenceCallOtherMediaPageComponent />
              </Route>
              <Route path={ROUTE_FX_PAGE}>
                <ProductPageComponent
                  key={FX_TITLE}
                  pageTitle={FX_PAGE_TITLE}
                  title={FX_TITLE}
                  type={PRODUCT_PAGE_TYPE.FX}
                  dropDownType={PRODUCT_PAGE_DROPDOWN_TYPES.FX}
                  routePath={ROUTE_FX_PAGE}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_UNITED_STATES}>
                <RegionPageComponent
                  key={UNITED_STATES_TITLE}
                  pageTitle={UNITED_STATES_PAGE_TITLE}
                  title={UNITED_STATES_TITLE}
                  type={REGION_PAGE_TYPES.UNITED_STATES}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_CANADA}>
                <RegionPageComponent
                  key={CANADA_TITLE}
                  pageTitle={CANADA_PAGE_TITLE}
                  title={CANADA_TITLE}
                  type={REGION_PAGE_TYPES.CANADA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_ASIA_PACIFIC}>
                <RegionPageComponent
                  key={ASIA_PACIFIC_TITLE}
                  pageTitle={ASIA_PACIFIC_PAGE_TITLE}
                  title={ASIA_PACIFIC_TITLE}
                  type={REGION_PAGE_TYPES.ASIA_PACIFIC}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_JAPAN}>
                <RegionPageComponent
                  key={JAPAN_TITLE}
                  pageTitle={JAPAN_PAGE_TITLE}
                  title={JAPAN_TITLE}
                  type={REGION_PAGE_TYPES.JAPAN}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_NEW_ZEALAND}>
                <RegionPageComponent
                  key={NEW_ZEALAND_TITLE}
                  pageTitle={NEW_ZEALAND_PAGE_TITLE}
                  title={NEW_ZEALAND_TITLE}
                  type={REGION_PAGE_TYPES.NEW_ZEALAND}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_AUSTRALIA}>
                <RegionPageComponent
                  key={AUSTRALIA_TITLE}
                  pageTitle={AUSTRALIA_PAGE_TITLE}
                  title={AUSTRALIA_TITLE}
                  type={REGION_PAGE_TYPES.AUSTRALIA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_EUROPE}>
                <RegionPageComponent
                  key={EUROPE_TITLE}
                  pageTitle={EUROPE_PAGE_TITLE}
                  title={EUROPE_TITLE}
                  type={REGION_PAGE_TYPES.EUROPE}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_EUROZONE}>
                <RegionPageComponent
                  key={EUROZONE_TITLE}
                  pageTitle={EUROZONE_PAGE_TITLE}
                  title={EUROZONE_TITLE}
                  type={REGION_PAGE_TYPES.EUROZONE}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_UNITED_KINGDOM}>
                <RegionPageComponent
                  key={UNITED_KINGDOM_TITLE}
                  pageTitle={UNITED_KINGDOM_PAGE_TITLE}
                  title={UNITED_KINGDOM_TITLE}
                  type={REGION_PAGE_TYPES.UNITED_KINGDOM}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_SCANDIES}>
                <RegionPageComponent
                  key={SCANDIES_TITLE}
                  pageTitle={SCANDIES_PAGE_TITLE}
                  title={SCANDIES_TITLE}
                  type={REGION_PAGE_TYPES.SCANDIES}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_EMEA}>
                <RegionPageComponent
                  key={EMEA_TITLE}
                  pageTitle={EMEA_PAGE_TITLE}
                  title={EMEA_TITLE}
                  type={REGION_PAGE_TYPES.EMEA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_ASIA}>
                <RegionPageComponent
                  key={ASIA_TITLE}
                  pageTitle={ASIA_PAGE_TITLE}
                  title={ASIA_TITLE}
                  type={REGION_PAGE_TYPES.ASIA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_CHINA}>
                <RegionPageComponent
                  key={CHINA_TITLE}
                  pageTitle={CHINA_PAGE_TITLE}
                  title={CHINA_TITLE}
                  type={REGION_PAGE_TYPES.CHINA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_ASIA_EX_CHINA}>
                <RegionPageComponent
                  key={ASIA_EX_CHINA_TITLE}
                  pageTitle={ASIA_EX_CHINA_PAGE_TITLE}
                  title={ASIA_EX_CHINA_TITLE}
                  type={REGION_PAGE_TYPES.ASIA_EX_CHINA}
                />
              </Route>
              <Route path={ROUTE_REGIONS_PAGE_LATIN_AMERICA}>
                <RegionPageComponent
                  key={LATIN_AMERICA_TITLE}
                  pageTitle={LATIN_AMERICA_PAGE_TITLE}
                  title={LATIN_AMERICA_TITLE}
                  type={REGION_PAGE_TYPES.LATIN_AMERICA}
                />
              </Route>
              <Route path={ROUTE_PATH_EQUITIES_RESEARCH_PAGE}>
                <ResearchPageComponent />
              </Route>
              <Route exact path={ROUTE_PATH_EQUITIES_OVERVIEW_PAGE}
                render={this.renderEqOverviewPage} >
              </Route>
              <Route path={ROUTE_LOGGEDOUT}>
                <LoggedoutPageComponent
                  authenticated={this.state.authenticated}
                  logoutUser={this.logoutUser}
                />
              </Route>
              <Route path={ROUTE_MACRO_PAGE}>
                <ProductPageComponent
                  key={MACRO_TITLE}
                  pageTitle={MACRO_PAGE_TITLE}
                  title={MACRO_TITLE}
                  type={PRODUCT_PAGE_TYPE.MACRO}
                  dropDownType={PRODUCT_PAGE_DROPDOWN_TYPES.MACRO}
                  routePath={ROUTE_MACRO_PAGE}
                />
              </Route>
              <Route path={ROUTE_MVI}>
                <PersonSearchComponent />
              </Route>
              <Route path={ROUTE_RATES_PAGE}>
                <ProductPageComponent
                  key={RATES_TITLE}
                  pageTitle={RATES_PAGE_TITLE}
                  title={RATES_TITLE}
                  type={PRODUCT_PAGE_TYPE.RATES}
                  dropDownType={PRODUCT_PAGE_DROPDOWN_TYPES.RATES}
                  routePath={ROUTE_RATES_PAGE}
                />
              </Route>
              <Route path={ROUTE_SALE_LITERATURE_PAGE}>
                {this.state.authenticated && <SalesLiteraturePageComponent />}
                {!this.state.authenticated &&
                  <div className="tds-landing-sub-page-container">
                    <LandingSubPageHeaderComponent />
                    <main>
                      <SalesLiteraturePageComponent />
                    </main>
                    <LandingPageFooterComponent />
                  </div>
                }
              </Route>
              {/*<Route path={ROUTE_SEARCH_PAGE}>*/}
              {/*  <SearchComponent hideSearchBar={this.hideSearchBar} />*/}
              {/*</Route>*/}
              {/*<Route path={ROUTE_V2_SEARCH_PAGE}>*/}
              {/*  <SearchComponentV2 hideSearchBar={this.hideSearchBar} />*/}
              {/*</Route>*/}
              {
                isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? (<Route path={ROUTE_SEARCH_PAGE}>
                  <SearchComponentV2 hideSearchBar={this.hideSearchBar} />
                </Route>) : (<Route path={ROUTE_SEARCH_PAGE}>
                  <SearchComponent hideSearchBar={this.hideSearchBar} />
                </Route>)
              }

              <Route
                exact path={ROUTE_HOME_PAGE}
                render={this.renderHomePage}
              >
              </Route>
              <Route
                exact path={ROUTE_HOME}
                render={this.renderHomePage}
              />

              <Route path={ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE}>
                <ResearchPageComponentV2 />
              </Route>
              <Route exact path={ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE}>
              <EqOverviewPageComponentV2 team={TEAM.CA} eqOnly={true}/>
              </Route>

              <Route exact path={ROUTE_PATH_EQUITIES_US_OVERVIEW_PAGE}> 
              <EqOverviewPageComponentV2 team={TEAM.US} eqOnly={true}/>
              </Route>

              <Route path={ROUTE_PATH_EQUITIES_US_RESEARCH_PAGE}>
                <ResearchPageComponentV2 />
              </Route>

              <Route path={ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_OVERVIEW_PAGE}>
              <EqOverviewPageComponentV2 team={TEAM.WRG} eqOnly={true}/>
              </Route>

              <Route path={ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_PAGE}>
                <ResearchPageComponentV2 />
              </Route>

              <Route path={ROUTE_PATH_PREFERENCES} exact component={Preferences}/>
              <Route
                path={ROUTE_ACCESS_DENIED}
                component={AccessDeniedComponent} />
              <Redirect from={'*'} to={ROUTE_PATH_404_PAGE} />
            </Switch>
          </div>
        }
        {
          this.state.authenticated &&
          <Fragment>
            <FooterComponent />
          </Fragment>
        }
        <CookieConsentBanner acceptCallback={this.cookieConsentAccepted} declineCallBack={this.cookieConsentDeclined}/>
      </Router>
    );
  }
}

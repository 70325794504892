import React from 'react';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import UserService from '../../services/User.service';
import queryString from 'query-string';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    ERROR_TYPE,
    PARAMS_DATE_FORMAT
} from '../../constants/Misc.constants';
import {
    ACL_EQUITIES_INDEX_MARKET_STRUCTURES
} from '../../constants/Entitlements.constants';
import {
    INDEX_MARKET_STRUCTURES_QUERY_PARAM_START_DATE,
    INDEX_MARKET_STRUCTURES_QUERY_PARAM_END_DATE,
    INDEX_MARKET_STRUCTURES_QUERY_PARAM_PAGE,
    INDEX_MARKET_STRUCTURES_QUERY_PARAM_DATE_FORMAT,
    INDEX_MARKET_STRUCTURES_PAGE_SIZE
} from '../../constants/IndexMarketStructures.constants';
import FundDetailsComponent from '../fund-details/FundDetails.component';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import UtilsService from '../../services/Utils.service';
import {
    ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE
} from '../../constants/RoutePaths.constants';
import {
    INDEX_MARKET_STRUCTURES_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import IndexMarketStructuresService from '../../services/IndexMarketStructures.service';

class IndexMarketStructuresPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = false;
        this.maxDate = new Date();
        this.state = {
            currentPage: 1,
            startDate: null,
            endDate: null,
            loading: true,
            error: false,
            data: null,
            maxStartDate: new Date(),
            minEndDate: null
        };
        this.applyUrlParams(this.props.location);
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_INDEX_MARKET_STRUCTURES);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.triggerGetData = this.triggerGetData.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }
    componentDidMount() {
        document.title = INDEX_MARKET_STRUCTURES_PAGE_TITLE;
        if (!this._mounted) {
            this._mounted = true;
            this.triggerGetData();
        }
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname.includes(ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE)) {
                if (this._mounted) {
                    this.applyUrlParams(location);
                    this.triggerGetData();
                }
            }
        });
    }
    applyUrlParams(location) {
        let urlQueryParams = queryString.parse(location.search);
        const startDateParam = urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_START_DATE] ? urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_START_DATE] : null; // eslint-disable-line
        const endDateParam = urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_END_DATE] ? urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_END_DATE] : null; // eslint-disable-line
        if (startDateParam) {
            this.state.startDate = moment(startDateParam).toDate(); // eslint-disable-line
            this.state.minEndDate = this.state.startDate; // eslint-disable-line
        }
        if (endDateParam) {
            this.state.endDate = moment(endDateParam).toDate(); // eslint-disable-line
            this.state.maxStartDate = this.state.endDate; // eslint-disable-line
        }
        this.state.currentPage = urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_PAGE] ? parseInt(urlQueryParams[INDEX_MARKET_STRUCTURES_QUERY_PARAM_PAGE]) : 1; // eslint-disable-line
    }
    componetWillUnMount() {
        this._mounted = false;
        this.unlisten();
    }
    handleStartDateChange(date) {
        this.setState(
            {
                startDate: date,
                minEndDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    handleEndDateChange(date) {
        this.setState(
            {
                endDate: date,
                maxStartDate: date,
                currentPage: 1
            }, this.updateUrl
        );
    }
    triggerGetData() {
        this.setState(
            {
                loading: true,
                error: false,
                data: null,
            }, this.getData
        )
    }
    getData() {
        const params = {
            page: this.state.currentPage,
            size: INDEX_MARKET_STRUCTURES_PAGE_SIZE,
        }
        if (this.state.startDate) {
            params.pubDateFrom = moment.utc(this.state.startDate).format(PARAMS_DATE_FORMAT)
        }
        if (this.state.endDate) {
            params.pubDateTo = moment.utc(this.state.endDate).format(PARAMS_DATE_FORMAT)
        }
        IndexMarketStructuresService.getIMSData(params).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState({
            data: data,
            loading: false,
            error: false
        })
    }
    getDataError(error) {
        this.setState( {
            loading: false,
            data: null,
            error: true
        })
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            }, this.updateUrl
        );
    }
    updateUrl() {
        var params = {};
        params[INDEX_MARKET_STRUCTURES_QUERY_PARAM_START_DATE] = this.state.startDate ? moment(this.state.startDate).format(INDEX_MARKET_STRUCTURES_QUERY_PARAM_DATE_FORMAT) : null;
        params[INDEX_MARKET_STRUCTURES_QUERY_PARAM_END_DATE] = this.state.endDate ? moment(this.state.endDate).format(INDEX_MARKET_STRUCTURES_QUERY_PARAM_DATE_FORMAT) : null;
        if (this.state.currentPage > 1) {
            params[INDEX_MARKET_STRUCTURES_QUERY_PARAM_PAGE] = this.state.currentPage;
        }
        this.props.history.push(ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE + UtilsService.buildURLParams(params));
    }
    accessDenied() {
        return !this.hasAccess && <AclAccessDeniedComponent />;
    }
    loading() {
        return this.state.loading && <SpinnerComponent />
    }
    error() {
        return this.state.error && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }
    render() {
        return (
            <React.Fragment>
                { this.accessDenied()}
                {
                    this.hasAccess &&
                    <section className="index-market-structures">
                        <h1>Index Market Structures</h1>
                        {this.loading()}
                        {this.error()}
                        {this.state.data &&
                            <FundDetailsComponent
                                handleStartDateChange={this.handleStartDateChange}
                                handleEndDateChange={this.handleEndDateChange}
                                data={this.state.data}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                maxStartDate={this.state.maxStartDate}
                                maxDate={this.maxDate}
                                minEndDate={this.state.minEndDate}
                                currentPage={this.state.currentPage}
                                size={INDEX_MARKET_STRUCTURES_PAGE_SIZE}
                                goToPage={this.goToPage}
                            />
                        }
                    </section>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(IndexMarketStructuresPageComponent);

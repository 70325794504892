import React from 'react';
import { Link } from "react-router-dom";
import ModalComponent from "../misc/modal/Modal.component";
import RequestMeetingComponent from "../request-meeting/RequestMeeting.component";
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../misc/mailto/Mailto.component';
import "react-datepicker/dist/react-datepicker.css";
import SidebarService from '../../services/Sidebar.service';
import UtilsService from '../../services/Utils.service';
import CalendarService from '../../services/Calendar.service';
import UserService from '../../services/User.service';
import {
    LINK_PUBLICATION_PAGE,
    LINK_PATH_ANALYST_PROFILE_PAGE,
    LINK_CALENDER,
    LINK_PATH_SALES_LITERATURE_PAGE,
    LINK_PATH_DISEMMINATION_POLICY_PAGE,
    LINK_EQUITIES_RESEARCH_ANALYST_PAGE,
    LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE,
    LINK_EQUITIES_RESEARCH_COMPANY_PAGE,
    LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE
} from '../../constants/LinkPaths.constants';

import {
    TDS_DAY_DATE_FORMAT,
    FORWARD_SLASH,
    EMAIL_DELIMITER,
    FIRM_NAME_STRATEGY,
    FIRM_NAME_RESEARCH,
    COWEN_COVERAGE_UNIVERSE, COWEN_DISCLOSURE_LINK, FIRM_NAME_COWEN
} from '../../constants/Misc.constants';
import {
    ACL_SIDEBAR_BOOK_MEETING,
    ACL_SIDEBAR_TRENDING_NOW,
    ACL_SIDEBAR_QUICK_LINKS_SALES_LITERATURE_LINK,
    ACL_SIDEBAR_QUICK_LINKS_DISSEMINATION_POLICY_LINK
} from '../../constants/Entitlements.constants';
import { TDS_CALENDAR_EVENT_TYPE } from '../../constants/Calendar.constants';
import { buildSecureLink } from '../../services/SecureLink.service';
import { isCowen, isCowenEnabled, isNotEmptyArray } from '../../utils/valueUtils';
import CowenFlagContext from "../../system/CowenFlagContext";

export default class SideBarComponent extends React.Component {

    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.requestMeetingModal = React.createRef();

        this.state = {
            upcomingEventData: null,
            trendingNowData: null,
            recentlyViewedData: null,
            loading: true,
            error: false,
            cowenEnabled: false,

        };
        this.hasAccessRequestMeeting = UserService.hasAccess(ACL_SIDEBAR_BOOK_MEETING) && !this.props.eqOnly;
        this.hasAccessTrendingNow = UserService.hasAccess(ACL_SIDEBAR_TRENDING_NOW) && !this.props.eqOnly;
        this.hasAccessSalesLiterature = UserService.hasAccess(ACL_SIDEBAR_QUICK_LINKS_SALES_LITERATURE_LINK);
        this.hasAccessDisseminationPolicy = UserService.hasAccess(ACL_SIDEBAR_QUICK_LINKS_DISSEMINATION_POLICY_LINK);

        this.getUpcomingEvent();

        if (this.hasAccessTrendingNow) {
            this.getTrendingNow();
        }
        this.getRecentlyViewed();
        this.showMeeting = this.showMeeting.bind(this);
        this.hideMeeting = this.hideMeeting.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
    }
    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(e);
        });
    }
    showMeeting(e) {
        if (e) {
            e.preventDefault();
        }
        this.requestMeetingModal.current.showModal();
    }
    hideMeeting() {
        this.requestMeetingModal.current.hideModal();
    }
    getUpcomingEvent() {
        SidebarService.getUpcomingEventData().then((_upcomingEventData) => {
            this.setState({
                upcomingEventData: this.filterEvents(_upcomingEventData),
                loading: false
            });
        }).catch((error) => {
            console.log("event error")
            this.setState({
                upcomingEventData: null,
                loading: false,
                error: true
            });
        });
    }
    filterEvents(upcomingEventData) {
        if (UserService.isBoth() && !this.props.eqOnly) {
            return upcomingEventData;
        }
        let filterFirmName = '';
        if (UserService.isFicmOnly()) {
            filterFirmName = FIRM_NAME_STRATEGY;
        }
        if (UserService.isEQOnly() || this.props.eqOnly) {
            filterFirmName = FIRM_NAME_COWEN;
        }
        upcomingEventData.results = upcomingEventData.results.filter(event => event.firmName === filterFirmName);
        return upcomingEventData
    }
    getTrendingNow() {
        SidebarService.getTrendingNowData().then((_trendingNowData) => {
            this.setState({
                trendingNowData: _trendingNowData,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                trendingNowData: null,
                loading: false,
                error: true
            });
        });
    }
    getRecentlyViewed() {
        SidebarService.getRecentlyViewedData(this.props.eqOnly).then((_recentlyViewedData) => {
            this.setState({
                recentlyViewedData: _recentlyViewedData,
                loading: false
            });
        }).catch((error) => {
            this.setState({
                recentlyViewedData: null,
                loading: false,
                error: true
            });
        });

    }
    handleEventClick = (index) => {
        CalendarService.downloadEvent(this.state.upcomingEventData.results[index]);
    }
    buildShareLink = () => {
        return UtilsService.buildShareLink(this.props.publicationContent.firmName, this.props.publicationContent.contentId)
    }
    isNotBanned(id, firmName) {
        if (firmName === FIRM_NAME_STRATEGY) {
            if (id === "26" || id === "27") {
                return false;
            }
        }
        return true;
    }
    showItem(firmName) {
        if (firmName === FIRM_NAME_STRATEGY && (UserService.isFicm() && !this.props.eqOnly)) {
            return true;
        } else if (firmName === FIRM_NAME_RESEARCH && (UserService.isEQ() || this.props.eqOnly)) {
            return true;
        }
        return false;
    }
    isEqUser() {
        return UserService.isEQ();
    }
    isResearchContent(firmName) {
        return firmName === FIRM_NAME_RESEARCH;
    }
    isStrategyContent(firmName) {
        return firmName === FIRM_NAME_STRATEGY;
    }

    render() {
        const cowenFlag = this.context;
        return (
            <React.Fragment>
                {
                    this.props.publicationPage && this.props.publicationContent &&
                    <React.Fragment>
                        <div className="tds-sidebar-share-container">
                            <MailtoComponent
                                email={''}
                                titleText={'Email'}
                                icon={true}
                                className={'email-icon-share'}
                                subject={this.props.publicationContent.title}
                                body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + this.buildShareLink()}
                            />
                            <MailtoComponent
                                email={''}
                                titleText={'Email'}
                                icon={false}
                                text={'Email'}
                                className={'email-text-share'}
                                subject={this.props.publicationContent.title}
                                body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + this.buildShareLink()}
                            />
                            {this.isNotBanned(this.props.publicationContent.bluematrixXmlID, this.props.publicationContent.firmName) &&
                                <React.Fragment>
                                    <a
                                        className={'pdf-icon-share'}
                                        href={buildSecureLink(this.props.publicationContent.securePdfLink)}
                                        target="_blank"
                                        title="View PDF"
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className="td-icon td-icon-insights icon-small"
                                            data-firmid={this.props.publicationContent.firmId}
                                            data-productid={this.props.publicationContent.productId}
                                            data-filename={this.props.publicationContent.title}
                                        ></span>
                                    </a>
                                    <a
                                        href={buildSecureLink(this.props.publicationContent.securePdfLink)}
                                        target="_blank"
                                        title="View PDF"
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className="pdf-text-share"
                                            data-firmid={this.props.publicationContent.firmId}
                                            data-productid={this.props.publicationContent.productId}
                                            data-filename={this.props.publicationContent.title}
                                        >
                                            PDF
                                        </span>
                                    </a>
                                </React.Fragment>
                            }
                        </div>
                    </React.Fragment>
                }
                <div className="tds-sidebar-container">
                    {
                        this.props.publicationPage && this.props.publicationContent &&
                        <div className="tds-sidebar-analyst-container">
                            {
                                this.props.publicationContent.primaryAnalyst &&
                                <div className="tds-sidebar-analyst-item">
                                    <Link
                                        onClick={this.props.publicationContent.primaryAnalyst.active ? () => { } : (e) => { e.preventDefault() }}
                                        to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.props.publicationContent.firmName +
                                            FORWARD_SLASH + this.props.publicationContent.primaryAnalyst.id}
                                        className={this.props.publicationContent.primaryAnalyst.active ? "tds-sidebar-analyst-link" : "tds-sidebar-analyst-link--disabled"}
                                        title={this.props.publicationContent.primaryAnalyst.firstName + " " + this.props.publicationContent.primaryAnalyst.lastName}
                                    >
                                        <img src={(this.props.publicationContent.primaryAnalyst.active && (this.props.publicationContent.primaryAnalyst.image || "/assets/img/Placeholder_Silhouette.jpg")) || "/assets/img/Placeholder_Silhouette.jpg"} alt={this.props.publicationContent.primaryAnalyst.firstName + " " + this.props.publicationContent.primaryAnalyst.lastName} />
                                        <span className="tds-sidebar-analyst-info">
                                            <span className="tds-sidebar-analyst-name">
                                                {this.props.publicationContent.primaryAnalyst.firstName} {this.props.publicationContent.primaryAnalyst.lastName}
                                            </span>
                                            <span className="tds-sidebar-analyst-title">
                                                {this.props.publicationContent.primaryAnalyst.title}
                                            </span>
                                        </span>
                                    </Link>
                                </div>
                            }
                            {
                                isNotEmptyArray(this.props.publicationContent.analysts) && this.props.publicationContent.analysts.map((analystItem, index) => (
                                    <div className="tds-sidebar-analyst-item">
                                        <Link
                                            onClick={analystItem.active ? () => { } : (e) => { e.preventDefault() }}
                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.props.publicationContent.firmName +
                                                FORWARD_SLASH + analystItem.id}
                                            className={analystItem.active ? "tds-sidebar-analyst-link" : "tds-sidebar-analyst-link--disabled"}
                                            title={analystItem.firstName + " " + analystItem.lastName}
                                        >
                                            <img src={(analystItem.active && (analystItem.image || "/assets/img/Placeholder_Silhouette.jpg")) || "/assets/img/Placeholder_Silhouette.jpg"} alt={analystItem.firstName + " " + analystItem.lastName} />
                                            <span className="tds-sidebar-analyst-info">
                                                <span className="tds-sidebar-analyst-name">
                                                    {analystItem.firstName} {analystItem.lastName}
                                                </span>
                                                <span className="tds-sidebar-analyst-title">
                                                    {analystItem.title}
                                                </span>
                                            </span>
                                        </Link>
                                    </div>
                                ))
                            }
                            <hr className="tds-sidebar-top-divider" />
                        </div>
                    }
                    {
                        !this.props.hideBookMeeting && this.hasAccessRequestMeeting &&
                        <React.Fragment>
                            <span className="td-icon td-icon-bookAppointment icon-medium"></span>
                            <h3 className="tds-sidebar-meet">Meet with a Strategist.</h3>
                            <span className="tds-sidebar-apointment">Book an appointment</span>
                            <button
                                className="tds-sidebar-book-now btn td-btn-primary-light"
                                title="Book Now"
                                onClick={this.showMeeting}>Book now</button>
                            <hr className="tds-sidebar-top-divider" />
                        </React.Fragment>
                    }
                    <h3 className="tds-sidebar-upcoming-events-title">Upcoming Events</h3>
                    <Link to={LINK_CALENDER} className="tds-view-full-calendar-cta" title="View Full calendar">
                        <span>View Full calendar</span>
                        <span className="td-icon td-icon-arrowRight icon-small"></span>
                    </Link>
                    {
                        this.state.upcomingEventData && this.state.upcomingEventData.results && this.state.upcomingEventData.results.length > 0 &&
                        <React.Fragment>
                            <ul className="tds-sidebar-calendar-list">
                                {this.state.upcomingEventData &&
                                    this.state.upcomingEventData.results.slice(0, 3).map((upcomingEventItem, index) => (
                                        <React.Fragment key={"tds-sidebar-calendar-list-item-" + index}>
                                            {
                                                upcomingEventItem.eventType &&
                                                <li>
                                                    {upcomingEventItem.eventType === TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE &&
                                                        <span className="tds-sidebar-calendar-list-title">{upcomingEventItem.company?.name} - {upcomingEventItem.eventType}</span>
                                                    }
                                                    {upcomingEventItem.eventType !== TDS_CALENDAR_EVENT_TYPE.EARNINGS_RELEASE &&
                                                        <span className="tds-sidebar-calendar-list-title">{upcomingEventItem.description || upcomingEventItem.eventType}</span>
                                                    }
                                                    <span className="tds-sidebar-calendar-list-date">
                                                        <FormatedDateComponent
                                                            date={upcomingEventItem.startDate}
                                                            format={TDS_DAY_DATE_FORMAT}
                                                        />
                                                    </span>
                                                    <span
                                                        className="tds-sidebar-calendar-list-cta"
                                                        onClick={this.handleEventClick.bind(this, index)}
                                                        title="Add to Calendar"
                                                    >
                                                        <span
                                                            className="td-icon td-icon-bookAppointment icon-small">
                                                        </span>
                                                        <span className="tds-sidebar-calendar-list-cta-text">Add to Calendar</span>
                                                        <span className="td-icon td-icon-arrowRight icon-small"></span>
                                                    </span>
                                                </li>
                                            }
                                        </React.Fragment>
                                    ))}
                            </ul>
                            <hr className="tds-sidebar-top-divider" />
                        </React.Fragment>
                    }
                    <div className="tds-quick-links-container">
                        <h3>Quick Links</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.tdsecurities.com/ca/en/home-page"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="TD Securities"
                                >TD Securities</a>
                            </li>
                            <li>
                                <a
                                    href="https://economics.td.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="TD Economics"
                                >TD Economics</a>
                            </li>
                            {this.hasAccessSalesLiterature &&
                                <li>
                                    <Link
                                        to={LINK_PATH_SALES_LITERATURE_PAGE}
                                        title="Sales Literature"
                                    >
                                        Sales Literature
                                    </Link>
                                </li>
                            }
                            {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                this.hasAccessDisseminationPolicy &&
                                <li>
                                    <Link
                                        to={LINK_PATH_DISEMMINATION_POLICY_PAGE}
                                        title="Dissemination Policy"
                                    >
                                        Dissemination Policy
                                    </Link>
                                </li>
                            }
                            {this.isEqUser() &&
                                <React.Fragment>
                                    <li>
                                        <Link
                                            to={{ pathname: COWEN_DISCLOSURE_LINK }}
                                            target="_blank"
                                            title="Important Disclosures"
                                        >
                                            Important Disclosures
                                        </Link>
                                    </li>

                                    <li>
                                        <Link className="link" to={LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE}>
                                            <span className="tds-filter-text">Coverage Universe - Advanced Filter</span>
                                        </Link>
                                    </li>

                                    {isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                        <li>
                                            <a
                                                href={buildSecureLink(COWEN_COVERAGE_UNIVERSE)}
                                                target="_parent"
                                                rel="noopener noreferrer"
                                                title="Download Excel - Coverage Universe"
                                            >
                                                Download Excel - Coverage Universe
                                            </a>
                                        </li>
                                    }
                                    {!isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                        <>
                                            <li>
                                                <Link
                                                    to={LINK_EQUITIES_RESEARCH_ANALYST_PAGE}
                                                    title="Analysts"
                                                >
                                                    Analysts
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={LINK_EQUITIES_RESEARCH_COMPANY_PAGE}
                                                    title="Companies"
                                                >
                                                    Companies
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE}
                                                    title="Industry"
                                                >
                                                    Industry
                                                </Link>
                                            </li>
                                        </>
                                    }
                                </React.Fragment>
                            }
                        </ul>
                    </div>
                    {this.hasAccessTrendingNow &&
                        <React.Fragment>
                            <hr className="tds-sidebar-top-divider" />
                            <div className="tds-trending-now-container">
                                <h3>Trending Now</h3>
                                <ul>
                                    {this.state.trendingNowData &&
                                        this.state.trendingNowData.results.map((trendingNowItem, index) => (
                                            <React.Fragment key={index}>
                                                {
                                                    this.showItem(trendingNowItem.firmName) &&
                                                    <li key={index}>
                                                        <Link
                                                            to={LINK_PUBLICATION_PAGE + trendingNowItem.firmName + FORWARD_SLASH + trendingNowItem.contentId}
                                                            title={trendingNowItem.title}
                                                        >
                                                            {trendingNowItem.title}
                                                        </Link>
                                                    </li>
                                                }
                                            </React.Fragment>
                                        ))}
                                </ul>
                            </div>
                        </React.Fragment>
                    }
                    <hr className="tds-sidebar-top-divider" />
                    <div className="tds-recently-viewed-container">
                        <h3>Recently Viewed</h3>
                        <ul>
                            {this.state.recentlyViewedData &&
                                this.state.recentlyViewedData.results.map((recentlyViewedItem, index) => (
                                    this.showItem(recentlyViewedItem.firmName) &&
                                    <React.Fragment key={index}>
                                        {this.isStrategyContent(recentlyViewedItem.firmName) &&
                                            <li key={index}>
                                                <Link
                                                    to={LINK_PUBLICATION_PAGE + recentlyViewedItem?.firmName + FORWARD_SLASH + recentlyViewedItem?.contentId}
                                                    title={recentlyViewedItem?.title}
                                                >
                                                    {recentlyViewedItem?.title}
                                                </Link>
                                            </li>
                                        }
                                        {this.isResearchContent(recentlyViewedItem.firmName) &&
                                            <li key={index}>
                                                <a href={buildSecureLink(recentlyViewedItem?.pdfLink)} target="_blank" rel="noreferrer" title={recentlyViewedItem?.title}>{recentlyViewedItem?.title}</a>
                                            </li>
                                        }
                                    </React.Fragment>
                                ))}
                        </ul>
                    </div>
                </div>
                <ModalComponent
                    ref={this.requestMeetingModal}
                    id={'requestMeetingModal'}
                    className={'tds-request-meeting'}>
                    <RequestMeetingComponent hideMeeting={this.hideMeeting} />
                </ModalComponent>
            </React.Fragment>
        )
    }
}

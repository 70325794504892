import React from "react";
import { withRouter } from "react-router-dom";
import MainCardsComponent from "../equity-overview/main-cards/MainCards.component";
import SpotlightComponent from "../equity-overview/spotlight/Spotlight.component";
import TailoredForYouComponent from "../equity-overview/tailored-for-you/TailoredForYou.component";
import EqOverviewTabsComponent from "../equity-overview/eq-overview-page-tabs/EqOverviewTabs.component";
import ShowContentComponent from '../../components/misc/show-content/ShowContent.component';
import ErrorComponent from "../misc/error/Error.component";
import {
  EQUITY_OVERVIEW_PAGE_TITLE,
  HOME_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import {
  AND,
  EQUALS,
  ERROR_TYPE, OVERVIEW_PAGE, QUESTION_MARK, URL_QUERY_PARAMS_TAB
} from '../../constants/Misc.constants';
import SideBarComponent from "../side-bar/SideBar.component";
import Spinner from "../spinner/Spinner.component";
import EquityOverviewService from "../../services/v2/EquityOverviewPage.service";
import UtilsService from "../../services/Utils.service";
import UserService from "../../services/User.service";
import queryString from 'query-string';
import { ROUTE_HOME } from "../../constants/RoutePaths.constants";
import { 
  HOME_PAGE_MOST_POPULAR_TAB,
  HOME_PAGE_MOST_RECENT_TAB,
  HOME_PAGE_REAL_TIME_COVERAGE_TAB,
  HOME_PAGE_IN_DEPTHS_TAB,
  HOME_PAGE_MOST_RECENT_PAGE,
  HOME_PAGE_MOST_POPULAR_PAGE,
  HOME_PAGE_REAL_TIME_COVERAGE_PAGE,
  HOME_PAGE_IN_DEPTHS_PAGE
 } from "../../constants/HomePage.constants";
import { RTC_DOC_TYPE_URL_PARAM, RTC_REGION_URL_PARAM } from "../../constants/RealTimeCoverage.constants";
import { getRoutePath } from "../../utils/valueUtils";
import AclAccessDeniedComponent from "../misc/acl-access-denied/AclAccessDenied.component";
import { ACL_EQUITIES_RESEARCH } from "../../constants/Entitlements.constants";

class EqOverviewPageComponent extends React.Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      equityOverviewPageData: null,
      equityOverviewPageDataLoading: true,
      equityOverviewPageDataError: false,
      tailoredForYouData: null,
      tailoredForYouDataLoading: true,
      tailoredForYouDataError: false,
      tab: null,
      mostRecentPage: 1,
      mostPopularPage: 1,
      realTimeCoveragePage: 1,
      inDepthsPage: 1,
    };
    let urlQueryParams = queryString.parse(this.props.location.search);
    this.hasAccess = UserService.hasAccess(ACL_EQUITIES_RESEARCH);
    if (urlQueryParams.mostRecentPage) {
      this.state.mostRecentPage = urlQueryParams.mostRecentPage;
    } 
    if (urlQueryParams.mostPopularPage) {
      this.state.mostPopularPage = urlQueryParams.mostPopularPage;
    }
    if (urlQueryParams.realTimeCoveragePage) {
      this.state.realTimeCoveragePage = urlQueryParams.realTimeCoveragePage;
    }
    if (urlQueryParams.inDepthsPage) {
      this.state.inDepthsPage = urlQueryParams.inDepthsPage;
    }
    if (urlQueryParams.rtcDocType) {
      this.state.rtcDocType = urlQueryParams.rtcDocType;
    }
    if (urlQueryParams.rtcRegion) {
      this.state.rtcRegion = UtilsService.getHomePageRegionByValue(urlQueryParams.rtcRegion);
    }
    this.state.tab = HOME_PAGE_MOST_POPULAR_TAB;
    if (UserService.isEQOnly() || this.props.eqOnly) {
      this.state.tab = HOME_PAGE_MOST_RECENT_TAB;
    }
    if (urlQueryParams.tab) {
      this.state.tab = urlQueryParams.tab;
    }
    this.getEquityOverviewPageData = this.getEquityOverviewPageData.bind(this);
    this.getEquityOverviewPageDataThen = this.getEquityOverviewPageDataThen.bind(this);
    this.getEquityOverviewPageDataError = this.getEquityOverviewPageDataError.bind(this);
    this.getTailoredForYouData = this.getTailoredForYouData.bind(this);
    this.getTailoredForYouDataThen = this.getTailoredForYouDataThen.bind(this);
    this.getTailoredForYouDataError = this.getTailoredForYouDataError.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.rtcDocTypeChange = this.rtcDocTypeChange.bind(this);
    this.rtcRegionChange = this.rtcRegionChange.bind(this);
  }
  componentDidMount() {
    if(this.props.eqOnly) {
      document.title = EQUITY_OVERVIEW_PAGE_TITLE +" - "+ this.props.team?.toUpperCase();
    }
    else {
      document.title = HOME_PAGE_TITLE;
    }
    this._mounted = true;
    this.getEquityOverviewPageData();
    this.getTailoredForYouData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.team !== this.props.team) {
      this.getEquityOverviewPageData();
      this.getTailoredForYouData();
    }
  }
  getEquityOverviewPageData() {
    EquityOverviewService.getEquityOverviewPageData(this.props.eqOnly, this.props.team)
      .then(this.getEquityOverviewPageDataThen)
      .catch(this.getEquityOverviewPageDataError);
  }
  getEquityOverviewPageDataThen(equityOverviewPageData) {
    var _equityOverviewPageData = equityOverviewPageData;
    if (_equityOverviewPageData.mainCards.middleCards) {
      _equityOverviewPageData.mainCards.middleCards = UtilsService.spliceFirst(_equityOverviewPageData.mainCards.middleCards, 2);
    }
    if (_equityOverviewPageData.spotlightItems) {
      _equityOverviewPageData.spotlightItems = UtilsService.spliceFirst(_equityOverviewPageData.spotlightItems, 5);
    }
    this.setState({
      equityOverviewPageData: _equityOverviewPageData,
      equityOverviewPageDataLoading: false,
    });
  }
  getEquityOverviewPageDataError(error) {
    this.setState({
      equityOverviewPageDataLoading: false,
      equityOverviewPageDataError: true,
    });
  }
  getTailoredForYouData() {
    EquityOverviewService.getTailoredForYouData(this.props.eqOnly, this.props.team)
      .then(this.getTailoredForYouDataThen)
      .catch(this.getTailoredForYouDataError);
  }
  getTailoredForYouDataThen(tailoredForYouData) {
    var _tailoredForYouData = tailoredForYouData;
    if (_tailoredForYouData.tailoredForYouItems) {
      _tailoredForYouData.tailoredForYouItems = UtilsService.spliceFirst(
        _tailoredForYouData.tailoredForYouItems, 6);
    }
    this.setState({
      tailoredForYouData: _tailoredForYouData,
      tailoredForYouDataLoading: false,
      tailoredForYouDataError: false
    });
    
  }
  getTailoredForYouDataError(error) {
    this.setState({
      tailoredForYouDataLoading: false,
      tailoredForYouDataError: true,
    });
  }
  pageChange(tab, page){
    let tabs = [HOME_PAGE_MOST_POPULAR_TAB, HOME_PAGE_MOST_RECENT_TAB, HOME_PAGE_REAL_TIME_COVERAGE_TAB, HOME_PAGE_IN_DEPTHS_TAB]
    let urls = [HOME_PAGE_MOST_POPULAR_PAGE, HOME_PAGE_MOST_RECENT_PAGE, HOME_PAGE_REAL_TIME_COVERAGE_PAGE, HOME_PAGE_IN_DEPTHS_PAGE];
    var stateUpdate = {};
    for (let i=0; i < tabs.length; i++) {
      if (tabs[i] === tab) {
        stateUpdate[urls[i]] = page;
        break;
      }
    }
    this.setState( stateUpdate , this.updateUrl)
  }
  tabChange(tab) {
    this.setState( { tab: tab }, this.updateUrl)
  }
  rtcDocTypeChange(value){
    this.setState( { rtcDocType: value }, this.updateUrl)
  }
  rtcRegionChange(value){
    this.setState( { rtcRegion: value }, this.updateUrl)
  }
  updateUrl() {
    var url = this.props.eqOnly ? getRoutePath(this.props.team, OVERVIEW_PAGE) : ROUTE_HOME;
    var urlParamsAdded = false;
    var defaultTab = HOME_PAGE_MOST_POPULAR_TAB;
    if (UserService.isEQOnly() || this.props.eqOnly) {
      defaultTab = HOME_PAGE_MOST_RECENT_TAB;
    }
    if (this.state.tab && (this.state.tab !== defaultTab))  {
      url += QUESTION_MARK + URL_QUERY_PARAMS_TAB + EQUALS + this.state.tab;
      urlParamsAdded = true;
    }
    let pages = [this.state.mostPopularPage, this.state.mostRecentPage, this.state.realTimeCoveragePage, this.state.inDepthsPage];
    let urls = [HOME_PAGE_MOST_POPULAR_PAGE, HOME_PAGE_MOST_RECENT_PAGE, HOME_PAGE_REAL_TIME_COVERAGE_PAGE, HOME_PAGE_IN_DEPTHS_PAGE];
    for (let i = 0; i < 4; i++) {
      if (pages[i] > 1) {
        if (urlParamsAdded) {
          url += AND;
        } else {
          url += QUESTION_MARK;
        }
        url += urls[i] + EQUALS + pages[i]
        urlParamsAdded = true;
      }
    }
    if (this.state.rtcDocType && (this.state.rtcDocType !== ''))  {
      if (urlParamsAdded) {
        url += AND;
      } else {
        url += QUESTION_MARK;
      }
      url += RTC_DOC_TYPE_URL_PARAM + EQUALS + this.state.rtcDocType;
      urlParamsAdded = true;
    }
    if (this.state.rtcRegion && (this.state.rtcRegion.value !== ''))  {
      if (urlParamsAdded) {
        url += AND;
      } else {
        url += QUESTION_MARK;
      }
      url += RTC_REGION_URL_PARAM + EQUALS + this.state.rtcRegion.value;
      urlParamsAdded = true;
    }
    this.props.history.replace(url);
  }
  render() {
    return (
      <React.Fragment>
        {!this.hasAccess && <AclAccessDeniedComponent />}
        {
          this.hasAccess && 
          <div className="tds-homepage-container">
            <ShowContentComponent
              if={this.state.equityOverviewPageDataLoading}
            >
              <div className="tds-three-column-title-container">
                <Spinner minHeight={100} />
              </div>
            </ShowContentComponent>
            <ShowContentComponent
              if={!this.state.equityOverviewPageDataLoading && this.state.equityOverviewPageDataError}
            >
              <div className="tds-three-column-title-container error-top-cards">
                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
              </div>
            </ShowContentComponent>

            {!this.state.equityOverviewPageDataLoading && !this.state.equityOverviewPageDataError &&
              <React.Fragment>
                <MainCardsComponent
                  mainCardData={this.state.equityOverviewPageData.mainCards}
                />
                <SpotlightComponent
                  spotlightData={this.state.equityOverviewPageData.spotlightItems}
                />
                <div className="tds-divider-space-10">&nbsp;</div>
              </React.Fragment>
            }
            <div className="tds-three-column-container">
              <ShowContentComponent
                if={this.state.tailoredForYouDataLoading}
              >
                { (UserService.isFicm() && !this.props.eqOnly) &&
                  <div className="tds-three-column-title-container">
                    <h2>Tailored For You</h2>
                  </div>
                }
                <Spinner marginTop={'20px'} noContainer={true} />
              </ShowContentComponent>
              <ShowContentComponent
                if={!this.state.tailoredForYouDataLoading && this.state.tailoredForYouDataError}
              >
                { (UserService.isFicm() && !this.props.eqOnly) &&
                  <div className="tds-three-column-title-container">
                    <h2>Tailored For You</h2>
                  </div>
                }
                <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
              </ShowContentComponent>
              {(!this.state.tailoredForYouDataLoading && !this.state.tailoredForYouDataError) &&
                <React.Fragment>
                  <TailoredForYouComponent
                    eqOnly={this.props.eqOnly}
                    tailoredForYouData={this.state.tailoredForYouData.tailoredForYouItems}
                    team={this.props.team}
                  />
                </React.Fragment>
              }
              <EqOverviewTabsComponent
                eqOnly={this.props.eqOnly}
                initialMostRecentPage={this.state.mostRecentPage}
                initialMostPopularPage={this.state.mostPopularPage}
                initialRealTimeCoveragePage={this.state.realTimeCoveragePage}
                initialInDepthsPage={this.state.inDepthsPage}
                initialTab={this.state.tab}
                initialRtcDocType={this.state.rtcDocType}
                initialRtcRegion={this.state.rtcRegion}
                pageChange={this.pageChange}
                tabChange={this.tabChange}
                rtcDocTypeChange={this.rtcDocTypeChange}
                rtcRegionChange={this.rtcRegionChange}
                team={this.props.team}
              />
            </div>
            <SideBarComponent eqOnly={this.props.eqOnly} team={this.props.team}/>
          </div>
        }
        </React.Fragment>
    );
  }
}
export default withRouter(EqOverviewPageComponent);